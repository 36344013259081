import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Button from '@material-ui/core/Button';
import SidebarWrapper from '../../layout/SidebarWrapper';
import { setActiveOuid } from '../../../actions/action-permissions';
import { getAllPostCategoriesByType } from '../../../actions/action-postcategory';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../common/Loader';
import CommonSnackBar from '../../common/CommonSnackBar';
import { Table, TableBody, TableCell, Typography,TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import CommonDelete from '../../common/CommonDelete';
// import { delete_post_category } from '../../../actions/action-categoryview';



const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  editButton : {
    margin: theme.spacing(1), 
  },
});

class GovtSchemeCategoryDash extends Component {
  state = {
    categories: [],
    loading: true,
    error: null,
  };

  componentDidMount() {
    this.fetchCategories();
  }

  async fetchCategories() {
    const { activeOuid, activeOuidChain } = this.props.permission;
    const { getAllPostCategoriesByType } = this.props;
    const postType = 'govt-scheme';

    try {
        const response = await getAllPostCategoriesByType(postType, activeOuid, activeOuidChain);
        console.log("response",response)

      
        const { items } = response;

        if (Array.isArray(items)) {
            this.setState({ categories: items.map(item => item.data), loading: false });
        } else {
            
            throw new Error('Fetched data is not in the expected structure');
        }
    } catch (error) {
        
        this.setState({ error, loading: false });
    }
}


  renderAddButton() {
    const { activeOuid, activeOuidType } = this.props.permission;

    if (activeOuidType === 'ROOT') {
      return (
        <Button
          onClick={() => {
            this.props.push(`/${activeOuid}/create-post-category`);
          }}
          variant="contained"
          color="primary"
          className={this.props.classes.button}
        >
          Add Post Category
        </Button>
      );
    } else {
      return null;
    }
  }

  renderCategories() {
    const { categories, loading, error } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <div>Error loading categories: {error.message}</div>;
    }

    if (categories.length === 0) {
      return <div>No categories found</div>;
    }

    return (
      <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell> <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
              Category Name
            </Typography></TableCell>
            <TableCell>
                <Typography variant="h6" component="div" style={{
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'end',
  }}>
                  Actions
                </Typography>
              </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {categories.map((category) => (
          <TableRow key={category.identifier
          }>
            <TableCell component="th" scope="row">
              {category.name}
            </TableCell>
            <TableCell className='EditButtonsWrapper' style={{ display: 'flex', justifyContent: 'end' }}>
            <Button className="editButton"
                  variant="contained"
                  color="primary"
                  onClick={() => this.props.push(`/${this.props.permission.activeOuid}/edit-govtscheme/${category.identifier
}`)}
                >
                  Edit
                </Button>
               
                {/* <CommonDelete className="CategoryDelete"
                  id={category.identifier}
                  type="GovtSchemeCategory"
                  rerender={() => this.fetchCategories()} // Optional: To refresh the list after deletion
                  style={{ display: 'none' }}
                /> */}
                </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
    );
  }

  render() {
    const { permissions } = this.props;

    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div className="container">
            <div className="listHeader">
              <h1 className="postHeading">Government Scheme Categories</h1>
              <div>{this.renderAddButton()}</div>
            </div>
            <div className='categoryList'>
              {this.renderCategories()}
            </div>
          </div>
        </section>
        <CommonSnackBar />
      </div>
    );
  }
}


const mapStateToProps = state => ({
  permission: state.permission,
});

const mapDispatchToProps = dispatch => ({
  setActiveOuid: activeOuid => dispatch(setActiveOuid(activeOuid)),
  getAllPostCategoriesByType: (postType, activeOuid, activeOuidChain) => dispatch(getAllPostCategoriesByType(postType, activeOuid, activeOuidChain)),
  push: path => dispatch(push(path)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GovtSchemeCategoryDash));
