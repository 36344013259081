import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Orgunit } from '@material-ui/icons';
import { history } from '../../reducers';
import Button from '@material-ui/core/Button';
import { RedirectFunc } from "./CommonFunctions";
import { setActiveOiudConfigs } from "../../actions/action-config";
import { setActiveOuid, setActiveOuidChain } from "../../actions/action-permissions";
import configureStore from '../../store/configureStore';
import ScheduleIcon from '@material-ui/icons/Schedule';

const store = configureStore();

const useStyles = makeStyles({
  root: {
    marginRight: 15,
    marginBottom: 15,
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    backgroundColor: '#ffffff',
    boxShadow: 'rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px',
    borderRadius: '3px',
    '&:hover': {
      boxShadow: 'rgb(9 30 66 / 25%) 0px 8px 16px -4px, rgb(9 30 66 / 31%) 0px 0px 1px',
      transition: 'box-shadow 0.2s ease 0s',
    },
  },
  OrchProfileCard: {
    fontSize: 14,
  },
  avatar: {
    backgroundColor: '#dc281e',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  CardActions: {
    padding: 0,
  },
});

export default function PostCard(props) {
  //console.log("props.Posts.data ", props.Post.data);
  //console.log("props.Posts ", props.Post);

  const classes = useStyles();
  const Icontooltip = withStyles({
    tooltip: {
      fontSize: 12,
    },
  })(Tooltip);
  const data = props.Post.data;

  const { activeOuid } = store.getState().permission;

  return (
    <Card className={classes.root}>
      {data && (
        <div>
          <div className="detailValue">Name :{data.title}</div>
          <div className="detailValue">{data.summary}</div>
          <div className="detailValue"><img src={data.featuredImage} alt="" /></div>
          <CardActions disableSpacing className={classes.CardActions}>
            <Icontooltip title="Edit/Delete">
              <IconButton aria-label="edit/delete" onClick={() => { history.push(`/${activeOuid}/post/${data.identifier}`); }}>
                <EditIcon />
              </IconButton>
            </Icontooltip>
          </CardActions>
        </div>
      )}
    </Card>
  );
}
