import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import configureStore from "../../store/configureStore";
import appconfig from "../../config";
import { Drawer } from "@material-ui/core";
import home from "../assets/images/ibcImages/home.svg";
import notification from "../assets/images/ibcImages/notification.svg";
import profile from "../assets/images/ibcImages/profile.svg";
import apps from "../assets/images/ibcImages/apps.svg";
import hrm from "../assets/images/ibcImages/hrm1.svg";
import crm from "../assets/images/ibcImages/crm1.svg";
import ecom from "../assets/images/ibcImages/ecom1.svg";
import dpr from "../assets/images/ibcImages/dpr-commingsoon.svg";
import vc from "../assets/images/ibcImages/accounts1.svg";
import accounts from "../assets/images/ibcImages/vc1.svg";
import ibc from "../assets/images/ibcImages/ibcLogoNew.svg";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "fixed",
    justifyContent: "space-between",
    alignItems: "center",
    bottom: 0,
    left: 0,
    right: 0,
    background: "#fff",
    borderRadius: "70px 10px 0 70px",
    boxShadow: "0px 2.46px 11.18px 0px rgba(0, 0, 0, 0.25)",
    padding: "6px 20px 6px 8px",
    zIndex: "99",
  },
  Icon: {
    position: "relative",
  },
  badge: {
    position: "absolute",
    color: "#fff",
    background: "#fc4f4f",
    height: 15,
    width: 15,
    top: -8,
    right: -5,
    borderRadius: 10,
    textAlign: "center",
  },
}));

export default function DashboardFooter() {
  const classes = useStyles();
  const theme = useTheme();
  const { activeOuid, activeOuidType } = store.getState().permission;
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);
  if (store.getState().jwt.api.credentials.idToken) {
    const { jwtToken } = store.getState().jwt.api.credentials.idToken;
    var hrmAppUrl = appconfig.appui.hrmUrl + "?token=" + jwtToken;
    var ecomAppUrl = appconfig.appui.ecomUrl + "?token=" + jwtToken;
    var crmAppUrl = appconfig.appui.crmUrl + "?token=" + jwtToken;
    var vcAppUrl = appconfig.appui.vcUrl + "?token=" + jwtToken;
    var accountsUrl = appconfig.appui.accountsUrl + "?token=" + jwtToken;
  }

  return (
    <div className={classes.root}>
      {activeOuidType === "biz" && (
        <>
          <div>
            <Link to={`/${activeOuid}/dashboard`}>
              <img src={ibc} />
            </Link>
          </div>
          <div>
            <Link to={`/${activeOuid}/notifications`}>
              <img src={notification} />
            </Link>
          </div>
          <div>
            <Link to={`/${activeOuid}/my-profile`}>
              <img src={profile} />
            </Link>
          </div>

          <div>
            <div className={classes.Icon} onClick={() => setIsDrawerOPen(true)}>
              <img src={apps} />
            </div>
            <Drawer
              className="edit_option_btn_drawer"
              anchor="bottom"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOPen(false)}
            >
              <div className="edit_option_head">Apps</div>
              <ul className="edit_option_tools_wrap">
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <a href={crmAppUrl}>
                    <img src={crm} />
                  </a>
                  <p className="AppLabel">CRM</p>
                </li>
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <a href={hrmAppUrl}>
                    <img src={hrm} />
                  </a>
                  <p className="AppLabel">HRM</p>
                </li>
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <a href={vcAppUrl}>
                    <img src={vc} />
                  </a>
                  <p className="AppLabel">IBC Connect</p>
                </li>

                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <a href={accountsUrl}>
                    <img src={accounts} />
                  </a>
                  <p className="AppLabel">Accounts</p>
                </li>
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <a href={ecomAppUrl}>
                    <img src={ecom} />
                  </a>
                  <p className="AppLabel">Ecom</p>
                </li>
              </ul>
            </Drawer>
          </div>
        </>
      )}
    </div>
  );
}
