import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormButton from '../../common/FormButton';
import FormErrors from '../../FormErrors';
import { create_post_category } from '../../../actions/action-postcategory';
import { history } from '../../../reducers';
import SidebarWrapper from '../../layout/SidebarWrapper';
import configureStore from '../../../store/configureStore';
import CommonSnackBar from '../../common/CommonSnackBar';
import Validate from '../../utility/FormValidation';
import ColorPicker from 'material-ui-color-picker';


const store = configureStore();

class CreatePostCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryDetails: {
        name: '',
        postType:'govt-scheme',
        image: '',  
        color: '', 
      },
      errors: {},
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {},
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.clearErrorState();
    const error = Validate(event, this.state);
    const { categoryDetails } = this.state;
  
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    } else {
      try {
        await this.props.create_post_category(categoryDetails);
        const ouid = window.location.pathname.split('/')[1];
        this.props.history.push(`/${ouid}/govtscheme-category-dash`);
      } catch (error) {
        console.error('Error creating post category:', error);
      }
    }
  };
  

  handleInputChange = (event) => {
    const { id, value } = event.target;
    this.setState((prevState) => ({
      categoryDetails: {
        ...prevState.categoryDetails,
        [id]: value,
      },
    }));
  };

 
  handleColorChange = (color) => {
    this.setState((prevState) => ({
      categoryDetails: {
        ...prevState.categoryDetails,
        color: color,
      },
    }));
  };

  render() {
    const { permissions } = store.getState().permission;
    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div className="container">
            <Paper className="">
              <div className="formContainer">
                <FormErrors formerrors={this.state.errors} />
                <form onSubmit={this.handleSubmit}>
                  <h1 className="formName">Create a Post Category</h1>
                  <div className="form-item-wrapper">
                    <TextField
                      className="input"
                      id="name"
                      label="Category Name"
                      value={this.state.categoryDetails.name}
                      onChange={this.handleInputChange}
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                  <div className="form-item-wrapper">
                    <TextField
                      variant="outlined"
                      label="Category Type"
                      value={this.state.categoryDetails.postType}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </div>
                  <div className="form-item-wrapper">
                    <TextField
                      className="input"
                      id="image"
                      label="Featured Image Url"
                      value={this.state.categoryDetails.image}
                      onChange={this.handleInputChange}
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                  <div className="form-item-wrapper" style={{display:"flex", alignItems:"center"}}>
                    <ColorPicker
                      placeholder={this.state.categoryDetails.color || 'Category Color'}
                      name="color"
                      onChange={this.handleColorChange}
                      value={this.state.categoryDetails.color} 
                    />
                    {this.state.categoryDetails.color && (
                      <div
                        style={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: this.state.categoryDetails.color,
                          border: '1px solid #000',
                        }}
                      />
                    )}
                  </div>
                  <div className="field">
                    <p className="control">
                      <FormButton onClick={this.handleSubmit} text="Add" />
                    </p>
                  </div>
                
                <CommonSnackBar />
                </form>
              </div>
            </Paper>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  create_post_category: (payload) => dispatch(create_post_category(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePostCategory);