import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import configureStore from "../../../store/configureStore";
import { showSnackbar, hideSnackbar } from "../../../actions/action-snackbar";
import { history } from "../../../reducers";
import { Paper } from "@material-ui/core";
import axios from "axios";

const store = configureStore();

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
  },
  formControl: {
    width: "100%",
    background: "#fff",
    marginBottom: 10,
  },
  
});

const indianStates = [
  'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat',
  'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh',
  'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
  'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand',
  'West Bengal'
];

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 750,
    margin: "0 auto",
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
}));

function getSteps() {
  return ["Step 1", "Step 2", "Step 3"];
}

export default function RegisterFormInvestor(props) {
  const { userDetails } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [state, setState] = React.useState({
    businessDetails: { type: "inv" },
  });

  console.log("bussiness", state.businessDetails);

  const [error, setError] = React.useState();

  function validatePhoneNumber(input_str) {
    console.log("input_str", input_str);
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return re.test(input_str);
  }

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validatePhoneNumber(state.businessDetails.phoneNumber)) {
      createWorkOrder(userDetails, state.businessDetails);
    } else {
      setError("Phone number not valid");
    }
  };

  const createWorkOrder = async (userDetails, businessDetails) => {
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    const method = "post";
    const url = `${apiEndpoint}/workorder`;
    const jwt = `${credentials.idToken.jwtToken}`;
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": "ibc",
      },
    };
    let userData = userDetails.data;
    var orgType = "inv";
    if (userDetails.data.usertype === "investor") {
      orgType = "inv";
      businessDetails.type = "inv"; //todo remove the duplication.
    }
    businessDetails.ouid = businessDetails.name.replace(/[^A-Z0-9]+/ig, "-").toLowerCase();

    const woDetails = {
      orgType: orgType,
      registrationDetails: businessDetails,
      userDetails: userData,
    };
    const payload = {
      type: "BusinessRegistrationWorkorder",
      description:
        "Business Registration WorkOrder to create organisation for the business and add org users.",
      requestId: "",
      deadline: "",
      details: woDetails,
    };

    console.log("payload:::::::", JSON.stringify(payload));
    return axios[method](url, payload, axiosHeader)
      .then(async (response) => {
        console.log(
          "Create WorkOrder response : ",
          JSON.stringify(response.data)
        );
        await store.dispatch(
          showSnackbar("Business Registration Requested", "success")
        );
        history.push(`/system/registration-completed`);
      })
      .catch((err) => {
        console.log(
          "Business Registration Request failed, Please contact support : ",
          err
        );
        if (err.response && err.response.data && err.response.data.error) {
          const errorMsg = JSON.parse(err.response.data.error).error;
          setError(errorMsg);
        } else {
          setError("An unknown error occurred. Please try again later.");
        }
      });
  };

  const onInputChange = (event) => {
    const newBusinessDetails = state.businessDetails;
    if (event.target.id) {
      newBusinessDetails[event.target.id] = event.target.value;
    } else {
      newBusinessDetails[event.target.name] = event.target.value;
    }
    setState({
      businessDetails: newBusinessDetails,
    });
  };

  

  return (
    <div className={classes.root}>
      <div className="container--narrow">
        <Paper className="formContainer">
          <h1>Complete your investor registration to continue.</h1>
          <form onSubmit={handleSubmit}>
            {error && <div className="form_error">{error}</div>}
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="name"
                label="Company Name"
                placeholder="Company Name"
                value={state.businessDetails.name || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="address"
                label="Address"
                placeholder="Address"
                value={state.businessDetails.address || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="phoneNumber"
                label="Mobile number"
                placeholder="Mobile number"
                value={state.businessDetails.phoneNumber || ""}
                onChange={onInputChange}
                variant="outlined"
                required
                inputProps={{
                  maxLength: 12,
                  minLength:10,
                  inputMode: "tel",
                  // pattern: "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/",
                }}
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="email"
                label="Email "
                placeholder="Email "
                value={state.businessDetails.email || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="pancard"
                label="Pan card "
                placeholder="Pan card  "
                value={state.businessDetails.pancard || ""}
                onChange={onInputChange}
                variant="outlined"
                required
               
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="aadharcard"
                label="Aadhar Card "
                placeholder="Aadhar Card"
                value={state.businessDetails.aadharcard || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="profession"
                label="Profession"
                placeholder="Profession"
                value={state.businessDetails.profession || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  labelId="state-label"
                  id="state"
                  name="state"
                  value={state.businessDetails.state || ""}
                  onChange={onInputChange}
                  label="State"
                  required
                >
                  {indianStates.map((stateName) => (
                    <MenuItem key={stateName} value={stateName}>
                      {stateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="city"
                label="City"
                placeholder="City"
                value={state.businessDetails.city || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>

            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="pincode"
                label="Pin code"
                placeholder="Pin code"
                value={state.businessDetails.pincode || ""}
                onChange={onInputChange}
                variant="outlined"
                required
                inputProps={{
                  maxLength: 6,
                  minLength: 6,
                  pattern: "\\d{6}",
                }}
              />
            </div>

            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="businessvintage"
                label="Business Vintage"
                placeholder="Business Vintage"
                value={state.businessDetails.businessvintage || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              Submit
            </Button>
          </form>
          {/* <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep)}
                </Typography>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.button}
                    >
                      Finish
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div> */}
        </Paper>
      </div>
      {/* {JSON.stringify(state.businessDetails)}
            {JSON.stringify(userDetails)} */}
    </div>
  );
}
