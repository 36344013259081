import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormButton from '../../common/FormButton';
import FormErrors from '../../FormErrors';
import { update_post_category } from '../../../actions/action-postcategory';
import { history } from '../../../reducers';
import SidebarWrapper from '../../layout/SidebarWrapper';
import configureStore from '../../../store/configureStore';
import CommonSnackBar from '../../common/CommonSnackBar';
import Validate from '../../utility/FormValidation';
import ColorPicker from 'material-ui-color-picker';
import { fetch_Category_By_Id } from '../../../actions/action-categoryview';

const store = configureStore();

class EditGovtSchemeCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
         
            categoryDetails: {
                identifier: "",
                name: '',
                postType: 'govt-scheme',
                image: '',
                color: '',
            },
            errors: {},
            loading: true,
        };
    }

    async componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return;
        }

        const categoryId = this.getCategoryIdFromUrl();
        
        await this.fetchCategoryDetails(categoryId);
    }

    clearErrorState = () => {
        this.setState({
            errors: {},
        });
    };

    getCategoryIdFromUrl = () => {
        const path = window.location.pathname.split('/');
        return path[path.length - 1];
    };

    async fetchCategoryDetails(categoryId) {
     
        try {
          const activeOuidChain = "activeOuidChain";
          const ouid = "ibc";
            const response = await this.props.fetch_Category_By_Id( ouid,
              activeOuidChain,
              categoryId);
              console.log('category edit',store.getState().category.categoryById);
              this.setState({
               loading:false,
                categoryDetails: {
                identifier: store.getState().category.categoryById?.identifier,
                  name: store.getState().category.categoryById?.name,
                  postType: 'govt-scheme',
                  image: store.getState().category.categoryById?.image,
                  color: store.getState().category.categoryById?.color,
              },
              });
        } catch (error) {
            console.error('Error fetching category details:', error);
            this.setState({ loading: false });
        }
    }
    handleSubmit = async (event) => {
        this.clearErrorState();
        const error = Validate(event, this.state);
        const { categoryDetails } = this.state;

        if (error) {
            this.setState({
                errors: { ...this.state.errors, ...error },
            });
        } else {
            try {
                console.log("submitting category details:", categoryDetails)
                const data = {data : {
                    identifier:  categoryDetails.identifier,
                    name: categoryDetails.name,
                    postType: 'govt-scheme',
                    image: categoryDetails.image,
                    color: categoryDetails.color,
                }}
                await this.props.update_post_category(data);
                const ouid = window.location.pathname.split('/')[1];
                this.props.history.push(`/${ouid}/govtscheme-category-dash`);
            } catch (error) {
                console.error('Error updating post category:', error);
            }
        }
    };

    handleInputChange = (event) => {
        const { id, value } = event.target;
        this.setState((prevState) => ({
            categoryDetails: {
                ...prevState.categoryDetails,
                [id]: value,
            },
        }));
    };

    handleColorChange = (color) => {
        this.setState((prevState) => ({
            categoryDetails: {
                ...prevState.categoryDetails,
                color: color,
            },
        }));
    };

    render() {
        const { permissions } = store.getState().permission;
        const { categoryDetails, loading } = this.state;

        if (loading) {
            return <div>Loading...</div>;
        }

        return (
            <div className="page">
                <SidebarWrapper auth={this.props.auth} permissions={permissions} />
                <section className="section">
                    <div className="container">
                        <Paper className="">
                            <div className="formContainer">
                                <FormErrors formerrors={this.state.errors} />
                                <form onSubmit={this.handleSubmit}>
                                    <h1 className="formName">Edit Post Category</h1>
                                    <div className="form-item-wrapper">
                                        <TextField
                                            className="input"
                                            id="name"
                                            label="Category Name"
                                            value={categoryDetails.name}
                                            onChange={this.handleInputChange}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="form-item-wrapper">
                                        <TextField
                                            variant="outlined"
                                            label="Category Type"
                                            value={categoryDetails.postType}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="form-item-wrapper">
                                        <TextField
                                            className="input"
                                            id="image"
                                            label="Featured Image Url"
                                            value={categoryDetails.image}
                                            onChange={this.handleInputChange}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="form-item-wrapper" style={{ display: "flex", alignItems: "center" }}>
                                        <ColorPicker
                                            placeholder={categoryDetails.color || 'Category Color'}
                                            name="color"
                                            onChange={this.handleColorChange}
                                            value={categoryDetails.color}
                                        />
                                        {categoryDetails.color && (
                                            <div
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    backgroundColor: categoryDetails.color,
                                                    border: '1px solid #000',
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="field">
                                      <p className="control">
                                        <FormButton
                                          onClick={() => this.handleSubmit()}
                                          text="Update"
                                        />
                                      </p>
                                    </div>
                                    <CommonSnackBar />
                                </form>
                            </div>
                        </Paper>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    update_post_category: (payload) => dispatch(update_post_category(payload)),
    fetch_Category_By_Id: (ouid,
      activeOuidChain,
      categoryId) => dispatch(fetch_Category_By_Id(ouid,
      activeOuidChain,
      categoryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGovtSchemeCategory);
