import {
  CREATE_BOOKING_REQUESTED,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_ERRORED,
  GET_ALL_BOOKINGS_BY_REQUESTED,
  GET_ALL_BOOKINGS_BY_SUCCESS,
  GET_ALL_BOOKINGS_BY_ERRORED,
  GET_ALL_BOOKINGS_FOR_REQUESTED,
  GET_ALL_BOOKINGS_FOR_SUCCESS,
  GET_ALL_BOOKINGS_FOR_ERRORED,
  GET_BOOKING_REQUESTED,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_ERRORED,
  DELETE_BOOKING_REQUESTED,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_ERRORED,
} from '../constant/action-types';
import axios from 'axios';
import { showSnackbar } from './action-snackbar';

import configureStore from '../store/configureStore';

const store = configureStore();




export const create_booking_requested = () => ({
  type: CREATE_BOOKING_REQUESTED,
});

export const create_booking_success = () => ({
  type: CREATE_BOOKING_SUCCESS,
});

export const create_booking_errored = (error) => ({
  type: CREATE_BOOKING_ERRORED,
  error,
});



export const create_booking = (payload) => async (dispatch) => {
  dispatch(create_booking_requested());
  const path = window.location.pathname.split('/');
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`create_booking`);
  const {
    apiEndpoint,
    credentials
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${apiEndpoint}/booking`;
    if (payload.bookInParent) {
      url = `${apiEndpoint}/booking?bookInParentOrg=true`
    }
    const method = 'post';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid,
        'x-ouidchain': activeOuidChain,
      },
    };
    return axios[method](
      url,
      payload,
      axiosHeader,
    )
      .then((response) => {
        // console.log("response : ",JSON.stringify(response));
        dispatch(showSnackbar('Booking created Successfully', 'success'));
        dispatch(create_booking_success());
      })
      .catch((err) => {
        console.log('Booking creation Failed');
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar('Booking creation Failed', 'error'));
        dispatch(create_booking_errored(err));
      });
  }
  return null;
};

export const get_all_bookings_by_requested = () => ({
  type: GET_ALL_BOOKINGS_BY_REQUESTED,
});

export const get_all_bookings_by_success = (payload) => ({
  type: GET_ALL_BOOKINGS_BY_SUCCESS,
  payload
});

export const get_all_bookings_by_errored = (error) => ({
  type: GET_ALL_BOOKINGS_BY_ERRORED,
  error,
});

export const get_all_bookings_for_requested = () => ({
  type: GET_ALL_BOOKINGS_FOR_REQUESTED,
});

export const get_all_bookings_for_success = (payload) => ({
  type: GET_ALL_BOOKINGS_FOR_SUCCESS,
  payload
});

export const get_all_bookings_for_errored = (error) => ({
  type: GET_ALL_BOOKINGS_FOR_ERRORED,
  error,
});

//bookedByCurrentOrgUnit=true
//fromDate={YYYY-MM-DD}&toDate={YYYY-MM-DD}
export const get_all_bookings_by = (activeOuid, activeOuidChain, fromDate, toDate) => async (dispatch) => {
  dispatch(get_all_bookings_by_requested());
  console.log(`get_all_bookings_by`);
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/booking?bookedByCurrentOrgUnit=true&fromDate=${fromDate}&toDate=${toDate}`;
    const method = 'get';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid,
        'x-ouidchain': activeOuidChain,
      },
    };
    return axios[method](
      url,
      axiosHeader,
    )
      .then((response) => {
        console.log("Get All Bookings By Fetched Successfully");
        dispatch(get_all_bookings_by_success(response.data));
      })
      .catch((err) => {
        console.log('Get All Bookings By Failed');
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, 'error'));
        } else {
          dispatch(showSnackbar('Get All Bookings By Failed', 'error'));
        }
        dispatch(get_all_bookings_by_errored(err));
      });
  }
  return null;
};


export const get_booking = (activeOuid, activeOuidChain, id) => async (dispatch) => {
  dispatch(get_booking_requested());
  console.log(`get_booking`);
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/booking/${id}`;
    const method = 'get';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid,
        'x-ouidchain': activeOuidChain,
      },
    };
    return axios[method](
      url,
      axiosHeader,
    )
      .then((response) => {
        console.log("Get Get Fetched Successfully");
        dispatch(get_booking_success(response.data));
      })
      .catch((err) => {
        console.log('Get Booking Failed');
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, 'error'));
        } else {
          dispatch(showSnackbar('Get All Bookings By Failed', 'error'));
        }
        dispatch(get_booking_errored(err));
      });
  }
  return null;
};

export const get_booking_requested = () => ({
  type: GET_BOOKING_REQUESTED,
});

export const get_booking_success = (payload) => ({
  type: GET_BOOKING_SUCCESS,
  payload
});

export const get_booking_errored = (error) => ({
  type: GET_BOOKING_ERRORED,
  error,
});

export const get_all_bookings_for = (activeOuid, activeOuidChain, fromDate, toDate) => async (dispatch) => {
  console.log(`get_all_bookings_for...........`);
  dispatch(get_all_bookings_for_requested());
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/booking?fromDate=${fromDate}&toDate=${toDate}`;
    const method = 'get';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid,
        'x-ouidchain': activeOuidChain,
      },
    };
    return axios[method](
      url,
      axiosHeader,
    )
      .then((response) => {
        console.log("Get All Bookings For Fetched Successfully");
        // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
        dispatch(get_all_bookings_for_success(response.data));
      })
      .catch((err) => {
        console.log('Get All Bookings For Failed');
        // console.error('ErrorLog : ', JSON.stringify(err));
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, 'error'));
        } else {
          dispatch(showSnackbar('Get All Bookings For Failed', 'error'));
        }
        dispatch(get_all_bookings_for_errored(err));
      });
  }
  return null;
};


export const delete_booking = (activeOuid, activeOuidChain, id) => async (dispatch) => {
  dispatch(delete_booking_requested());
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/booking/${id}`;
    const { activeOuidCoreConfigs } = store.getState().config;
    if (activeOuidCoreConfigs.data) {
      if (activeOuidCoreConfigs.data.config.entityRules) {
        const entityRules = activeOuidCoreConfigs.data.config.entityRules;
        const enitityRule = entityRules.find(({ entity }) => entity === 'booking');
        if (enitityRule) {
          const actionsData = enitityRule.publishToApiTopic.actions;
          if (actionsData.indexOf('D') > -1) {
            url = `${apiEndpoint}/booking/${id}?publishToApiTopic=true`;
          }
        }
      }
    }
    const method = 'delete';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid,
        'x-ouidchain': activeOuidChain,
      },
    };
    return axios[method](
      url,
      axiosHeader,
    )
      .then((response) => {
        console.log("Booking Deleted Successfully");
        dispatch(showSnackbar('Booking Deleted Successfully', 'success'));
        dispatch(delete_booking_success(response.data));
      })
      .catch((err) => {
        console.log('Delete Booking  Failed');
        dispatch(showSnackbar('Delete Booking Failed', 'error'));
        dispatch(delete_booking_errored(err));
      });
  }
  return null;
};


export const delete_booking_requested = () => ({
  type: DELETE_BOOKING_REQUESTED,
});

export const delete_booking_success = (payload) => ({
  type: DELETE_BOOKING_SUCCESS,
  payload
});

export const delete_booking_errored = (error) => ({
  type: DELETE_BOOKING_ERRORED,
  error,
});




