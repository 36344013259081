const dev = {
  api: {
    invokeUrl: "https://a3wknrxgv4.execute-api.ap-south-1.amazonaws.com/demo",
    coursesUrl: "https://3fb08b9d2a.execute-api.ap-south-1.amazonaws.com/demo",
    dprUrl: "https://kkq7w8h8u4.execute-api.ap-south-1.amazonaws.com/demo",
  },
  appui: {
    baseUrl: "https://appdemo.leaperx.com",
    coursesUrl: "",
    hrmUrl: "https://hrmdemo.leaperx.com",
    ecomUrl: "https://ecomdemo.leaperx.com",
    crmUrl: "https://crmdemo.leaperx.com",
    vcUrl: "https://vcommdemo.leaperx.com",
    accountsUrl: "https://accdemo.leaperx.com",
  },
  cognito: {
    REGION: "ap-south-1",
    USER_POOL_ID: "ap-south-1_BM5XxfIn4",
    APP_CLIENT_ID: "2ofuu0tn1t4vu3b7s8s275kemm",
  },
  payemntSecretName: "demo_leaperx_razorpay",
  razorpayId: "rzp_test_ojbD3ipjt4m7g9",
};

const prod = {
  api: {
    invokeUrl: "https://93s0ewtq2f.execute-api.ap-south-1.amazonaws.com/prod",
    coursesUrl: "https://5mvaqqvfb7.execute-api.ap-south-1.amazonaws.com/prod",
    dprUrl: "https://emiofc7rol.execute-api.ap-south-1.amazonaws.com/prod",
  },
  appui: {
    baseUrl: "https://app.leaperx.com",
    coursesUrl: "",
    hrmUrl: "https://hrm.leaperx.com",
    ecomUrl: "https://ecom.leaperx.com",
    crmUrl: "https://crm.leaperx.com",
    vcUrl: "https://vcomm.leaperx.com",
    accountsUrl: "https://acc.leaperx.com",
  },
  cognito: {
    REGION: "ap-south-1",
    USER_POOL_ID: "ap-south-1_Yz42Y735f",
    APP_CLIENT_ID: "1bie4bd2if5c6nlh61ugbsdcs2",
  },
  payemntSecretName: "leaperXlive_razorpay",
  razorpayId: "rzp_live_wQP661Aj5pQMUG",
};

let config = dev;
if (process.env.REACT_APP_STAGE) {
  switch (process.env.REACT_APP_STAGE.trim()) {
    case "dev":
      config = dev;
      break;
    case "prod":
      config = prod;
      break;
    default:
      config = dev;
      break;
  }
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
