import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import CommonConfirm from "./CommonConfirm";
import configureStore from "../../store/configureStore";
import { delete_Orgunit } from "../../actions/action-orgunit";
import { delete_work } from "../../actions/action-work";
import { delete_booking } from "../../actions/action-booking";
import { delete_post } from "../../actions/action-post";
import { delete_course, delete_course_line } from "../../actions/action-course";
import { delete_delete_user_profile } from "../../actions/action-userProfiles";
import { history } from "../../reducers";
import { delete_post_category } from "../../actions/action-categoryview";

const store = configureStore();

export default function CommonDelete(props) {
  const { id, type } = props;
  const [open, setOpen] = React.useState(false);
  const Icontooltip = withStyles({
    tooltip: {
      fontSize: 12,
    },
  })(Tooltip);
  const deleteItem = (id) => {
    console.log("deleteItem id", id);
    setOpen(true);
  };

  const deleteConfirmFunc = async (confirmation, props) => {
    console.log("deleteConfirmFunc : ", props);
    //props.rerender();
    //console.log('rerender : ', props.rerender);

    const { activeOuid, activeOuidChain } = store.getState().permission;
    setOpen(false);
    if (confirmation) {
      switch (type) {
        case "Orgunit":
          console.log("delete Orgunit");
          await store.dispatch(delete_Orgunit(id));
          history.push(`/${activeOuid}/orgunits`);
          return;
        case "work":
          console.log("delete work");
          await store.dispatch(delete_work(id));
          history.push(`/${activeOuid}/leads`);
          return;
        case "userProfile":
          console.log("delete userProfile");
          await store.dispatch(delete_delete_user_profile(id));
          history.push(`/${activeOuid}/users`);
          return;
        case "Booking":
          console.log("delete appointment");
          await store.dispatch(delete_booking(activeOuid, activeOuidChain, id));
          console.log("delete appointment props", props);
          props.rerender();
          history.push(`/${activeOuid}/appointment-dash`);
          return;
        case "Post":
          console.log("delete post");
          await store.dispatch(delete_post(activeOuid, activeOuidChain, id));
          history.push(`/${activeOuid}/post-dash`);
          return;
        case "Faq":
          console.log("delete Faq");
          await store.dispatch(delete_post(activeOuid, activeOuidChain, id));
          history.push(`/${activeOuid}/faq-dash`);
          return;
        case "Course":
          console.log("delete course");
          await store.dispatch(delete_course(activeOuid, activeOuidChain, id));
          history.push(`/${activeOuid}/course-dash`);
          return;
        case "CourseLine":
          console.log("delete courseline");
          await store.dispatch(
            delete_course_line(activeOuid, activeOuidChain, id)
          );
          history.push(`/${activeOuid}/course-dash`);
          return;
        case "Postcategory":
          console.log("delete post category", delete_post_category);
          await store.dispatch(
            delete_post_category(activeOuid, activeOuidChain, id)
          );
          history.push(`/${activeOuid}/govtscheme-category-dash`);
          return;
        case "Govtscheme":
          console.log("delete govtscheme");
          await store.dispatch(delete_post(activeOuid, activeOuidChain, id));
          history.push(`/${activeOuid}/govtscheme-dash`);
          return;
        default:
          return;
      }
    }
    //props.rerender();
  };

  return (
    <div>
      <Icontooltip title="Delete">
        <IconButton
          aria-label="add to favorites"
          onClick={() => {
            deleteItem(id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Icontooltip>
      <CommonConfirm
        confirmBox={open}
        closeConfirmBox={(confirmation) =>
          deleteConfirmFunc(confirmation, props)
        }
      />
    </div>
  );
}
