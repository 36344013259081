import React, { Component } from "react";
import { connect } from "react-redux";
import OrgMappingCard from "../../common/OrgMappingCard";
import {
  get_businesses,
  get_channel_partners,
  get_investors,
} from "../../../actions/action-orgunit";
import configureStore from "../../../store/configureStore";
import SearchField from "../../common/SearchField";
import Loader from "../../common/Loader";
import Button from "@material-ui/core/Button";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import axios from "axios";
import {
  setActiveOuid,
  setActiveOuidChain,
} from "../../../actions/action-permissions";
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { setActiveOiudConfigs } from "../../../actions/action-config";

const store = configureStore();

class ChannelPartnerMapping extends Component {
  constructor() {
    super();
    this.state = {
      cpOrgunitsToShow: [],
      bizOrgunitsToShow: [],
      invOrgunitsToShow: [],
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.unregisterHistoryListener = history.listen((location, action) => {
      const path = window.location.pathname.split("/");
      if (path[path.length - 1] === "mapcp") {
        this.fetchDetailsBasedOnUrl();
      }
    });
    this.fetchDetails();
  }
  componentWillUnmount() {
    console.log("componentWillUnmount");
    this.unregisterHistoryListener();
    store.dispatch(hideSnackbar());
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  async fetchDetails() {
    console.log("fetchDetails");

    const { get_channel_partners, get_businesses, get_investors } = this.props;
    const { activeOuid } = store.getState().permission;
    await get_channel_partners(activeOuid);
    await get_businesses(activeOuid);
    await get_investors(activeOuid);

    const { cpOrgunits, bizOrgunits, invOrgunits } = store.getState().orgunit;
    this.setState({
      cpOrgunitsToShow: cpOrgunits,
      bizOrgunitsToShow: bizOrgunits,
      invOrgunitsToShow: invOrgunits,
    });
  }

  async fetchDetailsBasedOnUrl() {
    console.log("fetchDetailsBasedOnUrl");
    const path = window.location.pathname.split("/");
    const urlOuid = path[1];
    if (path[path.length - 1] === "mapcp") {
      const { get_channel_partners, get_businesses, get_investors } =
        this.props;
      await get_channel_partners(urlOuid);
      await get_businesses(urlOuid);
      await get_investors(urlOuid);
      const { cpOrgunits, bizOrgunits, invOrgunits } = store.getState().orgunit;
      this.setState(
        {
          cpOrgunitsToShow: cpOrgunits,
          bizOrgunitsToShow: bizOrgunits,
          invOrgunitsToShow: invOrgunits,
        },
        async () => {
          await store.dispatch(setActiveOuid(urlOuid));
          await store.dispatch(setActiveOuidChain(urlOuid));
          await store.dispatch(setActiveOiudConfigs(urlOuid));
          const { activeOuidCoreConfigs } = store.getState().config;
          if (
            activeOuidCoreConfigs.data &&
            path[path.length - 1] !== "orgunits"
          ) {
            const deafaultUrl = activeOuidCoreConfigs.data.config.home;
            this.props.history.push(`/${urlOuid}/${deafaultUrl}`);
          }
        }
      );
    }
  }

  renderOrgunit(Orgunit) {
    console.log("Orgunits : ", JSON.stringify(Orgunit));
    return Orgunit.map((item) => (
      <div>
        <OrgMappingCard key={item.identifier} Orgunit={item} />
      </div>
    ));
  }

  searchBiz(keyword, bizOrgunits) {
    if (keyword) {
      const searchPattern = new RegExp(
        keyword.map((term) => `(?=.*${term})`).join(""),
        "i"
      );
      if (bizOrgunits) {
        this.setState({
          ...this.state,
          bizOrgunitsToShow: bizOrgunits.filter((bizOrgunit) =>
            bizOrgunit.data.name.match(searchPattern)
          ),
        });
      }
    } else {
      this.setState({
        ...this.state,
        bizOrgunitsToShow: bizOrgunits,
      });
    }
  }

  searchInv(keyword, invOrgunits) {
    if (keyword) {
      const searchPattern = new RegExp(
        keyword.map((term) => `(?=.*${term})`).join(""),
        "i"
      );
      if (invOrgunits) {
        this.setState({
          ...this.state,
          invOrgunitsToShow: invOrgunits.filter((invOrgunit) =>
            invOrgunit.data.name.match(searchPattern)
          ),
        });
      }
    } else {
      this.setState({
        ...this.state,
        invOrgunitsToShow: invOrgunits,
      });
    }
  }

  searchCp(keyword, cpOrgunits) {
    if (keyword) {
      const searchPattern = new RegExp(
        keyword.map((term) => `(?=.*${term})`).join(""),
        "i"
      );
      if (cpOrgunits) {
        this.setState({
          ...this.state,
          cpOrgunitsToShow: cpOrgunits.filter((cpOrgunit) =>
            cpOrgunit.data.name.match(searchPattern)
          ),
        });
      }
    } else {
      this.setState({
        ...this.state,
        cpOrgunitsToShow: cpOrgunits,
      });
    }
  }

  handleChangeStatus({ meta, file }, status) {
    console.log("status", status, meta, file);
  }
  async handlePut(response, f) {
    const url = response.data.uploadURL;
    // console.log('file', f);
    const body = f;
    const method = "put";
    await axios[method](url, body)
      .then((response) => {
        // console.log("response : ",JSON.stringify(response));
        // console.log('Response Upload', response);
      })
      .catch((err) => {
        console.error("ErrorLog : ", JSON.stringify(err));
      });
  }
  async handleSubmit(files) {
    // console.log(files)
    const f = files[0];
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/document?signedUrl=true`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "Content-type": "image/jpeg",
      },
    };

    const response = await axios[method](url, axiosHeader);
    const body = f["file"];
    url = response.data.uploadURL;
    const postHeader = {
      headers: {
        "Content-type": "image/jpeg",
      },
    };
    const uploadresponse = axios["put"](url, body, postHeader);
    console.log(uploadresponse);
  }

  render() {
    const { bizOrgunits, cpOrgunits, invOrgunits, showGlobalLoader } =
      store.getState().orgunit;
    const { permissions, activeOuid } = store.getState().permission;
    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          {showGlobalLoader ? (
            <Loader />
          ) : (
            <>
              <div className="container">
                <div className="listHeader">
                  <h3 className="pageHeading">Businesses</h3>
                  <SearchField
                    onChange={(e) =>
                      this.searchBiz(e.target.value.split(" "), bizOrgunits)
                    }
                  />
                </div>
                {this.state.bizOrgunitsToShow && (
                  <div>
                    <div className="List">
                      {this.state.invOrgunitsToShow.length !== 0
                        ? this.renderOrgunit(this.state.bizOrgunitsToShow)
                        : "No Businesses Found"}
                    </div>
                  </div>
                )}
                <div className="listHeader">
                  <h3 className="pageHeading">Investors</h3>
                  <SearchField
                    onChange={(e) =>
                      this.searchInv(e.target.value.split(" "), invOrgunits)
                    }
                  />
                </div>
                {this.state.invOrgunitsToShow && (
                  <div>
                    <div className="List">
                      {this.state.invOrgunitsToShow.length !== 0
                        ? this.renderOrgunit(this.state.invOrgunitsToShow)
                        : "No Investors Found"}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <CommonSnackBar />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  orgunit: state.orgunit,
});

const mapDispatchToProps = (dispatch) => ({
  get_channel_partners: (activeOuid) =>
    dispatch(get_channel_partners(activeOuid)),
  get_businesses: (activeOuid) => dispatch(get_businesses(activeOuid)),
  get_investors: (activeOuid) => dispatch(get_investors(activeOuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelPartnerMapping);
