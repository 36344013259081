import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardActions from '@material-ui/core/CardActions';
import moment from 'moment';
import CommonDelete from './CommonDelete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Orgunit, PinDropSharp } from '@material-ui/icons';
import { history } from '../../reducers';
import Button from '@material-ui/core/Button';
import { RedirectFunc } from "./CommonFunctions";
import { setActiveOiudConfigs } from "../../actions/action-config";
import { setActiveOuid, setActiveOuidChain } from "../../actions/action-permissions";
import configureStore from '../../store/configureStore';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CommonSnackBar from "./CommonSnackBar";

const store = configureStore();

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 275,
    marginRight: 10,
    marginBottom: 15,
    padding: '20px 10px',
    justifyContent: 'space-between',
    position: 'relative',
    backgroundColor: '#ffffff',
    boxShadow: 'rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px',
    borderRadius: '3px',
    borderLeft: '4px solid #757575',
    '&:hover': {
      boxShadow: 'rgb(9 30 66 / 25%) 0px 8px 16px -4px, rgb(9 30 66 / 31%) 0px 0px 1px',
      transition: 'box-shadow 0.2s ease 0s',
    },
  },
  MeetingCard:{
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  MeetingCardNote:{
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    width: '80%',
  },
  MeetingCardTime: {
    fontSize: 12,
    fontWeight: 600,
    color: '#939393',
    marginBottom: 5,
  },
  MeetingCardUsers: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1875FF',
  },
  MeetingCardMeeticon: {
    fontSize: 12,
    color: '#1875FF',
  },
  MeetingCardWith: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 15,
  },
  CardActions: {
    position: 'absolute',
    right: 0,
    top: 0,
  }
});

export default function BookingCard(props) {
  console.log("props.Bookings.data ", props.Booking.data);
  console.log("props.Bookings ", props.Booking);
  console.log("props.rerender ", props.rerender);

  const classes = useStyles();
  const Icontooltip = withStyles({
    tooltip: {
      fontSize: 12,
    },
  })(Tooltip);
  const data = props.Booking.data;

  const { activeOuid } = store.getState().permission;

  return (
    <Card className={classes.root}>
      {data && (
        <div className={classes.MeetingCard}>
          <div>
            <div className="">
              <div className={classes.MeetingCardNote}>{data.note}</div>
              <div className={classes.MeetingCardTime}>{moment(data.appointmentStartTime).format('MMMM Do YYYY, h:mm:ss a')}</div>
              <div className={classes.MeetingCardWith}>
                <div className={classes.MeetingCardUsers}>{data.bookedByUser}</div>
                <span className={classes.MeetingCardMeeticon}>--------</span>
                <div className={classes.MeetingCardUsers}>{data.bookedInOrgUnit}</div>
              </div>
              
            </div>
          </div>
          <CardActions disableSpacing className={classes.CardActions}>
            <CommonDelete
              id={data.identifier}
              type="Booking"
              rerender={props.rerender}
            />
          </CardActions>
          <CommonSnackBar />
        </div>
      )}
    </Card>
  );
}
