import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../../../reducers";
import configureStore from "../../../store/configureStore";
import SidebarWrapper from "../../layout/SidebarWrapper";
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import { get_all_posts } from "../../../actions/action-post";
import { getAllPostCategoriesByType } from '../../../actions/action-postcategory';

import Loader from "../../common/Loader";
import Paper from "@material-ui/core/Paper";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import Banner from "../../assets/images/ibcImages/homebanner.png";
import DashboardFooter from "../../layout/DashboardFooter";
import HeaderInner from "../../layout/HeaderInner";

const styles = (theme) => ({
  root: {
    maxWidth: 288,
    minWidth: 288,
  },
  media: {
    height: 140,
  },
  CardContent: {
    padding: 10,
  },
});

const store = configureStore();

class GovtScheme extends Component {
  constructor() {
    super();
    this.state = {
      selectionModel: [],
      postListToShow: [],
      categories: [],
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const category = queryParams.get("category");

    console.log("category componentDidMount", category);
    this.fetchDetails(category);
    this.fetchCategories();
  }

  async fetchDetails(category) {
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);
    this.setState({
      postListToShow: [],
    });
    const { get_all_posts } = this.props;
    await get_all_posts(
      activeOuid,
      activeOuidChain,
      "govtscheme",
      category,
      "ibc"
    );
    console.log(
      "get_all_posts store.getState().post.posts ",
      store.getState().post
    );
    this.setState({
      postListToShow: store.getState().post.posts,
    });
  }

  async fetchCategories() {
    const { activeOuid, activeOuidChain } = this.props.permission;
    const { getAllPostCategoriesByType } = this.props;
    const postType = 'govt-scheme';
    console.log('fetch category')
    try {
        const response = await getAllPostCategoriesByType(postType,'ibc', activeOuidChain);
        console.log('fetch category,',response)

      
        const { items } = response;

        if (Array.isArray(items)) {
            this.setState({ categories: items.map(item => item.data), loading: false });
        } else {
            
            throw new Error('Fetched data is not in the expected structure');
        }
    } catch (error) {
        
        this.setState({ error, loading: false });
    }
}

  

  render() {
    const { categories, loading, error } = this.state;
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().post;
    const { activeOuid, activeOuidChain, activeOuidType } =
      store.getState().permission;

    return (
      <div className="page">
        <div className="container">
          {/* <SidebarWrapper auth={this.props.auth} permissions={permissions} /> */}
          <HeaderInner />
          <div className="section">
            <div className="PageBannerWrapper">
              <div>
                <Paper className="PageBanner">
                  <div>
                    <h1>Information portal</h1>
                    <p>
                      Know about latest Information on govt schemes, Loans,
                      events and much more
                    </p>
                  </div>
                  {/* <div>
                    <img src={Banner} alt=''/>
                  </div> */}
                </Paper>
              </div>
            </div>
            {showGlobalLoader ? (
              <Loader />
            ) : (
              <div>
                <div className="CourseGrid">
                  <div className="CourseWrapperflex">
                    {this.state.postListToShow.length !== 0
                      ? this.renderPosts(this.state.postListToShow)
                      : "No Posts found..."}
                  </div>
                  <Paper className="govtSchemeCategoryWrapper">
                    <div className="CategoryHeading">Category</div>
                    {loading ? (
                      <Loader />
                    ) : error ? (
                      <div>Error loading categories: {error.message}</div>
                    ) : categories.length === 0 ? (
                      <div>No categories found</div>
                    ) : (
                      categories.map((category) => (
                        <Link
                          key={category.id}
                          to={`/${activeOuid}/user-govtscheme?category=${category.name}`}
                          className="categoryLink"
                        >
                          {category.name}
                        </Link>
                      ))
                    )}
                  </Paper>
                </div>
              </div>
            )}
            <DashboardFooter />
            <CommonSnackBar />
          </div>
        </div>
      </div>
    );
  }

  renderPosts(PostList, category) {
    console.log("PostList : ", JSON.stringify(PostList));
    console.log(
      "this.state.postListToShow.length : ",
      this.state.postListToShow.length
    );
    const { activeOuid } = store.getState().permission;
    const { classes, theme } = this.props;
    return PostList.map((item) => (
      <Card
        className={classes.root}
        key={item.data.title}
        onClick={() => {
          history.push(
            `/${activeOuid}/user-govtscheme/${item.data.identifier}`
          );
        }}
      >
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={item.data.featuredImage}
            title="Govt schemes"
          />
          <CardContent className={classes.CardContent}>
            <Typography gutterBottom variant="h5" component="h5">
              {item.data.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {item.data.summary}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    ));
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  posts: state.post,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_posts: (activeOuid, activeOuidChain, type, category, ouid) =>
  dispatch(get_all_posts(activeOuid, activeOuidChain, type, category, ouid)),
  getAllPostCategoriesByType: (postType, activeOuid, activeOuidChain) => dispatch(getAllPostCategoriesByType(postType, activeOuid, activeOuidChain)),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(GovtScheme));
