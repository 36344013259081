import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import SidebarWrapper from "../../layout/SidebarWrapper";
import CreateLoanRequest from "./CreateLoanRequest";
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import { get_all_workorders } from "../../../actions/action-workorder";
import Loader from "../../common/Loader";
import Paper from "@material-ui/core/Paper";
import Completed from "../../assets/images/ibcImages/registrationcomplete.png";
import DashboardFooter from "../../layout/DashboardFooter";
import HeaderInner from "../../layout/HeaderInner";

const store = configureStore();

class LoanRequestDash extends Component {
  constructor() {
    super();
    this.state = {
      selectionModel: [],
      loanRequestsToShow: [],
    };
  }

  componentDidMount() {
    this.fetchDetails();
  }

  async fetchDetails() {
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);
    const { get_all_workorders } = this.props;
    await get_all_workorders(
      activeOuid,
      activeOuidChain,
      "RequestLoanWorkorder"
    );
    console.log(
      "get_all_workorders store.getState().workorder.workorders ",
      store.getState().workorder.workorders
    );
    this.setState({
      loanRequestsToShow: store.getState().workorder.workorders,
    });
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  setSelectionModel(selection) {
    this.setState({
      selectionModel: selection,
    });
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().workorder;
    const { activeOuid, activeOuidChain } = store.getState().permission;

    return (
      <div className="page">
        {/* <SidebarWrapper auth={this.props.auth} permissions={permissions} /> */}
        <HeaderInner />
        <section className="section">
          <div className="container--narrow">
            <Paper className="formContainer">
              {showGlobalLoader ? (
                <Loader />
              ) : (
                <div>
                  {this.state.loanRequestsToShow.length !== 0
                    ? this.renderList(this.state.loanRequestsToShow)
                    : this.renderCreate(this.props)}
                </div>
              )}
            </Paper>
          </div>
          <DashboardFooter />
          <CommonSnackBar />
        </section>
      </div>
    );
  }

  rerender = () => {
    console.log("rerender called *********");
    this.fetchDetails();
  };

  renderCreate(props) {
    const { permissions } = store.getState().permission;

    return (
      <>
        <h1>In 4 steps, provide details and get a DPR for Loans!</h1>
        <p>
          The details will be used to create a DPR by experts and experts will
          get in touch with you to support.
        </p>
        <CreateLoanRequest
          auth={this.props.auth}
          permissions={permissions}
          rerender={this.rerender}
        />
      </>
    );
  }

  renderList(list) {
    console.log("list : ", JSON.stringify(list));
    console.log(
      "this.state.loanRequestsToShow.length : ",
      this.state.loanRequestsToShow.length
    );
    const { activeOuid } = store.getState().permission;
    var tableContent = list.map((item) => (
      <div key={item.data.title}>
        <div className="amount">
          <div className="lable">Loan amount</div>
          <div className="figure">
            Rs. {item.data.details.loanRequestDetails.totalCost}
          </div>
        </div>
        {/* <TableCell>{timeFormat(item.data.createdDate)}</TableCell>
        <TableCell>{item.data.createdBy}</TableCell>
        <TableCell>{item.data.status}</TableCell> */}
      </div>
    ));

    return (
      <div className="requestSuccessWrapper">
        <img src={Completed} className="requestSuccessImg" />
        <h1 className="requestSuccessText">Loan application is under review</h1>
        <p className="requestSuccessMessage">
          You will recieve a notification once your application has been
          reviewed.
        </p>
        <div className="requestSuccessDetailsWrapper">
          <h3>Here's what you have currently applied for.</h3>
          {tableContent}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  workorders: state.workorder,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_workorders: (activeOuid, activeOuidChain, type) =>
    dispatch(get_all_workorders(activeOuid, activeOuidChain, type)),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanRequestDash);
