import React, { useState } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import Loader from "../../../common/Loader";
import { history } from "../../../../reducers";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { DataGrid } from "@material-ui/data-grid";
import WorkDetails from "./InvReqWorkDetails";
import { timeFormat } from "../../../common/CommonFunctions";

const store = configureStore();

const workColumns = [
  {
    field: "businessName",
    headerName: "Business Name",
    width: 250,
    valueFormatter: (params) =>
      params.row?.data?.details?.investmentRequestDetails?.businessName,
  },
  {
    field: "investment",
    headerName: "Investment Amount",
    width: 200,
    editable: false,
    valueFormatter: (params) =>
      "Rs " + params.row?.data?.details?.investmentRequestDetails?.totalCost,
  },

  {
    field: "created_Date",
    headerName: "Created Date",
    type: "number",
    width: 200,
    editable: false,
    valueGetter: (params) =>
      `${timeFormat(params.getValue(params.id, "createdDate")) || ""}`,
  },
  {
    field: "requestedUser",
    headerName: "User",
    width: 150,
    editable: false,
    valueFormatter: (params) => params.row?.data?.username,
  },
  {
    field: "requestedOrg",
    headerName: "Organisation",
    width: 150,
    editable: false,
    valueFormatter: (params) => params.row?.data?.clientOrg,
  },
  {
    field: "workstatus",
    headerName: "Status",
    sortable: false,
    width: 150,
    valueGetter: (params) =>
      `${params.getValue(params.id, "status") || ""} ${
        params.getValue(params.id, "status") === "ASSIGNED" ? "To" : ""
      } ${params.getValue(params.id, "username") || ""}`,
  },
];

function LoanReqMyWorkQueue(props) {
  const {
    workContext,
    rows,
    defaultStatus,
    usersToshow,
    handleMyWorkStatusChange,
    handleDetailsClick,
  } = props;
  const { activeOuid } = store.getState().permission;
  const { showGlobalLoader } = store.getState().work;
  const path = window.location.pathname.split("/");
  const teamId = path[path.length - 2];

  const [workDash, setWorkDash] = useState(false);
  const [currentWork, setCurrentWork] = useState();
  const [selectionModel, setSelectionModel] = React.useState();

  const openWorkDash = (data) => {
    console.log("openWorkDash : ", data.row);
    setWorkDash(true);
    setCurrentWork(data.row);
    setSelectionModel(data.row.id);
    return handleDetailsClick();
  };

  const closeWorkDash = () => {
    console.log("closeWorkDas");
    setWorkDash(false);
    setCurrentWork();
    return handleDetailsClick();
  };

  return (
    <div>
      <div className="listHeader">
        <FormControl variant="outlined" className="selectBox">
          <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={defaultStatus}
            onChange={(event) => handleMyWorkStatusChange(event.target.value)}
            label="Status"
          >
            <MenuItem value="ASSIGNED">Assigned</MenuItem>
            <MenuItem value="INPROGRESS">InProgress</MenuItem>
            <MenuItem value="CLOSED">Closed</MenuItem>
          </Select>
        </FormControl>
      </div>
      {showGlobalLoader ? (
        <Loader />
      ) : (
        <div className="formList">
          {rows !== 0 ? (
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={workColumns}
                pageSize={10}
                checkboxSelection
                disableSelectionOnClick
                onCellClick={(e) => {
                  openWorkDash(e);
                }}
                selectionModel={selectionModel}
                hideFooterSelectedRowCount
                onSelectionModelChange={(selection) => {
                  console.log("selection  :::: ", selection);
                  if (selection.length > !2) {
                    let lastElement = selection.pop();
                    setSelectionModel(lastElement);
                  } else {
                    let lastElement = selection.pop();
                    setSelectionModel(lastElement);
                  }
                }}
              />
            </div>
          ) : (
            "No work queues....."
          )}
          <div>
            {workDash && (
              <WorkDetails
                workContext={workContext}
                work={currentWork}
                usersToshow={usersToshow}
                closeWorkDash={() => {
                  closeWorkDash();
                }}
                rerender={props.rerender}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoanReqMyWorkQueue);
