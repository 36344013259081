import React from "react";
import ReactHlsPlayer from "react-hls-player";

export default function Player(props) {
  const { urlSrc } = props;

  const playerRef = React.useRef();
  const percentCurrent = React.useRef(0);

  React.useEffect(() => {
    playerRef.current.addEventListener("play", fireOnVideoStart);
    playerRef.current.addEventListener("timeupdate", timeupdate);
    playerRef.current.addEventListener("ended", fireOnVideoEnd);

    //return playerRef.current.removeEventListener('play', fireOnVideoStart);
  }, []);

  function fireOnVideoStart() {
    // Do some stuff when the video starts/resumes playing
    console.log("video started/resumed");
  }

  function timeupdate(e) {
    const duration = playerRef.current.duration;
    const currentTime = playerRef.current.currentTime;

    var handlePercent = Math.ceil((currentTime / duration) * 100);
    // console.log("currentTime/duration", currentTime + "/", duration);

    if (percentCurrent.current < 75 && handlePercent === 75) {
      console.log("percentCurrent");
      percentCurrent.current = handlePercent;
    } else if (percentCurrent.current < 95 && handlePercent === 95) {
      console.log("percentCurrent");
      percentCurrent.current = handlePercent;
    } else if (percentCurrent.current < 100 && handlePercent === 100) {
      console.log("percentCurrent");
      percentCurrent.current = handlePercent;
    }
  }

  function fireOnVideoEnd() {
    // Do some stuff when the video ends
    console.log("video ended, update item");
  }

  return (
    <ReactHlsPlayer
      playerRef={playerRef}
      src={urlSrc}
      autoPlay={false}
      controls={true}
      width="100%"
      className="video_player"
      hlsConfig={{
        maxLoadingDelay: 4,
        minAutoBitrate: 0,
        lowLatencyMode: true,
        startPosition: 0,
      }}
    />
  );
}
