import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../../../reducers";
import BookingCard from "../../common/BookingCard";
import configureStore from "../../../store/configureStore";
import SidebarWrapper from "../../layout/SidebarWrapper";
import SearchField from "../../common/SearchField";
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import {
  get_all_bookings_by,
  create_booking,
} from "../../../actions/action-booking";
import { DataGrid } from "@material-ui/data-grid";
import { timeFormat } from "../../common/CommonFunctions";
import CommonPopUp from "../../common/CommonPopUp";
import Button from "@material-ui/core/Button";
import Loader from "../../common/Loader";
import CertificationImage from "../../assets/images/ibcImages/certificationbanner.png";
import Paper from "@material-ui/core/Paper";
import Banner from "../../assets/images/ibcImages/meetingbanner.png";
import Business from "../../assets/images/ibcImages/buildings.png";
import DashboardFooter from "../../layout/DashboardFooter";
import HeaderInner from "../../layout/HeaderInner";

const store = configureStore();

class AppointmentDash extends Component {
  constructor() {
    super();
    this.state = {
      selectionModel: [],
      bookingListToShow: [],
      counter: 0,
    };
  }

  componentDidMount() {
    this.fetchDetails();
  }

  rerender = () => {
    console.log("rerender called *********");
    this.fetchDetails();
    //this.forceUpdate();
  };

  forceUpdate = () => {
    this.setState((state) => ({
      counter: state.counter + 1,
    }));
  };

  async fetchDetails() {
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);
    const { get_all_bookings_by } = this.props;
    await get_all_bookings_by(
      activeOuid,
      activeOuidChain,
      "2022-01-01",
      "2222-12-30"
    );
    console.log(
      "get_all_bookings_by store.getState().booking.bookings ",
      store.getState().booking.bookings
    );
    this.setState({
      bookingListToShow: store.getState().booking.bookings,
    });
  }

  renderBookings(BookingList) {
    console.log("BookingList : ", JSON.stringify(BookingList));
    return BookingList.map((item) => (
      <BookingCard
        key={item.data.name}
        Booking={item}
        rerender={this.rerender}
      />
    ));
  }
  componentWillUnmount() {
    // console.log("componentWillUnmount");
    // this.unregisterHistoryListener()
    // store.dispatch(hideSnackbar());
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
    //console.log("componentDidUpdate bookingListToShow", this.state.bookingListToShow);
    console.log(
      "componentDidUpdate store.getState().booking.bookings",
      store.getState().booking.bookings
    );
  }

  setSelectionModel(selection) {
    this.setState({
      selectionModel: selection,
    });
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().booking;
    const { activeOuid, activeOuidChain } = store.getState().permission;

    return (
      <div className="page">
        {/* <SidebarWrapper auth={this.props.auth} permissions={permissions} /> */}
        <HeaderInner />
        <section className="section">
          <div className="">
            <div>
              <div className="PageBannerWrapper">
                <div>
                  <Paper className="PageBanner">
                    <div>
                      <h3>Schedule a meeting with your channel partner</h3>
                      {/* <p>We have complied a comprehensive entrepreneurship course, So that you can understand.</p> */}
                      <Button
                        onClick={() => {
                          history.push(`/${activeOuid}/create-booking`);
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Schedule Meeting
                      </Button>
                    </div>
                    {/* <div>
                                            <img src={Banner} alt=''/>
                                        </div> */}
                  </Paper>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="listHeader">
              <h3 className="pageHeading margin0"> Meetings</h3>
            </div>
            <div className="split">
              {showGlobalLoader ? (
                <Loader />
              ) : this.state.bookingListToShow.length !== 0 ? (
                this.renderBookings(this.state.bookingListToShow)
              ) : (
                "No Appointments ..."
              )}
            </div>
          </div>
          <DashboardFooter />
          <CommonSnackBar />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  booking: state.booking,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_bookings_by: (activeOuid, activeOuidChain, fromDate, toDate) =>
    dispatch(
      get_all_bookings_by(activeOuid, activeOuidChain, fromDate, toDate)
    ),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDash);
