import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import { get_booking } from "../../../actions/action-booking";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import CommonDelete from "../../common/CommonDelete";
import { timeFormat } from "../../common/CommonFunctions";
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { Paper } from "@material-ui/core";
import DashboardFooter from "../../layout/DashboardFooter";

const store = configureStore();

class BookingDetails extends Component {
  constructor() {
    super();
    this.state = {
      BookingDetails: {},
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {
    // store.dispatch(hideSnackbar());
  }

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("id : ", id);
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const { get_booking } = this.props;
    await get_booking(activeOuid, activeOuidChain, id);
    console.log(
      "store.getState().booking : ",
      store.getState().booking.booking[0]
    );
    this.setState({
      BookingDetails: store.getState().booking.booking[0],
    });
  }

  renderDetails(details) {
    return Object.keys(details).map(function (keyName, keyIndex) {
      return (
        <div key={keyName} className="detailsItem">
          <div className="detailsKey">{keyName} :</div>
          {keyName === "lastModifiedDate" || keyName === "createdDate" ? (
            <div className="detailsValue">{timeFormat(details[keyName])}</div>
          ) : (
            <div className="detailsValue">{details[keyName]}</div>
          )}
        </div>
      );
    });
  }

  render() {
    const { showGlobalLoader } = store.getState().orgunit;
    const { permissions } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          {showGlobalLoader ? (
            <Loader />
          ) : (
            <div className="container">
              <Paper component="div" className="paper">
                <div className="listHeader">
                  <h1>Booking Details</h1>
                  <CommonDelete id={id} type="Booking" />
                </div>
                {this.state.BookingDetails.data
                  ? this.renderDetails(this.state.BookingDetails.data)
                  : "No Booking Details Found"}
              </Paper>
            </div>
          )}
          <DashboardFooter />
          <CommonSnackBar />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_booking: (activeOuid, activeOuidChain, id) =>
    dispatch(get_booking(activeOuid, activeOuidChain, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);
