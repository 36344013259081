import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../reducers';
import PostCard from '../../common/PostCard';
import configureStore from '../../../store/configureStore';
import SidebarWrapper from '../../layout/SidebarWrapper';
import SearchField from '../../common/SearchField';
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import { get_all_posts, create_post } from "../../../actions/action-post";
import { DataGrid } from '@material-ui/data-grid';
import { timeFormat } from '../../common/CommonFunctions'
import CommonPopUp from '../../common/CommonPopUp'
import Button from '@material-ui/core/Button';
import Loader from '../../common/Loader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';


const store = configureStore();

class PostDash extends Component {
    constructor() {
        super();
        this.state = {
            selectionModel: [],
            typesToshow: ["govtscheme", "faq", "course"],
            postListToShow: [],
        };
    }

    componentDidMount() {
        this.fetchDetails();
    }

    async fetchDetails() {
        const { activeOuid, activeOuidChain } = store.getState().permission;
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        console.log("path : ", path);
        const { setActiveOuid } = this.props;
        await setActiveOuid(ouid);
        this.setState({
            postType: 'govtscheme',
            postListToShow: [],
        });
        const { get_all_posts } = this.props;
        await get_all_posts(activeOuid, activeOuidChain, 'govtscheme', '', 'ibc');
        console.log("get_all_posts store.getState().post.posts ", store.getState().post);
        this.setState({
            postType: 'govtscheme',
            postListToShow: store.getState().post.posts,
        });
    }

    componentWillUnmount() {
        // console.log("componentWillUnmount");
        // this.unregisterHistoryListener()
        // store.dispatch(hideSnackbar());
    }

    componentDidUpdate() {
        console.log("componentDidUpdate");
    }

    setSelectionModel(selection) {
        this.setState({
            selectionModel: selection,
        });
    }



    render() {
        const { permissions } = store.getState().permission;
        const { showGlobalLoader } = store.getState().permission;
        const { activeOuid, activeOuidChain } = store.getState().permission;

        return (
            <div className="page">
                <SidebarWrapper
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    {showGlobalLoader ?
                        <Loader />
                        : <div className="container">
                            <div className="listHeader">
                                <h1 className="postHeading">POSTS</h1>
                                <Button onClick={() => { history.push(`/${activeOuid}/create-post`); }} variant="contained" color="primary">
                                    Add Post
                                </Button>
                            </div>
                            <div className="form-item-wrapper">
                                <Autocomplete
                                    id="combo-box-demo"
                                    onChange={(event, newValue) => {
                                        this.typeChange(newValue);
                                    }}
                                    defaultValue={"govtscheme"}
                                    options={this.state.typesToshow}
                                    getOptionLabel={(option) => option}
                                    style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Select Type" variant="outlined" />}
                                />
                            </div>

                            <div>
                                <div className="List">
                                    {(this.state.postListToShow.length !== 0) ? this.renderPosts(this.state.postListToShow) : 'No Posts found...'}
                                </div>
                            </div>
                        </div>}
                    <CommonSnackBar />
                </section>
            </div >
        )
    }

    async typeChange(newValue) {
        console.log("newValue  newValue : ", newValue);
        const { activeOuid, activeOuidChain } = store.getState().permission;
        const { get_all_posts } = this.props;
        await get_all_posts(activeOuid, activeOuidChain, newValue, '', 'ibc');
        console.log("get_all_posts store.getState().post.posts ", store.getState().post);
        this.setState({
            postType: newValue,
            postListToShow: store.getState().post.posts,
        });
    }

    renderPosts(PostList) {
        console.log("PostList : ", JSON.stringify(PostList));
        console.log("this.state.postListToShow.length : ", this.state.postListToShow.length);


        return PostList.map((item) => (
            <div>
                <PostCard key={item.title}
                    Post={item}
                />
            </div>
        ));
    }
}




const mapStateToProps = (state) => ({
    permission: state.permission,
    posts: state.post,
});

const mapDispatchToProps = (dispatch) => ({
    get_all_posts: (activeOuid, activeOuidChain, type, category, ouid) => dispatch(get_all_posts(activeOuid, activeOuidChain, type, category, ouid)),
    setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostDash);