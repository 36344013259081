import React, { Component } from "react";
import { connect } from "react-redux";
import BizForInvestorCard from "./BizForInvestorCard";
import { get_businesses_with_parentouid } from "../../../actions/action-orgunit";
import configureStore from "../../../store/configureStore";
import SearchField from "../../common/SearchField";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import axios from "axios";
import {
  setActiveOuid,
  setActiveOuidChain,
} from "../../../actions/action-permissions";
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { setActiveOiudConfigs } from "../../../actions/action-config";

const store = configureStore();

class MyCPBusinesses extends Component {
  constructor() {
    super();
    this.state = {
      bizOrgunitsToShow: [],
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.unregisterHistoryListener = history.listen((location, action) => {
      const path = window.location.pathname.split("/");
      if (path[path.length - 1] === "mapcp") {
        this.fetchDetailsBasedOnUrl();
      }
    });
    this.fetchDetails();
  }
  componentWillUnmount() {
    console.log("componentWillUnmount");
    this.unregisterHistoryListener();
    store.dispatch(hideSnackbar());
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  async fetchDetails() {
    console.log("fetchDetails");

    const { get_businesses_with_parentouid } = this.props;
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const parentOuId = activeOuidChain[activeOuidChain.length - 2];
    await get_businesses_with_parentouid(activeOuid, parentOuId);

    const { bizOrgunits } = store.getState().orgunit;
    this.setState({
      bizOrgunitsToShow: bizOrgunits,
    });
  }

  async fetchDetailsBasedOnUrl() {
    console.log("fetchDetailsBasedOnUrl");
    const { activeOuidChain } = store.getState().permission;
    const parentOuId = activeOuidChain[activeOuidChain.length - 2];

    const path = window.location.pathname.split("/");
    const urlOuid = path[1];
    if (path[path.length - 1] === "mapcp") {
      const { get_businesses_with_parentouid } = this.props;

      await get_businesses_with_parentouid(urlOuid, parentOuId);
      const { bizOrgunits } = store.getState().orgunit;
      this.setState(
        {
          bizOrgunitsToShow: bizOrgunits,
        },
        async () => {
          await store.dispatch(setActiveOuid(urlOuid));
          await store.dispatch(setActiveOuidChain(urlOuid));
          await store.dispatch(setActiveOiudConfigs(urlOuid));
          const { activeOuidCoreConfigs } = store.getState().config;
          if (
            activeOuidCoreConfigs.data &&
            path[path.length - 1] !== "orgunits"
          ) {
            const deafaultUrl = activeOuidCoreConfigs.data.config.home;
            this.props.history.push(`/${urlOuid}/${deafaultUrl}`);
          }
        }
      );
    }
  }

  renderOrgunit(Orgunit) {
    console.log("Orgunits : ", JSON.stringify(Orgunit));
    return Orgunit.map((item) => (
      <div>
        <BizForInvestorCard key={item.identifier} Orgunit={item} />
      </div>
    ));
  }

  searchBiz(keyword, bizOrgunits) {
    if (keyword) {
      const searchPattern = new RegExp(
        keyword.map((term) => `(?=.*${term})`).join(""),
        "i"
      );
      if (bizOrgunits) {
        this.setState({
          ...this.state,
          bizOrgunitsToShow: bizOrgunits.filter((bizOrgunit) =>
            bizOrgunit.data.name.match(searchPattern)
          ),
        });
      }
    } else {
      this.setState({
        ...this.state,
        bizOrgunitsToShow: bizOrgunits,
      });
    }
  }

  handleChangeStatus({ meta, file }, status) {
    console.log("status", status, meta, file);
  }
  async handlePut(response, f) {
    const url = response.data.uploadURL;
    // console.log('file', f);
    const body = f;
    const method = "put";
    await axios[method](url, body)
      .then((response) => {
        // console.log("response : ",JSON.stringify(response));
        // console.log('Response Upload', response);
      })
      .catch((err) => {
        console.error("ErrorLog : ", JSON.stringify(err));
      });
  }
  async handleSubmit(files) {
    // console.log(files)
    const f = files[0];
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/document?signedUrl=true`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "Content-type": "image/jpeg",
      },
    };

    const response = await axios[method](url, axiosHeader);
    const body = f["file"];
    url = response.data.uploadURL;
    const postHeader = {
      headers: {
        "Content-type": "image/jpeg",
      },
    };
    const uploadresponse = axios["put"](url, body, postHeader);
    console.log(uploadresponse);
  }

  render() {
    const { bizOrgunits, showGlobalLoader } = store.getState().orgunit;
    const { permissions, activeOuid } = store.getState().permission;
    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          {showGlobalLoader ? (
            <Loader />
          ) : (
            <>
              <div className="container">
                <div className="listHeader">
                  <h3 className="pageHeading">Businesses</h3>
                  <SearchField
                    onChange={(e) =>
                      this.searchBiz(e.target.value.split(" "), bizOrgunits)
                    }
                  />
                </div>
                {this.state.bizOrgunitsToShow && (
                  <div>
                    <div className="List">
                      {this.state.bizOrgunitsToShow.length !== 0
                        ? this.renderOrgunit(this.state.bizOrgunitsToShow)
                        : "No Businesses Found"}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <CommonSnackBar />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  orgunit: state.orgunit,
});

const mapDispatchToProps = (dispatch) => ({
  get_businesses_with_parentouid: (activeOuid, parentOuid) =>
    dispatch(get_businesses_with_parentouid(activeOuid, parentOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCPBusinesses);
