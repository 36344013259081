import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../store/configureStore';
import { makeStyles, useTheme } from "@material-ui/core/styles";

import HomeIcon from '../assets/images/ibcImages/Home.png';
import UserIcon from '../assets/images/ibcImages/Profile.png';
import NotificationIcon from '../assets/images/ibcImages/Notify.png';
import MessageIcon from '../assets/images/ibcImages/Support.png';


const store = configureStore();

const useStyles = makeStyles((theme) => ({
  mobile: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    height: 50,
    background: "#fff",
    alignItems: "center",
    zIndex: 9,
    display: "none",
    justifyContent: "center",
    boxShadow: "0 3px 13px 1px rgb(0 0 0 / 20%)",
    // [theme.breakpoints.up("xs")]: {
    //   display: "flex",
    // },
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
  },
  desktop:{
    background: "#ebebeb",
    padding: 15,
    // [theme.breakpoints.up("xs")]: {
    //   display: "flex",
    // },
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
  },
  split: {
    display: "flex",
    alignItems: 'flex-start'
  },
  footerCard: {
    flexBasis: "100%",
  },
  menu: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    color: '#000',
  },
  menuLink: {
    color: "#000",
    padding: "5px 0",
    display: "inline-block",
  },
  footerPhone: {
    display: "flex",
    alignItems: "center",
    gap: "4px", 
    minWidth: "172px",
  },
  footerEmail: {
    display: "flex",
    alignItems: "center",
    gap: "4px", 
  },
  contact: {
    display:"flex", 
    gap:"28px"
  }
  
}));

function Footer() {
  const classes = useStyles();


  const { activeOuid } = store.getState().permission;
  return (
    <>
      <div className={classes.mobile}>
        <a href={`/${activeOuid}/user-home`} className="UserFooterItem">
          <img src={HomeIcon} />
        </a>
        <a href={`/${activeOuid}/my-profile`} className="UserFooterItem">
          <img src={UserIcon} />
        </a>
        <a href={`/${activeOuid}/notifications`} className="UserFooterItem">
          <img src={NotificationIcon} />
        </a>
        <a href={`/${activeOuid}/user-support`} className="UserFooterItem">
          <img src={MessageIcon} />
        </a>
      </div >

      <div className={classes.desktop}>
      <div className='split alignStart'>

        <div className={classes.footerCard}>
          <h4>QUICK LINKS</h4>
          <div className="menu-quicklinks-container">
            <ul className={classes.menu}>
              <li ><a className={classes.menuLink} href="https://leaplynk.com/" aria-current="page">Home</a></li>
              <li ><a className={classes.menuLink} href="https://leaplynk.com/about/">About</a></li>
              <li ><a className={classes.menuLink} href="https://leaplynk.com/services/">Services</a></li>
              <li ><a className={classes.menuLink} href="https://leaplynk.com/contact/">Contact Us</a></li>
            </ul>
          </div>
        </div>
        <div className={classes.footerCard}>
          <h4>USEFULL LINKS</h4>
          <div>
            <ul className={classes.menu}>
              <li ><a className={classes.menuLink} href="/register-investor">Register as investor</a></li>
              <li ><a className={classes.menuLink} href="/register-cp">Register as Channel partner</a></li>
              <li ><a className={classes.menuLink} href="/register-incubator">Register as Incubator</a></li>
            </ul>
          </div>
        </div>
        <div className={classes.footerCard}>
          <h4>CONTACT US</h4>
          <div className={classes.contact}>
            <p>MG Road <br/> Ernakulam <br/> 
              <div className={classes.footerPhone}>
                <h5 style={{marginTop:"4px", marginBottom:"4px"}}>Email:</h5>
                <a href="mailto:contact@leaperx.com">Contact@leaperx.com</a>
              </div>
              <div className={classes.footerPhone}>
                <h5 style={{marginTop:"4px", marginBottom:"4px"}}>Phone Number:</h5>
                <a href="tel:+917012508393">+91 70125 08393</a>
              </div>
            </p>
            <p>Cybercity <br/> Delhi <br/>
              <div className={classes.footerPhone}>
                <h5 style={{marginTop:"4px", marginBottom:"4px"}}>Email:</h5>
                <a href="mailto:leaplinkarv@gmail.com">Leaplinkarv@gmail.com</a>
              </div>
              <div className={classes.footerPhone}>
                <h5 style={{marginTop:"4px", marginBottom:"4px"}}>Phone Number:</h5>
                <a href="tel:+919745470743">+91 97454 70743</a>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div >
    </>
  )
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);