import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import SidebarWrapper from "../../layout/SidebarWrapper";
import { setActiveOuid } from "../../../actions/action-permissions";
import { getAllPostCategoriesByType } from "../../../actions/action-postcategory";
import Loader from "../../common/Loader";
import HeaderInner from "../../layout/HeaderInner";
import CommonSnackBar from "../../common/CommonSnackBar";
import DashboardFooter from "../../layout/DashboardFooter";
import "./InfoPortalDashboard.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import infobanner1 from "../../../components/assets/images/InformationPortalImages/infobanner1.svg";
import infobanner2 from "../../../components/assets/images/InformationPortalImages/infobanner2.svg";
import infobanner3 from "../../../components/assets/images/InformationPortalImages/infobanner3.svg";
import { history } from "../../../reducers";
import configureStore from "../../../store/configureStore";

const store = configureStore();

class InfoPortalDashboard extends Component {
  state = {
    categories: [],
    loading: true,
    error: null,
  };

  componentDidMount() {
    this.fetchCategories();
  }

  async fetchCategories() {
    const { activeOuid, activeOuidChain } = this.props.permission;
    const { getAllPostCategoriesByType } = this.props;
    const postType = "govt-scheme";

    try {
      const response = await getAllPostCategoriesByType(
        postType,
        "ibc",
        activeOuidChain
      );
      console.log("Fetch response:", response); // Debugging line

      const items = response && response.items ? response.items : [];
      console.log("Fetched items:", items); // Debugging line

      if (Array.isArray(items)) {
        this.setState({
          categories: items.map((item) => item.data),
          loading: false,
        });
      } else {
        throw new Error("Fetched data is not in the expected structure");
      }
    } catch (error) {
      console.error("Error fetching categories:", error); // Debugging line
      this.setState({ error, loading: false });
    }
  }

  renderCategories() {
    const { categories, loading, error } = this.state;
    const { activeOuid } = store.getState().permission;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <div>Error loading categories: {error.message}</div>;
    }

    if (categories.length === 0) {
      return <div>No categories found</div>;
    }

    return (
      <div className="InfoportalUsercategories">
        {categories.map((category, index) => (
          <div
            key={index}
            className="InfoPortalCategories"
            style={{ "--hover-bg-color": category.color }}
            onClick={() => {
              history.push(
                `/${activeOuid}/inforportalCategory/${category.identifier}`
              );
            }}
          >
            <div className="InfoportalIcon">
              <img
                src={category.image}
                alt={category.name}
                className="CategoryIconImg"
              />
            </div>
            <p className="CategoryName">{category.name}</p>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { permissions } = this.props;

    return (
      <div className="page" style={{ marginTop: 0 }}>
        <HeaderInner />
        <section className="section" style={{ backgroundColor: "#FDFDFD" }}>
          <div className="container">
            <div className="container--narrow">
              <div className="UserDashboard">
                <div className="headerBanner">
                  <Carousel autoPlay infiniteLoop showThumbs={false}>
                    <div className="infoportalbanner">
                      <img
                        src={infobanner1}
                        alt="InfoPortalBannerImg"
                        className="UserHomeBannerImg"
                      />
                      <div className="bannerText">
                        Welcome to the <br></br>Information Portal
                      </div>
                    </div>
                    <div className="infoportalbanner">
                      <img
                        src={infobanner2}
                        alt="InfoPortalBannerImgTwo"
                        className="UserHomeBannerImg"
                      />
                      <div className="bannerText">
                        Browse schemes<br></br>by category
                      </div>
                    </div>
                    <div className="infoportalbanner">
                      <img
                        src={infobanner3}
                        alt="InfoPortalBannerImgThree"
                        className="UserHomeBannerImg"
                      />
                      <div className="bannerText">
                        Find the Right<br></br>scheme to empower<br></br>your
                        business
                      </div>
                    </div>
                  </Carousel>
                </div>
                <div className="categoryList">{this.renderCategories()}</div>
              </div>
            </div>
          </div>
        </section>
        <CommonSnackBar />
        <DashboardFooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
  getAllPostCategoriesByType: (postType, activeOuid, activeOuidChain) =>
    dispatch(getAllPostCategoriesByType(postType, activeOuid, activeOuidChain)),
  push: (path) => dispatch(push(path)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoPortalDashboard);
