import React from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { timeFormat } from '../../common/CommonFunctions'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { create_update_work } from '../../../actions/action-work';
import Button from '@material-ui/core/Button';
import { history } from '../../../reducers';

const store = configureStore();

function WorkDetails(props) {
    const { workContext, work, usersToshow, closeWorkDash } = props;
    const [user, setUser] = React.useState();
    const renderDetails = (details) => {
        return Object.keys(details).map(function (keyName, keyIndex) {
            return (
                <div key={keyName} className="detailsItem">
                    <div className="detailsKey">
                        {keyName} {" : "}
                    </div>
                    {keyName === 'lastModifiedDate' || keyName === 'createdDate' ?
                        <div className="detailsValue">
                            {timeFormat(details[keyName])}
                        </div>
                        :
                        <div className="detailsValue">
                            {details[keyName]}
                        </div>
                    }
                </div>
            )
        });
    }

    const userChange = (newValue) => {
        console.log("newValue  newValue : ", newValue);
        if (newValue) {
            // newworkDetails['username'] = newValue.data.username;
            // newworkDetails['userDisplayName'] = newValue.data.name;
            setUser(newValue.data.username);
        } else {
            setUser("");
        }
    }

    const updateWork = async () => {
        let data = work;
        data["username"] = user;
        console.log("newValue  newValue : ", data);
        const workid = data.identifier;
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        if (ouid && workid) {
            let updateWorkDetails = { "data": data }
            await store.dispatch(create_update_work(workid, ouid, updateWorkDetails));
            window.location.reload();
        }
    }


    return (
        <div className="detailsDrawer">
            <Button onClick={() => { closeWorkDash() }} variant="contained">
                Close
            </Button>
            <div>{renderDetails(work)}</div>
            <div>{user}</div>
            <div>
                {usersToshow.length !== 0 && work.status === "OPEN" ?
                    <div className="form-item-wrapper">
                        <Autocomplete
                            id="combo-box-demo"
                            // value={this.state.selectedCp}
                            onChange={(event, newValue) => {
                                userChange(newValue);
                            }}
                            options={usersToshow}
                            getOptionLabel={(option) => option.data.name}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                        />
                    </div>
                    :
                    ""}
            </div>
            <div>
                <Button onClick={() => { updateWork() }} variant="contained" color="primary">
                    Update Work
                </Button>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkDetails);

