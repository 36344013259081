import React, { Component } from "react";
import { connect } from "react-redux";
import { create_booking } from "../../../actions/action-booking";
import CommonSnackBar from "../../common/CommonSnackBar";
import Paper from "@material-ui/core/Paper";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import configureStore from "../../../store/configureStore";
import { Auth } from "aws-amplify";
import FormErrors from "../../FormErrors";
import TextField from "@material-ui/core/TextField";
import FormButton from "../../common/FormButton";
import Validate from "../../utility/FormValidation";
import DashboardFooter from "../../layout/DashboardFooter";
import HeaderInner from "../../layout/HeaderInner";

const store = configureStore();

class CreateBooking extends Component {
  constructor() {
    super();

    let r = (Math.random() + 1).toString(36).substring(7);
    var today = new Date();
    var nextweek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    var initialApptTime = nextweek.toISOString().substr(0, 10) + "T10:30";

    this.state = {
      bookingDetails: {
        name: "CP Appointment " + r,
        description: "",
        appointmentStartTime: initialApptTime,
        note: "",
        bookInParent: true,
      },
      typesToshow: [
        { name: "Parent Org Appointment", value: "P" },
        { name: "Internal Appointment", value: "S" },
      ],
      errors: {},
      dateError: "",
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {},
      dateError: "",
    });
  };

  validateForm = () => {
    const { appointmentStartTime, note } = this.state.bookingDetails;
    const errors = {};

    if (!appointmentStartTime) {
      errors.appointmentStartTime = "Appointment Start Time is required";
    }
    if (!note) {
      errors.note = "Note is required";
    }

    return errors;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.clearErrorState();
    const formErrors = this.validateForm();
    const error = Validate(event, this.state);
    const payload = this.state.bookingDetails;
    const path = window.location.pathname.split("/");
    const ouid = path[1];

    if (
      this.state.bookingDetails.appointmentStartTime >
      new Date().toISOString("en", { timeZone: "Asia/Kolkata" })
    ) {
      if (Object.keys(formErrors).length > 0) {
        this.setState({
          errors: { ...this.state.errors, ...formErrors },
        });
      } else {
        payload["type"] = "booking";
        const { create_booking } = this.props;
        await create_booking(payload);
        this.props.history.push(`/${ouid}/appointment-dash`);
      }
    } else {
      this.setState({
        dateError: "Date not valid",
      });
    }
  };

  onInputChange = (event) => {
    const newBookingDetails = { ...this.state.bookingDetails };
    newBookingDetails[event.target.id] = event.target.value;
    this.setState({
      bookingDetails: newBookingDetails,
    });
  };

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().permission;
    return (
      <div>
        <div className="page">
          {/* <SidebarWrapper auth={this.props.auth} permissions={permissions} /> */}
          <HeaderInner />
          <section className="section">
            <div className="container">
              <Paper className="container--narrow">
                <div className="formContainer">
                  <FormErrors formerrors={this.state.errors} />
                  {this.state.dateError && (
                    <div className="form_error">{this.state.dateError}</div>
                  )}
                  <form onSubmit={this.handleSubmit}>
                    <h1 className="formName">
                      Book Appointment with Channel Partner
                    </h1>
                    <div className="form-item-wrapper">
                      <TextField
                        id="appointmentStartTime"
                        label="Appointment Start Time"
                        type="datetime-local"
                        defaultValue={
                          this.state.bookingDetails.appointmentStartTime
                        }
                        sx={{ width: 250 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: new Date().toISOString().slice(0, 16),
                        }}
                        required
                        onChange={this.onInputChange}
                        error={!!this.state.errors.appointmentStartTime}
                        helperText={this.state.errors.appointmentStartTime}
                      />
                    </div>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="note"
                        placeholder="Note"
                        label="Add a note for the meeting"
                        value={this.state.bookingDetails.note}
                        onChange={this.onInputChange}
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        required
                        error={!!this.state.errors.note}
                        helperText={this.state.errors.note}
                      />
                    </div>
                    <div className="field">
                      <p className="control">
                        <FormButton
                          onClick={this.handleSubmit}
                          text="Add"
                        />
                      </p>
                    </div>
                  </form>
                  <DashboardFooter />
                  <CommonSnackBar />
                </div>
              </Paper>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  create_booking: (payload) => dispatch(create_booking(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateBooking);
