import React, { Component } from "react";
import { connect } from "react-redux";
import SidebarWrapper from "../layout/SidebarWrapper";
import Footer from "../layout/Footer";
import configureStore from "../../store/configureStore";
import RegisterFormInvestor from "./InvestorPages/RegisterFormInvestor";
import InvRegisteredUser from "./InvestorPages/InvRegisteredUser";
import { history } from "../../reducers";
import CommonSnackBar from "../common/CommonSnackBar";
import Header from "../layout/Header";

const store = configureStore();

class InvestorUserHome extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    console.log("componentDidMount  : ");
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
  }

  render() {
    const { permissions } = store.getState().permission;
    const { details } = store.getState().myProfile;

    return (
      <div className="page">
        <div className="container">
          <SidebarWrapper auth={this.props.auth} permissions={permissions} />
          <div className="section">
            {details.data.subscriptionStatus === "unsubscribed" &&
            details.data.defaultOrgType !== "SYSTEM" &&
            details.data.defaultOrgType !== "ROOT" ? (
              <RegisterFormInvestor userDetails={details} />
            ) : (
              <InvRegisteredUser userDetails={details} />
            )}
            <CommonSnackBar />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  myProfile: state.myProfile,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InvestorUserHome);
