import React, { Component } from "react";
import { connect } from "react-redux";
import { update_post } from "../../../actions/action-post";
import configureStore from "../../../store/configureStore";
import { get_post } from "../../../actions/action-post";
import Loader from "../../common/Loader";
import SidebarWrapper from "../../layout/SidebarWrapper";
import CommonDelete from "../../common/CommonDelete";
import { timeFormat } from "../../common/CommonFunctions";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { Paper } from "@material-ui/core";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from "../../../reducers";
import { Auth } from "aws-amplify";
import FormErrors from "../../FormErrors";
import Input from "@material-ui/core/Input";
import FormButton from "../../common/FormButton";
import Validate from "../../utility/FormValidation";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

const store = configureStore();

class FaqDetails extends Component {
  constructor() {
    super();
    this.state = {
      postDetails: null,
      categoriesToshow: ["ChannelPartner", "Business", "Investor"],
    };
    EditorState.createEmpty();
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {
    // store.dispatch(hideSnackbar());
  }

  clearErrorState = () => {
    this.setState({
      errors: {},
    });
  };

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("id : ", id);
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const { get_post } = this.props;
    await get_post(activeOuid, activeOuidChain, id);
    console.log("store.getState().post.post[0] : ", store.getState().post.post);
    this.setState({
      postDetails: store.getState().post.post,
    });
    var c = "";
    if (store.getState().post.post.data.content) {
      try {
        c = JSON.parse(store.getState().post.post.data.content);
      } catch (e) {
        c = store.getState().post.post.data.content;
      }
      this.setState({
        editorState: EditorState.createWithContent(convertFromRaw(c)),
      });
    } else {
      this.setState({
        editorState: EditorState.createEmpty(),
      });
    }
  }

  render() {
    const { showGlobalLoader } = store.getState().post;
    const { permissions } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          {showGlobalLoader ? (
            <Loader />
          ) : (
            <div className="List">
              {this.state.postDetails
                ? this.renderPostDetails(this.state.postDetails)
                : "No Posts Details..."}
            </div>
          )}
        </section>
      </div>
    );
  }

  renderPostDetails(postDetails) {
    console.log("postDetails : ", JSON.stringify(postDetails));
    console.log("this.state.postDetails : ", this.state.postDetails);

    return (
      <div className="container">
        <CommonDelete id={this.state.postDetails.data.identifier} type="Faq" />
        <Paper className="">
          <div className="formContainer">
            <FormErrors formerrors={this.state.errors} />
            <form>
              <h1 className="formName">Edit FAQ</h1>
              <div className="form-item-wrapper">
                <Autocomplete
                  id="category"
                  onChange={(event, newValue) => {
                    this.categoryChange(newValue);
                  }}
                  defaultValue={"ChannelPartner"}
                  value={this.state.postDetails.data.category}
                  options={this.state.categoriesToshow}
                  getOptionLabel={(option) => option}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Category"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div className="form-item-wrapper">
                <TextField
                  className="input"
                  id="title"
                  placeholder="Title"
                  value={this.state.postDetails.data.title}
                  onChange={this.onInputChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              {}
              <div className="form-item-wrapper">
                {this.state.editorState ? (
                  <Editor
                    editorState={this.state.editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="form-item-wrapper">
                <TextField
                  className="input"
                  id="featuredImage"
                  placeholder="FeaturedImage"
                  value={this.state.postDetails.data.featuredImage}
                  onChange={this.onInputChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
            </form>
            <div className="field">
              <p className="control">
                <FormButton onClick={() => this.handleSubmit()} text="Update" />
              </p>
            </div>
            <CommonSnackBar />
          </div>
        </Paper>
      </div>
    );
  }

  handleSubmit = async (event) => {
    console.log("in update: ", event);

    this.clearErrorState();
    const error = Validate(event, this.state);
    const payload = this.state.postDetails;
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    } else {
      console.log("in else of update: ", this.props);

      const { update_post } = this.props;
      console.log("await start");

      await update_post(payload);
      console.log("await done");

      this.props.history.push(`/${ouid}/faq-dash`);
    }
  };

  onInputChange = (event) => {
    const newPostDetails = this.state.postDetails;
    newPostDetails.data[event.target.id] = event.target.value;
    this.setState({
      postDetails: newPostDetails,
    });
  };

  async categoryChange(newValue) {
    const newPostDetails = this.state.postDetails;
    newPostDetails.data["category"] = newValue;
    console.log("newValue  newValue : ", newValue);
    this.setState({
      postDetails: newPostDetails,
    });
  }
  onEditorStateChange = (editorState) => {
    const newPostDetails = this.state.postDetails;
    newPostDetails.data["content"] = convertToRaw(
      editorState.getCurrentContent()
    );
    console.log("newValue editorState : ", editorState.getCurrentContent());
    this.setState({
      editorState,
      postDetails: newPostDetails,
    });
  };
}

const mapStateToProps = (state) => ({
  post: state.post,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_post: (activeOuid, activeOuidChain, id) =>
    dispatch(get_post(activeOuid, activeOuidChain, id)),
  update_post: (payload) => dispatch(update_post(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FaqDetails);
