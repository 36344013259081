import React, { useEffect } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import Paper from "@material-ui/core/Paper";

import SearchField from '../../../common/SearchField';

import { get_tags } from "../../../../actions/action-tag";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const store = configureStore();

function renderProjects() {
  console.log("store.getState().tag   :", store.getState().tag);
  const projectList = [];
  store.getState().tag.primaryTags.forEach((project) => {
    let identifier = project.data.identifier;

    projectList.push(
      <div className="projectListCardName">
        <a>{identifier}</a>
      </div>
    );
  });

  return projectList;
}

function Sprint(props) {
  const { activeOuid, activeOuidChain } = store.getState().permission;

  useEffect(() => {
    let filter = { type: "primaryTag", status: "active" };
    store.dispatch(get_tags(activeOuid, activeOuidChain, filter));
  }, [activeOuid, activeOuidChain]);

  return (
    <Paper className="TicketTablePaper">
      <div className="ticketTagHeadingWrap">
        <h3>Projects</h3>
        <IconButton color="primary" aria-label="add to shopping cart">
          <AddCircleIcon />
        </IconButton>
      </div>
      <SearchField onChange={(e) => this.search(e.target.value.split(' '), true)} />
      {renderProjects()}
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  tag: state.tag,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Sprint);
