import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { create_post } from "../../../actions/action-post";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import configureStore from "../../../store/configureStore";
import { Auth } from "aws-amplify";
import FormErrors from "../../FormErrors";
import TextField from "@material-ui/core/TextField";
import FormButton from "../../common/FormButton";
import Validate from "../../utility/FormValidation";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "../../common/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { getAllPostCategoriesByType } from "../../../actions/action-postcategory";
import { setActiveOuid } from "../../../actions/action-permissions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { category } from "../../../reducers/reducer-category";
import { ListItemText } from "@material-ui/core";

const store = configureStore();

const occupations = ["ENGINEER", "BUSINESS", "DOCTOR"];
const applicationModes = ["ONLINE", "OFFLINE"];
const indianStates = [
  "ANDHRA PRADESH",
  "ARUNACHAL PRADESH",
  "ASSAM",
  "BIHAR",
  "CHHATTISGARH",
  "GOA",
  "GUJARAT",
  "HARYANA",
  "HIMACHAL PRADESH",
  "JHARKHAND",
  "KARNATAKA",
  "KERALA",
  "MADHYA PRADESH",
  "MAHARASHTRA",
  "MANIPUR",
  "MEGHALAYA",
  "MIZORAM",
  "NAGALAND",
  "ODISHA",
  "PUNJAB",
  "RAJASTHAN",
  "SIKKIM",
  "TAMIL NADU",
  "TELANGANA",
  "TRIPURA",
  "UTTAR PRADESH",
  "UTTARAKHAND",
  "WEST BENGAL",
];

class CreateGovtScheme extends Component {
  constructor() {
    super();
    let r = (Math.random() + 1).toString(36).substring(7);
    this.state = {
      error: null,
      postDetails: {
        title: "",
        summary: "",
        type: "govtscheme",
        category: "",
        categoryId: "",
        featuredImage: "https://www.gstatic.com/webp/gallery/1.jpg",
        content: "",
        tags: [],
        tagstatus: "",
        applyUrl: "",
      },
      errors: {},
      categories: [],
      loading: true,
      showStates: false,
      showOccupations: false,
      showApplicationModes: false,
      selectedState: [],
      selectedOccupation: [],
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const queryParams = new URLSearchParams(window.location.search);
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchCategories();
  }

  clearErrorState = () => {
    this.setState({
      errors: {},
    });
  };

  async fetchCategories() {
    const { activeOuid, activeOuidChain } = this.props.permission;
    const { getAllPostCategoriesByType } = this.props;
    const postType = "govt-scheme";

    try {
      const response = await getAllPostCategoriesByType(
        postType,
        "ibc",
        activeOuidChain
      );
      const { items } = response;

      if (Array.isArray(items)) {
        this.setState({
          categories: items.map((item) => item.data),
          loading: false,
        });
      } else {
        throw new Error("Fetched data is not in the expected structure");
      }
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  handleSubmit = async (event) => {
    this.clearErrorState();
    const error = Validate(event, this.state);
    const payload = this.state.postDetails;
    const path = window.location.pathname.split("/");
    const ouid = path[1];

    if (payload.tags && payload.tags.length > 0) {
      payload.tagstatus = "Active";
    } else {
      payload.tagstatus = "";
    }

    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    } else {
      const { create_post } = this.props;
      await create_post(payload);
      this.props.history.push(`/${ouid}/govtscheme-dash`);
    }
  };

  onInputChange = (event) => {
    const newPostDetails = { ...this.state.postDetails };
    newPostDetails[event.target.id] = event.target.value;
    this.setState({
      postDetails: newPostDetails,
    });
  };

  handleTagChange = (event) => {
    const { name, checked } = event.target;
    let { tags } = this.state.postDetails;

    if (!Array.isArray(tags)) {
      tags = [];
    }

    if (name === "central") {
      if (checked) {
        // Add CENTRAL and remove state-related tags
        tags = [
          "CENTRAL",
          ...tags.filter(
            (tag) => occupations.includes(tag) || applicationModes.includes(tag)
          ),
        ];
        this.setState({ showStates: false }); // Hide state dropdown if central is selected
      } else {
        tags = tags.filter((tag) => tag !== "CENTRAL");
      }
    } else if (name === "state") {
      if (checked) {
        // Add STATE and remove CENTRAL
        tags = [
          "STATE",
          ...tags.filter(
            (tag) => occupations.includes(tag) || applicationModes.includes(tag)
          ),
        ];
        this.setState({ showStates: true }); // Show state dropdown
      } else {
        tags = tags.filter((tag) => tag !== "STATE");
        this.setState({ showStates: false }); // Hide state dropdown when unchecked
      }
    }
    // Do not store "occupation" or "applicationMode" in tags
    else if (name === "occupation" || name === "applicationMode") {
      this.setState((prevState) => ({
        showOccupations:
          name === "occupation" ? checked : prevState.showOccupations,
        showApplicationModes:
          name === "applicationMode" ? checked : prevState.showApplicationModes,
      }));
    }

    this.setState({
      postDetails: {
        ...this.state.postDetails,
        tags,
        tagstatus: tags.length > 0 ? "Active" : "",
      },
    });
  };

  handleStateChange = (event) => {
    const { value } = event.target;
    const newPostDetails = { ...this.state.postDetails };

    if (!Array.isArray(newPostDetails.tags)) {
      newPostDetails.tags = [];
    }

    newPostDetails.tags = [
      ...newPostDetails.tags.filter(
        (tag) => !indianStates.includes(tag) && tag !== "central"
      ),
      ...value,
    ];

    this.setState({
      postDetails: newPostDetails,
      selectedState: value,
    });
  };

  handleOccupationChange = (event) => {
    const { value } = event.target;
    const newPostDetails = { ...this.state.postDetails };

    newPostDetails.tags = [
      ...newPostDetails.tags.filter((tag) => !occupations.includes(tag)),
      ...value,
    ];

    this.setState({
      postDetails: newPostDetails,
      selectedOccupation: value,
    });
  };

  handleApplicationModeChange = (event) => {
    const { value } = event.target;
    const newPostDetails = { ...this.state.postDetails };

    newPostDetails.tags = [
      ...newPostDetails.tags.filter((tag) => !applicationModes.includes(tag)),
      value,
    ];

    this.setState({
      postDetails: newPostDetails,
      selectedApplicationMode: value,
    });
  };

  onEditorStateChange = (editorState) => {
    const newPostDetails = { ...this.state.postDetails };
    newPostDetails.content = convertToRaw(editorState.getCurrentContent());
    this.setState({
      editorState,
      postDetails: newPostDetails,
    });
  };

  render() {
    const { permissions } = store.getState().permission;
    const {
      categories,
      loading,
      error,
      showStates,
      showOccupations,
      showApplicationModes,
    } = this.state;
    const { showGlobalLoader } = store.getState().permission;
    const { activeOuid, activeOuidChain, activeOuidType } =
      store.getState().permission;

    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div className="container">
            <Paper className="">
              <div className="formContainer">
                <FormErrors formerrors={this.state.errors} />
                <form>
                  <h1 className="formName">Create a Govt. Scheme Post</h1>
                  <div className="form-item-wrapper">
                    {loading ? (
                      <Loader />
                    ) : (
                      <Autocomplete
                        id="category"
                        options={categories}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.setState((prevState) => ({
                            postDetails: {
                              ...prevState.postDetails,
                              categoryId: value ? value.identifier : "",
                              category: value ? value.name : "",
                            },
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Category"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    )}
                  </div>
                  <div className="form-item-wrapper">
                    <TextField
                      className="input"
                      id="title"
                      placeholder="Title"
                      value={this.state.postDetails.title}
                      onChange={this.onInputChange}
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                  <div className="form-item-wrapper">
                    <TextField
                      className="input"
                      id="summary"
                      placeholder="Summary"
                      value={this.state.postDetails.summary}
                      onChange={this.onInputChange}
                      fullWidth
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </div>
                  <div className="form-item-wrapper">
                    <TextField
                      className="input"
                      id="featuredImage"
                      placeholder="Featured Image"
                      value={this.state.postDetails.featuredImage}
                      onChange={this.onInputChange}
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                  <div className="form-item-wrapper">
                    <div style={{ fontSize: "14px", marginBottom: "6px" }}>
                      Tag
                    </div>
                    <div
                      className="form-item-wrapper"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.postDetails.tags.includes(
                              "CENTRAL"
                            )}
                            onChange={this.handleTagChange}
                            name="central"
                            disabled={this.state.postDetails.tags.includes(
                              "STATE"
                            )}
                          />
                        }
                        label="Central"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.postDetails.tags.includes(
                              "STATE"
                            )}
                            onChange={this.handleTagChange}
                            name="state"
                            disabled={this.state.postDetails.tags.includes(
                              "CENTRAL"
                            )}
                          />
                        }
                        label="State"
                      />

                      {this.state.showStates && (
                        <FormControl
                          variant="outlined"
                          style={{ width: "40%", marginTop: "10px" }}
                        >
                          <InputLabel id="state-label">Select State</InputLabel>
                          <Select
                            labelId="state-label"
                            id="state"
                            value={this.state.selectedState || []}
                            onChange={this.handleStateChange}
                            label="Select State"
                            renderValue={(selected) => selected.join(", ")}
                            multiple
                          >
                            {indianStates.map((stateName) => (
                              <MenuItem key={stateName} value={stateName}>
                                <Checkbox
                                  checked={this.state.selectedState.includes(
                                    stateName
                                  )}
                                />
                                <ListItemText primary={stateName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={occupations.some((occupation) =>
                              this.state.postDetails.tags.includes(occupation)
                            )}
                            onChange={this.handleTagChange}
                            name="occupation"
                          />
                        }
                        label="Occupation"
                      />

                      {this.state.showOccupations && (
                        <FormControl
                          variant="outlined"
                          style={{ width: "40%", marginTop: "10px" }}
                        >
                          <InputLabel id="occupation-label">
                            Occupation
                          </InputLabel>
                          <Select
                            labelId="occupation-label"
                            id="occupation"
                            value={this.state.selectedOccupation || []}
                            onChange={this.handleOccupationChange}
                            label="Occupation"
                            renderValue={(selected) => selected.join(", ")}
                            multiple
                          >
                            {occupations.map((occupation) => (
                              <MenuItem key={occupation} value={occupation}>
                                <Checkbox
                                  checked={this.state.selectedOccupation.includes(
                                    occupation
                                  )}
                                />
                                <ListItemText primary={occupation} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={applicationModes.some((mode) =>
                              this.state.postDetails.tags.includes(mode)
                            )}
                            onChange={this.handleTagChange}
                            name="applicationMode"
                          />
                        }
                        label="Application Mode"
                      />

                      {this.state.showApplicationModes && (
                        <FormControl
                          variant="outlined"
                          style={{ width: "40%", marginTop: "10px" }}
                        >
                          <InputLabel id="applicationMode-label">
                            Application Mode
                          </InputLabel>
                          <Select
                            labelId="applicationMode-label"
                            id="applicationMode"
                            value={this.state.selectedApplicationMode || ""}
                            onChange={this.handleApplicationModeChange}
                            label="Application Mode"
                            renderValue={(selected) => selected} // Single selection
                          >
                            {applicationModes.map((mode) => (
                              <MenuItem key={mode} value={mode}>
                                <Checkbox
                                  checked={
                                    this.state.selectedApplicationMode === mode
                                  }
                                />
                                {mode}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                  </div>
                  <div className="form-item-wrapper">
                    <TextField
                      className="input"
                      id="applyUrl"
                      placeholder="Apply Url"
                      value={this.state.postDetails.applyUrl}
                      onChange={this.onInputChange}
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                  <div className="form-item-wrapper">
                    <Editor
                      editorState={this.state.editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange}
                    />
                  </div>
                </form>
                <div className="field">
                  <p className="control">
                    <FormButton
                      onClick={() => this.handleSubmit()}
                      text="Add"
                    />
                  </p>
                </div>
                <CommonSnackBar />
              </div>
            </Paper>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  create_post: (payload) => dispatch(create_post(payload)),
  getAllPostCategoriesByType: (postType, activeOuid, activeOuidChain) =>
    dispatch(getAllPostCategoriesByType(postType, activeOuid, activeOuidChain)),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateGovtScheme);
