import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../layout/Header";
import banner from "../assets/images/bannerimg.png";
import SidebarWrapper from "../layout/SidebarWrapper";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { history } from "../../reducers";
import Footer from "../layout/Footer";

// Images
import homeBanner from "../assets/images/ibcImages/Rocket.png";
import bisconsultation from "../assets/images/ibcImages/Investror-support.png";
import bisreport from "../assets/images/ibcImages/Financial-support.png";
import companyFormation from "../assets/images/ibcImages/Mentor-and-monitoring.png";
import financialSupports from "../assets/images/ibcImages/Report.png";
import analyticTool from "../assets/images/ibcImages/Market-Nalayisisi.png";
import govSchemes from "../assets/images/ibcImages/erp.png";
import Ecommerce from "../assets/images/ibcImages/Ecommerce.png";
import training from "../assets/images/ibcImages/Market-Analysis.png";
import mentorMonitor from "../assets/images/ibcImages/Virtual-Community.png";
// Tools
import accountingSoftware from "../assets/images/ibcImages/Invoice-amico1.png";
import hrmtool from "../assets/images/ibcImages/hrmtool.png";
import upi from "../assets/images/ibcImages/upi.png";
import crm from "../assets/images/ibcImages/crm.png";
import analytics from "../assets/images/ibcImages/analytics.png";
import configureStore from "../../store/configureStore";

const store = configureStore();

export default function Home(props) {
  const { details } = store.getState().myProfile;

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(details).length != 0) {
      console.log("details", details);
      if (
        details.data.defaultOrgType &&
        details.data.defaultOrgType === "biz"
      ) {
        props.history.push(`/${details.data.defaultOrg}/dashboard`);
      } else if (
        details.data.defaultOrgType &&
        details.data.defaultOrgType === "inv"
      ) {
        props.history.push(`/${details.data.defaultOrg}/inv-user-home`);
      } else if (
        details.data.defaultOrgType &&
        details.data.defaultOrgType === "cp"
      ) {
        props.history.push(`/${details.data.defaultOrg}/cp-user-home`);
      }
      // else {
      //   props.history.push(`/${details.data.defaultOrg}/dashboard`);
      // }
    }
  }, [dispatch]);

  return (
    <div className="Homepage">
      {/* <SidebarWrapper
        auth={this.props.auth}
      /> */}
      <Header auth={props.auth} />
      <section className="hero is-primary homePageBanner">
        <div className="hero-body">
          <div className="container">
            <div className="split">
              <div>
                <img src={homeBanner} />
              </div>
              <div>
                <h1>Digital Ecosystem</h1>
                <p>
                  LeaperX is always there for you to solve your problems and to
                  provide assistance at anytime, anywhere. We are here to help
                  your business to reach its full potential through our services
                  like investment portal, Information portal, consultation and
                  also through mentoring and monitoring program.
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  Get started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="homeSection">
        <div className="container">
          <h2 class="section-heading">
            <span>Features</span>
            <span></span>
          </h2>
          <div className="homeServicesCardWrapper">
            <div className="homeServicesCard">
              <img src={bisconsultation} />
              <div className="homeServicesCardHeading">Investor support</div>
              {/* <p className='homeServicesCardText'>IBC provides expert consulting assistance to investors, which can help value new opportunities and also enhance the performance of existing portfolio companies. Our services are tailored to your needs, whether for an acquisition target search or for time-critical due diligence.</p> */}
            </div>
            <div className="homeServicesCard">
              <img src={bisreport} />
              <div className="homeServicesCardHeading">Financial support</div>
              {/* <p className='homeServicesCardText'>IBC will focus on the whole picture of a client’s financial life, including debts, assets, expenses and income, to help clients determine what those goals should be.
                Bank loans- IBC has connections with public and private banks. Hence, we will help to get your bank loans easily and conveniently.</p> */}
            </div>
            <div className="homeServicesCard">
              <img src={companyFormation} />
              <div className="homeServicesCardHeading">
                Mentor and monitoring
              </div>
              {/* <p className='homeServicesCardText'>We are always there for you to closely monitor your business. So we get to know which part of your business needs more attention and guidance. IBC users get online + offline consultation, which makes them more flexible.</p> */}
            </div>
            <div className="homeServicesCard">
              <img src={training} />
              <div className="homeServicesCardHeading">Market study</div>
              {/* <p className='homeServicesCardText'>An in-depth analysis of the market is carried out to determine if the product or service is suitable in a particular market or region where the business shall be established.</p> */}
            </div>
            <div className="homeServicesCard">
              <img src={govSchemes} />
              <div className="homeServicesCardHeading">ERP solution</div>
              {/* <p className='homeServicesCardText'>We provide all kind of ERP solution according to the need, like Accounting software, HR software, Integration with UPI , CRM, Analytics tools and may more.</p> */}
            </div>
            <div className="homeServicesCard">
              <img src={financialSupports} />
              <div className="homeServicesCardHeading">
                Business Project Reports
              </div>
              {/* <p className='homeServicesCardText'>The detailed project report is the foundation of the business and consists of information on economic, technical, financial, managerial and production aspects. We are here to help you prepare the DPR (Detailed Project Report) for your upcoming venture or business ideas and provide a customization facility.</p> */}
            </div>
            <div className="homeServicesCard">
              <img src={analyticTool} />
              <div className="homeServicesCardHeading">Analytical tools</div>
              {/* <p className='homeServicesCardText'>Sales Analytics Tool. Marketing Analytics Tool, Financial Analytics, HR Analytics Social media Analytics, E-commerce Advanced Analytics.</p> */}
            </div>
            <div className="homeServicesCard">
              <img src={mentorMonitor} />
              <div className="homeServicesCardHeading">Virtual community</div>
              {/* <p className='homeServicesCardText'>By using this feature, the customer can complete an online transaction directly from the app</p> */}
            </div>
          </div>
          {/* <Button 
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/login");
            }}
            >
              Get started
          </Button> */}
        </div>
      </section>
      <section className="homeSection">
        <div className="container">
          <h2 class="section-heading">
            <span>Tools</span>
            <span></span>
          </h2>
          <div className="split">
            <div className="ToolsCard">
              <img src={accountingSoftware} />
              Accounting software
            </div>
            <div className="ToolsCard">
              <img src={hrmtool} />
              HR software
            </div>
            <div className="ToolsCard">
              <img src={upi} />
              Integration with UPI{" "}
            </div>
            <div className="ToolsCard">
              <img src={crm} />
              CRM
            </div>
            <div className="ToolsCard">
              <img src={analytics} />
              Analytics tools
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
