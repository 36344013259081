import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../../../reducers";
import FaqCard from "../../common/FaqCard";
import configureStore from "../../../store/configureStore";
import SidebarWrapper from "../../layout/SidebarWrapper";
import SearchField from "../../common/SearchField";
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import { get_all_posts, create_post } from "../../../actions/action-post";
import { DataGrid } from "@material-ui/data-grid";
import { timeFormat } from "../../common/CommonFunctions";
import CommonPopUp from "../../common/CommonPopUp";
import Button from "@material-ui/core/Button";
import Loader from "../../common/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const store = configureStore();

class FaqDash extends Component {
  constructor() {
    super();
    this.state = {
      selectionModel: [],
      postListToShow: [],
    };
  }

  componentDidMount() {
    this.fetchDetails();
  }

  async fetchDetails() {
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);
    this.setState({
      postListToShow: [],
    });
    const { get_all_posts } = this.props;
    await get_all_posts(activeOuid, activeOuidChain, "faq", "", "ibc");
    console.log(
      "get_all_posts store.getState().post.posts ",
      store.getState().post
    );
    this.setState({
      postListToShow: store.getState().post.posts,
    });
  }

  componentWillUnmount() {
    // console.log("componentWillUnmount");
    // this.unregisterHistoryListener()
    // store.dispatch(hideSnackbar());
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  setSelectionModel(selection) {
    this.setState({
      selectionModel: selection,
    });
  }

  renderAddButton() {
    const { activeOuid, userRoles, policies, activeOuidType } =
      store.getState().permission;

    if (activeOuidType === "ROOT") {
      return (
        <Button
          onClick={() => {
            history.push(`/${activeOuid}/create-faq`);
          }}
          variant="contained"
          color="primary"
        >
          Add an FAQ
        </Button>
      );
    } else {
      return null;
    }
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().post;
    const { activeOuid, activeOuidChain } = store.getState().permission;

    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          {showGlobalLoader ? (
            <Loader />
          ) : (
            <div className="container">
              <div className="listHeader">
                <h1 className="postHeading">FAQs</h1>
                <div>{this.renderAddButton()}</div>
              </div>
              <div>
                <div className="List">
                  {this.state.postListToShow.length !== 0
                    ? this.renderPosts(this.state.postListToShow)
                    : "No Posts found..."}
                </div>
              </div>
            </div>
          )}
          <CommonSnackBar />
        </section>
      </div>
    );
  }

  renderPosts(PostList) {
    if (Array.isArray(PostList)) {
      return PostList.map((item) => (
        <div key={item.title}>
          <FaqCard Post={item} />
        </div>
      ));
    } else {
      console.error("PostList is not an array:", PostList);
      return <div>No posts available.</div>;
    }
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  posts: state.post,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_posts: (activeOuid, activeOuidChain, type, category, ouid) =>
    dispatch(get_all_posts(activeOuid, activeOuidChain, type, category, ouid)),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FaqDash);
