import {
  JWT_SET,
  UN_JWT_SET,
  JWT_ERRORED,
  SET_CREDS,
  UNSET_CREDS,
} from "../constant/action-types";
import config from "../config";

const initialState = {
  api: {
    apiEndpoint: `${config.api.invokeUrl}`,
    coursesApiEndpoint: `${config.api.coursesUrl}`,
    dprApiEndpoint: `${config.api.dprUrl}`,
    userSession: "",
    credentials: "",
    error: null,
  },
  ucreds: "",
};

export function jwt(state = initialState, { type, payload }) {
  switch (type) {
    case JWT_SET:
      console.log(
        "JWT_SET",
        payload.session.signInUserSession.idToken.jwtToken
      );
      return {
        ...state,
        api: {
          apiEndpoint: `${config.api.invokeUrl}`,
          coursesApiEndpoint: `${config.api.coursesUrl}`,
          dprApiEndpoint: `${config.api.dprUrl}`,
          userSession: payload,
          credentials: payload.session.signInUserSession,
          error: null,
        },
      };

    case UN_JWT_SET:
      console.log("UN_JWT_SET");
      return {
        ...state,
        api: {
          apiEndpoint: `${config.api.invokeUrl}`,
          coursesApiEndpoint: `${config.api.coursesUrl}`,
          dprApiEndpoint: `${config.api.dprUrl}`,
          userSession: "",
          credentials: "",
          error: null,
        },
      };

    case JWT_ERRORED:
      return {
        ...state,
        api: {
          credentials: "",
          error: payload.error,
        },
      };

    case SET_CREDS:
      console.log("SET_CREDS > username", payload.creds.username);
      console.log("SET_CREDS > payload", payload);
      console.log("SET_CREDS > password", payload.creds.pass);
      return {
        ...state,
        ucreds: payload,
      };

    case UNSET_CREDS:
      console.log("UNSET_CREDS");
      return {
        ...state,
        ucreds: "",
      };

    default:
      return state;
  }
}
