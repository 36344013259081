import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../../../reducers";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import PostCard from "../../common/PostCard";
import configureStore from "../../../store/configureStore";
import SidebarWrapper from "../../layout/SidebarWrapper";
import SearchField from "../../common/SearchField";
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import { get_all_posts, create_post } from "../../../actions/action-post";
import Loader from "../../common/Loader";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import Box from "@material-ui/core/Box";


const styles = (theme) => ({
  root: {
    maxWidth: 280,
    minWidth: 280,
  },
  media: {
    height: 140,
  }, 
    
});

const store = configureStore();

class GovtSchemeDash extends Component {
  constructor() {
    super();
    this.state = {
      selectionModel: [],
      postListToShow: [],
      hasMoreData: false,
      lastEvaluatedKey: {},
      isLoading: false,
    };
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    this.fetchDetails();
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  async fetchDetails() {
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);
    this.setState({
      postListToShow: [],
      isLoading: true,
    });
    const { get_all_posts } = this.props;
    await get_all_posts(
      activeOuid,
      activeOuidChain,
      "govtscheme",
      "",
      "ibc",
      "",
      "",
      5
    );
    console.log(
      "get_all_posts store.getState().post.posts ",
      store.getState().post
    );
    this.setState({
      postListToShow: store.getState().post.posts.items,
      lastEvaluatedKey: store.getState().post.posts.lastEvaluatedKey,
      hasMoreData:
        store.getState().post.posts.lastEvaluatedKey !== null ? true : false,
      isLoading: false,
    });
  }

  async handleNext() {
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const { get_all_posts } = this.props;

    const lastEvaluatedKey = encodeURIComponent(
      this.state.lastEvaluatedKey?.lastEvaluatedKey
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      this.state.lastEvaluatedKey?.lastEvaluatedSortKey
    );

    console.log("lastEvaluatedKey", lastEvaluatedKey, lastEvaluatedSortKey);
    console.log("postListToShow", this.state.postListToShow);


    const endOfView = window.scrollY + window.innerHeight;
    if (lastEvaluatedKey && endOfView) {
      try {
        const response = await get_all_posts(
          activeOuid,
          activeOuidChain,
          "govtscheme",
          "",
          "ibc",
          lastEvaluatedKey,
          lastEvaluatedSortKey,
          5
        );
        const { items } = response;

        this.setState((prevState) => ({
          postListToShow: [...prevState.postListToShow, ...items],
          lastEvaluatedKey: response?.lastEvaluatedKey,
          hasMoreData: response?.lastEvaluatedKey !== null ? true : false,
        }));
      } catch (error) {
        this.setState({ error, loading: false });
      }
    }
  }


  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  setSelectionModel(selection) {
    this.setState({
      selectionModel: selection,
    });
  }

  renderAddButton() {
    const { activeOuid, userRoles, policies, activeOuidType } =
      store.getState().permission;

    if (activeOuidType === "ROOT") {
      return (
        <Button
          onClick={() => {
            history.push(`/${activeOuid}/create-govtscheme`);
          }}
          variant="contained"
          color="primary"
        >
          Add Govt.Scheme
        </Button>
      );
    } else {
      return null;
    }
  }
  renderEditButton(props) {
    const { activeOuid, userRoles, policies, activeOuidType } =
      store.getState().permission;
    const Icontooltip = withStyles({
      tooltip: {
        fontSize: 12,
      },
    })(Tooltip);
    const data = props.Post.data;

    if (activeOuidType === "ROOT") {
      return (
        <Icontooltip title="Edit/Delete">
          <IconButton
            aria-label="edit/delete"
            onClick={() => {
              history.push(`/${activeOuid}/govtscheme/${data.identifier}`);
            }}
          >
            <EditIcon />
          </IconButton>
        </Icontooltip>
      );
    } else {
      return null;
    }
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().post;
    const { activeOuid, activeOuidChain, activeOuidType } =
      store.getState().permission;

    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <div className="container">
              <div className="listHeader">
                <h1 className="postHeading">GOVT SCHEMES</h1>
                <div>{this.renderAddButton()}</div>
              </div>
              {this.state?.postListToShow?.length > 0 && (
                <InfiniteScroll
                  style={{ paddingBottom: "100px" }}
                  dataLength={
                    this.state.postListToShow?.length
                      ? this.state.postListToShow?.length
                      : this.state.postListToShow?.length
                  }
                  next={() => this.handleNext()}
                  hasMore={this.state.hasMoreData}
                  loader={
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <PuffLoader color="#36d7b7" /> */}
                    </Box>
                  }
                  height={`calc(100vh - 150px)`}
                >
                  <div>
                    <div className="CourseWrapperflex">
                      {this.state.postListToShow?.length !== 0
                        ? this.renderPosts(this.state.postListToShow)
                        : "No Posts found..."}
                    </div>
                  </div>
                </InfiniteScroll>
              )}
            </div>
          )}
          <CommonSnackBar />
        </section>
      </div>
    );
  }

  renderPosts(PostList) {
    console.log("PostList : ", JSON.stringify(PostList));
    console.log(
      "this.state.postListToShow.length : ",
      this.state.postListToShow.length
    );
    const { activeOuid } = store.getState().permission;
    const { classes, theme } = this.props;
    return PostList.map((item) => (
      <Card
        className={classes.root}
        key={item.data.title}
        onClick={() => {
          history.push(`/${activeOuid}/govtscheme/${item.data.identifier}`);
        }}
      >
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={item.data.featuredImage}
            title="Govt schemes"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h5" className="postSchemeTitle">
              {item.data.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className="postSchemeContent">
              {item.data.summary}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              history.push(`/${activeOuid}/govtscheme/${item.data.identifier}`);
            }}
          >
            Read more
          </Button>
        </CardActions>
      </Card>
    ));
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  posts: state.post,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_posts: (
    activeOuid,
    activeOuidChain,
    type,
    category,
    ouid,
    lastEvaluatedKey,
    lastEvaluatedSortKey,
    limit
  ) =>
    dispatch(
      get_all_posts(
        activeOuid,
        activeOuidChain,
        type,
        category,
        ouid,
        lastEvaluatedKey,
        lastEvaluatedSortKey,
        limit
      )
    ),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(GovtSchemeDash));
