import React, { useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import AssignmentIcon from "@material-ui/icons/Assignment";
import List from "@material-ui/core/List";
import Link from "@material-ui/core/Link";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Auth } from "aws-amplify";
import { history } from "../../../../reducers";
import configureStore from "../../../../store/configureStore";
import { resetState } from "../../../../actions";
import {
  setActiveOuid,
  setActiveOuidChain,
} from "../../../../actions/action-permissions";
import { setActiveOiudConfigs } from "../../../../actions/action-config";
import { RedirectFunc } from "../../../common/CommonFunctions";
import TopBar from "../../../../components/layout/Topbar";

import Button from "@material-ui/core/Button";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
// Images
import Orgunit from "../../../assets/images/diagram.png";
import Manageuser from "../../../assets/images/group.png";
import OrgUsers from "../../../assets/images/orgusers.png";
import PlanIcon from "../../../assets/images/board.png";
import AuthuserIcon from "../../../assets/images/group.png";
import Queue from "../../../assets/images/queue.png";
import Settings from "../../../assets/images/settings.png";
import Sprint from "../../../assets/images/sprint.png";
import Docs from "../../../assets/images/docs.png";
import Project from "../../../assets/images/project.png";

import AccountCircle from "@material-ui/icons/AccountCircle";

// Material Ui
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Menu from "@material-ui/core/Menu";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import SidebarTree from '../common/SidebarTree';
import AccountTreeIcon from "@material-ui/icons/AccountTree";
// import { Star } from '@material-ui/icons';

// const apps = require('../pages/Apps');

const store = configureStore();

const drawerWidth = 240;
const headerHeight = 75;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: 9,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo: {
    color: "#fff",
    textDecoration: "none",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    marginTop: headerHeight,
    position: "relative",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginTop: 0,
    position: "relative",
    zIndex: 8,
    height: "100%",
    minHeight: "100vh",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },

  sectionDesktop: {
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  select: {
    color: "#fff",
    "&:before": {
      borderColor: "#fff",
    },
    "&:after": {
      borderColor: "#fff",
    },
  },
  icon: {
    fill: "#fff",
  },
  treeroot: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
    padding: 20,
  },
  ticketAppNavlinks: {
    flexGrow: 1,
    textAlign: "center",
  },
  ticketApplink: {
    color: "#fff",
    padding: 10,
    fontSize: 14,
    display: "inline-block",
    cursor: "pointer",
  },
}));

function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [mopen, setMOpen] = React.useState(false);

  const [orgOpen, setOrgOpen] = React.useState(false);
  const [usrOpen, setUsrOpen] = React.useState(false);

  const anchorRef = React.useRef(null);
  const anchorRef2 = React.useRef(null);
  const anchorRef3 = React.useRef(null);
  const [anchorProfile, setAnchorProfile] = React.useState(null);
  const openProfile = Boolean(anchorProfile);
  const isAuthenticated = props.auth.isAuthenticated;
  const { activeOuid, userRoles, policies, activeOuidType } =
    store.getState().permission;
  const { activeOuidCoreConfigs } = store.getState().config;
  const { session } = store.getState().jwt.api.userSession;
  useEffect(() => {
    setActiveOuidValue(store.getState().permission.activeOuid);
  }, [JSON.stringify(props)]);
  const [activeOuidValue, setActiveOuidValue] = React.useState(activeOuid);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setMOpen(false);
    } else if (event.key === "Escape") {
      setMOpen(false);
    }
  }

  const handleToggle = () => {
    setMOpen((prevOpen) => !prevOpen);
  };

  const handleOrgToggle = () => {
    setOrgOpen((prevOpen) => !prevOpen);
  };
  const handleUsrToggle = () => {
    setUsrOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDropDown = async (event) => {
    setActiveOuidValue(event.target.value);
    await store.dispatch(setActiveOuid(event.target.value));
    await store.dispatch(setActiveOuidChain(event.target.value));
    await store.dispatch(setActiveOiudConfigs(event.target.value));
    const { activeOuidCoreConfigs } = store.getState().config;
    if (activeOuidCoreConfigs.data) {
      const deafaultUrl = activeOuidCoreConfigs.data.config.home;
      RedirectFunc(`/${event.target.value}/${deafaultUrl}`);
      return;
    } else {
      RedirectFunc(`/${event.target.value}`);
      return;
    }
  };

  const renderMenuTtem = () => {
    const activeOuidExist = userRoles.find(({ ouid }) => ouid === activeOuid);
    let renderOuids = userRoles;
    if (activeOuidExist) {
      renderOuids = userRoles;
    } else {
      renderOuids.push({ ouid: activeOuid, roles: [] });
    }
    return renderOuids.map((item, index) => (
      <MenuItem key={item.ouid} value={item.ouid}>
        {item.ouid}
      </MenuItem>
    ));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleProfileMenu = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorProfile(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogOut = async (event) => {
    event.preventDefault();
    try {
      Auth.signOut();
      props.auth.setAuthStatus(false);
      props.auth.setUser(null);
      store.dispatch(resetState());
      history.push("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  const orgUnits = [];

  if (true) {
    const newLink = {
      url: `/${activeOuid}/apps/ticketapp`,
      text: "Tickets",
      icon: Docs,
    };
    orgUnits.push(newLink);
  }

  if (true) {
    const newLink = {
      url: `/${activeOuid}/apps/ticketapp/projects`,
      text: "Projects",
      icon: Project,
    };
    orgUnits.push(newLink);
  }
  if (true) {
    const newLink = {
      url: `/${activeOuid}/apps/ticketapp/sprints`,
      text: "Sprints",
      icon: Sprint,
    };
    orgUnits.push(newLink);
  }

  const renderMenuIcon = (icon) => {
    try {
      return (
        <img
          className="nav_icon"
          src={require(`../../../pages/Apps/${icon}`).default}
          alt="Icon"
        />
      );
    } catch (error) {
      return <img className="nav_icon" alt="Icon" />;
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        sx={{ zIndex: (theme) => 9 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component={Link}>
            <Link href="/home" underline="none" className={classes.logo}>
              PROBOT
            </Link>
          </Typography>

          <TopBar />
          <div className={classes.grow} />
          {isAuthenticated && (
            <div>
              <FormControl style={{ minWidth: 120 }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeOuidValue}
                  onChange={handleDropDown}
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  {userRoles.length !== 0 ? (
                    renderMenuTtem()
                  ) : (
                    <MenuItem value="">No item Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          )}
          <div className={classes.sectionDesktop}>
            {isAuthenticated && (
              <div>
                <IconButton aria-label="show notifications" color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </div>
            )}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleProfileMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            {isAuthenticated && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorProfile}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={openProfile}
                onClose={handleProfileClose}
              >
                <MenuItem
                  onClick={() => {
                    history.push(`/${activeOuid}/my-profile`);
                  }}
                >
                  My Profile
                </MenuItem>
                <MenuItem onClick={handleLogOut}>Logout</MenuItem>
              </Menu>
            )}
            {!isAuthenticated && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorProfile}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openProfile}
                onClose={handleProfileClose}
              >
                <MenuItem
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  Login
                </MenuItem>
              </Menu>
            )}
            {isAuthenticated && <div>{session.username}</div>}
          </div>
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <div className={classes.toolbar}>
            <Typography variant="h6" noWrap component="h6">
              {activeOuidValue}
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          {orgUnits.length !== 0 && (
            <>
              <List>
                {orgUnits.map((item) => (
                  <ListItem
                    button
                    key={item.text}
                    onClick={() => {
                      history.push(item.url);
                    }}
                  >
                    <ListItemIcon>
                      {item.icon ? (
                        <img className="nav_icon" src={item.icon} alt="Icon" />
                      ) : (
                        ""
                      )}
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
                {/* <SidebarTree activeOuid={activeOuidValue}/> */}
              </List>
              <Divider />
            </>
          )}
        </Drawer>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  jwt: state.jwt,
  config: state.config,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MiniDrawer);
