import { Category } from "@material-ui/icons";
import {
  FETCH_POSTS_BY_CATEGORY_REQUESTED,
  FETCH_POSTS_BY_CATEGORY_SUCCESS,
  FETCH_POSTS_BY_CATEGORY_ERRORED,
  FETCH_CATEGORY_BY_ID_REQUESTED,
  FETCH_CATEGORY_BY_ID_SUCCESS,
  FETCH_CATEGORY_BY_ID_ERRORED
    
  } from "../constant/action-types";
  const initialState = {
    showGlobalLoader: false,
    categories: [],
    category: {},
    categoryById: {},
    error: {}
  };
  
  export function category(state = initialState, { type, payload }) {
    switch (type) {
  
    
        case FETCH_POSTS_BY_CATEGORY_REQUESTED:
            return {
              ...state,
              showGlobalLoader: true,
              categories: []
            };
      
          case FETCH_POSTS_BY_CATEGORY_SUCCESS:
            return {
              ...state,
              showGlobalLoader: false,
              categories: payload
            };
      
          case FETCH_POSTS_BY_CATEGORY_ERRORED:
            return {
              ...state,
              showGlobalLoader: false,
              error: payload
            };
            case FETCH_CATEGORY_BY_ID_REQUESTED:
              return {
                ...state,
                showGlobalLoader: true,
                categoryById: []
              };
        
            case FETCH_CATEGORY_BY_ID_SUCCESS:
              return {
                ...state,
                showGlobalLoader: false,
                categoryById: payload.data
              };
        
            case FETCH_CATEGORY_BY_ID_ERRORED:
              return {
                ...state,
                showGlobalLoader: false,
                error: payload
              };
      default:
        return state;
    }
  }
  