import React from 'react';
import RegisterForm from './RegisterForm';
import BizRegisteredUser from './BizRegisteredUser';
import InvRegisteredUser from '../InvestorPages/InvRegisteredUser';




export default function SubscribedUser(props) {
  const { userDetails } = props;
  console.log("userDetails.data.defaultOrgType", userDetails.data.defaultOrgType);
  console.log("userDetails.data", userDetails.data);
  return (
    <>
      {userDetails.data.defaultOrgType === 'biz' && <BizRegisteredUser userDetails={userDetails} />}
      {userDetails.data.defaultOrgType === 'inv' && <InvRegisteredUser userDetails={userDetails} />}
      {!userDetails.data.defaultOrgType && <RegisterForm userDetails={userDetails} />}
      {userDetails.data.defaultOrgType === 'root' && <BizRegisteredUser userDetails={userDetails} />}
      {userDetails.data.defaultOrgType === 'system' && <BizRegisteredUser userDetails={userDetails} />}
    </>
  );
}