import React from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import { timeFormat } from "../../../common/CommonFunctions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { create_update_work } from "../../../../actions/action-work";
import Button from "@material-ui/core/Button";
import { history } from "../../../../reducers";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const store = configureStore();

function InvReqWorkDetailsForInv(props) {
  const { workContext, work, usersToshow, closeWorkDash } = props;
  const [user, setUser] = React.useState();

  const [state, setState] = React.useState(work);
  const [error, setError] = React.useState();

  const renderDetails = (details) => {
    return (
      <>
        <br />
        {error && <div className="form_error">{error}</div>}
        <div className="form-item-wrapper">
          <TextField
            id="businessName"
            label="Business Name"
            placeholder="Business Name"
            value={
              details.data.details.investmentRequestDetails.businessName || ""
            }
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="natureofBusiness"
            label="Nature of Business"
            placeholder="Example - Retail Shop, Bakery, Wholesale "
            value={
              details.data.details.investmentRequestDetails.natureofBusiness ||
              ""
            }
            variant="outlined"
          />
        </div>

        <div className="form-item-wrapper">
          <TextField
            id="address"
            label="Business Address"
            placeholder="Business Address"
            value={details.data.details.investmentRequestDetails.address || ""}
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="pincode"
            label="Pincode"
            placeholder="Pincode"
            value={details.data.details.investmentRequestDetails.pincode || ""}
            variant="outlined"
            inputProps={{
              maxLength: 6,
              minLength: 6,
              pattern: "\\d{6}",
            }}
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="phoneNumber"
            label="Phone Number"
            placeholder="Phone Number"
            value={
              details.data.details.investmentRequestDetails.phoneNumber || ""
            }
            variant="outlined"
            // type="tel"
            inputProps={{
              maxLength: 12,
              inputMode: "tel",
              // pattern: "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/",
            }}
          />
        </div>

        <div className="form-item-wrapper">
          <TextField
            id="numberOfStaffs"
            type="number"
            label="Number Of Staffs"
            placeholder="Number Of Staffs"
            value={
              details.data.details.investmentRequestDetails.numberOfStaffs || ""
            }
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="websiteAddress"
            label="Website Address"
            placeholder="Website Address"
            value={
              details.data.details.investmentRequestDetails.websiteAddress || ""
            }
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="primaryOwnerName"
            label="Name Of The Primary Owner"
            placeholder="Name of the owner"
            value={
              details.data.details.investmentRequestDetails.primaryOwnerName ||
              ""
            }
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="primaryOwnerAddress"
            label="Primary Owner's Address"
            placeholder="Primary Owner's Address"
            value={
              details.data.details.investmentRequestDetails
                .primaryOwnerAddress || ""
            }
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="primaryOwnerPhone"
            label="Primary Owner's Contact Phone"
            placeholder="Primary Owner's Phone"
            value={
              details.data.details.investmentRequestDetails.primaryOwnerPhone ||
              ""
            }
            variant="outlined"
            inputProps={{
              maxLength: 12,
              inputMode: "tel",
              // pattern: "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/",
            }}
          />
        </div>
        <br />
        <Typography gutterBottom>Project Costs</Typography>
        <br />
        <div className="form-item-wrapper">
          <TextField
            id="landPurchaseCost"
            name="landPurchaseCost"
            value={
              details.data.details.investmentRequestDetails.landPurchaseCost
            }
            label="Purchase Cost (Rs)"
            placeholder="Purchase Cost (Rs)"
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="buildingPurchaseCost"
            name="buildingPurchaseCost"
            value={
              details.data.details.investmentRequestDetails.buildingPurchaseCost
            }
            label="Purchase Cost (Rs)"
            placeholder="Purchase Cost (Rs)"
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="machinaryPurchaseCost"
            name="machinaryPurchaseCost"
            value={
              details.data.details.investmentRequestDetails
                .machinaryPurchaseCost
            }
            label="Purchase Cost (Rs)"
            placeholder="Purchase Cost (Rs)"
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="computerPurchaseCost"
            name="computerPurchaseCost"
            value={
              details.data.details.investmentRequestDetails.computerPurchaseCost
            }
            label="Purchase Cost (Rs)"
            placeholder="Purchase Cost (Rs)"
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="otherInitialCost"
            name="otherInitialCost"
            value={
              details.data.details.investmentRequestDetails.otherInitialCost
            }
            label="Other Initial Costs  (Rs)"
            placeholder="Other Initial Costs(Rs)"
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="workingCapitalCost"
            name="workingCapitalCost"
            value={
              details.data.details.investmentRequestDetails.workingCapitalCost
            }
            label="Working Capital Needed  (Rs)"
            placeholder="Working Capital Needed (Rs)"
            variant="outlined"
          />
        </div>
        <br />
        <Typography>
          Total Project Costs &nbsp; &nbsp;&nbsp;&nbsp;
          <strong>
            Rs.&nbsp; {details.data.details.investmentRequestDetails.totalCost}
          </strong>
        </Typography>
        <br />
        <br />
      </>
    );
  };

  const userChange = (newValue) => {
    console.log("newValue  newValue : ", newValue);
    if (newValue) {
      setUser(newValue.data.username);
    } else {
      setUser("");
    }
  };

  const onStatusChange = (event) => {
    console.log("state", state);

    console.log("event.target.id", event.target.value);
    setState({ ...state, status: event.target.value });
  };

  const onInputChange = (event) => {
    if (event.target.id) {
      setState({ ...state, [event.target.id]: event.target.value });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  function validatePhoneNumber(input_str) {
    console.log("input_str", input_str);
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return re.test(input_str);
  }

  const updateWork = async (e) => {
    e.preventDefault();
    let updatedWork = work;
    updatedWork["username"] = user;
    updatedWork["status"] = state.status;
    //update rest of the fields specific to investmentRequestDetails
    updatedWork.data.details.investmentRequestDetails.pincode =
      state.data.details.investmentRequestDetails.pincode;

    console.log("updatedWork : ", updatedWork);
    const workid = work.identifier;
    const path = window.location.pathname.split("/");
    const ouid = path[1];

    if (validatePhoneNumber(state.businessDetails.phoneNumber)) {
      if (ouid && workid) {
        let updatePayload = { data: updatedWork };
        await store.dispatch(create_update_work(workid, ouid, updatePayload));
        //window.location.reload();
        props.rerender();
      }
    } else {
      setError("Phone number not valid");
    }
  };

  const currentUserData = usersToshow.find(
    ({ data }) => data.username === work.username
  );

  return (
    <div className="detailsDrawer">
      <Button
        onClick={() => {
          closeWorkDash();
        }}
        variant="contained"
      >
        Close
      </Button>
      <div>{renderDetails(work)}</div>
      <div>{user}</div>
      <div>
        {usersToshow.length !== 0 ? (
          <div className="form-item-wrapper">
            <Autocomplete
              id="combo-box-demo"
              defaultValue={currentUserData}
              options={usersToshow}
              getOptionLabel={(option) => option.data.username}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Select User" variant="outlined" />
              )}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="form-item-wrapper">
        <InputLabel id="Status-label">Status</InputLabel>
        <Select
          labelId="Status-label"
          id="priority"
          name="status"
          value={state.status || ""}
        ></Select>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvReqWorkDetailsForInv);
