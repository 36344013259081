import React, { Component } from "react";
import { connect } from "react-redux";
import { update_post } from "../../../actions/action-post";
import configureStore from "../../../store/configureStore";
import { get_post } from "../../../actions/action-post";
import Loader from "../../common/Loader";
import SidebarWrapper from "../../layout/SidebarWrapper";
import CommonDelete from "../../common/CommonDelete";
import { timeFormat } from "../../common/CommonFunctions";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { ListItemText, Paper } from "@material-ui/core";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from "../../../reducers";
import { Auth } from "aws-amplify";
import FormErrors from "../../FormErrors";
import Input from "@material-ui/core/Input";
import FormButton from "../../common/FormButton";
import Validate from "../../utility/FormValidation";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { getAllPostCategoriesByType } from "../../../actions/action-postcategory";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const store = configureStore();
const occupations = ["ENGINEER", "BUSINESS", "DOCTOR"];
const applicationModes = ["ONLINE", "OFFLINE"];
const indianStates = [
  "ANDHRA PRADESH",
  "ARUNACHAL PRADESH",
  "ASSAM",
  "BIHAR",
  "CHHATTISGARH",
  "GOA",
  "GUJARAT",
  "HARYANA",
  "HIMACHAL PRADESH",
  "JHARKHAND",
  "KARNATAKA",
  "KERALA",
  "MADHYA PRADESH",
  "MAHARASHTRA",
  "MANIPUR",
  "MEGHALAYA",
  "MIZORAM",
  "NAGALAND",
  "ODISHA",
  "PUNJAB",
  "RAJASTHAN",
  "SIKKIM",
  "TAMIL NADU",
  "TELANGANA",
  "TRIPURA",
  "UTTAR PRADESH",
  "UTTARAKHAND",
  "WEST BENGAL",
];

class GovtSchemeDetails extends Component {
  constructor() {
    super();
    this.state = {
      postDetails: null,
      categories: [],
      loading: true,
      error: null,
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
    this.fetchCategories();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  clearErrorState = () => {
    this.setState({
      errors: {},
    });
  };

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const { get_post } = this.props;
    await get_post(activeOuid, activeOuidChain, id);
    const post = store.getState().post.post;

    // Ensure tags is always an array
    let tags = post.data.tags || []; // Use an empty array if tags is undefined or null
    if (typeof tags === "string") {
      tags = [tags]; // Convert string to an array
    }

    // Extract relevant tags
    const selectedState = tags.filter((tag) => indianStates.includes(tag));
    const selectedOccupation = tags.filter((tag) => occupations.includes(tag));
    const selectedApplicationMode = tags.filter((tag) =>
      applicationModes.includes(tag)
    );

    this.setState({
      postDetails: post,
      selectedState, // Initialize selected states
      selectedOccupation, // Initialize selected occupations
      selectedApplicationMode, // Initialize selected application modes
      showStates: tags.includes("STATE"), // Show the state dropdown if STATE is selected
      showOccupations: selectedOccupation.length > 0, // Show occupation dropdown if any occupation is selected
      showApplicationModes: selectedApplicationMode.length > 0,
    });

    if (post.data.content) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(post.data.content)
        ),
      });
    } else {
      this.setState({
        editorState: EditorState.createEmpty(),
      });
    }
  }

  async fetchCategories() {
    const { activeOuid, activeOuidChain } = this.props.permission;
    const { getAllPostCategoriesByType } = this.props;
    const postType = "govt-scheme";
    try {
      const response = await getAllPostCategoriesByType(
        postType,
        "ibc",
        activeOuidChain
      );
      const { items } = response;
      if (Array.isArray(items)) {
        this.setState({
          categories: items.map((item) => item.data),
          loading: false,
        });
      } else {
        throw new Error("Fetched data is not in the expected structure");
      }
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  handleSubmit = async (event) => {
    this.clearErrorState();
    const error = Validate(event, this.state);
    const payload = { ...this.state.postDetails };
    const path = window.location.pathname.split("/");
    const ouid = path[1];

    if (payload.data.tags && payload.data.tags.length > 0) {
      payload.data.tagstatus = "Active";
    } else {
      payload.data.tagstatus = "";
    }

    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    } else {
      const { update_post } = this.props;
      await update_post(payload);
      this.props.history.push(`/${ouid}/govtscheme-dash`);
    }
  };

  onInputChange = (event) => {
    const newPostDetails = this.state.postDetails;
    newPostDetails.data[event.target.id] = event.target.value;
    this.setState({
      postDetails: newPostDetails,
    });
  };

  handleTagChange = (event) => {
    const { name, checked } = event.target;
    let { tags } = this.state.postDetails.data;

    if (!Array.isArray(tags)) {
      tags = [];
    }

    if (name === "central") {
      if (checked) {
        // Add CENTRAL and remove state-related tags
        tags = [
          "CENTRAL",
          ...tags.filter(
            (tag) => occupations.includes(tag) || applicationModes.includes(tag)
          ),
        ];
        this.setState({ showStates: false }); // Hide state dropdown if central is selected
      } else {
        tags = tags.filter((tag) => tag !== "CENTRAL");
      }
    } else if (name === "state") {
      if (checked) {
        // Add STATE and remove CENTRAL
        tags = [
          "STATE",
          ...tags.filter(
            (tag) => occupations.includes(tag) || applicationModes.includes(tag)
          ),
        ];
        this.setState({ showStates: true }); // Show state dropdown
      } else {
        tags = tags.filter((tag) => tag !== "STATE");
        this.setState({ showStates: false }); // Hide state dropdown when unchecked
      }
    } else if (name === "occupation" || name === "applicationMode") {
      this.setState((prevState) => ({
        showOccupations:
          name === "occupation" ? checked : prevState.showOccupations,
        showApplicationModes:
          name === "applicationMode" ? checked : prevState.showApplicationModes,
      }));
    }

    this.setState({
      postDetails: {
        ...this.state.postDetails,
        data: {
          ...this.state.postDetails.data,
          tags,
        },
      },
    });
  };

  handleStateChange = (event) => {
    const { value } = event.target;
    const newPostDetails = { ...this.state.postDetails };

    // Ensure tags is an array before performing operations on it
    if (!Array.isArray(newPostDetails.data.tags)) {
      newPostDetails.data.tags = [];
    }

    // Remove only the "central" tag and other state tags, keep occupation and application modes
    newPostDetails.data.tags = [
      ...newPostDetails.data.tags.filter(
        (tag) => !indianStates.includes(tag) && tag !== "central"
      ),
      ...value,
    ];

    this.setState({
      postDetails: newPostDetails,
      selectedState: value,
    });
  };

  handleOccupationChange = (event) => {
    const { value } = event.target;
    const newPostDetails = { ...this.state.postDetails };

    newPostDetails.data.tags = [
      ...newPostDetails.data.tags.filter((tag) => !occupations.includes(tag)),
      ...value,
    ];

    this.setState({
      postDetails: newPostDetails,
      selectedOccupation: value,
    });
  };

  handleApplicationModeChange = (event) => {
    const { value } = event.target;
    const newPostDetails = { ...this.state.postDetails };

    newPostDetails.data.tags = [
      ...newPostDetails.data.tags.filter(
        (tag) => !applicationModes.includes(tag)
      ),
      value,
    ];

    this.setState({
      postDetails: newPostDetails,
      selectedApplicationMode: value,
    });
  };

  onEditorStateChange = (editorState) => {
    const newPostDetails = this.state.postDetails;
    newPostDetails.data["content"] = convertToRaw(
      editorState.getCurrentContent()
    );
    this.setState({
      editorState,
      postDetails: newPostDetails,
    });
  };

  render() {
    const { showGlobalLoader } = store.getState().post;
    const { permissions } = store.getState().permission;
    const { categories, loading, error, postDetails } = this.state;

    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          {showGlobalLoader ? (
            <Loader />
          ) : (
            <div className="List">
              {postDetails
                ? this.renderPostDetails(postDetails)
                : "No Posts Details..."}
            </div>
          )}
        </section>
      </div>
    );
  }

  renderPostDetails(postDetails) {
    const tags = Array.isArray(postDetails.data.tags)
      ? postDetails.data.tags
      : [];

    return (
      <div className="container">
        <CommonDelete id={postDetails.data.identifier} type="Govtscheme" />
        <Paper className="">
          <div className="formContainer">
            <FormErrors formerrors={this.state.errors} />
            <form>
              <h1 className="formName">Edit Govt Scheme</h1>
              <div className="form-item-wrapper">
                <Autocomplete
                  id="category"
                  options={this.state.categories}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    this.setState((prevState) => ({
                      postDetails: {
                        ...prevState.postDetails,
                        data: {
                          ...prevState.postDetails.data,
                          categoryId: value ? value.identifier : "",
                          category: value ? value.name : "",
                        },
                      },
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  value={
                    this.state.categories.find(
                      (category) =>
                        category.identifier === postDetails.data.categoryId
                    ) || ""
                  }
                />
              </div>
              <div className="form-item-wrapper">
                <TextField
                  className="input"
                  id="title"
                  placeholder="Title"
                  value={postDetails.data.title}
                  onChange={this.onInputChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div className="form-item-wrapper">
                <TextField
                  className="input"
                  id="summary"
                  placeholder="Summary"
                  value={this.state.postDetails.data.summary}
                  onChange={this.onInputChange}
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                />
              </div>
              <div className="form-item-wrapper">
                <TextField
                  className="input"
                  id="featuredImage"
                  placeholder="FeaturedImage"
                  value={postDetails.data.featuredImage}
                  onChange={this.onInputChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div className="form-item-wrapper">
                <div style={{ fontSize: "14px", marginBottom: "6px" }}>Tag</div>
                <div
                  className="form-item-wrapper"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tags.includes("CENTRAL")}
                        onChange={this.handleTagChange}
                        name="central"
                        disabled={tags.includes("STATE")}
                      />
                    }
                    label="Central"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tags.includes("STATE")}
                        onChange={this.handleTagChange}
                        name="state"
                        disabled={tags.includes("CENTRAL")}
                      />
                    }
                    label="State"
                  />

                  {this.state.showStates && (
                    <FormControl
                      variant="outlined"
                      style={{ width: "40%", marginTop: "10px" }}
                    >
                      <InputLabel id="state-label">Select State</InputLabel>
                      <Select
                        labelId="state-label"
                        id="state"
                        value={this.state.selectedState || []}
                        onChange={this.handleStateChange}
                        label="Select State"
                        renderValue={(selected) => selected.join(", ")}
                        multiple
                      >
                        {indianStates.map((stateName) => (
                          <MenuItem key={stateName} value={stateName}>
                            <Checkbox
                              checked={this.state.selectedState.includes(
                                stateName
                              )}
                            />
                            <ListItemText primary={stateName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tags.some((tag) => occupations.includes(tag))}
                        onChange={this.handleTagChange}
                        name="occupation"
                      />
                    }
                    label="Occupation"
                  />

                  {this.state.showOccupations && (
                    <FormControl
                      variant="outlined"
                      style={{ width: "40%", marginTop: "10px" }}
                    >
                      <InputLabel id="occupation-label">Occupation</InputLabel>
                      <Select
                        labelId="occupation-label"
                        id="occupation"
                        value={this.state.selectedOccupation || []}
                        onChange={this.handleOccupationChange}
                        label="Occupation"
                        renderValue={(selected) => selected.join(", ")}
                        multiple
                      >
                        {occupations.map((occupation) => (
                          <MenuItem key={occupation} value={occupation}>
                            <Checkbox
                              checked={this.state.selectedOccupation.includes(
                                occupation
                              )}
                            />
                            <ListItemText primary={occupation} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tags.some((tag) =>
                          applicationModes.includes(tag)
                        )}
                        onChange={this.handleTagChange}
                        name="applicationMode"
                      />
                    }
                    label="Application Mode"
                  />

                  {this.state.showApplicationModes && (
                    <FormControl
                      variant="outlined"
                      style={{ width: "40%", marginTop: "10px" }}
                    >
                      <InputLabel id="applicationMode-label">
                        Application Mode
                      </InputLabel>
                      <Select
                        labelId="applicationMode-label"
                        id="applicationMode"
                        value={this.state.selectedApplicationMode || ""}
                        onChange={this.handleApplicationModeChange}
                        label="Application Mode"
                        renderValue={(selected) => selected}
                      >
                        {applicationModes.map((mode) => (
                          <MenuItem key={mode} value={mode}>
                            <Checkbox
                              checked={
                                this.state.selectedApplicationMode === mode
                              }
                            />
                            {mode}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
              </div>
              <div className="form-item-wrapper">
                <TextField
                  className="input"
                  id="applyUrl"
                  placeholder="Apply Url"
                  value={this.state.postDetails.data.applyUrl}
                  onChange={this.onInputChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div className="form-item-wrapper">
                {this.state.editorState && (
                  <Editor
                    editorState={this.state.editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                  />
                )}
              </div>
            </form>
            <div className="field">
              <p className="control">
                <FormButton onClick={() => this.handleSubmit()} text="Update" />
              </p>
            </div>
            <CommonSnackBar />
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  post: state.post,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_post: (activeOuid, activeOuidChain, id) =>
    dispatch(get_post(activeOuid, activeOuidChain, id)),
  update_post: (payload) => dispatch(update_post(payload)),
  getAllPostCategoriesByType: (postType, activeOuid, activeOuidChain) =>
    dispatch(getAllPostCategoriesByType(postType, activeOuid, activeOuidChain)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GovtSchemeDetails);
