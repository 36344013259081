import React, { Component } from "react";
import SidebarWrapper from "../../layout/SidebarWrapper";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import configureStore from "../../../store/configureStore";
import hrm from "../../assets/images/ibcImages/hrm.svg";
import crm from "../../assets/images/ibcImages/crm.svg";
import ecom from "../../assets/images/ibcImages/ecom.svg";
import dprCs from "../../assets/images/ibcImages/dpr-commingsoon.svg";
import accountCs from "../../assets/images/ibcImages/account-commingsoon.svg";

import virtualCs from "../../assets/images/ibcImages/vc-commingsoon.svg";
import meeting from "../../assets/images/ibcImages/meetings.svg";
import investment from "../../assets/images/ibcImages/investment.svg";
import loan from "../../assets/images/ibcImages/loan.svg";
import info from "../../assets/images/ibcImages/info.svg";
import tutorail from "../../assets/images/ibcImages/tutorial.svg";
import appconfig from "../../../config";

const store = configureStore();

class OrgHome extends Component {
  render() {
    const { activeOuid, permission } = store.getState().permission;
    const { jwtToken } = store.getState().jwt.api.credentials.idToken;
    var hrmAppUrl = appconfig.appui.hrmUrl + "?token=" + jwtToken;
    var ecomAppUrl = appconfig.appui.ecomUrl + "?token=" + jwtToken;
    var crmAppUrl = appconfig.appui.crmUrl + "?token=" + jwtToken;
    var vcAppUrl = appconfig.appui.vcUrl + "?token=" + jwtToken;
    var accountsUrl = appconfig.appui.accountsUrl + "?token=" + jwtToken;
    console.log("hrmAppUrl : ", hrmAppUrl);
    console.log("jwtToken : ", jwtToken);
    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} />
        <section className="hero is-primary container--narrow">
          <div className="HrmAppHomeListWrapper">
            <div>
              <a href={hrmAppUrl}>
                <img src={hrm} />
              </a>
              <p className="AppLabel">HRM</p>
            </div>
            <div>
              <a href={crmAppUrl}>
                <img src={crm} />
              </a>
              <p className="AppLabel">CRM </p>
            </div>
            <div>
              <a href={vcAppUrl}>
                <img src={virtualCs} />
              </a>
              <p className="AppLabel">IBC Connect</p>
            </div>
            <div>
              <Link to={`/${activeOuid}/dprreq-dash`} className="">
                <img src={dprCs} />
              </Link>
              <p className="AppLabel">DPR</p>
            </div>
            <div>
              <Link to={`/${activeOuid}/payroll`} className="">
                <img src={accountCs} />
              </Link>
              <p className="AppLabel">Accounts</p>
            </div>
          </div>
          <div className="homeMisServiceWrap">
            <Link
              to={`/${activeOuid}/appointment-dash`}
              className="HrmAppHomeListItem"
            >
              <img src={meeting} />
            </Link>
            <Link
              to={`/${activeOuid}/investreq-dash`}
              className="HrmAppHomeListItem"
            >
              <img src={investment} />
            </Link>
            <Link
              to={`/${activeOuid}/loanreq-dash`}
              className="HrmAppHomeListItem"
            >
              <img src={loan} />
            </Link>
            <Link
              to={`/${activeOuid}/user-govtscheme`}
              className="HrmAppHomeListItem"
            >
              <img src={info} />
            </Link>
            {/* <Link to={`/${activeOuid}/payroll`} className="HrmAppHomeListItem">
            <img src={dm} />
          </Link> */}
            <Link
              to={`/${activeOuid}/course-dash`}
              className="HrmAppHomeListItem"
            >
              <img src={tutorail} />
            </Link>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OrgHome);
