/* eslint-disable import/prefer-default-export */
import {
  JWT_SET,
  UN_JWT_SET,
  JWT_ERRORED,
  SET_CREDS,
  UNSET_CREDS,
} from "../constant/action-types";

export const setJwt = (session) => ({
  type: JWT_SET,
  payload: {
    session,
  },
});

export const unSetJwt = () => ({
  type: UN_JWT_SET,
});

export const setError = (error) => ({
  type: JWT_ERRORED,
  payload: {
    error,
  },
});

export const setCreds = (creds) => ({
  type: SET_CREDS,
  payload: {
    creds,
  },
});

export const unsetCreds = () => ({
  type: UNSET_CREDS,
});
