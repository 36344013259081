/* eslint-disable import/prefer-default-export */
import {
  LOGIN_REQUSTED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
};
// The Default Filter.
export function auth_state(state = defaultState, action) {
  switch (action.type) {
    case LOGIN_REQUSTED:
      console.log("LOGIN_REQUSTED");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case LOGIN_SUCCESS:
      console.log("LOGIN_SUCCESS");
      return {
        ...state,
        showGlobalLoader: false,
      };

    case LOGIN_FAILED:
      console.log("LOGIN_FAILED");
      return {
        ...state,
        showGlobalLoader: false,
      };

    default:
      return state;
  }
}
