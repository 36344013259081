import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import { timeFormat, downloadFromUrl } from "../../../common/CommonFunctions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { create_update_work } from "../../../../actions/action-work";
import Button from "@material-ui/core/Button";
import { history } from "../../../../reducers";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import file from "../../../../components/assets/images/ibcImages/janani.pdf";
import down from "../../../../components/assets/images/ibcImages/down.png";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  button: {
    position: "absolute",
    right: "0",
    top: "0",
    background: "rebeccapurple",
    color: "#fff",
    borderRadius: 0,
  },
  download: {
    display: "flex",
    flexDirection: "column",
    // background: "#fc4e4f",
    border: "none",
    color: "#fff",
    margin: "10px auto 10px auto",
    borderRadius: "5px",
    padding: "0",
  },
}));

function InvReqWorkDetails(props) {
  const classes = useStyles();
  const { workContext, work, usersToshow, closeWorkDash } = props;
  const [user, setUser] = React.useState();
  const [video, setVideo] = React.useState("");

  const { activeOuid } = store.getState().permission;

  const [state, setState] = React.useState(work);

  const getPitchVideo = async (url) => {
    var s3uri = new URL(url);
    console.log("url invrequest pitch video", s3uri);
    var key = s3uri.pathname.slice(1);
    // const key = url.split("/").pop();
    console.log("key", key);

    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "get";
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/document?getDownloadSignedUrl=true&file=${key}`;
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log(
            "signed url download : ",
            JSON.stringify(response.data.getURL)
          );
          setVideo(response.data.getURL);
          // return(JSON.stringify(response.data.getURL));
        })
        .catch((err) => {
          console.log("signed url get failed : ", err);
        });
    }
  };

  const getDpr = async (url) => {
    var s3uri = new URL(url);
    console.log("url invrequest dpr ", s3uri);
    var key = s3uri.pathname.slice(1);
    console.log("key", key);

    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "get";
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/document?getDownloadSignedUrl=true&file=${key}`;
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log("signed url get response : ", JSON.stringify(response));
          console.log(
            "signed url download : ",
            JSON.stringify(response.data.getURL)
          );
          await downloadFromUrl(response.data.getURL, "dpr");
        })
        .catch((err) => {
          console.log("signed url get failed : ", err);
        });
    }
  };

  const renderDetails = (details) => {
    getPitchVideo(details.data.details.investmentRequestDetails.pitchVideo);
    console.log("video", video);
    return (
      <>
        <div className="split alignStart">
          <div>
            {video != "" && <video src={video} width="100%" controls></video>}

            <button
              onClick={() => {
                getDpr(details.data.details.investmentRequestDetails.dpr);
              }}
              className={classes.download}
            >
              <img src={down} />
              <div className="download_text">Download DPR</div>
            </button>

            {/* <Button
              onClick={() => {
                getDpr(details.data.details.investmentRequestDetails.dpr);
              }}
              variant="contained"
              color="primary"
              // className={classes.download}
              InputProps={{
                className: classes.download,
              }}
            >
              <img src={down} />
              <div>Download</div>
            </Button> */}

            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Business Name: </div>
              <div className="invReqDetailsData">
                {details.data.details.investmentRequestDetails.businessName ||
                  ""}
              </div>
            </div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Nature of Business</div>
              <div className="invReqDetailsData">
                {details.data.details.investmentRequestDetails
                  .natureofBusiness || ""}
              </div>
            </div>

            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Business Address</div>
              <div className="invReqDetailsData">
                {details.data.details.investmentRequestDetails.address || ""}
              </div>
            </div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Pincode</div>
              <div className="invReqDetailsData">
                {details.data.details.investmentRequestDetails.pincode || ""}
              </div>
            </div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Phone Number</div>
              <div className="invReqDetailsData">
                {details.data.details.investmentRequestDetails.phoneNumber ||
                  ""}
              </div>
            </div>
          </div>
          <div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Number Of Staffs</div>
              <div className="invReqDetailsData">
                {details.data.details.investmentRequestDetails.numberOfStaffs ||
                  ""}
              </div>
            </div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Website Address</div>
              <div className="invReqDetailsData">
                {details.data.details.investmentRequestDetails.websiteAddress ||
                  ""}
              </div>
            </div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">
                Name Of The Primary Owner
              </div>
              <div className="invReqDetailsData">
                {details.data.details.investmentRequestDetails
                  .primaryOwnerName || ""}
              </div>
            </div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Primary Owner's Address</div>
              <div className="invReqDetailsData">
                {details.data.details.investmentRequestDetails
                  .primaryOwnerAddress || ""}
              </div>
            </div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">
                Primary Owner's Contact Phone
              </div>
              <div className="invReqDetailsData">
                {details.data.details.investmentRequestDetails
                  .primaryOwnerPhone || ""}
              </div>
            </div>
          </div>
          <div>
            <Typography gutterBottom>Project Costs</Typography>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Land Purchase Cost (Rs)</div>
              <div className="invReqDetailsData">
                {details.data.details.investmentRequestDetails.landPurchaseCost}
              </div>
            </div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Building Purchase Cost</div>
              <div className="invReqDetailsData">
                {
                  details.data.details.investmentRequestDetails
                    .buildingPurchaseCost
                }
              </div>
            </div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Machinary Purchase Cost</div>
              <div className="invReqDetailsData">
                {
                  details.data.details.investmentRequestDetails
                    .machinaryPurchaseCost
                }
              </div>
            </div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Computer Purchase Cost</div>
              <div className="invReqDetailsData">
                {
                  details.data.details.investmentRequestDetails
                    .computerPurchaseCost
                }
              </div>
            </div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">Other Initial Costs (Rs)</div>
              <div className="invReqDetailsData">
                {details.data.details.investmentRequestDetails.otherInitialCost}
              </div>
            </div>
            <div className="form-item-wrapper">
              <div className="invReqDetailsLabel">
                Working Capital Needed (Rs)
              </div>
              <div className="invReqDetailsData">
                {
                  details.data.details.investmentRequestDetails
                    .workingCapitalCost
                }
              </div>
            </div>
          </div>
        </div>

        <br />
        <Typography>
          Total Project Costs &nbsp; &nbsp;&nbsp;&nbsp;
          <strong>
            Rs.&nbsp; {details.data.details.investmentRequestDetails.totalCost}
          </strong>
        </Typography>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(`/${activeOuid}/appointment-dash`);
          }}
        >
          Schedule a meeting
        </Button>
      </>
    );
  };

  // const userChange = (newValue) => {
  //   console.log("newValue  newValue : ", newValue);
  //   if (newValue) {
  //     setUser(newValue.data.username);
  //   } else {
  //     setUser("");
  //   }
  // };

  // const onStatusChange = (event) => {
  //   console.log("state", state);

  //   console.log("event.target.id", event.target.value);
  //   setState({ ...state, status: event.target.value });
  // };

  // const onInputChange = (event) => {
  //   if (event.target.id) {
  //     setState({ ...state, [event.target.id]: event.target.value });
  //   } else {
  //     setState({ ...state, [event.target.name]: event.target.value });
  //   }
  // };

  // const updateWork = async () => {
  //   let updatedWork = work;
  //   updatedWork["username"] = user;
  //   updatedWork["status"] = state.status;
  //   //update rest of the fields specific to investmentRequestDetails
  //   updatedWork.data.details.investmentRequestDetails.pincode =
  //     state.data.details.investmentRequestDetails.pincode;

  //   console.log("updatedWork : ", updatedWork);
  //   const workid = work.identifier;
  //   const path = window.location.pathname.split("/");
  //   const ouid = path[1];
  //   if (ouid && workid) {
  //     let updatePayload = { data: updatedWork };
  //     await store.dispatch(create_update_work(workid, ouid, updatePayload));
  //     //window.location.reload();
  //     props.rerender();
  //   }
  // };

  const currentUserData = usersToshow.find(
    ({ data }) => data.username === work.username
  );

  return (
    <div className="invRequestDetailsDrawer">
      <Button
        onClick={() => {
          closeWorkDash();
        }}
        variant="contained"
        // className="detailsCloseBtn"
        className={classes.button}
      >
        <CloseIcon />
      </Button>
      <div>{renderDetails(work)}</div>
      <div>{user}</div>
      <div>
        {usersToshow.length !== 0 ? (
          <div className="form-item-wrapper">
            <Autocomplete
              id="combo-box-demo"
              defaultValue={currentUserData}
              options={usersToshow}
              getOptionLabel={(option) => option.data.username}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Select User" variant="outlined" />
              )}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvReqWorkDetails);
