import {
  CREATE_WORKORDER_REQUESTED,
  CREATE_WORKORDER_SUCCESS,
  CREATE_WORKORDER_ERRORED,
  GET_ALL_WORKORDERS_REQUESTED,
  GET_ALL_WORKORDERS_SUCCESS,
  GET_ALL_WORKORDERS_ERRORED,
  GET_WORKORDER_REQUESTED,
  GET_WORKORDER_SUCCESS,
  GET_WORKORDER_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const create_workorder_requested = () => ({
  type: CREATE_WORKORDER_REQUESTED,
});

export const create_workorder_success = (payload) => ({
  type: CREATE_WORKORDER_SUCCESS,
  payload,
});

export const create_workorder_errored = (error) => ({
  type: CREATE_WORKORDER_ERRORED,
  error,
});

export const create_workorder = (payload) => async (dispatch) => {
  dispatch(create_workorder_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`create_workorder`);
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${apiEndpoint}/workorder`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Workorder created Successfully", "success"));
        console.log("Workorder created Successfully", "success", response);

        dispatch(create_workorder_success(response.data));
      })
      .catch((err) => {
        console.log("Workorder creation Failed");
        dispatch(showSnackbar("Workorder creation Failed", "error"));
        dispatch(create_workorder_errored(err));
      });
  }
  return null;
};

export const get_all_workorders_requested = () => ({
  type: GET_ALL_WORKORDERS_REQUESTED,
});

export const get_all_workorders_success = (payload) => ({
  type: GET_ALL_WORKORDERS_SUCCESS,
  payload,
});

export const get_all_workorders_errored = (error) => ({
  type: GET_ALL_WORKORDERS_ERRORED,
  error,
});

export const get_all_workorders =
  (activeOuid, activeOuidChain, type) => async (dispatch) => {
    dispatch(get_all_workorders_requested());
    console.log(`get_all_workorders`);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${apiEndpoint}/workorder?type=${type}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Workorders Fetched Successfully");
          dispatch(get_all_workorders_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Workorders Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Workorders Failed", "error"));
          }
          dispatch(get_all_workorders_errored(err));
        });
    }
    return null;
  };

export const get_workorder =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(get_workorder_requested());
    console.log(`get_workorder`);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${apiEndpoint}/workorder/${id}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get Fetched Successfully");
          dispatch(get_workorder_success(response.data));
        })
        .catch((err) => {
          console.log("Get Workorder Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Workorder By Failed", "error"));
          }
          dispatch(get_workorder_errored(err));
        });
    }
    return null;
  };

export const get_workorder_requested = () => ({
  type: GET_WORKORDER_REQUESTED,
});

export const get_workorder_success = (payload) => ({
  type: GET_WORKORDER_SUCCESS,
  payload,
});

export const get_workorder_errored = (error) => ({
  type: GET_WORKORDER_ERRORED,
  error,
});
