import React, { Component } from "react";
import { connect } from "react-redux";
import CommonSnackBar from "../../common/CommonSnackBar";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import configureStore from "../../../store/configureStore";
import FormErrors from "../../FormErrors";
import FormButton from "../../common/FormButton";
import { create_update_work } from "../../../actions/action-work";
import { get_all_teamuser } from "../../../actions/action-teamuser";
import { get_all_orguesr } from "../../../actions/action-orguser";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Loader from "../../common/Loader";

const store = configureStore();

class Work extends Component {
  constructor() {
    super();
    this.state = {
      createContext: "",
      workDetails: {},
      usersToshow: [],
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    const urlParams = new URLSearchParams(window.location.search);
    const teamId = urlParams.get("teamId");
    if (teamId) {
      const { get_all_teamuser } = this.props;
      await get_all_teamuser(ouid, teamId);
      this.setState({
        createContext: "team",
        usersToshow: store.getState().teamUserProfile.teamUserProfiles,
      });
    } else {
      const { get_all_orguesr } = this.props;
      await get_all_orguesr(ouid);
      const { orgUserProfiles } = store.getState().orgUserProfile;
      this.setState({
        createContext: "org",
        usersToshow: orgUserProfiles,
      });
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {},
    });
  };

  handleSubmit = async (event) => {
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    const payload = this.state.workDetails;
    const urlParams = new URLSearchParams(window.location.search);
    const teamId = urlParams.get("teamId");
    if (teamId) {
      if (teamId !== null) {
        payload["teamId"] = teamId;
      }
      payload["type"] = "Task";
      if (!payload["status"] || payload["status"] === "") {
        payload["status"] = "OPEN";
      }
      const { create_update_work } = this.props;
      await create_update_work("", ouid, payload, "");
      this.props.history.push(`/${ouid}/team/${teamId}/board`);
    } else {
      payload["type"] = "Task";
      if (!payload["status"] || payload["status"] === "") {
        payload["status"] = "OPEN";
      }
      const { create_update_work } = this.props;
      await create_update_work("", ouid, payload, "");
      this.props.history.push(`/${ouid}/org-workqueue-dash`);
    }
  };

  onInputChange = (event) => {
    const newworkDetails = this.state.workDetails;
    newworkDetails[event.target.id] = event.target.value;
    this.setState({
      workDetails: newworkDetails,
    });
  };

  userChange(newValue) {
    console.log("newValue  newValue : ", newValue);
    if (newValue) {
      const newworkDetails = this.state.workDetails;
      newworkDetails["username"] = newValue.data.username;
      newworkDetails["userDisplayName"] = newValue.data.name;
      newworkDetails["status"] = "ASSIGNED";
      this.setState({
        workDetails: newworkDetails,
      });
    } else {
      const newworkDetails = this.state.workDetails;
      newworkDetails["username"] = "";
      newworkDetails["status"] = "OPEN";

      this.setState({
        workDetails: newworkDetails,
      });
    }
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().teamUserProfile;
    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div className="container">
            <Paper className="container--narrow">
              <div className="formContainer">
                <FormErrors formerrors={this.state.errors} />
                <form>
                  <h1 className="formName">Add Task</h1>
                  <div className="form-item-wrapper">
                    <TextField
                      className="input"
                      id="title"
                      placeholder="Work Title"
                      value={this.state.workDetails.title}
                      onChange={this.onInputChange}
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                  <div className="form-item-wrapper">
                    <TextField
                      className="input"
                      id="detail"
                      placeholder="Detail"
                      value={this.state.workDetails.detail}
                      onChange={this.onInputChange}
                      fullWidth
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </div>
                  <div className="form-item-wrapper">
                    <TextField
                      className="input"
                      id="summary"
                      placeholder="Add a summary of your task"
                      value={this.state.workDetails.summary}
                      onChange={this.onInputChange}
                      fullWidth
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </div>

                  {showGlobalLoader ? (
                    <Loader />
                  ) : this.state.usersToshow.length !== 0 ? (
                    <div className="form-item-wrapper">
                      <InputLabel htmlFor="combo-box-demo">
                        Select a user in your company you want to assign this
                        task
                      </InputLabel>
                      <Autocomplete
                        id="combo-box-demo"
                        // value={this.state.selectedCp}
                        onChange={(event, newValue) => {
                          this.userChange(newValue);
                        }}
                        options={this.state.usersToshow}
                        getOptionLabel={(option) => option.data.name}
                        style={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select User"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  ) : (
                    "No Users Found....."
                  )}
                </form>
                <div className="field">
                  <p className="control">
                    <FormButton
                      onClick={() => this.handleSubmit()}
                      text="Add"
                    />
                  </p>
                </div>
                <CommonSnackBar />
              </div>
            </Paper>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  teamUserProfile: state.teamUserProfile,
  orgUserProfile: state.orgUserProfile,
});

const mapDispatchToProps = (dispatch) => ({
  create_update_work: (workid, ouid, payload, type) =>
    dispatch(create_update_work(workid, ouid, payload, type)),
  get_all_teamuser: (ouid, teamId) => dispatch(get_all_teamuser(ouid, teamId)),
  get_all_orguesr: (activeOuid) => dispatch(get_all_orguesr(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Work);
