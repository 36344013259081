import React from 'react';
import configureStore from '../../../store/configureStore';
import ActionCard from '../UserPage/ActionCard';
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { history } from '../../../reducers';
import Banner from '../../assets/images/ibcImages/homebanner.png';
import Business from "../../assets/images/ibcImages/buildings.png";

import Businesses from '../../assets/images/ibcImages/money-bag.png';
import Meetings from '../../assets/images/ibcImages/work.png';

const store = configureStore();

export default function IncubatorRegisteredUser(props) {
  const { userDetails } = props;
  const { activeOuid, activeOuidChain } = store.getState().permission;

  const actionCards = [{ "heading": "Get Business Details", "color": "#1693dc" }, { "heading": "Govt. Schemes", "color": "#251c71" }];
  return (
    <div>
      <div className="businessServicesSection">
        <div className='PageBannerWrapper'>
          <div>
            <Paper className="PageBanner BannerWithGradient">
              <div >
                <h1>Certification programs</h1>
                <p>Get Certified with latest courses on Business. Learn and grow. All you need to know to run your business.</p>
                <Button variant="contained" color="primary" onClick={() => { history.push(`/${activeOuid}/course-dash`); }}>
                    Get started
                </Button>
                </div>
                <div>
                <img src={Banner} alt=''/>
              </div>
            </Paper>    
          </div>
          {/* <div>
            <Paper className="HomeCompanyProfileCard" onClick={() => { history.push(`/${activeOuid}/company-profile`); }}>
              <div>
                <div className='CompanyName'>Business Name</div>
                <div className='OwnerName'>Owner Name</div>
                <div className='AddressTitle'>Address</div>
                <div className='Address'>1st floor, Trags complex</div>
              </div>
              <div>
                <img src={Business} alt=''/>
              </div> 
            </Paper>
          </div> */}
        </div>
        <div className="businessServicesWrapper">
          <div href="#" onClick={() => { history.push(`/${activeOuid}/investreq-wq-dash-inv`); }} className="HelpCategoryItem">
            <div><img src={Businesses} alt="" /></div>
            <div>
              <p className="HelpCategoryItemTitle">Businesses investment requests</p>
              <p className="HelpCategoryItemText">Start Preparing to get investment. We will guide you through the details and help you prepare documents.</p>
            </div>
          </div>
          <div href="#" onClick={() => { history.push(`/${activeOuid}/appointment-dash`); }} className="HelpCategoryItem">
            <div><img src={Meetings} alt="" /></div>
            <div>
              <p className="HelpCategoryItemTitle">Meetings</p>
              <p className="HelpCategoryItemText">Fix a meeting with one of our officials for all your concerns regarding the process we will be avilable throughout your journey</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}