import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import {
  get_orgunit_details,
  get_channel_partners,
} from "../../../actions/action-orgunit";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import CommonSnackBar from "../../common/CommonSnackBar";
import FormButton from "../../common/FormButton";
import { Input, Paper, TextField } from "@material-ui/core";
import { timeFormat } from "../../common/CommonFunctions";
import { setActiveOuid } from "../../../actions/action-permissions";
import Typography from "@material-ui/core/Typography";
import SearchField from "../../common/SearchField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { showSnackbar } from "../../../actions/action-snackbar";

const store = configureStore();

class MapOrgUnit extends Component {
  constructor() {
    super();
    this.state = {
      OrgunitDetails: {},
      cpOrgunitsToShow: [],
      parent: "",
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  parentChange = (newValue) => {
    if (newValue) {
      this.setState({
        ...this.state,
        parent: newValue.data.identifier,
      });
    } else {
      this.setState({
        ...this.state,
        parent: "",
      });
    }
  };

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];

    const { activeOuid } = store.getState().permission;
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid, get_channel_partners } = this.props;
    await setActiveOuid(ouid);

    const { get_orgunit_details } = this.props;
    await get_orgunit_details(id);
    await get_channel_partners(activeOuid);

    const { OrgunitDetails, cpOrgunits } = store.getState().orgunit;

    this.setState({
      OrgunitDetails: OrgunitDetails,
      cpOrgunitsToShow: cpOrgunits,
    });
  }

  onInputChange(event) {
    let newOrgunitDetails = this.state.OrgunitDetails;
    newOrgunitDetails.data[event.target.id] = event.target.value;
    this.setState({
      OrgunitDetails: newOrgunitDetails,
    });
  }

  async handleSubmit() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("id", id);
    console.log("this.state.parent", this.state.parent);
    const { update_parentOuId } = this.props;

    await update_parentOuId(id, this.state.parent);
  }

  renderDetails(details, onInputChange) {
    var ouIdChainArray = details.ouidChain;
    console.log("ouidChain", ouIdChainArray);
    const currentParentOuId = ouIdChainArray[ouIdChainArray.length - 2];
    return (
      <Paper component="form" className="paper">
        <form>
          <Typography>
            <strong>{details.name}</strong>'s current Channel Partner :{" "}
            <strong>{currentParentOuId}</strong>
          </Typography>
          <br></br>

          <Typography>New Channel Partner :</Typography>
          <div>
            {this.state.cpOrgunitsToShow.length !== 0 ? (
              <div className="form-item-wrapper">
                <Autocomplete
                  id="parentOuIds"
                  onChange={(event, newValue) => {
                    this.parentChange(newValue);
                  }}
                  options={this.state.cpOrgunitsToShow}
                  getOptionLabel={(option) => option.data.identifier}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select New Channel Partner"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <br></br>
        </form>
        <FormButton
          onClick={() => this.handleSubmit()}
          text="Update Channel Partner Mapping"
        />
        <CommonSnackBar />
      </Paper>
    );
  }

  render() {
    const { showGlobalLoader } = store.getState().orgunit;
    const { permissions } = store.getState().permission;
    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          {showGlobalLoader ? (
            <Loader />
          ) : (
            <div className="container">
              {this.state.OrgunitDetails.data
                ? this.renderDetails(
                    this.state.OrgunitDetails.data,
                    this.onInputChange
                  )
                : "No Orgunit details Found"}
            </div>
          )}
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orgunit: state.orgunit,
  showGlobalLoader: state.orgunit.showGlobalLoader,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_orgunit_details: (id) => dispatch(get_orgunit_details(id)),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
  get_channel_partners: (activeOuid) =>
    dispatch(get_channel_partners(activeOuid)),
  update_parentOuId: (currentOuid, newParentOuId) =>
    dispatch(update_parentOuId(currentOuid, newParentOuId)),
});

const update_parentOuId = (currentOuid, newParentOuId) => async (dispatch) => {
  console.log(`update_parentOuId`);
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    console.log(`apiEndpoint`, apiEndpoint);
    console.log(`credentials`, credentials);

    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/orgunit/${currentOuid}?updateOuChainWith=${newParentOuId}`;
    const { activeOuid, activeOuidChain } = store.getState().permission;
    console.log(`jwt`, jwt);

    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, "", axiosHeader)
      .then((response) => {
        dispatch(
          showSnackbar("Channel Partner Mapped Successfully", "success")
        );
      })
      .catch((err) => {
        console.log("Channel Partner Mapping Failed", err);
        dispatch(showSnackbar("Channel Partner Mapping Failed", "error"));
      });
  }
  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(MapOrgUnit);
