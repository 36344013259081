import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";

const store = configureStore();

class HrmDash extends Component {
  constructor() {
    super();
    this.state = {
      
    };
  }

  componentDidMount() {
    const { activeOuidConfigs } = store.getState().config;
    const appConfig = activeOuidConfigs.find(
      ({ data }) => data.identifier === "hrmapp"
    );
    const urlParams = new URLSearchParams(window.location.search);
    
  }

  componentWillUnmount() {
    console.log("componentWillUnmount");
  }
  rerender = () => {
    
  };

  async fetchDetails() {
    
  }

 

  render() {
    const { activeOuid, activeOuidChain } = store.getState().permission;
    return (
      <div></div>
    );
  }
}

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(HrmDash);
