import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import {
  document_upload_requested,
  create_document,
  document_upload_success,
  document_upload_errored,
} from "../../../actions/action-document";

import {
  create_workorder_success,
  create_workorder_errored,
  create_workorder_requested,
} from "../../../actions/action-workorder";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import configureStore from "../../../store/configureStore";
import { showSnackbar, hideSnackbar } from "../../../actions/action-snackbar";
import { history } from "../../../reducers";
import axios from "axios";
import FormDocumentUploader from "../../common/FormDocumentUploader";

import CloseIcon from "@material-ui/icons/Close";
import fileIcon from "../../../components/assets/images/ibcImages/file.png";

const store = configureStore();

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 750,
    margin: "0 auto",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  buttonWrapper: {
    marginTop: 30,
  },
}));

function getSteps() {
  return ["Step 1", "Step 2", "Step 3", "Step 4"];
}


export default function CreateLoanRequest(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const { userDetails, activeOuid, activeOuidChain } = props;
  const [attachment, setFile] = React.useState();
  const [uploadFileName, setUploadFileName] = React.useState("");

  const [state, setState] = React.useState({
    loanRequestDetails: {
      businessName: "",
      loanType: "",
      landPurchaseCost: 0,
      buildingPurchaseCost: 0,
      workingCapitalCost: 0,
      otherInitialCost: 0,
      computerPurchaseCost: 0,
      machinaryPurchaseCost: 0,
      dpr: "",
      totalCost: 0,

      land: false,
      building: false,
      machinary: false,
      computers: false,
      capital: false,
      other: false,
    },
    reload: false,
  });

  const handleUploadChange = async (filelink, data) => {
    console.log("handleUploadChange", filelink);
    setState({
      ...state,
      loanRequestDetails: {
        ...state.loanRequestDetails,
        [data]: filelink,
      },
    });
    // setState({ ...state, dprFile: filelink });
  };

  const [error, setError] = React.useState();

  function validatePhoneNumber(input_str) {
    console.log("input_str", input_str);
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return re.test(input_str);
  }

  const steps = getSteps();

  const handleStepOne = (e) => {
    e.preventDefault();
    if (validatePhoneNumber(state.loanRequestDetails.phoneNumber)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setError("");
    } else {
      setError("Phone number not valid");
    }
  };

  const handleStepTwo = (e) => {
    e.preventDefault();

    if (validatePhoneNumber(state.loanRequestDetails.phoneNumber)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setError("");
    } else {
      setError("Phone number not valid");
    }
  };

  const handleStepThree = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onFileChange = (event) => {
    console.log("setFile");
    setFile(event.target.files[0]);
  };

  const onFileUpload = async (file, workId) => {
    console.log(`onFileUpload`);
    await store.dispatch(document_upload_requested());
    console.log("showUploadLoader", store.getState().document.showUploadLoader);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    setUploadFileName(file.name);
    if (apiEndpoint && credentials) {
      const fileName = file.name;
      const type = file.type;
      const method = "get";
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/document?getUploadSignedUrl=true&file=${fileName}&contentType=${type}`;
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log("signed url get response : ", response);
          await uploadFile(response, file, workId);
        })
        .catch((err) => {
          console.log("signed url get failed : ", err);
        });
    }
  };

  const uploadFile = async (signedUrlData, file, workId) => {
    console.log(`uploadFile`);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const method = "put";
      const url = signedUrlData.data.uploadURL;
      const body = file;
      var axiosConfig = {
        headers: {
          "Content-type": file.type,
        },
      };

      return axios[method](url, body, axiosConfig)
        .then(async (response) => {
          console.log("s3 upload response cc : ", response);
          var payload = {
            name: file.name,
            title: file.name,
            key: signedUrlData.data.Key,
            workId: workId,
          };
          console.log("documet payload: ", payload);

          await store.dispatch(document_upload_success());
          await store.dispatch(create_document(payload));

          return;
        })
        .catch((err) => {
          store.dispatch(document_upload_errored());
          console.log("s3 upload failed : ", err);
        });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("totalCost", state.loanRequestDetails.totalCost);
    await createWorkOrder(userDetails, state.loanRequestDetails);
    props.rerender();
  };

  const createWorkOrder = async (userDetails, loanRequestDetails) => {
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    const method = "post";
    const url = `${apiEndpoint}/workorder`;
    const jwt = `${credentials.idToken.jwtToken}`;
    const path = window.location.pathname.split("/");
    const activeOuid = path[1];
    const { activeOuidChain } = store.getState().permission;
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    //let userData = userDetails.data;

    const woDetails = {
      orgType: "biz",
      loanRequestDetails: loanRequestDetails,
    };
    const payload = {
      type: "RequestLoanWorkorder",
      description: "Loan Request Workorder to create a request for loans",
      targetOrg: "ibc",
      clientOrg: activeOuid,
      requestId: "",
      deadline: "",
      details: woDetails,
    };

    console.log("payload:::::::", JSON.stringify(payload));
    return axios[method](url, payload, axiosHeader)
      .then(async (response) => {
        console.log(
          "Create WorkOrder response : ",
          JSON.stringify(response.data)
        );
        var data = JSON.parse(response.data);

        console.log("data.identifier : ", data.identifier);
        console.log("data : ", data);

        await store.dispatch(create_workorder_success(data));
        console.log("identifier: ", response.data["identifier"]);
        console.log(
          "store.getState().workorder.workorder: ",
          store.getState().workorder.workorder
        );

        await onFileUpload(attachment, data.identifier);
        await store.dispatch(showSnackbar("Loan Requested", "success"));
        //history.push(`/system/registration-completed`);
      })
      .catch((err) => {
        console.log(
          "RequestLoanWorkorder failed, Please contact support : ",
          err
        );
      });
  };

  const onInputChange = (event) => {
    const newLoanRequestDetails = state.loanRequestDetails;
    console.log("type : ", event.target.type);

    if (event.target.id) {
      newLoanRequestDetails[event.target.id] = event.target.value;
    } else {
      newLoanRequestDetails[event.target.name] = event.target.value;
    }

    setState({
      loanRequestDetails: newLoanRequestDetails,
    });
  };

  const onCheckInputChange = (event) => {
    const newLoanRequestDetails = state.loanRequestDetails;
    console.log("type : ", event.target.type);

    if (event.target.type === "checkbox") {
      newLoanRequestDetails[event.target.name] = event.target.checked;
      if (!event.target.checked) {
        if (event.target.name === "land") {
          newLoanRequestDetails["totalCost"] =
            +state.loanRequestDetails["totalCost"] -
            +state.loanRequestDetails["landPurchaseCost"];
          newLoanRequestDetails["landPurchaseCost"] = 0;
        }
        if (event.target.name === "machinary") {
          newLoanRequestDetails["totalCost"] =
            +state.loanRequestDetails["totalCost"] -
            +state.loanRequestDetails["machinaryPurchaseCost"];
          newLoanRequestDetails["machinaryPurchaseCost"] = 0;
        }
        if (event.target.name === "capital") {
          newLoanRequestDetails["totalCost"] =
            +state.loanRequestDetails["totalCost"] -
            +state.loanRequestDetails["workingCapitalCost"];
          newLoanRequestDetails["workingCapitalCost"] = 0;
        }
        if (event.target.name === "computers") {
          newLoanRequestDetails["totalCost"] =
            +state.loanRequestDetails["totalCost"] -
            +state.loanRequestDetails["computerPurchaseCost"];
          newLoanRequestDetails["computerPurchaseCost"] = 0;
        }
        if (event.target.name === "other") {
          newLoanRequestDetails["totalCost"] =
            +state.loanRequestDetails["totalCost"] -
            +state.loanRequestDetails["otherInitialCost"];
          newLoanRequestDetails["otherInitialCost"] = 0;
        }
      }
      setState({
        loanRequestDetails: newLoanRequestDetails,
      });
    }
  };

  const onAmountInputChange = (event) => {
    const newLoanRequestDetails = state.loanRequestDetails;
    console.log("type : ", event.target.type);
    event.target.value = event.target.value.replace(/[^0-9]/g, "");

    if (event.target.type === "checkbox") {
      newLoanRequestDetails[event.target.name] = event.target.checked;
      if (!event.target.checked) {
        if (event.target.name === "land") {
          console.log("old Total: ", state.loanRequestDetails["totalCost"]);
          console.log(
            "old land cost: ",
            state.loanRequestDetails["landPurchaseCost"]
          );
          newLoanRequestDetails["totalCost"] =
            +state.loanRequestDetails["totalCost"] -
            +state.loanRequestDetails["landPurchaseCost"];
          console.log("new Total ", newLoanRequestDetails["totalCost"]);
          newLoanRequestDetails["landPurchaseCost"] = 0;
        }
      }
      setState({
        loanRequestDetails: newLoanRequestDetails,
      });
      return;
    }

    console.log("oldValue: ", state.loanRequestDetails[event.target.id]);
    console.log("newValue: ", event.target.value);
    console.log("oldTotal: ", state.loanRequestDetails["totalCost"]);
    const diff =
      +event.target.value - +state.loanRequestDetails[event.target.id];
    console.log("diff: ", diff);
    var newTotalCost = state.loanRequestDetails["totalCost"] + diff;
    console.log("newTotalCost: ", newTotalCost);

    if (event.target.id) {
      newLoanRequestDetails[event.target.id] = event.target.value;
    } else {
      newLoanRequestDetails[event.target.name] = event.target.value;
    }

    newLoanRequestDetails["totalCost"] = newTotalCost;

    setState({
      loanRequestDetails: newLoanRequestDetails,
    });
  };

  const handleDprDelete = (event) => {
    event.preventDefault();
    console.log("Handle delete");
    setState({
      ...state,
      loanRequestDetails: {
        ...state.loanRequestDetails,
        dpr: "",
      }
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <form onSubmit={handleStepOne}>
            {error && <div className="form_error">{error}</div>}
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="businessName"
                label="Business Name"
                placeholder="Business Name"
                value={state.loanRequestDetails.businessName || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="loanType">Loan Type</InputLabel>
                <Select
                  labelId="loanType"
                  id="loanType"
                  name="loanType"
                  value={state.loanRequestDetails.loanType || ""}
                  onChange={onInputChange}
                  label="loanType"
                  className={classes.input}
                  required
                >
                  <MenuItem value={"Mudra"}>Mudra</MenuItem>
                  <MenuItem value={"PMEGS"}>PMEGS</MenuItem>
                  <MenuItem value={"Startup"}>Startup</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="natureofBusiness"
                label="Nature of Business"
                placeholder="Example - Retail Shop, Bakery, Wholesale "
                value={state.loanRequestDetails.natureofBusiness || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="industry">Industry</InputLabel>
                <Select
                  labelId="industry"
                  id="industry"
                  name="industry"
                  value={state.loanRequestDetails.industry || ""}
                  onChange={onInputChange}
                  label="Industry"
                  className={classes.input}
                  required
                >
                  <MenuItem value={"Manufacturing"}>Manufacturing</MenuItem>
                  <MenuItem value={"Service"}>Service</MenuItem>
                  <MenuItem value={"Trading"}>Trading</MenuItem>
                  <MenuItem value={"Agriculture"}>Agriculture</MenuItem>
                  <MenuItem value={"Education"}>Education</MenuItem>
                  <MenuItem value={"Information Technology"}>
                    Information Technology
                  </MenuItem>

                  <MenuItem value={"Construction"}>Construction</MenuItem>
                  <MenuItem value={"Entertainment"}>Entertainment</MenuItem>
                  <MenuItem value={"Healthcare"}>Healthcare</MenuItem>
                  <MenuItem value={"Legal Services"}>Legal Services</MenuItem>
                  <MenuItem value={"Financial Services"}>
                    Financial Services
                  </MenuItem>
                  <MenuItem value={"Distribution"}>Distribution</MenuItem>
                  <MenuItem value={"Real Estate"}>Real Estate</MenuItem>
                  <MenuItem value={"Travel and Tourism"}>
                    Travel and Tourism
                  </MenuItem>
                  <MenuItem value={"Food"}>Food</MenuItem>
                  <MenuItem value={"Technology services"}>
                    Technology services
                  </MenuItem>

                  <MenuItem value={"E Commerce"}>E Commerce</MenuItem>
                  <MenuItem value={"Gaming"}>Gaming</MenuItem>
                  <MenuItem value={"Miscellaneous"}>Miscellaneous</MenuItem>
                  
                </Select>
              </FormControl>
            </div>
            <div className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="business-type">Type Of Entity</InputLabel>
                <Select
                  labelId="entityType"
                  id="entityType"
                  name="entityType"
                  value={state.loanRequestDetails.entityType || ""}
                  onChange={onInputChange}
                  label="Type Of Entity"
                  className={classes.input}
                  required
                >
                  <MenuItem value={"Company"}>Limited Company</MenuItem>
                  <MenuItem value={"Partnership"}>Partnership</MenuItem>
                  <MenuItem value={"Proprietorship"}>Proprietorship</MenuItem>
                  <MenuItem value={"LLP"}>LLP</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="address"
                label="Business Address"
                placeholder="Business Address"
                value={state.loanRequestDetails.address || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="pincode"
                label="Pincode"
                placeholder="Pincode"
                value={state.loanRequestDetails.pincode || ""}
                onChange={onInputChange}
                variant="outlined"
                inputProps={{
                  maxLength: 6,
                  minLength: 6,
                  pattern: "\\d{6}",
                }}
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="phoneNumber"
                label="Phone Number"
                placeholder="Phone Number"
                value={state.loanRequestDetails.phoneNumber || ""}
                onChange={onInputChange}
                variant="outlined"
                required
                type="number"
                // type="tel"
                inputProps={{
                  maxLength: 12,
                  inputMode: "tel",
                  // pattern: "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/",
                }}
              />
            </div>
            <div className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="business-type">Years in business</InputLabel>
                <Select
                  labelId="yearsInBusiness"
                  id="yearsInBusiness"
                  name="yearsInBusiness"
                  value={state.loanRequestDetails.yearsInBusiness || ""}
                  onChange={onInputChange}
                  label="Years In Business"
                  className={classes.input}
                  required
                >
                  <MenuItem value={"0"}>Just started</MenuItem>
                  <MenuItem value={"1"}>1 year</MenuItem>
                  <MenuItem value={"2"}>2 years</MenuItem>
                  <MenuItem value={"3"}>3 years</MenuItem>
                  <MenuItem value={"4"}>4 years</MenuItem>
                  <MenuItem value={"5"}>5 years</MenuItem>
                  <MenuItem value={"6+"}>Above 5 years</MenuItem>
                </Select>
              </FormControl>
              <div className={classes.buttonWrapper}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.button}
                >
                  Next
                </Button>
              </div>
            </div>
          </form>
        );
      case 1:
        return (
          <form onSubmit={handleStepTwo}>
            {error && <div className="form_error">{error}</div>}
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="numberOfStaffs"
                label="Number Of Staffs"
                placeholder="Number Of Staffs"
                value={state.loanRequestDetails.numberOfStaffs || ""}
                onChange={onInputChange}
                variant="outlined"
                required
                type="number"
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="websiteAddress"
                label="Website Address"
                placeholder="Website Address"
                value={state.loanRequestDetails.websiteAddress || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="primaryOwnerName"
                label="Name Of The Primary Owner"
                placeholder="Name of the owner"
                value={state.loanRequestDetails.primaryOwnerName || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="primaryOwnerGender">Gender</InputLabel>
                <Select
                  labelId="primaryOwnerGender"
                  id="primaryOwnerGender"
                  name="primaryOwnerGender"
                  value={state.loanRequestDetails.primaryOwnerGender || ""}
                  onChange={onInputChange}
                  label="Gender"
                  className={classes.input}
                  required
                >
                  <MenuItem value={"Female"}>Female</MenuItem>
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="primaryOwnerAddress"
                label="Primary Owner's Address"
                placeholder="Primary Owner's Address"
                value={state.loanRequestDetails.primaryOwnerAddress || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="primaryOwnerPhone"
                label="Primary Owner's Contact Phone"
                placeholder="Primary Owner's Phone"
                value={state.loanRequestDetails.primaryOwnerPhone || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                Next
              </Button>
            </div>
          </form>
        );
      case 2:
        return (
          <form onSubmit={handleStepThree}>
            <Typography gutterBottom>Project Costs</Typography>

            <div className="form-item-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.loanRequestDetails.land}
                    onChange={onCheckInputChange}
                    name="land"
                    id="land"
                  />
                }
                label="Land"
              />

              <Collapse in={state.loanRequestDetails.land}>
                <TextField
                  id="landPurchaseCost"
                  name="landPurchaseCost"
                  value={state.loanRequestDetails.landPurchaseCost}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </div>
            <div className="form-item-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.loanRequestDetails.building}
                    onChange={onCheckInputChange}
                    name="building"
                  />
                }
                label="Building, Interior Work etc."
              />
              <Collapse in={state.loanRequestDetails.building}>
                <TextField
                  id="buildingPurchaseCost"
                  name="buildingPurchaseCost"
                  value={state.loanRequestDetails.buildingPurchaseCost}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </div>
            <div className="form-item-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.loanRequestDetails.machinary}
                    onChange={onCheckInputChange}
                    name="machinary"
                  />
                }
                label="Machinery and Equipments"
              />
              <Collapse in={state.loanRequestDetails.machinary}>
                <TextField
                  id="machinaryPurchaseCost"
                  name="machinaryPurchaseCost"
                  value={state.loanRequestDetails.machinaryPurchaseCost}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </div>
            <div className="form-item-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.loanRequestDetails.computers}
                    onChange={onCheckInputChange}
                    name="computers"
                  />
                }
                label="Mobile Devices, Computers, Routers, Printers etc."
              />
              <Collapse in={state.loanRequestDetails.computers}>
                <TextField
                  id="computerPurchaseCost"
                  name="computerPurchaseCost"
                  value={state.loanRequestDetails.computerPurchaseCost}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </div>
            <div className="form-item-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.loanRequestDetails.other}
                    onChange={onCheckInputChange}
                    name="other"
                  />
                }
                label="Other Initial Expenses"
              />
              <Collapse in={state.loanRequestDetails.other}>
                <TextField
                  id="otherInitialCost"
                  name="otherInitialCost"
                  value={state.loanRequestDetails.otherInitialCost}
                  label="Other Initial Costs  (Rs)"
                  placeholder="Other Initial Costs(Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </div>
            <div className="form-item-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.loanRequestDetails.capital}
                    onChange={onCheckInputChange}
                    name="capital"
                  />
                }
                label="Working capital needed"
              />
              <Collapse in={state.loanRequestDetails.capital}>
                <TextField
                  id="workingCapitalCost"
                  name="workingCapitalCost"
                  value={state.loanRequestDetails.workingCapitalCost}
                  label="Working Capital Needed  (Rs)"
                  placeholder="Working Capital Needed (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </div>
            <Typography className={classes.instructions}>
              Total Project Costs &nbsp; &nbsp;&nbsp;&nbsp;
              <strong>Rs.&nbsp; {state.loanRequestDetails.totalCost}</strong>
            </Typography>

            <FormHelperText>*Loan Amount can be changed later.</FormHelperText>
            <div className={classes.buttonWrapper}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                // onClick={handleStepOne}
                type="submit"
                className={classes.button}
              >
                Next
              </Button>
            </div>
          </form>
        );
      case 3:
        return (
          <form onSubmit={handleSubmit}>
            <div className="form-item-wrapper">
              <FormHelperText>Upload Your DPR</FormHelperText>
              <div>&nbsp;</div>
              {/* <input type="file" onChange={onFileChange} id="fileInput" /> */}
              {state.loanRequestDetails.dpr && (
                <>
                  <img className="file_input_img_preview" src={fileIcon} />
                  <button
                    className="img_delete_btn"
                    onClick={handleDprDelete}
                  >
                    <CloseIcon />
                  </button>
                </>
              )}
              {state.loanRequestDetails.dpr === "" && (
                <FormDocumentUploader
                  data="dpr"
                  activeOuid={activeOuid}
                  activeOuidChain={activeOuidChain}
                  defaultState={state.upload}
                  handleUploadChange={handleUploadChange}
                />
              )}
              <div>&nbsp;</div>
            </div>
            <div>&nbsp;</div>
            <div className={classes.buttonWrapper}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                // onClick={handleStepOne}
                type="submit"
                className={classes.button}
              >
                Next
              </Button>
            </div>
          </form>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
          </div>
        )}
      </div>

      {/* {JSON.stringify(state.businessDetails)}
            {JSON.stringify(userDetails)} */}
    </div>
  );
}
