import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import SidebarWrapper from "../../layout/SidebarWrapper";
import CreateDprRequest from "./CreateDprRequest";
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import Paper from "@material-ui/core/Paper";
import DashboardFooter from "../../layout/DashboardFooter";

const store = configureStore();

class DprRequestDash extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.fetchDetails();
  }

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  render() {
    const { permissions } = store.getState().permission;
    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div className="container">
            <Paper className="formContainer">
              <CreateDprRequest />
            </Paper>
          </div>
          <DashboardFooter />
          <CommonSnackBar />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  workorders: state.workorder,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DprRequestDash);
