import React, { Component } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
  fetch_Category_By_Id,
  fetch_posts_by_category,
} from "../../../actions/action-categoryview";
import Loader from "../../common/Loader";
import CommonSnackBar from "../../common/CommonSnackBar";
import Box from "@material-ui/core/Box";
import HeaderInner from "../../layout/HeaderInner";
import DashboardFooter from "../../layout/DashboardFooter";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import configureStore from "../../../store/configureStore";
import { history } from "../../../reducers";
import "./CategoryPage.css";
import { setActiveOuid } from "../../../actions/action-permissions";

const store = configureStore();

const occupations = ["ENGINEER", "BUSINESS", "DOCTOR"];
const applicationModes = ["ONLINE", "OFFLINE"];
const indianStates = [
  "ANDHRA PRADESH",
  "ARUNACHAL PRADESH",
  "ASSAM",
  "BIHAR",
  "CHHATTISGARH",
  "GOA",
  "GUJARAT",
  "HARYANA",
  "HIMACHAL PRADESH",
  "JHARKHAND",
  "KARNATAKA",
  "KERALA",
  "MADHYA PRADESH",
  "MAHARASHTRA",
  "MANIPUR",
  "MEGHALAYA",
  "MIZORAM",
  "NAGALAND",
  "ODISHA",
  "PUNJAB",
  "RAJASTHAN",
  "SIKKIM",
  "TAMIL NADU",
  "TELANGANA",
  "TRIPURA",
  "UTTAR PRADESH",
  "UTTARAKHAND",
  "WEST BENGAL",
];

const styles = (theme) => ({
  root: {
    maxWidth: 345,
    minWidth: 280,
  },
  media: {
    height: 140,
  },
});

class CategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: "",
      searchQuery: "",
      occupation: "",
      applicationMode: "",
      showStateDropdown: false,
      clickedButton: "all",
      postListToShow: [],
      expandedPostIds: [],
      categoryById: {},
      isLoading: false,
      hasMoreData: false,
      lastEvaluatedKey: {},
      hoveredButton: "",
      selectedTags: [],
      selectedState: [],
    };
  }

  componentDidMount() {
    this.fetchPostsByCategory(); 
  }

  fetchPostsByCategory = async () => {
    const { fetch_posts_by_category, fetch_Category_By_Id, setActiveOuid } =
      this.props;
    const ouid = "ibc";
    const activeOuidChain = "activeOuidChain";
    const type = "govtscheme";
    const path = window.location.pathname.split("/");
    const categoryId = path[path.length - 1];

    await setActiveOuid(ouid);
    this.setState({ postListToShow: [], isLoading: true });

    await fetch_posts_by_category(
      ouid,
      activeOuidChain,
      type,
      categoryId,
      "",
      "",
      "",
      5
    );
    await fetch_Category_By_Id(ouid, activeOuidChain, categoryId);

    this.setState({
      postListToShow: store.getState().category.categories?.items,
      categoryById: store.getState().category.categoryById,
      lastEvaluatedKey: store.getState().category.categories?.lastEvaluatedKey,
      hasMoreData: !!store.getState().category.categories?.lastEvaluatedKey,
      isLoading: false,
    });
  };

  fetchPostsByCategoryTag = async () => {
    const { fetch_posts_by_category, fetch_Category_By_Id, setActiveOuid } =
      this.props;
    const { selectedTags, selectedState, occupation, applicationMode } =
      this.state;
    const ouid = "ibc";
    const activeOuidChain = "activeOuidChain";
    const type = "govtscheme";
    const path = window.location.pathname.split("/");
    const categoryId = path[path.length - 1];

    const combinedTags = [
      ...selectedTags,
      ...selectedState,
      occupation,
      applicationMode,
    ].filter(Boolean);
    const selectedtag = combinedTags.join(",");

    this.setState({
      postListToShow: [],
      isLoading: true,
    });

    await fetch_posts_by_category(
      ouid,
      activeOuidChain,
      type,
      categoryId,
      selectedtag,
      "",
      "",
      5
    );

    await fetch_Category_By_Id(ouid, activeOuidChain, categoryId);

    this.setState({
      postListToShow: store.getState().category.categories.items,
      lastEvaluatedKey: store.getState().category.categories?.lastEvaluatedKey,
      hasMoreData:
        store.getState().category.categories?.lastEvaluatedKey !== null
          ? true
          : false,
      isLoading: false,
    });
  };

  handleNext = async () => {
    const { fetch_posts_by_category } = this.props;
    const {
      lastEvaluatedKey,
      selectedTags,
      selectedState,
      occupation,
      applicationMode,
    } = this.state;
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const categoryId = path[path.length - 1];
    const ouid = "ibc";

    const combinedTags = [
      ...selectedTags,
      ...selectedState,
      occupation,
      applicationMode,
    ].filter(Boolean);
    const selectedTag = combinedTags.join(",");

    const tagstatus = combinedTags.length > 0 ? "Active" : "";

    if (lastEvaluatedKey) {
      try {
        const response = await fetch_posts_by_category(
          activeOuid,
          activeOuidChain,
          "govtscheme",
          categoryId,
          selectedTag,
          encodeURIComponent(lastEvaluatedKey?.lastEvaluatedKey),
          encodeURIComponent(lastEvaluatedKey?.lastEvaluatedSortKey),
          5,
          tagstatus
        );

        if (response?.items) {
          this.setState((prevState) => ({
            postListToShow: [...prevState.postListToShow, ...response.items],
            lastEvaluatedKey: response?.lastEvaluatedKey,
            hasMoreData: !!response?.lastEvaluatedKey,
          }));
        } else {
          console.error("No items in response");
        }
      } catch (error) {
        this.setState({ error, loading: false });
      }
    }
  };

  handleSearch = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleTagSelection = (tag) => {
    this.setState((prevState) => {
      const { selectedTags } = prevState;
      let updatedTags = selectedTags;

      if (tag === "CENTRAL") {
        this.setState({
          selectedState: [],
          clickedButton: "central",
        });
        updatedTags = ["CENTRAL"];
      } else if (tag === "state") {
        this.setState({ selectedTags: [] });
        updatedTags = [];
      } else {
        updatedTags = selectedTags.includes(tag)
          ? selectedTags.filter((t) => t !== tag)
          : [...selectedTags, tag];
      }

      return { selectedTags: updatedTags };
    }, this.fetchPostsByCategoryTag);
  };

  handleStateSelection = (event) => {
    const { value } = event.target;
    this.setState(
      {
        selectedState: value,
        clickedButton: "STATE",
        selectedTags: [],
      },
      () => {
        this.fetchPostsByCategoryTag();
      }
    );
  };

  handleOccupationChange = (event) => {
    const occupation = event.target.value;
    this.setState(
      { occupation,
        clickedButton: "occupation",
      }, this.fetchPostsByCategoryTag);
  };

  handleApplicationModeChange = (event) => {
    const applicationMode = event.target.value;
    this.setState(
      { applicationMode,
        clickedButton: "applicationMode",
      }, this.fetchPostsByCategoryTag);
  };

  handleButtonallClick = (buttonType) => {
    this.setState(
      {
        clickedButton: buttonType,
        selectedTags: [],
        selectedState: [], 
        occupation: "",
        applicationMode: "",
        showStateDropdown: false,
      },
      () => {
        this.fetchPostsByCategoryTag();
      }
    );
  };
  
  handleButtonstateClick = (event) => {
    const { value } = event.target;
    this.setState({ selectedState: value }, async () => {
      await this.fetchPostsByCategoryTag();
      this.setState({ clickedButton: "state" });
    });
  };

  handleClick = (buttonType) => {
    this.setState((prevState) => ({
      clickedButton: "state",
      showStateDropdown:
        buttonType === "state" ? !prevState.showStateDropdown : false,
    }));
  };

  togglePostExpansion = (postId) => {
    this.setState((prevState) => {
      const expandedPostIds = prevState.expandedPostIds.includes(postId)
        ? prevState.expandedPostIds.filter((id) => id !== postId)
        : [...prevState.expandedPostIds, postId];

      return { expandedPostIds };
    });
  };

  renderPosts = () => {
    const { postListToShow, searchQuery, expandedPostIds, categoryById } =
      this.state;

    const filteredPosts = postListToShow ? 
    postListToShow?.filter(
      (post) =>
        post?.data.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        post?.data.summary?.toLowerCase().includes(searchQuery.toLowerCase())
    ) : [];   


    if (filteredPosts?.length === 0) {
      return <div className="categoryResultsTitle">No schemes found!</div>;
    }

    return <div>
      <div className="categoryResultsTitle">
        We found schemes based on your preferences
      </div>
      <div className="schemWrapper">
        {filteredPosts?.map((item) => (          
          <div
            className="postschemeCard"
            key={item.identifier}
            onClick={() =>
              history.push(
                `/${store.getState().permission.activeOuid}/user-govtscheme/${
                  item.data.identifier
                }`
              )
            }
            style={{
              borderColor: categoryById?.color,
              borderStyle: "solid",
              borderWidth: "1px",
              borderRadius: "12px",
            }}
          >
            <div className="cardContent">
              <div className="schemeTitle">{item.data.title}</div>
              <div
                className="schemetitleborder"
                style={{
                  height: "2px",
                  backgroundColor: categoryById?.color,
                }}
              ></div>
              <div
                className={`CategoryscehemDescription ${
                  expandedPostIds.includes(item.data.identifier) ? "expanded" : ""
                }`}
              >
                {item.data.summary}
              </div>
              <div
                className="expand-button"
                onClick={() => this.togglePostExpansion(item.data.identifier)}
              >
                {expandedPostIds.includes(item.identifier) ? "Collapse" : ""}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  };

  getButtonStyle = (buttonType) => {
    const {
      clickedButton,
      hoveredButton,
      categoryById,
      occupation,
      applicationMode,
      selectedTags,
    } = this.state;

    const isActive =
      clickedButton === buttonType ||
      (buttonType === "occupation" && occupation !== "") ||
      (buttonType === "applicationMode" && applicationMode !== "") ||
      (buttonType === "central" && selectedTags.includes("CENTRAL")) ||
      (buttonType === "state" && this.state.selectedState.length > 0);


    const isHovered = hoveredButton === buttonType;

    const borderColor = isActive || isHovered ? categoryById?.color : "#A9AFAC";
    const backgroundColor =
      isActive || isHovered ? this.rgba(categoryById?.color, 0.5) : "#FFF";
    const textColor = isActive || isHovered ? categoryById?.color : "#A9AFAC";

    return {
      border: `2px solid ${borderColor}`,
      backgroundColor: backgroundColor,
      color: textColor,
    };
  };

  rgba = (hex, alpha) => {
    if (!hex) return "";
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        alpha +
        ")"
      );
    }
  };

  render() {
    const {
      searchQuery,
      occupation,
      applicationMode,
      showStateDropdown,
      clickedButton,
    } = this.state;

    return (
      <div style={{ backgroundColor: "#FDFDFD" }}>
        <HeaderInner />
        <InfiniteScroll
          style={{ paddingBottom: "40px" }}
          dataLength={this.state.postListToShow?.length || 0}
          next={this.handleNext}
          hasMore={this.state.hasMoreData}
          loader={
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Loader Placeholder */}
            </Box>
          }
        >
          <div className="page" style={{ marginBottom: "unset" }}>
            <section className="section">
              {this.state.isLoading ? (
                <Loader />
              ) : (
                <div className="container">
                  <div className="container_narrow_category">
                    <div className="bannerSection">
                      <div
                        className="titleSection"
                        style={{
                          backgroundColor: this.state.categoryById?.color,
                        }}
                      >
                        <img
                          src={this.state.categoryById?.image}
                          alt="Category"
                        />
                        <h1 className="categoryTitle">
                          {this.state.categoryById?.name}
                        </h1>
                      </div>
                      <div className="search-bar">
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchQuery}
                          onChange={this.handleSearch}
                          className="search-input"
                        />
                        <i className="fas fa-search search-icon"></i>
                      </div>
                    </div>
                    <div className="bodySection">
                      <div className="filters">
                        <div className="tagSection">
                          <button
                            className={`categoryButton ${
                              clickedButton === "all" ? "clicked" : ""
                            }`}
                            onClick={() => this.handleButtonallClick("all")}
                            style={this.getButtonStyle("all")}
                          >
                            All categories
                          </button>
                          <button
                            className={`categoryButton ${
                              clickedButton === "central" ? "clicked" : ""
                            }`}
                            onClick={() => {
                              this.handleTagSelection("CENTRAL");
                              this.setState({ selectedState: [] });
                              this.setState({ showStateDropdown: false });
                            }}
                            style={this.getButtonStyle("central")}
                          >
                            Central
                          </button>
                          <button
                            className={`categoryButton ${
                              clickedButton === "state" ? "clicked" : ""
                            }`}
                            onClick={() => this.handleClick("state")}
                            style={this.getButtonStyle("state")}
                          >
                            State
                          </button>
                          <select
                            value={occupation}
                            onChange={this.handleOccupationChange}
                            className="categorySelectButton"
                            style={this.getButtonStyle("occupation")}
                          >
                            <option value="" disabled>
                              Occupation
                            </option>
                            {occupations.map((occupation, index) => (
                              <option key={index} value={occupation}>
                                {occupation}
                              </option>
                            ))}
                          </select>

                          <select
                            value={applicationMode}
                            onChange={this.handleApplicationModeChange}
                            className="categorySelectButton"
                            style={this.getButtonStyle("applicationMode")}
                          >
                            <option value="" disabled>
                              Application Mode
                            </option>
                            {applicationModes.map((mode, index) => (
                              <option key={index} value={mode}>
                                {mode}
                              </option>
                            ))}
                          </select>
                        </div>
                        {showStateDropdown && (
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%", marginTop: "10px" }}
                          >
                            <InputLabel id="state-label">
                              Select State
                            </InputLabel>
                            <Select
                              labelId="state-label"
                              id="state-select"
                              multiple
                              value={this.state.selectedState}
                              onChange={this.handleStateSelection}
                              label="Select State"
                              renderValue={(selected) => selected.join(", ")}
                            >
                              {indianStates.map((state) => (
                                <MenuItem key={state} value={state}>
                                  <Checkbox
                                    checked={
                                      this.state.selectedState.indexOf(state) >
                                      -1
                                    }
                                  />
                                  <ListItemText primary={state} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </div>
                      <div className="categoryResults">                        
                        <div className="schemWrapper">
                          {this.state?.postListToShow?.length !== 0
                            ? this.renderPosts()
                            : "No Posts found..."}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </InfiniteScroll>
        <CommonSnackBar />
        <DashboardFooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.categories,
  showGlobalLoader: state.category.showGlobalLoader,
  posts: state.category.categories,
});

const mapDispatchToProps = (dispatch) => ({
  fetch_posts_by_category: (
    ouid,
    activeOuidChain,
    type,
    category,
    tag,
    lastEvaluatedKey,
    lastEvaluatedSortKey,
    limit
  ) =>
    dispatch(
      fetch_posts_by_category(
        ouid,
        activeOuidChain,
        type,
        category,
        tag,
        lastEvaluatedKey,
        lastEvaluatedSortKey,
        limit
      )
    ),
  fetch_Category_By_Id: (ouid, activeOuidChain, categoryId) =>
    dispatch(fetch_Category_By_Id(ouid, activeOuidChain, categoryId)),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CategoryPage));
