import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { get_team_user_details } from '../../../actions/action-teamuser';
import Loader from '../../common/Loader';
import { history } from '../../../reducers';
import SidebarWrapper from '../../layout/SidebarWrapper';
import MenuItem from '@material-ui/core/MenuItem';
import PolicyCard from '../../common/PolicyCard';
import CommonDelete from '../../common/CommonDelete';
import { timeFormat } from '../../common/CommonFunctions'
import { setActiveOuid } from "../../../actions/action-permissions";



const store = configureStore();

class TeamUserDetails extends Component {

    constructor() {
        super();
        this.state = {
            userDetails: {},
            errors: ''
        };
    }

    componentDidMount() {
        console.log("componentDidMount");
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
        this.fetchDetails();
    }

    componentDidUpdate() {
        console.log("componentDidUpdate");
    }

    async fetchDetails() {
        const path = window.location.pathname.split('/');
        const username = path[path.length - 1];
        const teamId = path[path.length - 3];
        const activeOuid = path[1];
        console.log("fetchDetails : ", activeOuid);
        const { get_team_user_details } = this.props;
        await get_team_user_details(username, activeOuid, teamId);
        const { teamUserDetails } = store.getState().teamUserProfile;
        this.setState({
            userDetails: teamUserDetails,
        });
    }

    renderDetails(details) {
        return Object.keys(details).map(function (keyName, keyIndex) {
            return (
                <div key={keyName} className="detailsItem">
                    <div className="detailsKey">
                        {keyName} : {' '}
                    </div>
                    {keyName === 'lastModifiedDate' ?
                        <div className="detailsValue">
                            {timeFormat(details[keyName])}
                        </div>
                        :
                        <div className="detailsValue">
                            {details[keyName]}
                        </div>
                    }

                </div>
            )
        });
    }

    render() {
        const { showGlobalLoader } = store.getState().teamUserProfile;
        const { permissions } = store.getState().permission;
        const path = window.location.pathname.split('/');
        const username = path[path.length - 1];
        return (
            <div className="page">
                <SidebarWrapper
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    {showGlobalLoader ?
                        <Loader />
                        : <div className="container">
                            <h1>Team User Details</h1>
                            {/* <CommonDelete
                                id={username}
                                type="userProfile"
                            /> */}
                            {this.state.userDetails.data ?
                                <div >
                                    {this.renderDetails(this.state.userDetails.data)}
                                    {this.state.roles && (
                                        <div>
                                            {this.state.roles.map((item, index) => (
                                                <PolicyCard key={index}
                                                    policy={item}
                                                    index={index}
                                                    deletePolicy={(index) => this.deletePolicy(index)}
                                                />
                                            ))
                                            }
                                        </div>
                                    )}
                                </div>

                                : 'No user details Found'}
                        </div>}
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    teamUserProfile: state.teamUserProfile,
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    get_team_user_details: (username, activeOuid, teamId) => dispatch(get_team_user_details(username, activeOuid, teamId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamUserDetails);

