import React from 'react';
import Button from '@material-ui/core/Button';
import { Paper } from '@material-ui/core';
import Completed from "../assets/images/ibcImages/check.png";
import { history } from '../../reducers';


export default function Welcome() {

  return (
    <div className="fullPageImage">
        <Paper className="SignupWelcomePage">
          <h1>Verify your email</h1>
          <img className='emailVerificationImg' src={Completed} alt='' />
          <p>Check your mail and click on the link to activate your account.</p>
          <Button variant="contained" color="primary" type="submit" fullWidth onClick={() => { history.push(`/login`); }}>
            Verified
          </Button>
        </Paper>
    </div>
  )
}
