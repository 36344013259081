import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { Auth } from "aws-amplify";
import { history } from "../../reducers";
import configureStore from "../../store/configureStore";
import { resetState } from "../../actions";
import {
  setActiveOuid,
  setActiveOuidChain,
} from "../../actions/action-permissions";
import { setActiveOiudConfigs } from "../../actions/action-config";
import { RedirectFunc } from "../common/CommonFunctions";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

const store = configureStore();

const drawerWidth = 240;
const headerHeight = 75;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: 9,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo: {
    color: "#fff",
    textDecoration: "none",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    marginTop: headerHeight,
    position: "relative",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginTop: 0,
    position: "relative",
    zIndex: 8,
    height: "100%",
    minHeight: "100vh",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },

  sectionDesktop: {
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  select: {
    color: "#fff",
    "&:before": {
      borderColor: "#fff",
    },
    "&:after": {
      borderColor: "#fff",
    },
  },
  icon: {
    fill: "#fff",
  },
  treeroot: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
    padding: 20,
  },
  ticketAppNavlinks: {
    flexGrow: 1,
    textAlign: "center",
  },
  ticketApplink: {
    color: "#fff",
    padding: 10,
    fontSize: 14,
    display: "inline-block",
    cursor: "pointer",
  },
}));

function MiniDrawer(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [mopen, setMOpen] = React.useState(false);

  const [orgOpen, setOrgOpen] = React.useState(false);
  const [usrOpen, setUsrOpen] = React.useState(false);

  const anchorRef = React.useRef(null);
  const anchorRef2 = React.useRef(null);
  const anchorRef3 = React.useRef(null);
  const [anchorProfile, setAnchorProfile] = React.useState(null);
  const { activeOuid, userRoles, policies, activeOuidType } =
    store.getState().permission;
  useEffect(() => {
    setActiveOuidValue(store.getState().permission.activeOuid);
  }, [JSON.stringify(props)]);
  const [activeOuidValue, setActiveOuidValue] = React.useState(activeOuid);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setMOpen(false);
    } else if (event.key === "Escape") {
      setMOpen(false);
    }
  }

  const handleToggle = () => {
    setMOpen((prevOpen) => !prevOpen);
  };

  const handleOrgToggle = () => {
    setOrgOpen((prevOpen) => !prevOpen);
  };
  const handleUsrToggle = () => {
    setUsrOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuTtem = () => {
    const activeOuidExist = userRoles.find(({ ouid }) => ouid === activeOuid);
    let renderOuids = userRoles;
    if (activeOuidExist) {
      renderOuids = userRoles;
    } else {
      renderOuids.push({ ouid: activeOuid, roles: [] });
    }
    return renderOuids.map((item, index) => (
      <MenuItem key={item.ouid} value={item.ouid}>
        {item.ouid}
      </MenuItem>
    ));
  };

  return (
    <div className={classes.ticketAppNavlinks}>
      <Link
        className={classes.ticketApplink}
        onClick={() => {
          history.push(`/${activeOuid}`);
        }}
      >
        Home
      </Link>
      <Link
        className={classes.ticketApplink}
        ref={anchorRef2}
        id="org-mng-link"
        aria-controls={orgOpen ? "org-mng-menu" : undefined}
        aria-expanded={orgOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleOrgToggle}
      >
        Org Management
      </Link>
      <Popper
        open={orgOpen}
        anchorEl={anchorRef2.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={orgOpen}
                  id="org-mng-menu"
                  aria-labelledby="org-mng-link"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() => {
                      history.push(`/${activeOuid}/orgunits`);
                    }}
                  >
                    Child Organisations
                  </MenuItem>
                  {activeOuidType === "ROOT" && (
                    <MenuItem
                      onClick={() => {
                        history.push(`/${activeOuid}/orgplan`);
                      }}
                    >
                      Organisation Plan
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Link
        className={classes.ticketApplink}
        ref={anchorRef3}
        id="usr-mng-link"
        aria-controls={usrOpen ? "usr-mng-menu" : undefined}
        aria-expanded={usrOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleUsrToggle}
      >
        User Management
      </Link>
      <Popper
        open={usrOpen}
        anchorEl={anchorRef3.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={mopen}
                  id="usr-mng-menu"
                  aria-labelledby="usr-mng-link"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() => {
                      history.push(`/${activeOuid}/orgusers`);
                    }}
                  >
                    Organisational Users
                  </MenuItem>
                  {activeOuidType === "SYSTEM" && (
                    <MenuItem
                      onClick={() => {
                        history.push(`/${activeOuid}/authusers`);
                      }}
                    >
                      Authenticated Users
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Link
        className={classes.ticketApplink}
        onClick={() => {
          history.push(`/${activeOuid}/teams`);
        }}
      >
        Teams
      </Link>
      <Link
        className={classes.ticketApplink}
        onClick={() => {
          history.push(`/${activeOuid}/org-workqueue-dash`);
        }}
      >
        Workqueue
      </Link>
      <Link
        className={classes.ticketApplink}
        onClick={() => {
          history.push(`/${activeOuid}/document-dash`);
        }}
      >
        Documents
      </Link>
      <Link
        className={classes.ticketApplink}
        ref={anchorRef}
        id="composition-button"
        aria-controls={mopen ? "composition-menu" : undefined}
        aria-expanded={mopen ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        Applications
      </Link>
      <Popper
        open={mopen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={mopen}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() => {
                      history.push(`/${activeOuid}/apps/ticketapp`);
                    }}
                  >
                    Issue Tracker
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push(`/${activeOuid}/course-dash`);
                    }}
                  >
                    Certifications
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push(`/${activeOuid}/apps/salespro`);
                    }}
                  >
                    Sales Pro
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push(`/${activeOuid}/hrmapp-dash`);
                    }}
                  >
                    HRM
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push(`/${activeOuid}/apps/accounting`);
                    }}
                  >
                    Accounting
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push(`/${activeOuid}/apps/approvals`);
                    }}
                  >
                    Approvals
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Link
        className={classes.ticketApplink}
        onClick={() => {
          history.push(`/${activeOuid}/config-dash`);
        }}
      >
        Settings
      </Link>
    </div>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  jwt: state.jwt,
  config: state.config,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MiniDrawer);
