import React, { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import DOMPurify from "dompurify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import { jsPDF } from "jspdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import html2canvas from "html2canvas";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import configureStore from "../../../store/configureStore";
import axios from "axios";

const store = configureStore();

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  tableContainer: {
    maxWidth: 700,
    margin: "0 auto",
  },
});

const indianStates = [
  'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat',
  'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh',
  'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
  'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand',
  'West Bengal'
];




function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <span
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <span className={classes.circle} />
      )}
    </span>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 750,
    margin: "0 auto",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
}));

function getSteps() {
  return ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6"];
}

export default function CreateDprRequest() {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(() => {
    const savedStep = localStorage.getItem("activeStep");
    return savedStep ? parseInt(savedStep, 10) : 0;
  });
  

  const doc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "letter",
  });

  const pdfRef = useRef(null);
  var content = document.getElementById("dprReportHTML");
  console.log("content", content);
  window.html2canvas = html2canvas;

  const initialState = {
    dprRequestDetails: {
      projectOpexAdvertising: "",
      projectOpexInsurance: "",
      projectOpexPurchases: "",
      projectOpexBuildingRent: "",
      projectOpexSalaries: "",
      projectOpexAmenities: "",
      projectOpexTransportation: "",
      projectOpexInternet: "",
      projectOpexMaintenance: "",
      projectOpexConsumables: "",
      projectOpexOthers: "",

      loanTermInterestRate: "",
      loanMoratoriumPeriod: "",
      loanType: "",
      loanRepaymentTerm: "",
      loanOwnFundsAmount: "",
      loanTermAmount: "",

      currentSalesCapacity: "",
      productName: "",
      salesExpectedPerMonth: "",
      salesPrice: "",
      purchasePrice: "",
      unit: "",
      salesAtFullCapacity: "",
      salesAtCapacityUnit: "Month",
      capacityDaysPerMonth: "26",

      ouid: "",

      businessAddressLine1: "",
      businessAddressLine2: "",
      businessAddressLine3: "",
      businessAddressLine4: "",
      businessPhoneNumber: "",
      businessYearsInBusiness: "",
      businessName: "",
      businessNature: "",
      businessEntityType: "",
      businessNumberOfEmployees: "",
      businessIndustry: "",

      ownerAddressLine1: "",
      ownerAddressLine2: "",
      ownerAddressLine3: "",
      ownerAddressLine4: "",
      ownerPhoneNumber: "",
      ownerName: "",
      ownerAge: "",
      ownerGender: "",
      ownerEdQualifications: "",

      projectCapexLand: "",
      projectCapexMaintenance: "",
      projectCapexMachinery: "",
      projectCapexComputers: "",
      projectCapexFurniture: "",
      projectCapexVehicles: "",
      projectCapexWorkingCapital: "",
      projectCapexElectrification: "",
      projectCapexInstallation: "",
      projectCapexSoftwares: "",
      projectCapexOthers: "",

      reportValueInLakhs: false,
    },
    dprFlagDetails: {
      projectOpexAdvertising: false,
      projectOpexInsurance: false,
      projectOpexPurchases: false,
      projectOpexBuildingRent: false,
      projectOpexSalaries: false,
      projectOpexAmenities: false,
      projectOpexTransportation: false,
      projectOpexInternet: false,
      projectOpexMaintenance: false,
      projectOpexConsumables: false,
      projectOpexOthers: false,

      projectCapexLand: false,
      projectCapexMaintenance: false,
      projectCapexMachinery: false,
      projectCapexComputers: false,
      projectCapexFurniture: false,
      projectCapexVehicles: false,
      projectCapexWorkingCapital: false,
      projectCapexElectrification: false,
      projectCapexInstallation: false,
      projectCapexSoftwares: false,
      projectCapexOthers: false,
    },
    dprHTML: [],
  };

  const [state, setState] = React.useState(() => {
    const savedState = localStorage.getItem("formState");
    return savedState ? JSON.parse(savedState) : initialState;
  });
  
  React.useEffect(() => {
    localStorage.setItem("formState", JSON.stringify(state));
    localStorage.setItem("activeStep", activeStep);
  }, [state, activeStep]);
  

  const [error, setError] = React.useState();

  function validatePhoneNumber(input_str) {
    console.log("input_str", input_str);
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return re.test(input_str);
  }

  function preparePayload() {
    var output = {
      projectOpex: {},
      loan: {},
      productSales: {},
      business: {},
      owner: {},
      projectCapex: {},
      reportValueInLakhs: false,
    };
    console.log("preparing payload with :", state.dprRequestDetails);
    // business
    if (state.dprRequestDetails.businessAddressLine1) {
      output.business["addressLine1"] =
        state.dprRequestDetails.businessAddressLine1;
    }
    if (state.dprRequestDetails.businessAddressLine2) {
      output.business["addressLine2"] =
        state.dprRequestDetails.businessAddressLine2;
    }
    if (state.dprRequestDetails.businessAddressLine3) {
      output.business["addressLine3"] =
        state.dprRequestDetails.businessAddressLine3;
    }
    if (state.dprRequestDetails.businessAddressLine4) {
      output.business["addressLine4"] =
        state.dprRequestDetails.businessAddressLine4;
    }
    if (state.dprRequestDetails.businessName) {
      output.business["name"] = state.dprRequestDetails.businessName;
    }
    if (state.dprRequestDetails.businessEntityType) {
      output.business["entityType"] =
        state.dprRequestDetails.businessEntityType;
    }
    if (state.dprRequestDetails.businessIndustry) {
      output.business["industry"] = state.dprRequestDetails.businessIndustry;
    }
    if (state.dprRequestDetails.businessNature) {
      output.business["nature"] = state.dprRequestDetails.businessNature;
    }
    if (state.dprRequestDetails.businessNumberOfEmployees) {
      output.business["numberOfEmployees"] =
        state.dprRequestDetails.businessNumberOfEmployees;
    }
    if (state.dprRequestDetails.businessPhoneNumber) {
      output.business["phoneNumber"] =
        state.dprRequestDetails.businessPhoneNumber;
    }
    if (state.dprRequestDetails.businessYearsInBusiness) {
      output.business["yearsInBusiness"] =
        state.dprRequestDetails.businessYearsInBusiness;
    }

    // owner
    if (state.dprRequestDetails.ownerAddressLine1) {
      output.owner["addressLine1"] = state.dprRequestDetails.ownerAddressLine1;
    }
    if (state.dprRequestDetails.ownerAddressLine2) {
      output.owner["addressLine2"] = state.dprRequestDetails.ownerAddressLine2;
    }
    if (state.dprRequestDetails.ownerAddressLine3) {
      output.owner["addressLine3"] = state.dprRequestDetails.ownerAddressLine3;
    }
    if (state.dprRequestDetails.ownerAddressLine4) {
      output.owner["addressLine4"] = state.dprRequestDetails.ownerAddressLine4;
    }
    if (state.dprRequestDetails.ownerPhoneNumber) {
      output.owner["phoneNumber"] = state.dprRequestDetails.ownerPhoneNumber;
    }
    if (state.dprRequestDetails.ownerAge) {
      output.owner["age"] = state.dprRequestDetails.ownerAge;
    }
    if (state.dprRequestDetails.ownerEdQualifications) {
      output.owner["edQualifications"] =
        state.dprRequestDetails.ownerEdQualifications;
    }
    if (state.dprRequestDetails.ownerGender) {
      output.owner["gender"] = state.dprRequestDetails.ownerGender;
    }
    if (state.dprRequestDetails.ownerName) {
      output.owner["name"] = state.dprRequestDetails.ownerName;
    }
    if (state.dprRequestDetails.ownerPhoneNumber) {
      output.owner["phoneNumber"] = state.dprRequestDetails.ownerPhoneNumber;
    }

    // loan
    if (state.dprRequestDetails.loanMoratoriumPeriod) {
      output.loan["moratoriumPeriod"] =
        state.dprRequestDetails.loanMoratoriumPeriod;
    }
    if (state.dprRequestDetails.loanOwnFundsAmount) {
      output.loan["ownFundsAmount"] =
        state.dprRequestDetails.loanOwnFundsAmount;
    }
    if (state.dprRequestDetails.loanRepaymentTerm) {
      output.loan["repaymentTerm"] = state.dprRequestDetails.loanRepaymentTerm;
    }
    if (state.dprRequestDetails.loanTermInterestRate) {
      output.loan["termInterestRate"] =
        state.dprRequestDetails.loanTermInterestRate;
    }
    if (state.dprRequestDetails.loanTermAmount) {
      output.loan["termAmount"] = state.dprRequestDetails.loanTermAmount;
    }
    if (state.dprRequestDetails.loanType) {
      output.loan["type"] = state.dprRequestDetails.loanType;
    }

    // product sales & capacity utilization
    if (state.dprRequestDetails.salesAtCapacityUnit) {
      output.productSales["salesAtCapacityUnit"] =
        state.dprRequestDetails.salesAtCapacityUnit;
    }
    if (state.dprRequestDetails.salesAtFullCapacity) {
      output.productSales["salesAtFullCapacity"] =
        state.dprRequestDetails.salesAtFullCapacity;
    }
    if (state.dprRequestDetails.salesPrice) {
      output.productSales["salesPrice"] = state.dprRequestDetails.salesPrice;
    }
    if (state.dprRequestDetails.salesExpectedPerMonth) {
      output.productSales["salesExpectedPerMonth"] =
        state.dprRequestDetails.salesExpectedPerMonth;
    }
    if (state.dprRequestDetails.purchasePrice) {
      output.productSales["purchasePrice"] =
        state.dprRequestDetails.purchasePrice;
    }
    if (state.dprRequestDetails.unit) {
      output.productSales["unit"] = state.dprRequestDetails.unit;
    }
    if (state.dprRequestDetails.currentSalesCapacity) {
      output.productSales["currentSalesCapacity"] =
        state.dprRequestDetails.currentSalesCapacity;
    }
    if (state.dprRequestDetails.productName) {
      output.productSales["name"] = state.dprRequestDetails.productName;
    }

    // opex
    if (state.dprRequestDetails.projectOpexAdvertising) {
      output.projectOpex["advertising"] =
        state.dprRequestDetails.projectOpexAdvertising;
    }
    if (state.dprRequestDetails.projectOpexAmenities) {
      output.projectOpex["amenities"] =
        state.dprRequestDetails.projectOpexAmenities;
    }
    if (state.dprRequestDetails.projectOpexBuildingRent) {
      output.projectOpex["buildingRent"] =
        state.dprRequestDetails.projectOpexBuildingRent;
    }
    if (state.dprRequestDetails.projectOpexInsurance) {
      output.projectOpex["insurance"] =
        state.dprRequestDetails.projectOpexInsurance;
    }
    if (state.dprRequestDetails.projectOpexInternet) {
      output.projectOpex["internet"] =
        state.dprRequestDetails.projectOpexInternet;
    }
    if (state.dprRequestDetails.projectOpexMaintenance) {
      output.projectOpex["maintenance"] =
        state.dprRequestDetails.projectOpexMaintenance;
    }
    if (state.dprRequestDetails.projectOpexOthers) {
      output.projectOpex["others"] = state.dprRequestDetails.projectOpexOthers;
    }
    if (state.dprRequestDetails.projectOpexPurchases) {
      output.projectOpex["purchases"] =
        state.dprRequestDetails.projectOpexPurchases;
    }
    if (state.dprRequestDetails.projectOpexSalaries) {
      output.projectOpex["salaries"] =
        state.dprRequestDetails.projectOpexSalaries;
    }
    if (state.dprRequestDetails.projectOpexTransportation) {
      output.projectOpex["transportation"] =
        state.dprRequestDetails.projectOpexTransportation;
    }

    // capex
    if (state.dprRequestDetails.projectCapexComputers) {
      output.projectCapex["computers"] =
        state.dprRequestDetails.projectCapexComputers;
    }
    if (state.dprRequestDetails.projectCapexElectrification) {
      output.projectCapex["electrification"] =
        state.dprRequestDetails.projectCapexElectrification;
    }
    if (state.dprRequestDetails.projectCapexFurniture) {
      output.projectCapex["furniture"] =
        state.dprRequestDetails.projectCapexFurniture;
    }
    if (state.dprRequestDetails.projectCapexInstallation) {
      output.projectCapex["installation"] =
        state.dprRequestDetails.projectCapexInstallation;
    }
    if (state.dprRequestDetails.projectCapexLand) {
      output.projectCapex["land"] = state.dprRequestDetails.projectCapexLand;
    }
    if (state.dprRequestDetails.projectCapexMachinery) {
      output.projectCapex["machinery"] =
        state.dprRequestDetails.projectCapexMachinery;
    }
    if (state.dprRequestDetails.projectCapexOthers) {
      output.projectCapex["others"] =
        state.dprRequestDetails.projectCapexOthers;
    }
    if (state.dprRequestDetails.projectCapexSoftwares) {
      output.projectCapex["softwares"] =
        state.dprRequestDetails.projectCapexSoftwares;
    }
    if (state.dprRequestDetails.projectCapexVehicles) {
      output.projectCapex["vehicles"] =
        state.dprRequestDetails.projectCapexVehicles;
    }
    if (state.dprRequestDetails.projectCapexWorkingCapital) {
      output.projectCapex["workingCapital"] =
        state.dprRequestDetails.projectCapexWorkingCapital;
    }

    if (state.dprRequestDetails.reportValueInLakhs) {
      output.reportValueInLakhs = state.dprRequestDetails.reportValueInLakhs;
    }

    console.log("prepared paylod", output);
    return output;
  }

  const reRender = (data) => {
    const newDprFlagDetails = state.dprFlagDetails;
    const newDprRequestDetails = state.dprRequestDetails;
    console.log("in reRender", data);
    setState({
      dprHTML: data,
      dprFlagDetails: newDprFlagDetails,
      dprRequestDetails: newDprRequestDetails,
    });
  };

  const onCheckInputChange = (event) => {
    const newDprFlagDetails = state.dprFlagDetails;
    const newDprRequestDetails = state.dprRequestDetails;
    console.log("event.target.name : ", event.target.name);
    newDprFlagDetails[event.target.name] = event.target.checked;
    if (!event.target.checked) {
      newDprRequestDetails[event.target.name] = "";
    }

    setState({
      dprFlagDetails: newDprFlagDetails,
      dprRequestDetails: newDprRequestDetails,
      dprHTML: [],
    });
  };
  const onAmountInputChange = (event) => {
    const newDprRequestDetails = state.dprRequestDetails;
    const newDprFlagDetails = state.dprFlagDetails;
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
    newDprRequestDetails[event.target.name] = event.target.value;
    setState({
      dprFlagDetails: newDprFlagDetails,
      dprRequestDetails: newDprRequestDetails,
      dprHTML: [],
    });
    return;
  };

  const steps = getSteps();

  const handleStepOne = (e) => {
    e.preventDefault();
    if (validatePhoneNumber(state.dprRequestDetails.businessPhoneNumber)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setError("");
    } else {
      setError("Phone number not valid");
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (validatePhoneNumber(state.dprRequestDetails.ownerPhoneNumber)) {
      setActiveStep((prevActiveStep) => {
        const newStep = prevActiveStep + 1;
        localStorage.setItem("activeStep", newStep);
        return newStep;
      });
      setError("");
    } else {
      setError("Phone number not valid");
    }
  };
  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const newStep = prevActiveStep - 1;
      localStorage.setItem("activeStep", newStep);
      return newStep;
    });
  };
  
  const handleReset = () => {
    setActiveStep(0);
    localStorage.setItem("activeStep", 0);
  };
  

  const handleSubmit = async (e) => {
    console.log("in handleSubmit");
    e.preventDefault();
    var payload = preparePayload();
    await createDPR(payload);  
  };  

  const createDPR = async (dprRequestDetails) => {
    console.log("in createDPR");
    const { dprApiEndpoint, credentials } = store.getState().jwt.api;
    const method = "put";
    const url = `${dprApiEndpoint}/generate-dpr`;
    const jwt = `${credentials.idToken.jwtToken}`;
    const path = window.location.pathname.split("/");
    const activeOuid = path[1];
    const { activeOuidChain } = store.getState().permission;

    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };

    const payload = dprRequestDetails;

    console.log("payload:::::::", JSON.stringify(payload));
    return axios[method](url, payload, axiosHeader)
      .then(async (response) => {
        console.log("Create DPR response : ", response.data);
        reRender(response.data);
      })
      .catch((err) => {
        console.log("DRP Request failed, Please contact support : ", err);
      });
  };

  const onInputChange = (event) => {
    const newDprRequestDetails = state.dprRequestDetails;
    const newDprFlagDetails = state.dprFlagDetails;
    console.log("event.target.id : ", event.target.id);
    console.log("event.target.name : ", event.target.name);

    if (event.target.id) {
      newDprRequestDetails[event.target.id] = event.target.value;
    } else {
      newDprRequestDetails[event.target.name] = event.target.value;
    }

    setState({
      dprRequestDetails: newDprRequestDetails,
      dprFlagDetails: newDprFlagDetails,
      dprHTML: [],
    });
  };

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(state.dprHTML),
  });

  const [step, setStep] = React.useState(1);

  const handleDownload = () => {
    window.html2canvas = html2canvas;
    const content = pdfRef.current;
    doc.html(content, {
      callback: function (doc) {
        console.log("in callback");
        doc.save("DPR.pdf");
        setActiveStep(0);
        setStep(1);  
        localStorage.removeItem("formState");
        localStorage.removeItem("activeStep");
      },
      margin: [30, 10, 30, 40],
      autoPaging: "text",
    }); 
  };

  const getReport = () => {
    return (
      <div>
        <div className="dprDownloadContainer">
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonRed}
            startIcon={<GetAppIcon />}
            onClick={() => handleDownload()}
          >
            Download DPR
          </Button>
        </div>
        <div
          id="dprReportHTML"
          ref={pdfRef}
          dangerouslySetInnerHTML={sanitizedData()}
        />
      </div>
    );
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <form onSubmit={handleStepOne}>
            {error && <span className="form_error">{error}</span>}
            <Typography gutterBottom>Business Details</Typography>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="businessName"
                label="Business Name"
                placeholder="Business Name"
                value={state.dprRequestDetails.businessName || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="businessNature"
                label="Nature of Business"
                placeholder="Example - Retail Shop, Bakery, Wholesale "
                value={state.dprRequestDetails.businessNature || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="industry">Industry</InputLabel>
                <Select
                  labelId="industry"
                  id="businessIndustry"
                  name="businessIndustry"
                  value={state.dprRequestDetails.businessIndustry || ""}
                  onChange={onInputChange}
                  label="Industry"
                  className={classes.input}
                  required
                >
                  <MenuItem value={"Manufacturing"}>Manufacturing</MenuItem>
                  <MenuItem value={"Service"}>Service</MenuItem>
                  <MenuItem value={"Trading"}>Trading</MenuItem>
                  <MenuItem value={"Agriculture"}>Agriculture</MenuItem>
                  <MenuItem value={"Education"}>Education</MenuItem>
                  <MenuItem value={"Information Technology"}>
                    Information Technology
                  </MenuItem>

                  <MenuItem value={"Construction"}>Construction</MenuItem>
                  <MenuItem value={"Entertainment"}>Entertainment</MenuItem>
                  <MenuItem value={"Healthcare"}>Healthcare</MenuItem>
                  <MenuItem value={"Legal Services"}>Legal Services</MenuItem>
                  <MenuItem value={"Financial Services"}>
                    Financial Services
                  </MenuItem>
                  <MenuItem value={"Distribution"}>Distribution</MenuItem>
                  <MenuItem value={"Real Estate"}>Real Estate</MenuItem>
                  <MenuItem value={"Travel and Tourism"}>
                    Travel and Tourism
                  </MenuItem>
                  <MenuItem value={"Food"}>Food</MenuItem>
                  <MenuItem value={"Technology services"}>
                    Technology services
                  </MenuItem>

                  <MenuItem value={"E Commerce"}>E Commerce</MenuItem>
                  <MenuItem value={"Gaming"}>Gaming</MenuItem>
                  <MenuItem value={"Miscellaneous"}>Miscellaneous</MenuItem>
                </Select>
              </FormControl>
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="business-type">Type Of Entity</InputLabel>
                <Select
                  labelId="businessEntityType"
                  id="businessEntityType"
                  name="businessEntityType"
                  value={state.dprRequestDetails.businessEntityType || ""}
                  onChange={onInputChange}
                  label="Type Of Entity"
                  className={classes.input}
                  required
                >
                  <MenuItem value={"Company"}>Limited Company</MenuItem>
                  <MenuItem value={"Partnership"}>Partnership</MenuItem>
                  <MenuItem value={"Proprietorship"}>Proprietorship</MenuItem>
                  <MenuItem value={"LLP"}>LLP</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="businessAddressLine1"
                label="Business Address - Building/Street Name/Number"
                placeholder="Building Name, Street Name/Number"
                value={state.dprRequestDetails.businessAddressLine1 || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="businessAddressLine2"
                label="Business Address - Town/City"
                placeholder="Village, Town/City"
                value={state.dprRequestDetails.businessAddressLine2 || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.input} required>
                <InputLabel id="businessAddressLine3-label">Business Address - State</InputLabel>
                <Select
                  labelId="businessAddressLine3-label"
                  id="businessAddressLine3"
                  name="businessAddressLine3"
                  value={state.dprRequestDetails.businessAddressLine3 || ''}
                  onChange={onInputChange}
                  label="Business Address - State"
                >
                  {indianStates.map((stateName) => (
                    <MenuItem key={stateName} value={stateName}>
                      {stateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="businessAddressLine4"
                label="Business Address - Post Code"
                placeholder="post code"
                value={state.dprRequestDetails.businessAddressLine4 || ""}
                onChange={onInputChange}
                variant="outlined"
                required
                inputProps={{
                  maxLength: 6,
                  minLength: 6,
                  pattern: "\\d{6}",
                }}
              />
            </span>{" "}
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="businessPhoneNumber"
                label="Phone Number"
                placeholder="Phone Number"
                value={state.dprRequestDetails.businessPhoneNumber || ""}
                onChange={onInputChange}
                variant="outlined"
                required
                type="tel"
                inputProps={{
                  maxLength: 12,
                  inputMode: "numeric",
                  pattern: "[0-9+-]*",

                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="business-type">Years in business</InputLabel>
                <Select
                  labelId="yearsInBusiness"
                  id="businessYearsInBusiness"
                  name="businessYearsInBusiness"
                  value={state.dprRequestDetails.businessYearsInBusiness || ""}
                  onChange={onInputChange}
                  label="Years In Business"
                  className={classes.input}
                  required
                >
                  <MenuItem value={"0"}>Just started</MenuItem>
                  <MenuItem value={"1"}>1 year</MenuItem>
                  <MenuItem value={"2"}>2 years</MenuItem>
                  <MenuItem value={"3"}>3 years</MenuItem>
                  <MenuItem value={"4"}>4 years</MenuItem>
                  <MenuItem value={"5"}>5 years</MenuItem>
                  <MenuItem value={"6+"}>Above 5 years</MenuItem>
                </Select>
              </FormControl>
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="businessNumberOfEmployees"
                label="Number of Staff"
                placeholder="Number of Staff"
                value={state.dprRequestDetails.businessNumberOfEmployees || ""}
                onChange={onInputChange}
                variant="outlined"
                required
                type="number"
                inputProps={{
                  maxLength: 12,
                  min: 0
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className={classes.buttonWrapper}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                Next
              </Button>
            </span>
          </form>
        );
      case 1:
        return (
          <form onSubmit={handleNext}>
            {error && <span className="form_error">{error}</span>}
            <Typography gutterBottom>Owner Details</Typography>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="ownerName"
                label="Owner Name"
                placeholder="Owner Name"
                value={state.dprRequestDetails.ownerName || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="ownerAge"
                label="Owner Age"
                placeholder="Owner Age"
                value={state.dprRequestDetails.ownerAge || ""}
                onChange={onInputChange}
                variant="outlined"
                type="number"
                required
                inputProps={{ min: 0, max: 120 }}
                />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="ownerEdQualifications"
                label="Owner Education"
                placeholder="Owner Education Qualifications"
                value={state.dprRequestDetails.ownerEdQualifications || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="ownerGender">Owner Gender</InputLabel>
                <Select
                  labelId="ownerGender"
                  id="ownerGender"
                  name="ownerGender"
                  value={state.dprRequestDetails.ownerGender || ""}
                  onChange={onInputChange}
                  label="ownerGender"
                  className={classes.input}
                  required
                >
                  <MenuItem value={"F"}>Female</MenuItem>
                  <MenuItem value={"M"}>Male</MenuItem>
                </Select>
              </FormControl>
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="ownerAddressLine1"
                label="Owner Address - Building/Street Name/Number"
                placeholder="Building Name, Street Name/Number"
                value={state.dprRequestDetails.ownerAddressLine1 || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="ownerAddressLine2"
                label="Owner Address - Town/City"
                placeholder="Village, Town/City"
                value={state.dprRequestDetails.ownerAddressLine2 || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.input} required>
                <InputLabel id="ownerAddressLine3-label">Owner Address - State</InputLabel>
                <Select
                  labelId="ownerAddressLine3-label"
                  id="ownerAddressLine3"
                  name="ownerAddressLine3"
                  value={state.dprRequestDetails.ownerAddressLine3 || ''}
                  onChange={onInputChange}
                  label="Owner Address - State"
                >
                  {indianStates.map((stateName) => (
                    <MenuItem key={stateName} value={stateName}>
                      {stateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="ownerAddressLine4"
                label="Owner Address - Post Code"
                placeholder="post code"
                value={state.dprRequestDetails.ownerAddressLine4 || ""}
                onChange={onInputChange}
                variant="outlined"
                required
                inputProps={{
                  maxLength: 6,
                  minLength: 6,
                  pattern: "\\d{6}",
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="ownerPhoneNumber"
                label="Phone Number"
                placeholder="Phone Number"
                value={state.dprRequestDetails.ownerPhoneNumber || ""}
                onChange={onInputChange}
                variant="outlined"
                required
                type="tel"
                inputProps={{
                  maxLength: 12,
                  inputMode: "numeric",
                  pattern: "[0-9+-]*",
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className={classes.buttonWrapper}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                Next
              </Button>
            </span>
          </form>
        );
      case 2:
        return (
          <form onSubmit={handleNext}>
            {error && <span className="form_error">{error}</span>}

            <Typography gutterBottom>
              Project Investments (One time expenses)
            </Typography>
            <br></br>
            <br></br>

            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectCapexLand}
                    onChange={onCheckInputChange}
                    name="projectCapexLand"
                    id="projectCapexLand"
                  />
                }
                label="Land & Building"
              />

              <Collapse in={state.dprFlagDetails.projectCapexLand}>
                <TextField
                  id="projectCapexLand"
                  name="projectCapexLand"
                  value={state.dprRequestDetails.projectCapexLand}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectCapexMachinery}
                    onChange={onCheckInputChange}
                    name="projectCapexMachinery"
                    id="projectCapexMachinery"
                  />
                }
                label="Machinery & Equipments"
              />

              <Collapse in={state.dprFlagDetails.projectCapexMachinery}>
                <TextField
                  id="projectCapexMachinery"
                  name="projectCapexMachinery"
                  value={state.dprRequestDetails.projectCapexMachinery}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectCapexComputers}
                    onChange={onCheckInputChange}
                    name="projectCapexComputers"
                    id="projectCapexComputers"
                  />
                }
                label="Computers, Printers etc."
              />

              <Collapse in={state.dprFlagDetails.projectCapexComputers}>
                <TextField
                  id="projectCapexComputers"
                  name="projectCapexComputers"
                  value={state.dprRequestDetails.projectCapexComputers}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectCapexFurniture}
                    onChange={onCheckInputChange}
                    name="projectCapexFurniture"
                    id="projectCapexFurniture"
                  />
                }
                label="Furniture & Fittings"
              />

              <Collapse in={state.dprFlagDetails.projectCapexFurniture}>
                <TextField
                  id="projectCapexFurniture"
                  name="projectCapexFurniture"
                  value={state.dprRequestDetails.projectCapexFurniture}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectCapexElectrification}
                    onChange={onCheckInputChange}
                    name="projectCapexElectrification"
                    id="projectCapexElectrification"
                  />
                }
                label="Electrification & Backup Power"
              />

              <Collapse in={state.dprFlagDetails.projectCapexElectrification}>
                <TextField
                  id="projectCapexElectrification"
                  name="projectCapexElectrification"
                  value={state.dprRequestDetails.projectCapexElectrification}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectCapexInstallation}
                    onChange={onCheckInputChange}
                    name="projectCapexInstallation"
                    id="projectCapexInstallation"
                  />
                }
                label="Installation"
              />

              <Collapse in={state.dprFlagDetails.projectCapexInstallation}>
                <TextField
                  id="projectCapexInstallation"
                  name="projectCapexInstallation"
                  value={state.dprRequestDetails.projectCapexInstallation}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectCapexSoftwares}
                    onChange={onCheckInputChange}
                    name="projectCapexSoftwares"
                    id="projectCapexSoftwares"
                  />
                }
                label="Software"
              />

              <Collapse in={state.dprFlagDetails.projectCapexSoftwares}>
                <TextField
                  id="projectCapexSoftwares"
                  name="projectCapexSoftwares"
                  value={state.dprRequestDetails.projectCapexSoftwares}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectCapexVehicles}
                    onChange={onCheckInputChange}
                    name="projectCapexVehicles"
                    id="projectCapexVehicles"
                  />
                }
                label="Vehicle for Business"
              />

              <Collapse in={state.dprFlagDetails.projectCapexVehicles}>
                <TextField
                  id="projectCapexVehicles"
                  name="projectCapexVehicles"
                  value={state.dprRequestDetails.projectCapexVehicles}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectCapexOthers}
                    onChange={onCheckInputChange}
                    name="projectCapexOthers"
                    id="projectCapexOthers"
                  />
                }
                label="Other initial expenses"
              />

              <Collapse in={state.dprFlagDetails.projectCapexOthers}>
                <TextField
                  id="projectCapexOthers"
                  name="projectCapexOthers"
                  value={state.dprRequestDetails.projectCapexOthers}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectCapexWorkingCapital}
                    onChange={onCheckInputChange}
                    name="projectCapexWorkingCapital"
                    id="projectCapexWorkingCapital"
                  />
                }
                label="Working Capital"
              />

              <Collapse in={state.dprFlagDetails.projectCapexWorkingCapital}>
                <TextField
                  id="projectCapexWorkingCapital"
                  name="projectCapexWorkingCapital"
                  value={state.dprRequestDetails.projectCapexWorkingCapital}
                  label="Working Capital (Rs)"
                  placeholder="Working Capital (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <br></br>

            <span className={classes.buttonWrapper}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                Next
              </Button>
            </span>
          </form>
        );
      case 3:
        return (
          <form onSubmit={handleNext}>
            {error && <span className="form_error">{error}</span>}
            <Typography gutterBottom>Monthly Expenses</Typography>
            <br></br>

            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectOpexPurchases}
                    onChange={onCheckInputChange}
                    name="projectOpexPurchases"
                    id="projectOpexPurchases"
                  />
                }
                label="Purchases"
              />

              <Collapse in={state.dprFlagDetails.projectOpexPurchases}>
                <TextField
                  id="projectOpexPurchases"
                  name="projectOpexPurchases"
                  value={state.dprRequestDetails.projectOpexPurchases}
                  label="Monthly Expense"
                  placeholder="Monthly Expense"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectOpexBuildingRent}
                    onChange={onCheckInputChange}
                    name="projectOpexBuildingRent"
                    id="projectOpexBuildingRent"
                  />
                }
                label="Rent & Lease"
              />

              <Collapse in={state.dprFlagDetails.projectOpexBuildingRent}>
                <TextField
                  id="projectOpexBuildingRent"
                  name="projectOpexBuildingRent"
                  value={state.dprRequestDetails.projectOpexBuildingRent}
                  label="Monthly Expense"
                  placeholder="Monthly Expense"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectOpexSalaries}
                    onChange={onCheckInputChange}
                    name="projectOpexSalaries"
                    id="projectOpexSalaries"
                  />
                }
                label="Salary & Wages"
              />

              <Collapse in={state.dprFlagDetails.projectOpexSalaries}>
                <TextField
                  id="projectOpexSalaries"
                  name="projectOpexSalaries"
                  value={state.dprRequestDetails.projectOpexSalaries}
                  label="Monthly Expense"
                  placeholder="Monthly Expense"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectOpexAmenities}
                    onChange={onCheckInputChange}
                    name="projectOpexAmenities"
                    id="projectOpexAmenities"
                  />
                }
                label="Electricity, Fuel & Water"
              />

              <Collapse in={state.dprFlagDetails.projectOpexAmenities}>
                <TextField
                  id="projectOpexAmenities"
                  name="projectOpexAmenities"
                  value={state.dprRequestDetails.projectOpexAmenities}
                  label="Monthly Expense"
                  placeholder="Monthly Expense"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>

            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectOpexMaintenance}
                    onChange={onCheckInputChange}
                    name="projectOpexMaintenance"
                    id="projectOpexMaintenance"
                  />
                }
                label="Maintenance & Repair"
              />
              <Collapse in={state.dprFlagDetails.projectOpexMaintenance}>
                <TextField
                  id="projectOpexMaintenance"
                  name="projectOpexMaintenance"
                  value={state.dprRequestDetails.projectOpexMaintenance}
                  label="Monthly Expense"
                  placeholder="Monthly Expense"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>

            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectOpexTransportation}
                    onChange={onCheckInputChange}
                    name="projectOpexTransportation"
                    id="projectOpexTransportation"
                  />
                }
                label="Transportation"
              />
              <Collapse in={state.dprFlagDetails.projectOpexTransportation}>
                <TextField
                  id="projectOpexTransportation"
                  name="projectOpexTransportation"
                  value={state.dprRequestDetails.projectOpexTransportation}
                  label="Monthly Expense"
                  placeholder="Monthly Expense"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>

            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectOpexInternet}
                    onChange={onCheckInputChange}
                    name="projectOpexInternet"
                    id="projectOpexInternet"
                  />
                }
                label="Postage, Phone & Internet"
              />
              <Collapse in={state.dprFlagDetails.projectOpexInternet}>
                <TextField
                  id="projectOpexInternet"
                  name="projectOpexInternet"
                  value={state.dprRequestDetails.projectOpexInternet}
                  label="Monthly Expense"
                  placeholder="Monthly Expense"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>

            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectOpexAdvertising}
                    onChange={onCheckInputChange}
                    name="projectOpexAdvertising"
                    id="projectOpexAdvertising"
                  />
                }
                label="Advertising & Marketing"
              />
              <Collapse in={state.dprFlagDetails.projectOpexAdvertising}>
                <TextField
                  id="projectOpexAdvertising"
                  name="projectOpexAdvertising"
                  value={state.dprRequestDetails.projectOpexAdvertising}
                  label="Monthly Expense"
                  placeholder="Monthly Expense"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>

            <span className="form-item-wrapper split">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.dprFlagDetails.projectOpexInsurance}
                    onChange={onCheckInputChange}
                    name="projectOpexInsurance"
                    id="projectOpexInsurance"
                  />
                }
                label="Insurance Cost"
              />
              <Collapse in={state.dprFlagDetails.projectOpexInsurance}>
                <TextField
                  id="projectOpexInsurance"
                  name="projectOpexInsurance"
                  value={state.dprRequestDetails.projectOpexInsurance}
                  label="Monthly Expense"
                  placeholder="Monthly Expense"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </span>
            <br></br>

            <div className={classes.buttonWrapper}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                Next
              </Button>
            </div>
          </form>
        );
      case 4:
        return (
          <form onSubmit={handleNext}>
            {error && <span className="form_error">{error}</span>}
            <Typography gutterBottom>Loan Details</Typography>
            <span className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="loanType">Loan Type</InputLabel>
                <Select
                  labelId="loanType"
                  id="loanType"
                  name="loanType"
                  value={state.dprRequestDetails.loanType || ""}
                  onChange={onInputChange}
                  label="loanType"
                  className={classes.input}
                >
                  <MenuItem value={"Mudra"}>Mudra</MenuItem>
                  <MenuItem value={"PMEGS"}>PMEGS</MenuItem>
                  <MenuItem value={"Startup"}>Startup</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="loanTermAmount"
                label="Term Loan Amount"
                placeholder="loan amount"
                value={state.dprRequestDetails.loanTermAmount || ""}
                onChange={onInputChange}
                variant="outlined"
                inputProps={{ inputMode: 'decimal', pattern: '[0-9.,]*' }}

              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="loanTermInterestRate"
                label="Term Loan Interest Rate"
                placeholder="term loan interest rate"
                value={state.dprRequestDetails.loanTermInterestRate || ""}
                onChange={onInputChange}
                variant="outlined"
                inputProps={{ inputMode: 'decimal', pattern: '[0-9.,]*' }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="loanRepaymentTerm"
                label="Repayment Term"
                placeholder="repayment term in years"
                value={state.dprRequestDetails.loanRepaymentTerm || ""}
                onChange={onInputChange}
                variant="outlined"
                inputProps={{
                  maxLength: 3,
                  minLength: 1,
                  pattern: "\\d{1,3}",
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="loanMoratoriumPeriod"
                label="Loan Moratorium Period (In Months)"
                placeholder="loan moratorium period in months"
                value={state.dprRequestDetails.loanMoratoriumPeriod || ""}
                onChange={onInputChange}
                variant="outlined"
                inputProps={{
                  maxLength: 3,
                  minLength: 1,
                  pattern: "\\d{1,3}",
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="loanOwnFundsAmount"
                label="Own Funds"
                placeholder="own funds amount"
                value={state.dprRequestDetails.loanOwnFundsAmount || ""}
                onChange={onInputChange}
                variant="outlined"
                inputProps={{ inputMode: 'decimal', pattern: '[0-9.,]*' }}

              />
            </span>
            <br></br>
            <br></br>
            <div className={classes.buttonWrapper}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                Next
              </Button>
            </div>
          </form>
        );
      case 5:
        return (
          <form onSubmit={handleSubmit}>
            {error && <span className="form_error">{error}</span>}
            <Typography gutterBottom>
              Sales Data & Operational Capacity
            </Typography>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="productName"
                label="Product Name / Service Name"
                placeholder="product/service name"
                value={state.dprRequestDetails.productName || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="salesExpectedPerMonth"
                label="Sales expected per month"
                placeholder="Sales expected per month"
                value={state.dprRequestDetails.salesExpectedPerMonth || ""}
                onChange={onInputChange}
                variant="outlined"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="salesPrice"
                label="Sales Price"
                placeholder="sales price"
                value={state.dprRequestDetails.salesPrice || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="purchasePrice"
                label="Purchase Price"
                placeholder="purchase price"
                value={state.dprRequestDetails.purchasePrice || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </span>
            <br></br>
            <br></br>

            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="currentSalesCapacity"
                label="Current Sales Capacity (percentage)"
                placeholder="current sales capacity percentage say 50"
                value={state.dprRequestDetails.currentSalesCapacity || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="unit"
                label="Unit - Number, Kg, Meter etc."
                placeholder="unit - number, kg, meter etc"
                value={state.dprRequestDetails.unit || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </span>
            <br></br>
            <br></br>
            <span className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="salesAtFullCapacity"
                label="Sales At Full Capacity"
                placeholder="sales at full capacity"
                value={state.dprRequestDetails.salesAtFullCapacity || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </span>
            <br></br>
            <br></br>
            <Typography gutterBottom>Report Value</Typography>
            <br></br>
            <span className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="loanType">Report Value in</InputLabel>
                <Select
                  labelId="reportValueInLakhs"
                  id="reportValueInLakhs"
                  name="reportValueInLakhs"
                  value={state.dprRequestDetails.reportValueInLakhs || false}
                  onChange={onInputChange}
                  label="reportValueInLakhs"
                  className={classes.input}
                >
                  <MenuItem value={false}>Actual</MenuItem>
                  <MenuItem value={true}>Lakhs</MenuItem>
                </Select>
              </FormControl>
            </span>
            <br></br>
            <br></br>
            <div className={classes.buttonWrapper}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                Submit
              </Button>
            </div>
          </form>
        );
      default:
        return "Unknown step";
    }
  };

  const getCreateReport = () => {
    return (
      <span className={classes.root}>
        <h1>In few steps, provide details and get a DPR prepared!</h1>
        <p>
          The details will get used to prepare a DPR for your specific needs.
        </p>
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <span>
            All steps completed
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </span>
        ) : (
          getStepContent(activeStep)
        )}
      </span>
    );
  };

  return (
    <div>{state.dprHTML.length !== 0 ? getReport() : getCreateReport()}</div>
  );
}
