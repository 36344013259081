import React from 'react';
import ActionCard from './ActionCard';
import CourseCard from './CourseCard';
import configureStore from '../../../store/configureStore';
import { history } from '../../../reducers';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

// Images 
import Suggestion from '../../assets/images/ibcImages/objects-lightbulb.svg';
import Faq from '../../assets/images/ibcImages/objects-messages.svg';
import Documentation from '../../assets/images/ibcImages/objects-manual.svg';
import Knowledge from '../../assets/images/ibcImages/objects-life-preserver.svg';
import Banner from '../../assets/images/ibcImages/homebanner.png';
import Investment from '../../assets/images/ibcImages/money-bag.png';
import Loan from '../../assets/images/ibcImages/loan.png';
import Dpr from '../../assets/images/ibcImages/dpricon.png';
import infoportal from '../../assets/images/ibcImages/infoportal.png';
import Meeting from '../../assets/images/ibcImages/work.png';
import CompanyProfile from "../../assets/images/ibcImages/userprofile.png";
import Tasks from "../../assets/images/ibcImages/tasks.png";
import Repo from "../../assets/images/ibcImages/repo.png";
import Employees from "../../assets/images/ibcImages/Employes.png";
import Branches from "../../assets/images/ibcImages/branches.png";
import Business from "../../assets/images/ibcImages/buildings.png";
import HRM from "../../assets/images/ibcImages/hrm.png";
import Account from "../../assets/images/ibcImages/account-manager.png";
import BusinessCardBg from "../../assets/images/ibcImages/businesscard.png"




const store = configureStore();

export default function BizRegisteredUser(props) {
  const { activeOuid } = store.getState().permission;
  
  const { userDetails } = props;
  return (
    <div>
      {/* <section>
        <CourseCard />
      </section> */}
        <div className='PageBannerWrapper'>
          <div>
            <Paper className="PageBanner BannerWithGradient">
              <div >
                <h1><strong>Welcome to IBC</strong> AAMSHE</h1>
                <p>We have complied a comprehensive entrepreneurship course, So that you can understand key aspects of your business operations and   improve the way you manage it.</p>
                <Button variant="contained" color="primary" onClick={() => { history.push(`/${activeOuid}/course-dash`); }}>
                  Get started
                </Button>
              </div>
              <div>
                <img src={Banner} alt=''/>
              </div>
            </Paper>    
          </div>
          {/* <div>
            <Paper className="HomeCompanyProfileCard" onClick={() => { history.push(`/${activeOuid}/company-profile`); }}>
              <div>
                <div className='CompanyName'>{userDetails.data.defaultOrg}</div>
                <div className='OwnerName'>{userDetails.data.name}</div>
                <div className='AddressTitle'>Address</div>
                <div className='Address'>1st floor, Trags complex</div>
              </div>
              <div>
                <img src={Business} alt=''/>
              </div>
              
            </Paper>
          </div> */}
        </div>
        <div className='HomeServicesWrapper'>
          <Paper className="HomeServicesCard" onClick={() => { history.push(`/${activeOuid}/investreq-dash`); }}>
            <div><img src={Investment} alt=''/></div>
            <h2>Investment</h2>
            <p>Start Preparing to get investment. We will guide you through the details and help you prepare documents.</p>
          </Paper>
          <Paper className="HomeServicesCard" onClick={() => { history.push(`/${activeOuid}/loanreq-dash`); }}>
            <div><img src={Loan} alt=''/></div>
            <h2>Loan</h2>
            <p>We make the complex process of applying for loan easy for you and help you understand the steps involved.</p>
          </Paper>
          <Paper className="HomeServicesCard" onClick={() => { history.push(`/${activeOuid}/appointment-dash`); }}>
            <div><img src={Meeting}  alt=''/></div>
            <h2>Meetings</h2>
            <p>Fix a meeting with one of our officials for all your concerns regarding the process we will be avilable throughout your journey</p>
          </Paper>
          <Paper className="HomeServicesCard" onClick={() => { history.push(`/${activeOuid}/dprreq-dash`); }}>
            <div><img src={Dpr}  alt=''/></div>
            <h2>Create DPR</h2>
            <p>It helps in project planning to final project execution and always crucial when you want to achieve your goals,</p>
          </Paper>
          <Paper className="HomeServicesCard" onClick={() => { history.push(`/${activeOuid}/user-govtscheme`); }}>
            <div><img src={infoportal}  alt=''/></div>
            <h2>Govt. Scheme</h2>
            <p>It helps in project planning to final project execution and always crucial when you want to achieve your goals,</p>
          </Paper>
        </div>
      
      {/* <div className="HomeCompanyAppsSection">
        <h3>Manage your company</h3>
        <Paper className='HomeCompanyAppsWrapper'>
          <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/company-profile`); }}>
            <img src={CompanyProfile} alt=''/>
            <p>Company Profile</p>
          </div>
          <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/orgusers`); }}>
            <img src={Employees} alt=''/>
            <p>Employees</p>
          </div>
          <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/orgunits`); }}>
            <img src={Branches} alt=''/>
            <p>Branches</p>
          </div>
          <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/org-workqueue-dash`); }}>
            <img src={Tasks} alt=''/>
            <p>Tasks</p>
          </div>
          <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/document-dash`); }}>
            <img src={Repo} alt=''/>
            <p>Repository</p>
          </div>
          <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/hrmapp-dash`); }}>
            <img src={HRM} alt=''/>
            <p>HRM</p>
          </div>
          <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/hrmapp-dash`); }}>
            <img src={Account} alt=''/>
            <p>Accounts</p>
          </div>
          
        </Paper>
      </div> */}
    </div>

  );
}