import React, { useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";

import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import logo from "../assets/images/ibc-logo.png";
import { Auth } from "aws-amplify";
import { history } from "../../reducers";
import configureStore from "../../store/configureStore";
import { resetState } from "../../actions";
import {
  setActiveOuid,
  setActiveOuidChain,
} from "../../actions/action-permissions";
import { setActiveOiudConfigs } from "../../actions/action-config";
import { RedirectFunc } from "../common/CommonFunctions";

// Images

import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import Logo from "../assets/images/ibcImages/weblogoNew.png";


// Material Ui
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const store = configureStore();

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    top: 64,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  navlinks: {
    flexGrow: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  userName: {
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  link: {
    color: "#fff",
    padding: 10,
    fontSize: 14,
  },
  Mobilelink: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 15,
  },
  MobileLinkItem: {
    fontSize: 14,
    padding: 10,
    color: '#000',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },

  sectionDesktop: {
    alignItems: "center",
    [theme.breakpoints.up("xs")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  select: {
    color: "#fff",
    "&:before": {
      borderColor: "#fff",
    },
    "&:after": {
      borderColor: "#fff",
    },
  },
  icon: {
    fill: "#fff",
  },
}));

function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorProfile, setAnchorProfile] = React.useState(null);
  const openProfile = Boolean(anchorProfile);
  const isAuthenticated = props.auth.isAuthenticated;
  const { activeOuid, userRoles, policies, activeOuidType } =
    store.getState().permission;
  const [activeOuidValue, setActiveOuidValue] = React.useState(activeOuid);
  const { session } = store.getState().jwt.api.userSession;

  useEffect(() => {
    console.log("Header Inv props: ", props)
    setActiveOuidValue(store.getState().permission.activeOuid);
  }, [JSON.stringify(props)]);

  const handleDrawerOpen = () => {
    setOpen((open) => !open);
  };

  const handleProfileMenu = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorProfile(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogOut = async (event) => {
    event.preventDefault();
    try {
      Auth.signOut();
      props.auth.setAuthStatus(false);
      props.auth.setUser(null);
      store.dispatch(resetState());
      history.push("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDropDown = async (event) => {
    setActiveOuidValue(event.target.value);
    const path = window.location.pathname.split("/");
    // await RedirectFunc(`/${event.target.value}/${path[path.length - 1]}`)
    await store.dispatch(setActiveOuid(event.target.value));
    await store.dispatch(setActiveOuidChain(event.target.value));
    await store.dispatch(setActiveOiudConfigs(event.target.value));
    const { activeOuidCoreConfigs } = store.getState().config;
    if (activeOuidCoreConfigs.data) {
      const deafaultUrl = activeOuidCoreConfigs.data.config.home;
      RedirectFunc(`/${event.target.value}/${deafaultUrl}`);
      return;
    } else {
      RedirectFunc(`/${event.target.value}`);
      return;
    }
  };
  const renderMenuTtem = () => {
    const activeOuidExist = userRoles.find(({ ouid }) => ouid === activeOuid);
    let renderOuids = userRoles;
    if (activeOuidExist) {
      renderOuids = userRoles;
    } else {
      renderOuids.push({ ouid: activeOuid, roles: [] });
    }
    return renderOuids.map((item, index) => (
      <MenuItem key={item.ouid} value={item.ouid}>
        {item.ouid}
      </MenuItem>
    ));
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        color="secondary"
      >
        <Toolbar>
          <Link to={`/${activeOuid}/inv-user-home`} className={classes.link}>
            <img src={Logo} alt="" className="leaperxLogo"/>
          </Link>
          {activeOuid ? (
            <div className={classes.navlinks}>
              <Link to={`/${activeOuid}/inv-user-home`} className={classes.link}>
                Home
              </Link>
              <Link to={`/${activeOuid}/appointment-dash`} className={classes.link}>
                Meetings
              </Link>
              <Link to={`/${activeOuid}/investreq-wq-dash-inv`} className={classes.link}>
                Investment Request
              </Link>
              
            </div>
          ) : (
            <div className={classes.navlinks}>
              <Link to="/" className={classes.link}>
                Home
              </Link>
              <Link to={`/system/user-home`} className={classes.link}>
                Register your company
              </Link>
            </div>
          )}

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {isAuthenticated ? (
              <div>
                <IconButton
                  aria-label="new notifications"
                  color="inherit"
                  onClick={() => {
                    history.push(`/${activeOuid}/notifications`);
                  }}
                >
                  <Badge color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push("/login");
                }}
              >
                Login
              </Button>
            )}

            {isAuthenticated && (
              <>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleProfileMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorProfile}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  getContentAnchorEl={null}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={openProfile}
                  onClose={handleProfileClose}
                >
                  <MenuItem
                    onClick={() => {
                      history.push(`/${activeOuid}/my-profile`);
                    }}
                  >
                    My Profile
                  </MenuItem>
                  <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                </Menu>
              </>
            )}
            {!isAuthenticated && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorProfile}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openProfile}
                onClose={handleProfileClose}
              >
                <MenuItem
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  Login
                </MenuItem>
              </Menu>
            )}
            {isAuthenticated && (
              <div className={classes.userName}>{session.username}</div>
            )}
          </div>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <Drawer
          variant="permanent"
          anchor="right"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          {activeOuid ? (
            <div>
              <div className={classes.Mobilelink}>
                <Link to="/" className={classes.MobileLinkItem}>
                  Home
                </Link>
                <Link
                  to={`/${activeOuid}/appointment-dash`}
                  className={classes.MobileLinkItem}
                >
                  Meeting
                </Link>
                <Link
                  to={`/${activeOuid}/investreq-wq-dash-inv`}
                  className={classes.MobileLinkItem}
                >
                  Investment Requests
                </Link>
              </div>
            </div>
          ) : (
            <div className={classes.Mobilelink}>
              <Link to="/" className={classes.MobileLinkItem}>
                Home
              </Link>
              <Link to={`/system/user-home`} className={classes.MobileLinkItem}>
                Register your company
              </Link>
            </div>
          )}
        </Drawer>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
