import React, { useState } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import Loader from "../../../common/Loader";
import { history } from "../../../../reducers";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { DataGrid } from "@material-ui/data-grid";
import InvReqWorkDetails from "./InvReqWorkDetails";
import { timeFormat } from "../../../common/CommonFunctions";

const store = configureStore();

const workColumns = [
  {
    field: "businessName",
    headerName: "Business Name",
    width: 250,
    valueFormatter: (params) =>
      params.row?.data?.details?.investmentRequestDetails?.businessName,
  },
  {
    field: "investment",
    headerName: "Investment Amount",
    width: 200,
    editable: false,
    valueFormatter: (params) =>
      "Rs " + params.row?.data?.details?.investmentRequestDetails?.totalCost,
  },

  {
    field: "created_Date",
    headerName: "Created Date",
    type: "number",
    width: 200,
    editable: false,
    valueGetter: (params) =>
      `${timeFormat(params.getValue(params.id, "createdDate")) || ""}`,
  },
  {
    field: "requestedUser",
    headerName: "User",
    width: 150,
    editable: false,
    valueFormatter: (params) => params.row?.data?.username,
  },
  {
    field: "requestedOrg",
    headerName: "Organisation",
    width: 150,
    editable: false,
    valueFormatter: (params) => params.row?.data?.clientOrg,
  },
  {
    field: "workstatus",
    headerName: "Status",
    sortable: false,
    width: 150,
    valueGetter: (params) =>
      `${params.getValue(params.id, "status") || ""} ${
        params.getValue(params.id, "status") === "ASSIGNED" ? "To" : ""
      } ${params.getValue(params.id, "username") || ""}`,
  },
];

function InvReqWorkQueue(props) {
  const {
    workContext,
    rows,
    defaultStatus,
    usersToshow,
    handleTeamWorkStatusChange,
    handleDetailsClick,
  } = props;
  const { activeOuid } = store.getState().permission;
  const { showGlobalLoader } = store.getState().work;
  const path = window.location.pathname.split("/");
  const teamId = path[path.length - 2];

  const [workDash, setWorkDash] = useState(false);
  const [currentWork, setCurrentWork] = useState();
  const [selectionModel, setSelectionModel] = React.useState();

  const openWorkDash = (data) => {
    console.log("openWorkDash : ", data.id);
    setWorkDash(true);
    setCurrentWork(data);
    setSelectionModel(data.id);
    return handleDetailsClick();
  };

  const closeWorkDash = () => {
    console.log("closeWorkDas");
    setWorkDash(false);
    setCurrentWork();
    return handleDetailsClick();
  };

  const renderInvReqWorkQueue = (rows) => {
    console.log(rows);
    return rows.map((item) => (
      <div className="InvReqWorkQueueCard">
        <div key={item.businessName} className="InvReqWorkQueueCardTitle">
          {item.data.clientOrg}
        </div>
        <div className="InvReqWorkQueueCardDetails">
          <span>Investment Amount</span>:
          {item.data.details.investmentRequestDetails.totalCost}
        </div>
        <div className="InvReqWorkQueueCardDetails">
          <span>Years in business</span>:
          {item.data.details.investmentRequestDetails.yearsInBusiness}
        </div>
        <div className="InvReqWorkQueueCardDetails">
          <span>Number of staffs</span>:
          {item.data.details.investmentRequestDetails.numberOfStaffs}
        </div>
        <br></br>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            openWorkDash(item);
          }}
        >
          Full details
        </Button>
      </div>
    ));
  };
  return (
    <div>
      {/* <div className="listHeader">
        <FormControl variant="outlined" className="selectBox">
          <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={defaultStatus}
            label="Status"
          >
            <MenuItem value="OPEN">Open</MenuItem>
            <MenuItem value="ASSIGNED">Assigned</MenuItem>
            <MenuItem value="INPROGRESS">InProgress</MenuItem>
          </Select>
        </FormControl>
      </div> */}
      {showGlobalLoader ? (
        <Loader />
      ) : (
        <div className="">
          {rows !== 0 ? (
            <div className="InvReqWorkQueueCardWrapper">
              {renderInvReqWorkQueue(rows)}
            </div>
          ) : (
            "No tasks....."
          )}
          <div>
            {workDash && (
              <InvReqWorkDetails
                workContext={workContext}
                work={currentWork}
                usersToshow={usersToshow}
                closeWorkDash={() => {
                  closeWorkDash();
                }}
                rerender={props.rerender}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InvReqWorkQueue);
