import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PaymentPopup from './PaymentPopup'
import PaymentPage from './PaymentPage';
import Welcome from '../../../components/assets/images/ibcImages/welcomecard.jpg';
import Money from '../../../components/assets/images/ibcImages/moneycard.jpg';
import Shop from '../../../components/assets/images/ibcImages/shopcard.jpg';
import Funding from '../../../components/assets/images/ibcImages/fundingcard.jpg';
import Done from '../../../components/assets/images/ibcImages/donecard.jpg';


const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: '0 auto',
    borderRadius: 15,
    overflow: 'hidden',
    marginTop: 30,
  },
  packageCardBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '100%',
  },
  eyebrow:{
    background: '#d1e9f8',
    marginBottom: 30,
    width: 'max-content',
    color: '#59b1e6',
    padding: '3px 10px',
    borderRadius: 25,
    fontSize: 12,
  },
  packageCardContent: {
    position: 'relative',
    minHeight:250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  heading: {
    maxWidth: '70%',
    color: '#fff',
    fontWeight: 'bold',
  },
  details: {
    maxWidth: '70%',
    color: '#fff',
  },
  packagePrice:{
    fontWeight: 'bold',
    color: '#00c853',
    fontSize: 28,
  },
  button: {
    alignSelf: 'flex-end',
  }

});
function getSteps() {
  return ['Step 1', 'Step 2', 'Step 3'];
}

export default function PackageCard(props) {
  const classes = useStyles();

  const { packageDetails, userDetails } = props;
  const [payment, setPayment] = React.useState(false);


  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step) => {
    switch (step) {
        case 0:
            return (<Card className={classes.root} component="div">
              <CardActionArea>
                <CardMedia
                  component="img"
                  image={Welcome}
                  alt="Package image"
                  className={classes.packageCardBg}
                />
                <CardContent className={classes.packageCardContent}>
                  <Typography gutterBottom variant="h5" component="h2" className={classes.heading}>
                  Welcome <br></br>Let’s get a membership.
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.details}>
                    {packageDetails.details}
                  </Typography>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                  >
                      Continue
                  </Button>
                </CardContent>
              </CardActionArea>
              
            </Card>);
        case 1:
            return (<Card className={classes.root} component="div">
              <CardActionArea>
                <CardMedia
                  component="img"
                  image={Money}
                  alt="Package image"
                  className={classes.packageCardBg}
                />
                <CardContent className={classes.packageCardContent}>
                  <Typography gutterBottom variant="h5" component="h2" className={classes.heading}>
                  Grow your business with us
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.details}>
                  We aim to provide business opportunities to existing and upcoming businesses.
                  </Typography>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                  >
                      Continue
                  </Button>
                </CardContent>
              </CardActionArea>
              
            </Card>);
        case 2:
            return (<Card className={classes.root} component="div">
              <CardActionArea onClick={() => setPayment(!payment)}>
                <CardMedia
                  component="img"
                  image={Shop}
                  alt="Package image"
                  className={classes.packageCardBg}
                />
                <CardContent className={classes.packageCardContent}>
                  <Typography gutterBottom variant="h5" component="h2" className={classes.heading}>
                    Get funding for your business
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.details}>
                    We will help to find the best investment option for your business.
                  </Typography>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setPayment(!payment)}
                      className={classes.button}
                  >
                      Continue
                  </Button>
                </CardContent>
              </CardActionArea>
              
            </Card>);
        default:
            return 'Unknown step';
    }
}


  return (
    <div>
      <div>
          {activeStep === steps.length ? (
              <div>
                  <Typography className={classes.instructions}>
                      All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset} className={classes.button}>
                      Reset
                  </Button>
              </div>
          ) : (
              <div>
                  <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              </div>
          )}
      </div>
      <PaymentPopup
        defaultState={payment}
        componentToPassDown={
          <PaymentPage
            userDetails={userDetails}
            packageDetails={packageDetails}
            handleClose={() => { setPayment(!payment) }}
          />}
        handleClose={() => { setPayment(!payment) }}
      />
    </div>
  );
}
