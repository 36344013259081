import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CertificationImage from '../../assets/images/ibcImages/Certification.jpg';

const useStyles = makeStyles({
  root: {
    minHeight: 200,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  media: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: '100%',
  },
  CardActionArea: {
    minHeight: 200,
    color: '#fff',
  },
  CardHeading: {
    maxWidth: '50%',
  },
  Cardbtn:{
    padding: '10px 20px',
    background: '#fa5055',
    display: 'inline-block',
    borderRadius: 25,
    color: '#fff',
    marginTop: 10,
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 'bold',
  }
});

export default function CourseCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
          className={classes.media}
          image={CertificationImage}
        />
      <CardActionArea className={classes.CardActionArea}>
        <CardContent>
          <Typography variant="p" component="h2" className={classes.CardHeading}>
            GET ENTREPRENEUR CERTIFICATION
          </Typography>
          <Typography variant="a" component="a" className={classes.Cardbtn}>
            Start Course
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
