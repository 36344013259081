import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import configureStore from "../../../store/configureStore";
import CommonSnackBar from "../../common/CommonSnackBar";
import { Auth } from "aws-amplify";
import { history } from "../../../reducers";
import { resetState } from "../../../actions";
import CompletedImage from "../../../components/assets/images/ibcImages/registrationcomplete.png";
import ProgressCircle from "../../common/ProgressCircle";
import { setJwt, setCreds } from "../../../actions/action-jwt";
import { setAllPermissions } from "../../../actions/action-permissions";
import { get_profile_details } from "../../../actions/action-myProfile";
import { setActiveOiudConfigs } from "../../../actions/action-config";
import {
  setActiveOuid,
  setActiveOuidChain,
} from "../../../actions/action-permissions";
import {
  login_requested,
  login_success,
  login_failed,
} from "../../../actions/action-auth";
import jwt from "jwt-decode";
import { userRoleCheck } from "../../common/CommonFunctions";
const store = configureStore();
class RegistartionCompleted extends Component {
  constructor() {
    super();
    this.state = {};
    this.handleLogOut = this.handleLogOut.bind(this);
    this.refreshJwt = this.refreshJwt.bind(this);
  }
  setAuthStatus = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  componentDidMount() {
    setTimeout(() => {
    //   this.handleLogOut();
      this.refreshJwt();
    }, 5000);
  }
  async refreshJwt(){
    try {
        const session = await Auth.currentSession();
        this.setAuthStatus(true);
        // console.log("currentSession", session);
        const user = await Auth.currentAuthenticatedUser({bypassCache: true});
        console.log("currentSession user registration completed", user);
        this.setUser(user);
        const { setJwt, get_profile_details } = this.props;
        await setJwt(user);
        await get_profile_details(user.username);
        const idToken = user.signInUserSession.idToken;
        const decodedToken = jwt(idToken.jwtToken);
        if (decodedToken.policies) {
          const policies = decodedToken.policies.split(";");
          let teamPolicies = [];
          if (decodedToken.teamPolicies !== "")
            teamPolicies = decodedToken.teamPolicies.split(";");
          let appPolicies = [];
          if (decodedToken.appPolicies !== "")
            appPolicies = decodedToken.appPolicies.split(";");
          const allPolicy = {
            policies,
            teamPolicies,
            appPolicies,
          };
          const {
            setAllPermissions,
            setActiveOuid,
            setActiveOuidChain,
            setActiveOiudConfigs,
          } = this.props;
          await setAllPermissions(allPolicy);
          await userRoleCheck(policies);
          const { userRoles } = store.getState().permission;
          const { details } = store.getState().myProfile;
          console.log("details myProfile", details);
          await setActiveOuid(userRoles[0].ouid);
          await setActiveOuidChain(userRoles[0].ouid);
          await setActiveOiudConfigs(userRoles[0].ouid);
          if (this.state.redirection) {
            console.log("this.state.redirection is true");
            this.props.history.push(this.state.redirection);
          } else {
            const { activeOuidCoreConfigs } = store.getState().config;
            if (
              details.data.defaultOrgType &&
              details.data.defaultOrgType === "biz"
            ) {
              console.log(
                "details.data.defaultOrgType",
                details.data.defaultOrgType
              );
              console.log("pushing to ", details.data.defaultOrg, "/user-home");
              this.props.history.push(`/${details.data.defaultOrg}/dashboard`);
            } else if (
              details.data.defaultOrgType &&
              details.data.defaultOrgType === "inv"
            ) {
              console.log(
                "pushing to ",
                details.data.defaultOrg,
                "/inv-user-home"
              );
              this.props.history.push(
                `/${details.data.defaultOrg}/inv-user-home`
              );
            } else if (activeOuidCoreConfigs.data) {
              //could be there for rootOrg, but not system org.
              const deafaultUrl = activeOuidCoreConfigs.data.config.home;
              this.props.history.push(`/${userRoles[0].ouid}/${deafaultUrl}`);
            } else {
              this.props.history.push(`/${userRoles[0].ouid}/dashboard`);
            }
          }
        } else {
          let myProfile = store.getState().myProfile;
          console.log("myProfile", myProfile);
          if (
            myProfile.details.data.usertype &&
            myProfile.details.data.usertype === "investor"
          ) {
            this.props.history.push(`/system/inv-user-home`);
          } else if (
            myProfile.details.data.usertype &&
            myProfile.details.data.usertype === "cp"
          ) {
            this.props.history.push(`/system/cp-user-home`);
          } else {
            this.props.history.push(`/system/user-home`);
          }
        }
      } catch (error) {
        console.log(error);
        await store.dispatch(login_failed());
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: err,
          },
        });
      }
  }

  handleLogOut() {
    try {
      Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      //store.dispatch(resetState());
      //history.push("/login");
    //   this.reLogin();
    } catch (error) {
      console.log(error.message);
    }
  }
  reLogin = async () => {
    // AWS Cognito integration here
    try {
      const { creds } = store.getState().jwt.ucreds;
      console.log("ucreds.username", creds.username);
      console.log("ucreds.pass", creds.pass);
      console.log(store.getState().jwt);
      store.dispatch(login_requested());
      const user = await Auth.signIn(creds.username, creds.pass);
      store.dispatch(login_success());
      console.log("currentSession user", user);
      this.props.auth.setAuthStatus(true);
      this.props.auth.setUser(user);
      const { setJwt, get_profile_details } = this.props;
      await setJwt(user);
      await get_profile_details(user.username);
      const idToken = user.signInUserSession.idToken;
      const decodedToken = jwt(idToken.jwtToken);
      if (decodedToken.policies) {
        const policies = decodedToken.policies.split(";");
        let teamPolicies = [];
        if (decodedToken.teamPolicies !== "")
          teamPolicies = decodedToken.teamPolicies.split(";");
        let appPolicies = [];
        if (decodedToken.appPolicies !== "")
          appPolicies = decodedToken.appPolicies.split(";");
        const allPolicy = {
          policies,
          teamPolicies,
          appPolicies,
        };
        const {
          setAllPermissions,
          setActiveOuid,
          setActiveOuidChain,
          setActiveOiudConfigs,
        } = this.props;
        await setAllPermissions(allPolicy);
        await userRoleCheck(policies);
        const { userRoles } = store.getState().permission;
        const { details } = store.getState().myProfile;
        console.log("details myProfile", details);
        await setActiveOuid(userRoles[0].ouid);
        await setActiveOuidChain(userRoles[0].ouid);
        await setActiveOiudConfigs(userRoles[0].ouid);
        if (this.state.redirection) {
          console.log("this.state.redirection is true");
          this.props.history.push(this.state.redirection);
        } else {
          const { activeOuidCoreConfigs } = store.getState().config;
          if (
            details.data.defaultOrgType &&
            details.data.defaultOrgType === "biz"
          ) {
            console.log(
              "details.data.defaultOrgType",
              details.data.defaultOrgType
            );
            console.log("pushing to ", details.data.defaultOrg, "/user-home");
            this.props.history.push(`/${details.data.defaultOrg}/user-home`);
          } else if (
            details.data.defaultOrgType &&
            details.data.defaultOrgType === "inv"
          ) {
            console.log(
              "pushing to ",
              details.data.defaultOrg,
              "/inv-user-home"
            );
            this.props.history.push(
              `/${details.data.defaultOrg}/inv-user-home`
            );
          } else if (activeOuidCoreConfigs.data) {
            //could be there for rootOrg, but not system org.
            const deafaultUrl = activeOuidCoreConfigs.data.config.home;
            this.props.history.push(`/${userRoles[0].ouid}/${deafaultUrl}`);
          } else {
            this.props.history.push(`/${userRoles[0].ouid}/dashboard`);
          }
        }
      } else {
        let myProfile = store.getState().myProfile;
        console.log("myProfile", myProfile);
        if (
          myProfile.details.data.usertype &&
          myProfile.details.data.usertype === "investor"
        ) {
          this.props.history.push(`/system/inv-user-home`);
        } else if (
          myProfile.details.data.usertype &&
          myProfile.details.data.usertype === "cp"
        ) {
          this.props.history.push(`/system/cp-user-home`);
        } else {
          this.props.history.push(`/system/user-home`);
        }
      }
    } catch (error) {
      console.log(error);
      await store.dispatch(login_failed());
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err,
        },
      });
    }
  };
  render() {
    const { permissions } = store.getState().permission;
    return (
      <div className="page">
        {/* <SidebarWrapper
                    auth={this.props.auth}
                    permissions={permissions}
                /> */}
        <section className="section">
          <div className="container--narrow">
            
              <div className="UserOrgConfirmationPageWrapper">
                <div>
                    <div class="spinner-box">
                        <div class="circle-border">
                        <div class="circle-core"></div>
                    </div>  
                </div>  
                  <div>You company is setting up</div>
                  <div className="redirect_message">
                    Will be ready in 5 sec.
                  </div>
                  
                </div>
              </div>
          </div>
        </section>
        <CommonSnackBar />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  permission: state.permission,
  auth_state: state.auth_state,
});
const mapDispatchToProps = (dispatch) => ({
  setJwt: (user) => dispatch(setJwt(user)),
  setCreds: (creds) => dispatch(setCreds(creds)),
  setAllPermissions: (policies) => dispatch(setAllPermissions(policies)),
  setActiveOuid: (ouid) => dispatch(setActiveOuid(ouid)),
  setActiveOuidChain: (ouid) => dispatch(setActiveOuidChain(ouid)),
  setActiveOiudConfigs: (ouid) => dispatch(setActiveOiudConfigs(ouid)),
  get_profile_details: (username) => dispatch(get_profile_details(username)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistartionCompleted);