import React, { Component } from "react";
import { connect } from "react-redux";
import SidebarWrapper from "../layout/SidebarWrapper";
import configureStore from "../../store/configureStore";
import appconfig from "../../config";
import hrm from "../assets/images/ibcImages/hrm.svg";
import crm from "../assets/images/ibcImages/crm.svg";
import dpr from "../assets/images/ibcImages/dpr.svg";
import ecom from "../assets/images/ibcImages/ecom.svg";
import vc from "../assets/images/ibcImages/vc.svg";
import ca from "../assets/images/ibcImages/ca.svg";
import dm from "../assets/images/ibcImages/digitalmarketing.svg";
import accounts from "../assets/images/ibcImages/accounts.svg";
import dprCs from "../assets/images/ibcImages/dpr-commingsoon.svg";
import accountCs from "../assets/images/ibcImages/account-commingsoon.svg";
import virtualCs from "../assets/images/ibcImages/vc-commingsoon.svg";
import { Link } from "react-router-dom";
import Footer from "../layout/Footer";
import DashboardFooter from "../layout/DashboardFooter";
import meeting from "../assets/images/ibcImages/meetings_n.svg";
import investment from "../assets/images/ibcImages/investment_n.svg";
import loan from "../assets/images/ibcImages/loan_n.svg";
import info from "../assets/images/ibcImages/info_portal_n.svg";
import tutorail from "../assets/images/ibcImages/bussiness_tutorial_n.svg";
import { history } from "../../reducers";
import Loader from "../common/Loader";

// import meeting from "../assets/images/ibcImages/meetings.svg";
// import investment from "../assets/images/ibcImages/investment.svg";
// import loan from "../assets/images/ibcImages/loan.svg";
// import info from "../assets/images/ibcImages/info.svg";
// import tutorail from "../assets/images/ibcImages/tutorial.svg";

const store = configureStore();

class Dashboard extends Component {
  state = {
    loading: false,
   };

  openAppUrl(url) {
    this.setState({ loading: true });
  setTimeout(() => {
    window.location.href = url;  
  }, 1000);
  }

  render() {
    const { loading } = this.state;
    const { activeOuid, permission, activeOuidType } =
      store.getState().permission;
    console.log("activeOuidType", activeOuidType);
    if (activeOuidType === "ROOT") {
      history.push(`/${activeOuid}/root-user-home`);
    } else if (activeOuidType === "cp") {
      history.push(`/${activeOuid}/cp-user-home`);
    } else if (activeOuidType === "inv") {
      history.push(`/${activeOuid}/inv-user-home`);
    }
    if (store.getState().jwt.api.credentials.idToken) {
      const { jwtToken } = store.getState().jwt.api.credentials.idToken;
      var hrmAppUrl = appconfig.appui.hrmUrl + "?token=" + jwtToken;
      var ecomAppUrl = appconfig.appui.ecomUrl + "?token=" + jwtToken;
      var crmAppUrl = appconfig.appui.crmUrl + "?token=" + jwtToken;
      var vcAppUrl = appconfig.appui.vcUrl + "?token=" + jwtToken;
      var accountsUrl = appconfig.appui.accountsUrl + "?token=" + jwtToken;
      console.log("hrmAppUrl : ", hrmAppUrl);
      console.log("jwtToken : ", jwtToken);
    }
    
    if (loading) {
      return <Loader/>;
    }

    return (
      <div className="page AppPage">
        <SidebarWrapper auth={this.props.auth} permission={permission} />
        <section className="hero is-primary container--narrow">
          <div className="w_box mb mt">
            <div className="DashboardCategoryTitle">services</div>
            <div className="homeMisServiceWrap">
              <Link
                to={`/${activeOuid}/appointment-dash`}
                className="HrmAppHomeListItem"
              >
                <img src={meeting} />
                <p className="AppLabel">Meetings</p>
              </Link>
              <Link
                to={`/${activeOuid}/investreq-dash`}
                className="HrmAppHomeListItem"
              >
                <img src={investment} />
                <p className="AppLabel">Investment</p>
              </Link>
              <Link
                to={`/${activeOuid}/loanreq-dash`}
                className="HrmAppHomeListItem"
              >
                <img src={loan} />
                <p className="AppLabel">Loan</p>
              </Link>
              <Link
                to={`/${activeOuid}/user-govtscheme`}
                className="HrmAppHomeListItem"
              >
                <img src={info} />
                <p className="AppLabel">Information Portal</p>
              </Link>
              {/* <Link to={`/${activeOuid}/payroll`} className="HrmAppHomeListItem">
                    <img src={dm} />
              </Link> */}
              <Link
                to={`/${activeOuid}/course-dash`}
                className="HrmAppHomeListItem"
              >
                <img src={tutorail} />
                <p className="AppLabel">Business Tutorial </p>
              </Link>
            </div>
          </div>
          <div className="w_box mb ">
            <div className="DashboardCategoryTitle">tools</div>
            <div className="HrmAppHomeListWrapper ">
              <div>
                <div onClick={() => this.openAppUrl(hrmAppUrl)}>
                  <img src={hrm} />
                </div>
                <p className="AppLabel">HRM</p>
              </div>
              <div>
                <div onClick={() => this.openAppUrl(crmAppUrl)}>
                  <img src={crm} />
                </div>
                <p className="AppLabel">CRM </p>
              </div>
              <div>
                <div onClick={() => this.openAppUrl(vcAppUrl)}>
                  <img src={vc} />
                </div>
                <p className="AppLabel">IBC Connect</p>
              </div>

              <div>
                <div onClick={() => this.openAppUrl(accountsUrl)}>
                  <img src={accounts} />
                </div>

                <p className="AppLabel">Accounts</p>
              </div>
              {
                <div>
                  <Link to={`/${activeOuid}/dprreq-dash`} className="">
                    <img src={dpr} />
                  </Link>
                  <p className="AppLabel">DPR</p>
                </div>
              }
              <div>
                <div onClick={() => this.openAppUrl(ecomAppUrl)}>
                  <img src={ecom} />
                </div>

                <p className="AppLabel">Ecom</p>
              </div>
            </div>
          </div>
        </section>
        <DashboardFooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
