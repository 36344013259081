import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Orgunit } from "@material-ui/icons";
import { history } from "../../reducers";
import Button from "@material-ui/core/Button";
import { RedirectFunc } from "./CommonFunctions";
import { setActiveOiudConfigs } from "../../actions/action-config";
import {
  setActiveOuid,
  setActiveOuidChain,
} from "../../actions/action-permissions";
import configureStore from "../../store/configureStore";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Editor } from "react-draft-wysiwyg";

import { EditorState, convertFromRaw } from "draft-js";

const store = configureStore();

const useStyles = makeStyles({
  root: {
    marginBottom: 10,
  },
  Title: {
    fontSize: 14,
    fontWeight: "bold",
  },
  pos: {
    marginBottom: 12,
  },
  CardActions: {
    padding: 0,
  },
});

export default function FaqAccordion(props) {
  //console.log("props.Posts.data ", props.Post.data);
  //console.log("props.Posts ", props.Post);

  const classes = useStyles();
  const Icontooltip = withStyles({
    tooltip: {
      fontSize: 12,
    },
  })(Tooltip);
  const data = props.Post.data;
  console.log("data", data);
  const { activeOuid } = store.getState().permission;

  var c = "";
  try {
    c = JSON.parse(data.content);
  } catch (e) {
    c = data.content;
  }
  console.log(
    "convertedState",
    EditorState.createWithContent(convertFromRaw(c))
  );
  const editorState = EditorState.createWithContent(convertFromRaw(c));
  const readOnly = true;

  return (
    <>
      <div className={classes.root}>
        {data && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.Title}>{data.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {editorState ? (
                <Editor
                  editorState={editorState}
                  readOnly={readOnly}
                  toolbarHidden
                />
              ) : (
                ""
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </>
  );
}
