import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { get_post } from '../../../actions/action-post';
import Loader from '../../common/Loader';
import { history } from '../../../reducers';
import SidebarWrapper from '../../layout/SidebarWrapper';
import CommonDelete from '../../common/CommonDelete';
import { timeFormat } from '../../common/CommonFunctions'
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from '../../../actions/action-snackbar';
import { Paper } from '@material-ui/core';


const store = configureStore();

class PostDetails extends Component {

  constructor() {
    super();
    this.state = {
      PostDetails: {},
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {
    // store.dispatch(hideSnackbar());
  }

  async fetchDetails() {
    const path = window.location.pathname.split('/');
    const id = path[path.length - 1];
    console.log("id : ", id);
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const { get_post } = this.props;
    await get_post(activeOuid, activeOuidChain, id);
    console.log("store.getState().post.post[0] : ", store.getState().post.post);
    this.setState({
      PostDetails: store.getState().post.post,
    });
  }

  renderDetails(details) {
    return Object.keys(details).map(function (keyName, keyIndex) {
      return (
        <div key={keyName} className="detailsItem">
          <div className="detailsKey">
            {keyName} :
          </div>
          {keyName === 'lastModifiedDate' || keyName === 'createdDate' ?
            <div className="detailsValue">
              {timeFormat(details[keyName])}
            </div>
            :
            <div className="detailsValue">
              {details[keyName]}
            </div>
          }
        </div>
      )
    });
  }

  render() {
    const { showGlobalLoader } = store.getState().post;
    const { permissions } = store.getState().permission;
    const path = window.location.pathname.split('/');
    const id = path[path.length - 1];
    return (
      <div className="page">
        <SidebarWrapper
          auth={this.props.auth}
          permissions={permissions}
        />
        <section className="section">
          {showGlobalLoader ?
            <Loader />
            : <div className="container">
              <Paper component="div" className="paper">
                <div className="listHeader">
                  <h1>Post Details</h1>
                  <CommonDelete
                    id={id}
                    type="Post"
                  />
                </div>
                {this.state.PostDetails.data ? this.renderDetails(this.state.PostDetails.data) : 'No Post Details Found'}
              </Paper>
            </div>
          }
          <CommonSnackBar />
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  post: state.post,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_post: (activeOuid, activeOuidChain, id) => dispatch(get_post(activeOuid, activeOuidChain, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostDetails);

