import {
  REGISTER_Orgunit_REQUESTED,
  REGISTER_Orgunit_SUCCESS,
  REGISTER_Orgunit_ERRORED,
  GET_ALL_Orgunit_REQUESTED,
  GET_ALL_Orgunit_SUCCESS,
  GET_ALL_Orgunit_ERRORED,
  GET_Orgunit_DETAILS_REQUESTED,
  GET_Orgunit_DETAILS_SUCCESS,
  GET_Orgunit_DETAILS_ERRORED,
  UPDATE_Orgunit_DETAILS_REQUESTED,
  UPDATE_Orgunit_DETAILS_SUCCESS,
  UPDATE_Orgunit_DETAILS_ERRORED,
  DELETE_Orgunit_REQUESTED,
  DELETE_Orgunit_SUCCESS,
  DELETE_Orgunit_ERRORED,
  GET_ORG_PLAN_DETAILS_REQUESTED,
  GET_ORG_PLAN_DETAILS_SUCCESS,
  GET_ORG_PLAN_DETAILS_ERRORED,
  GET_CHANNEL_PARTNERS_REQUESTED,
  GET_CHANNEL_PARTNERS_SUCCESS,
  GET_CHANNEL_PARTNERS_ERRORED,
  GET_INVESTORS_REQUESTED,
  GET_INVESTORS_SUCCESS,
  GET_INVESTORS_ERRORED,
  GET_BUSINESSES_REQUESTED,
  GET_BUSINESSES_SUCCESS,
  GET_BUSINESSES_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  orgunits: [],
  OrgunitDetails: {},
  orgPlanDetails: {},
  error: {},
  bizOrgunits: [],
  cpOrgunits: [],
  invOrgunits: [],
};

export function orgunit(state = defaultState, { type, payload }) {
  switch (type) {
    case REGISTER_Orgunit_REQUESTED:
      console.log("REGISTER_Orgunit_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case REGISTER_Orgunit_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case REGISTER_Orgunit_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_ALL_Orgunit_REQUESTED:
      // console.log("GET_ALL_Orgunit_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        orgunits: [],
      };

    case GET_ALL_Orgunit_SUCCESS:
      // console.log("GET_ALL_Orgunit_SUCCESS : ", JSON.stringify(payload));
      return {
        ...state,
        showGlobalLoader: false,
        orgunits: payload.items,
      };

    case GET_ALL_Orgunit_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_Orgunit_DETAILS_REQUESTED:
      // console.log("GET_Orgunit_DETAILS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        OrgunitDetails: {},
      };

    case GET_Orgunit_DETAILS_SUCCESS:
      // console.log("GET_Orgunit_DETAILS_SUCCESS : ", JSON.stringify(payload));
      return {
        ...state,
        showGlobalLoader: false,
        OrgunitDetails: payload,
      };

    case GET_Orgunit_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_Orgunit_DETAILS_REQUESTED:
      // console.log("UPDATE_Orgunit_DETAILS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_Orgunit_DETAILS_SUCCESS:
      // console.log("UPDATE_Orgunit_DETAILS_SUCCESS : ", JSON.stringify(payload));
      return {
        ...state,
        showGlobalLoader: false,
      };

    case UPDATE_Orgunit_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case DELETE_Orgunit_REQUESTED:
      // console.log("DELETE_Orgunit_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_Orgunit_SUCCESS:
      // console.log("DELETE_Orgunit_SUCCESS : ", JSON.stringify(payload));
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_Orgunit_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_ORG_PLAN_DETAILS_REQUESTED:
      // console.log("GET_ORG_PLAN_DETAILS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        orgPlanDetails: {},
      };

    case GET_ORG_PLAN_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        orgPlanDetails: payload,
      };

    case GET_ORG_PLAN_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case GET_CHANNEL_PARTNERS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        cpOrgunits: [],
      };

    case GET_CHANNEL_PARTNERS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        cpOrgunits: payload.items,
      };

    case GET_CHANNEL_PARTNERS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_INVESTORS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        invOrgunits: [],
      };

    case GET_INVESTORS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        invOrgunits: payload.items,
      };

    case GET_INVESTORS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_BUSINESSES_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        bizOrgunits: [],
      };

    case GET_BUSINESSES_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        bizOrgunits: payload.items,
      };

    case GET_BUSINESSES_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
