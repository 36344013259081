import {
  CREATE_POST_REQUESTED,
  CREATE_POST_SUCCESS,
  CREATE_POST_ERRORED,
  UPDATE_POST_REQUESTED,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_ERRORED,
  GET_ALL_POSTS_REQUESTED,
  GET_ALL_POSTS_SUCCESS,
  GET_ALL_POSTS_ERRORED,
  GET_POST_REQUESTED,
  GET_POST_SUCCESS,
  GET_POST_ERRORED,
  DELETE_POST_REQUESTED,
  DELETE_POST_SUCCESS,
  DELETE_POST_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const create_post_requested = () => ({
  type: CREATE_POST_REQUESTED,
});

export const create_post_success = () => ({
  type: CREATE_POST_SUCCESS,
});

export const create_post_errored = (error) => ({
  type: CREATE_POST_ERRORED,
  error,
});

export const update_post_requested = () => ({
  type: UPDATE_POST_REQUESTED,
});

export const update_post_success = () => ({
  type: UPDATE_POST_SUCCESS,
});

export const update_post_errored = (error) => ({
  type: UPDATE_POST_ERRORED,
  error,
});

export const create_post = (payload) => async (dispatch) => {
  dispatch(create_post_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${apiEndpoint}/post`;
    if (payload.bookInParent) {
      url = `${apiEndpoint}/post`;
    }
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        // console.log("response : ",JSON.stringify(response));
        dispatch(showSnackbar("Post created Successfully", "success"));
        dispatch(create_post_success());
      })
      .catch((err) => {
        console.log("Post creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("Post creation Failed", "error"));
        dispatch(create_post_errored(err));
      });
  }
  return null;
};

export const update_post = (payload) => async (dispatch) => {
  dispatch(update_post_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`update_post`);
  const id = path[3];
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${apiEndpoint}/post/${id}?ouid=${activeOuid}`;
    const method = "put";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        // console.log("response : ",JSON.stringify(response));
        dispatch(showSnackbar("Post updated Successfully", "success"));
        dispatch(update_post_success());
      })
      .catch((err) => {
        console.log("Post update Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("Post update Failed", "error"));
        dispatch(update_post_errored(err));
      });
  }
  return null;
};

export const get_all_posts_requested = () => ({
  type: GET_ALL_POSTS_REQUESTED,
});

export const get_all_posts_success = (payload) => ({
  type: GET_ALL_POSTS_SUCCESS,
  payload,
});

export const get_all_posts_errored = (error) => ({
  type: GET_ALL_POSTS_ERRORED,
  error,
});

export const get_all_posts =
  (
    activeOuid,
    activeOuidChain,
    type,
    category,
    ouid,
    lastEvaluatedKey,
    lastEvaluatedSortKey,
    limit
  ) =>
  async (dispatch) => {
    dispatch(get_all_posts_requested());
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${apiEndpoint}/post?ouid=${ouid}&type=${type}&limit=5`;      
      if (lastEvaluatedKey !== "" && lastEvaluatedKey !== undefined) {
        url += `&lastEvaluatedKey=${lastEvaluatedKey}`;
      }
      if (lastEvaluatedSortKey !== "" && lastEvaluatedSortKey !== undefined) {
        url += `&lastEvaluatedSortKey=${lastEvaluatedSortKey}`;
      }
      console.log("url", url);
      if (category !== "") {
        console.log(`get_all_posts`, category);

        url = `${apiEndpoint}/post?ouid=${ouid}&type=${type}`;
      }
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Posts Fetched Successfully");
          dispatch(get_all_posts_success(response.data));
          return response.data;
        })
        .catch((err) => {
          console.log("Get All Posts Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Posts Failed", "error"));
          }
          dispatch(get_all_posts_errored(err));
        });
    }
    return null;
  };

export const get_post =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(get_post_requested());
    console.log(`get_post`);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${apiEndpoint}/post/${id}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get post Fetched Successfully", response.data);
          dispatch(get_post_success(response.data));
        })
        .catch((err) => {
          console.log("Get Post Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Posts By Failed", "error"));
          }
          dispatch(get_post_errored(err));
        });
    }
    return null;
  };

export const get_post_requested = () => ({
  type: GET_POST_REQUESTED,
});

export const get_post_success = (payload) => ({
  type: GET_POST_SUCCESS,
  payload,
});

export const get_post_errored = (error) => ({
  type: GET_POST_ERRORED,
  error,
});

export const delete_post =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(delete_post_requested());
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${apiEndpoint}/post/${id}`;
      const { activeOuidCoreConfigs } = store.getState().config;
      if (activeOuidCoreConfigs.data) {
        if (activeOuidCoreConfigs.data.config.entityRules) {
          const entityRules = activeOuidCoreConfigs.data.config.entityRules;
          const enitityRule = entityRules.find(
            ({ entity }) => entity === "post"
          );
          if (enitityRule) {
            const actionsData = enitityRule.publishToApiTopic.actions;
            if (actionsData.indexOf("D") > -1) {
              url = `${apiEndpoint}/post/${id}?publishToApiTopic=true`;
            }
          }
        }
      }
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Post Deleted Successfully");
          dispatch(delete_post_success(response.data));
        })
        .catch((err) => {
          console.log("Delete Post  Failed");
          dispatch(showSnackbar("Delete Post Failed", "error"));
          dispatch(delete_post_errored(err));
        });
    }
    return null;
  };

export const delete_post_requested = () => ({
  type: DELETE_POST_REQUESTED,
});

export const delete_post_success = (payload) => ({
  type: DELETE_POST_SUCCESS,
  payload,
});

export const delete_post_errored = (error) => ({
  type: DELETE_POST_ERRORED,
  error,
});
