import React from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import { timeFormat } from "../../../common/CommonFunctions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { create_update_work } from "../../../../actions/action-work";
import Button from "@material-ui/core/Button";
import { history } from "../../../../reducers";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const store = configureStore();

function LoanReqWorkDetails(props) {
  const { work, usersToshow, closeWorkDash } = props;
  console.log(" work : ", work);

  const [user, setUser] = React.useState();
  const [state, setState] = React.useState(work);
  const [error, setError] = React.useState();

  const renderDetails = (work) => {
    console.log(" work : ", work);

    return (
      <>
        <br />
        {error && <div className="form_error">{error}</div>}
        <div className="form-item-wrapper">
          <TextField
            id="businessName"
            label="Business Name"
            placeholder="Business Name"
            value={work.data.details.loanRequestDetails.businessName || ""}
            variant="outlined"
            onChange={onInputChange}
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="loanType"
            label="Loan Type"
            placeholder="Loan Type"
            value={work.data.details.loanRequestDetails.loanType || ""}
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="natureofBusiness"
            label="Nature of Business"
            placeholder="Example - Retail Shop, Bakery, Wholesale "
            value={work.data.details.loanRequestDetails.natureofBusiness || ""}
            variant="outlined"
          />
        </div>

        <div className="form-item-wrapper">
          <TextField
            id="address"
            label="Business Address"
            placeholder="Business Address"
            value={work.data.details.loanRequestDetails.address || ""}
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="pincode"
            label="Pincode"
            placeholder="Pincode"
            value={work.data.details.loanRequestDetails.pincode || ""}
            variant="outlined"
            inputProps={{
              maxLength: 6,
              minLength: 6,
              pattern: "\\d{6}",
            }}
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="phoneNumber"
            label="Phone Number"
            placeholder="Phone Number"
            value={work.data.details.loanRequestDetails.phoneNumber || ""}
            variant="outlined"
            // type="tel"
            inputProps={{
              maxLength: 12,
              inputMode: "tel",
              // pattern: "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/",
            }}
          />
        </div>

        <div className="form-item-wrapper">
          <TextField
            id="numberOfStaffs"
            label="Number Of Staffs"
            placeholder="Number Of Staffs"
            value={work.data.details.loanRequestDetails.numberOfStaffs || ""}
            variant="outlined"
            type="number"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="websiteAddress"
            label="Website Address"
            placeholder="Website Address"
            value={work.data.details.loanRequestDetails.websiteAddress || ""}
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="primaryOwnerName"
            label="Name Of The Primary Owner"
            placeholder="Name of the owner"
            value={work.data.details.loanRequestDetails.primaryOwnerName || ""}
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="primaryOwnerAddress"
            label="Primary Owner's Address"
            placeholder="Primary Owner's Address"
            value={
              work.data.details.loanRequestDetails.primaryOwnerAddress || ""
            }
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="primaryOwnerPhone"
            label="Primary Owner's Contact Phone"
            placeholder="Primary Owner's Phone"
            value={work.data.details.loanRequestDetails.primaryOwnerPhone || ""}
            variant="outlined"
            inputProps={{
              maxLength: 12,
              inputMode: "tel",
              // pattern: "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/",
            }}
          />
        </div>
        <br />
        <Typography gutterBottom>Project Costs</Typography>
        <br />
        <div className="form-item-wrapper">
          <TextField
            id="landPurchaseCost"
            name="landPurchaseCost"
            value={work.data.details.loanRequestDetails.landPurchaseCost}
            label="Purchase Cost (Rs)"
            placeholder="Purchase Cost (Rs)"
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="buildingPurchaseCost"
            name="buildingPurchaseCost"
            value={work.data.details.loanRequestDetails.buildingPurchaseCost}
            label="Purchase Cost (Rs)"
            placeholder="Purchase Cost (Rs)"
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="machinaryPurchaseCost"
            name="machinaryPurchaseCost"
            value={work.data.details.loanRequestDetails.machinaryPurchaseCost}
            label="Purchase Cost (Rs)"
            placeholder="Purchase Cost (Rs)"
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="computerPurchaseCost"
            name="computerPurchaseCost"
            value={work.data.details.loanRequestDetails.computerPurchaseCost}
            label="Purchase Cost (Rs)"
            placeholder="Purchase Cost (Rs)"
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="otherInitialCost"
            name="otherInitialCost"
            value={work.data.details.loanRequestDetails.otherInitialCost}
            label="Other Initial Costs  (Rs)"
            placeholder="Other Initial Costs(Rs)"
            variant="outlined"
          />
        </div>
        <div className="form-item-wrapper">
          <TextField
            id="workingCapitalCost"
            name="workingCapitalCost"
            value={work.data.details.loanRequestDetails.workingCapitalCost}
            label="Working Capital Needed  (Rs)"
            placeholder="Working Capital Needed (Rs)"
            variant="outlined"
          />
        </div>
        <br />
        <Typography>
          Total Project Costs &nbsp; &nbsp;&nbsp;&nbsp;
          <strong>
            Rs.&nbsp; {work.data.details.loanRequestDetails.totalCost}
          </strong>
        </Typography>
        <br />
        <br />
      </>
    );
  };

  const userChange = (newValue) => {
    console.log("userChange newValue : ", newValue);
    if (newValue) {
      setUser(newValue.data.username);
    } else {
      setUser("");
    }
  };

  function validatePhoneNumber(input_str) {
    console.log("input_str", input_str);
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return re.test(input_str);
  }

  const updateWork = async (e) => {
    e.preventDefault();
    let updatedWork = work;
    updatedWork["username"] = user;
    updatedWork["status"] = state.status;
    //update rest of the fields specific to loanRequestDetails
    updatedWork.data.details.loanRequestDetails.pincode =
      state.data.details.loanRequestDetails.pincode;

    console.log("updatedWork : ", updatedWork);
    const workid = work.identifier;
    const path = window.location.pathname.split("/");
    const ouid = path[1];

    if (validatePhoneNumber(state.businessDetails.phoneNumber)) {
      if (ouid && workid) {
        let updatePayload = { data: updatedWork };
        await store.dispatch(create_update_work(workid, ouid, updatePayload));
        //window.location.reload();
        props.rerender();
      }
    } else {
      setError("Phone number not valid");
    }
  };

  const currentUserData = usersToshow.find(
    ({ data }) => data.username === work.username
  );

  const onInputChange = (event) => {
    if (event.target.id) {
      setState({ ...state, [event.target.id]: event.target.value });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  return (
    <div className="detailsDrawer">
      <Button
        onClick={() => {
          closeWorkDash();
        }}
        variant="contained"
      >
        Close
      </Button>
      <div>{renderDetails(work)}</div>
      <div>{user}</div>
      <div>
        <div>
          {usersToshow.length !== 0 ? (
            <div className="form-item-wrapper">
              <Autocomplete
                id="combo-box-demo"
                defaultValue={currentUserData}
                onChange={(event, newValue) => {
                  userChange(newValue);
                }}
                options={usersToshow}
                getOptionLabel={(option) => option.data.username}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select User"
                    variant="outlined"
                  />
                )}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="form-item-wrapper">
          <InputLabel id="Status-label">Status</InputLabel>
          <Select
            labelId="Status-label"
            id="priority"
            name="status"
            value={state.status || ""}
            onChange={onInputChange}
          >
            <MenuItem value="OPEN">Open</MenuItem>
            <MenuItem value="ASSIGNED">Assigned</MenuItem>
            <MenuItem value="INPROGRESS">InProgress</MenuItem>
            <MenuItem value="CLOSED">Closed</MenuItem>
          </Select>
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            updateWork();
          }}
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  //permission: state.data.permission,
});

const mapDispatchToProps = (dispatch) => ({
  // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanReqWorkDetails);
