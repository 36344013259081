import {
  FETCH_POSTS_BY_CATEGORY_REQUESTED,
  FETCH_POSTS_BY_CATEGORY_SUCCESS,
  FETCH_POSTS_BY_CATEGORY_ERRORED,
  FETCH_CATEGORY_BY_ID_REQUESTED,
  FETCH_CATEGORY_BY_ID_SUCCESS,
  FETCH_CATEGORY_BY_ID_ERRORED,
  DELETE_POST_CATEGORY_REQUESTED,
  DELETE_POST_CATEGORY_SUCCESS,
  DELETE_POST_CATEGORY_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";
import configureStore from "../store/configureStore";

const store = configureStore();

export const fetch_posts_by_category_requested = () => ({
  type: FETCH_POSTS_BY_CATEGORY_REQUESTED,
});

export const fetch_posts_by_category_success = (payload) => ({
  type: FETCH_POSTS_BY_CATEGORY_SUCCESS,
  payload,
});

export const fetch_posts_by_category_errored = (error) => ({
  type: FETCH_POSTS_BY_CATEGORY_ERRORED,
  error,
});
export const fetch_Category_By_Id_requested = () => ({
  type: FETCH_CATEGORY_BY_ID_REQUESTED,
});

export const fetch_Category_By_Id_success = (payload) => ({
  type: FETCH_CATEGORY_BY_ID_SUCCESS,
  payload,
});

export const fetch_Category_By_Id_errored = (error) => ({
  type: FETCH_CATEGORY_BY_ID_ERRORED,
  error,
});

export const fetch_posts_by_category =
  (
    activeOuid,
    activeOuidChain,
    type,
    category,
    selectedtag,
    lastEvaluatedKey,
    lastEvaluatedSortKey,
    limit
  ) =>
  async (dispatch) => {
    dispatch(fetch_posts_by_category_requested());
    const { apiEndpoint, credentials } = store.getState().jwt.api;

    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${apiEndpoint}/post?ouid=${activeOuid}&type=${type}&categoryId=${category}&limit=5`;     

      if (selectedtag !== "" && selectedtag !== undefined) {
        url += `&tags=${selectedtag}&tagStatus=Active`;
      }

      if (lastEvaluatedKey !== "" && lastEvaluatedKey !== undefined) {
        url += `&lastEvaluatedKey=${lastEvaluatedKey}`;
      }
      if (lastEvaluatedSortKey !== "" && lastEvaluatedSortKey !== undefined) {
        url += `&lastEvaluatedSortKey=${lastEvaluatedSortKey}`;
      }

      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };

      try {
        const response = await axios.get(url, axiosHeader);
        console.log("Get All Posts Fetched Successfully", response.data.items);
        const posts = response.data.items; // Extract the data from items
        dispatch(fetch_posts_by_category_success(response.data));
        return response.data;
      } catch (err) {
        console.log("Get All Posts Failed");
        if (err.response) {
          const data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get All Posts Failed", "error"));
        }
        dispatch(fetch_posts_by_category_errored(err));
      }
    } else {
      dispatch(showSnackbar("API endpoint or credentials missing", "error"));
      dispatch(
        fetch_posts_by_category_errored(
          new Error("API endpoint or credentials missing")
        )
      );
    }   
  };
export const fetch_Category_By_Id =
  (ouid, activeOuidChain, id) => async (dispatch) => {
    // dispatch(fetch_Category_By_Id());
    console.log(`categoryid`, ouid, activeOuidChain, id);
    const { apiEndpoint, credentials } = store.getState().jwt.api;

    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/postcategory/${id}`;

      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": ouid,
          "x-ouidchain": activeOuidChain,
        },
      };

      try {
        const response = await axios.get(url, axiosHeader);
        console.log("Get Category By Id Successfully", response.data.items);
        const posts = response.data.items; // Extract the data from items
        dispatch(fetch_Category_By_Id_success(response.data));
      } catch (err) {
        console.log("Get Category By Id Failed");
        if (err.response) {
          const data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get Category By Id Failed", "error"));
        }
        dispatch(fetch_Category_By_Id_errored(err));
      }
    } else {
      dispatch(showSnackbar("API endpoint or credentials missing", "error"));
      dispatch(
        fetch_Category_By_Id_errored(
          new Error("API endpoint or credentials missing")
        )
      );
    }
  };
export const delete_post_category_requested = () => ({
  type: DELETE_POST_CATEGORY_REQUESTED,
});

export const delete_post_category_success = () => ({
  type: DELETE_POST_CATEGORY_SUCCESS,
});

export const delete_post_category_errored = (error) => ({
  type: DELETE_POST_CATEGORY_ERRORED,
  error,
});

export const delete_post_category =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(delete_post_category_requested());
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${apiEndpoint}/postcategory/${id}?ouid=${activeOuid}`;
      // const { activeOuidCoreConfigs } = store.getState().config;
      // const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      // return axios[method](url, axiosHeader)
      return axios
        .delete(url, { headers: axiosHeader })
        .then((response) => {
          console.log("Post Catagory Deleted Successfully");
          //dispatch(delete_post_category_success(response.data));
          dispatch(delete_post_category_success());
          return response.data;
        })
        .catch((err) => {
          console.log("Delete Post Catagory  Failed");
          dispatch(showSnackbar("Delete post catagory Failed", "error"));
          // dispatch(delete_post_category_errored(err));
          dispatch(delete_post_category_errored(err));
        });
    }
    return null;
  };
