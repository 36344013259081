import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import configureStore from "../../../store/configureStore";
import { showSnackbar, hideSnackbar } from "../../../actions/action-snackbar";
import { history } from "../../../reducers";
import { FormControlLabel, Paper, Radio } from "@material-ui/core";
import axios from "axios";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";

const store = configureStore();

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

const indianStates = [
'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat',
'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh',
'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand',
'West Bengal'
];
function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 750,
    margin: "0 auto",
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
}));

function getSteps() {
  return ["Step 1", "Step 2", "Step 3"];
}

export default function RegisterFormIncubator(props) {
  const { userDetails } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [state, setState] = React.useState({
    businessDetails: { type: "inc" },
    // businessDetails: { type: "inc", branches: [] },

    // branches: [],
  });
  console.log("bussiness", state.businessDetails);

  const [error, setError] = React.useState();

  function validatePhoneNumber(input_str) {
    console.log("input_str", input_str);
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return re.test(input_str);
  }

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validatePhoneNumber(state.businessDetails.phoneNumber)) {
      createWorkOrder(userDetails, state.businessDetails);
    } else {
      setError("Phone number not valid");
    }
  };

  const createWorkOrder = async (userDetails, businessDetails) => {
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    const method = "post";
    const url = `${apiEndpoint}/workorder`;
    const jwt = `${credentials.idToken.jwtToken}`;
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": "ibc",
      },
    };
    let userData = userDetails.data;
    var orgType = "inc";
    if (userDetails.data.usertype === "incubator") {
      orgType = "inc";
      businessDetails.type = "inc"; //todo remove the duplication.
    }
    businessDetails.ouid = businessDetails.name.replace(/[^A-Z0-9]+/ig, "-").toLowerCase();

    /**
         * 
         *  {
             "type": "BusinessRegistrationWorkOrder",
             "requestId": "{clientSideIdIfNeededForTracking}",
             "description": "Business Registration Order",
             "deadline": "2022-01-02T18:11:43.000Z",
             "details": {
                 "orgType": "biz",
                 "registrationDetails": {
                     "name": "test",
                     "businessType": "Education",
                     "companyID": "12345",
                     "GSTNumber": "123456",
                     "address": "mattannur",
                     "phoneNumber": "1234567890",
                     "numberOfStaffs": "2"
                 },
                 "userDetails": {
                     "username": "vyshak",
                     "name": "Vyshak",
                     "email": "vyshak@cloudechosystems.com",
                     "cognitoStatus": "CONFIRMED",
                     "createdBy": "Cognito Login",
                     "subscriptionStatus": "subscribed",
                     "createdDate": 1640602098014,
                     "lastModifiedDate": 1641450050104,
                     "paymentDetails": {
                         "razorpay_payment_id": "pay_IgM5iltfnJsdwN",
                         "razorpay_order_id": "order_IgM5bOddSfz0Bn",
                         "razorpay_signature": "37a8a98928711b0633caf6da92ceb5c6b16e5284eb8531cd5690b3b1cb5edfdf"
                     },
                     "bizRegistrationStatus": "inprogress"
                 }
             }
         }
         * 
         * 
         * 
         */
    const woDetails = {
      orgType: orgType,
      registrationDetails: businessDetails,
      userDetails: userData,
    };
    const payload = {
      type: "BusinessRegistrationWorkorder",
      description:
        "Business Registration WorkOrder to create organisation for the business and add org users.",
      requestId: "",
      deadline: "",
      details: woDetails,
    };

    console.log("payload:::::::", JSON.stringify(payload));
    return axios[method](url, payload, axiosHeader)
      .then(async (response) => {
        console.log(
          "Create WorkOrder response : ",
          JSON.stringify(response.data)
        );
        await store.dispatch(
          showSnackbar("Business Registration Requested", "success")
        );
        history.push(`/system/registration-completed`);
      })
      .catch((err) => {
        console.log(
          "Business Registration Request failed, Please contact support : ",
          err
        );
      });
  };

  const onInputChange = (event) => {
    const newBusinessDetails = state.businessDetails;
    if (event.target.id) {
      newBusinessDetails[event.target.id] = event.target.value;
      console.log("inputValues", event.target.value);
    } else {
      newBusinessDetails[event.target.name] = event.target.value;
    }
    setState({
      businessDetails: newBusinessDetails,
    });
  };

  const [branch, setBranch] = useState([]);

  console.log("branch", branch);

  const addInput = () => {
    setBranch([...branch, { text: "" }]);
  };

  const handleBranchChange = (e, i) => {
    e.preventDefault();
    const index = e.target.id;
    console.log("event", index);
    console.log("eventvalue", e.target.value);

    const newBranch = [...branch];
    newBranch[index].text = e.target.value;
    setBranch(() => {
      return newBranch;
    });
  };

  return (
    <div className={classes.root}>
      <div className="container--narrow">
        <Paper className="formContainer">
          <h1>Complete your incubator registration to continue.</h1>
          {error && <div className="form_error">{error}</div>}
          <div className="form-item-wrapper">
            <TextField
              className={classes.input}
              id="name"
              label="Company Name"
              placeholder="Company Name"
              value={state.businessDetails.name || ""}
              onChange={onInputChange}
              variant="outlined"
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className={classes.input}
              id="legalName"
              label="Legal  Name"
              placeholder="Legal Name"
              value={state.businessDetails.legalName || ""}
              onChange={onInputChange}
              variant="outlined"
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className={classes.input}
              id="GSTNumber"
              label="GST Number"
              placeholder="GST Number"
              value={state.businessDetails.GSTNumber || ""}
              onChange={onInputChange}
              variant="outlined"
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className={classes.input}
              id="address"
              label="Address"
              placeholder="Address"
              value={state.businessDetails.address || ""}
              onChange={onInputChange}
              variant="outlined"
            />
          </div>
          <div className="form-item-wrapper">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="state-label">State</InputLabel>
              <Select
                labelId="state-label"
                id="state"
                name="state"
                value={state.businessDetails.state || ""}
                onChange={onInputChange}
                label="State"
              >
                {indianStates.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="form-item-wrapper">
            <TextField
              className={classes.input}
              id="officialMail"
              label="Official mail "
              placeholder="Official mail "
              value={state.businessDetails.officialMail || ""}
              onChange={onInputChange}
              variant="outlined"
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className={classes.input}
              id="landphoneNumber"
              label="Landphone number"
              placeholder="Landphone number"
              value={state.businessDetails.landphoneNumber || ""}
              onChange={onInputChange}
              variant="outlined"
              // type="tel"
              inputProps={{
                maxLength: 12,
                inputMode: "tel",
                // pattern: "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/",
              }}
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className={classes.input}
              id="phoneNumber"
              label="Mobile number"
              placeholder="Mobile number"
              value={state.businessDetails.phoneNumber || ""}
              onChange={onInputChange}
              variant="outlined"
              // type="tel"
              inputProps={{
                maxLength: 12,
                inputMode: "tel",
                // pattern: "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/",
              }}
            />
          </div>
        
          <div className="form-item-wrapper">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                id="agency"
                value={state.businessDetails.agency || ""}
                onChange={onInputChange}
              >
                <FormControlLabel
                  value="Government agency"
                  control={
                    <Radio
                      color="primary"
                      name="agency"
                      checked={state.businessDetails === "Government agency"}
                    />
                  }
                  label="Government agency"
                  labelPlacement="start"

                  // onChange={onInputChange}
                />
                <FormControlLabel
                  value="Private"
                  checked={state.businessDetails === "Private"}
                  control={<Radio color="primary" name="agency" />}
                  label="Private"
                  labelPlacement="start"
                  // onChange={onInputChange}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <button className="add_branch_btn" onClick={addInput}>
              Add Branches
            </button>

            {branch.map((item, i) => {
              return (
                <div key={i} className="form-item-wrapper">
                  <TextField
                    className={classes.input}
                    id={i}
                    name="branches"
                    label="branches"
                    placeholder="Branches"
                    value={item.text}
                    type="text"
                    onChange={(e) => handleBranchChange(e, i)}
                    variant="outlined"
                  />
                </div>
              );
            })}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.button}
          >
            Submit
          </Button>
        </Paper>
        {/* <Paper className="formContainer">
          <h1>Complete your incubator registration to continue.</h1>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep)}
                </Typography>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.button}
                    >
                      Finish
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </Paper> */}
      </div>
      {/* {JSON.stringify(state.businessDetails)}
            {JSON.stringify(userDetails)} */}
    </div>
  );
}
