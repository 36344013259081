import React from "react";
import configureStore from "../../../store/configureStore";
import ActionCard from "../UserPage/ActionCard";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { history } from "../../../reducers";
import Banner from "../../assets/images/ibcImages/homebanner.png";
import Business from "../../assets/images/ibcImages/buildings.png";

import Businesses from "../../assets/images/ibcImages/business(cp).png";
import Meetings from "../../assets/images/ibcImages/round-table(cp).png";
import inv from "../../assets/images/ibcImages/investment(cp).png";
import loan from "../../assets/images/ibcImages/money-bag(cp).png";

const store = configureStore();

export default function CpRegisteredUser(props) {
  const { userDetails } = props;
  const { activeOuid, activeOuidChain } = store.getState().permission;

  const actionCards = [
    { heading: "Get Business Details", color: "#1693dc" },
    { heading: "Govt. Schemes", color: "#251c71" },
  ];
  return (
    <div className="">
      <div className="businessServicesSection">
        <div className="businessServicesWrapper">
          <div
            href="#"
            onClick={() => {
              history.push(`/${activeOuid}/orgunits`);
            }}
            className="HelpCategoryItem"
          >
            <div className="HelpCategoryItemIcon">
              <img src={Businesses} alt="" />
            </div>
            <div>
              <p className="HelpCategoryItemTitle">Businesses</p>
            </div>
          </div>
          <div
            href="#"
            onClick={() => {
              history.push(`/${activeOuid}/appointment-dash`);
            }}
            className="HelpCategoryItem"
          >
            <div className="HelpCategoryItemIcon">
              <img src={Meetings} alt="" />
            </div>
            <div>
              <p className="HelpCategoryItemTitle">Meetings</p>
            </div>
          </div>
          <div
            href="#"
            onClick={() => {
              history.push(`/${activeOuid}/investreq-wq-dash`);
            }}
            className="HelpCategoryItem"
          >
            <div className="HelpCategoryItemIcon">
              <img src={inv} alt="" />
            </div>
            <div>
              <p className="HelpCategoryItemTitle">
                Manage investment Requests
              </p>
            </div>
          </div>
          <div
            href="#"
            onClick={() => {
              history.push(`/${activeOuid}/loanreq-wq-dash`);
            }}
            className="HelpCategoryItem"
          >
            <div className="HelpCategoryItemIcon">
              <img src={loan} alt="" />
            </div>
            <div>
              <p className="HelpCategoryItemTitle">Manage Loan Requests</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
