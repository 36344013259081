import {
  CREATE_BOOKING_REQUESTED,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_ERRORED,
  GET_ALL_BOOKINGS_BY_REQUESTED,
  GET_ALL_BOOKINGS_BY_SUCCESS,
  GET_ALL_BOOKINGS_BY_ERRORED,
  GET_ALL_BOOKINGS_FOR_REQUESTED,
  GET_ALL_BOOKINGS_FOR_SUCCESS,
  GET_ALL_BOOKINGS_FOR_ERRORED,
  GET_BOOKING_REQUESTED,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_ERRORED,
} from '../constant/action-types';

const initialState = {
  showGlobalLoader: false,
  bookings: [],
  bookingsFor: [],
  error: {}
};

export function booking(state = initialState, { type, payload }) {
  switch (type) {

    case CREATE_BOOKING_REQUESTED:
      return {
        ...state,
      };

    case CREATE_BOOKING_SUCCESS:
      return {
        ...state,
      };

    case CREATE_BOOKING_ERRORED:
      return {
        ...state,
        error: payload
      };

    case GET_ALL_BOOKINGS_BY_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        bookings: []
      };

    case GET_ALL_BOOKINGS_BY_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        bookings: payload.items
      };

    case GET_ALL_BOOKINGS_BY_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload
      };

    case GET_ALL_BOOKINGS_FOR_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        bookingsFor: []
      };

    case GET_ALL_BOOKINGS_FOR_SUCCESS:
      console.log("GET_ALL_BOOKINGS_FOR_SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
        bookingsFor: payload.items
      };

    case GET_ALL_BOOKINGS_FOR_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload
      };

    case GET_BOOKING_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        bookings: []
      };

    case GET_BOOKING_SUCCESS:
      console.log("GET__BOOKING__SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
        booking: payload.items
      };

    case GET_BOOKING_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload
      };


    default:
      return state;
  }
}
