import React from "react";
import configureStore from "../../../store/configureStore";
import ActionCard from "../UserPage/ActionCard";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { history } from "../../../reducers";
import Banner from "../../assets/images/ibcImages/homebanner.png";
import Business from "../../assets/images/ibcImages/buildings.png";

// import Businesses from '../../assets/images/ibcImages/money-bag.png';
// import Meetings from '../../assets/images/ibcImages/work.png';
import Businesses from "../../assets/images/ibcImages/business(cp).png";
import Meetings from "../../assets/images/ibcImages/round-table(cp).png";

const store = configureStore();

export default function InvRegisteredUser(props) {
  const { userDetails } = props;
  const { activeOuid, activeOuidChain } = store.getState().permission;

  const actionCards = [
    { heading: "Get Business Details", color: "#1693dc" },
    { heading: "Govt. Schemes", color: "#251c71" },
  ];
  return (
    <div>
      <div className="businessServicesSection">
        <div className="businessServicesWrapper">
          <div
            href="#"
            onClick={() => {
              history.push(`/${activeOuid}/investreq-wq-dash-inv`);
            }}
            className="HelpCategoryItem"
          >
            <div className="InvUserHomIcon">
              <img src={Businesses} alt="" />
            </div>
            <div>
              <p className="HelpCategoryItemTitle">
                Businesses investment requests
              </p>
              <p className="HelpCategoryItemText">
                Start Preparing to get investment. We will guide you through the
                details and help you prepare documents.
              </p>
            </div>
          </div>
          <div
            href="#"
            onClick={() => {
              history.push(`/${activeOuid}/appointment-dash`);
            }}
            className="HelpCategoryItem"
          >
            <div className="InvUserHomIcon">
              <img src={Meetings} alt="" />
            </div>
            <div>
              <p className="HelpCategoryItemTitle">Meetings</p>
              <p className="HelpCategoryItemText">
                Fix a meeting with one of our officials for all your concerns
                regarding the process we will be avilable throughout your
                journey
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
