import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PaymentPopup from './PaymentPopup'
import PaymentPage from './PaymentPage';
import Packageimage from '../../assets/images/ibcImages/service-bg.png';

const useStyles = makeStyles({
  root: {
    maxWidth: 140,
    minWidth: 140,
    minHeight: 120,
    position: 'relative',
  },
  media: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: '100%',
  },
  CardActionArea: {
    height: '100%',
    color: '#fff',
  },
  CardHeading: {
    maxWidth: '50%',
  }
});

export default function ActionCard(props) {
  const classes = useStyles();
  const [payment, setPayment] = React.useState(false);
  const { packageDetails, userDetails } = props;
  const { details } = props;

  return (
    <>
      <Card className={classes.root} style={{ backgroundColor: details.color , width: '100%' }} onClick={() => setPayment(!payment)}>
      <CardMedia
          className={classes.media}
          image={Packageimage}
        />
      <CardActionArea className={classes.CardActionArea}>
        <CardContent>
          <Typography variant="p" component="h2" className={classes.CardHeading}>
            {details.heading}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    <PaymentPopup
        defaultState={payment}
        componentToPassDown={
          <PaymentPage
            userDetails={userDetails}
            packageDetails={packageDetails}
            handleClose={() => { setPayment(!payment) }}
          />}
        handleClose={() => { setPayment(!payment) }}
      />
    </>
  );
}
