import React, { Component } from 'react';
import { connect } from 'react-redux';
import { create_post } from "../../../actions/action-post";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from '../../../reducers';
import SidebarWrapper from '../../layout/SidebarWrapper';
import configureStore from '../../../store/configureStore';
import { Auth } from "aws-amplify";
import FormErrors from "../../FormErrors";
import Input from '@material-ui/core/Input';
import FormButton from '../../common/FormButton';
import Validate from "../../utility/FormValidation";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Loader from '../../common/Loader';
import { makeStyles } from '@material-ui/core/styles';

const store = configureStore();


class CreatePost extends Component {

    constructor() {
        super();
        let r = (Math.random() + 1).toString(36).substring(7);
        this.state = {
            postDetails: {
                "title": "Title for the post " + r,
                "summary": "Some summary for the title",
                "type": "govtscheme",
                "category": "cat1",
                "featuredImage": "https://www.gstatic.com/webp/gallery/1.jpg",
                "content": "[ { type: 'paragraph', children: [{ text: 'An opening paragraph...' }], }, { type: 'quote', children: [{ text: 'A wise quote.' }], }, { type: 'paragraph', children: [{ text: 'A closing paragraph!' }], }, ]",
            },

            errors: {}
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
    }

    clearErrorState = () => {
        this.setState({
            errors: {}
        });
    }


    handleSubmit = async event => {
        this.clearErrorState();
        const error = Validate(event, this.state);
        const payload = this.state.postDetails;
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        if (error) {
            this.setState({
                errors: { ...this.state.errors, ...error }
            });
        } else {
            const { create_post } = this.props;
            await create_post(payload);
            this.props.history.push(`/${ouid}/post-dash`);
        }
    }

    onInputChange = event => {
        const newPostDetails = this.state.postDetails;
        newPostDetails[event.target.id] = event.target.value;
        this.setState({
            postDetails: newPostDetails
        });
    }



    render() {
        const { permissions } = store.getState().permission;
        const { showGlobalLoader } = store.getState().permission;
        return (
            <div className="page">
                <SidebarWrapper
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    <div className="container">
                        <div className="formContainer">
                            <FormErrors formerrors={this.state.errors} />
                            <form>
                                <h1 className="formName">Post Details</h1>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="type"
                                        placeholder="Type"
                                        value={this.state.postDetails.type}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="category"
                                        placeholder="Category"
                                        value={this.state.postDetails.category}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="title"
                                        placeholder="Title"
                                        value={this.state.postDetails.title}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="summary"
                                        placeholder="Summary"
                                        value={this.state.postDetails.summary}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="featuredImage"
                                        placeholder="FeaturedImage"
                                        value={this.state.postDetails.featuredImage}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="content"
                                        placeholder="Content"
                                        value={this.state.postDetails.content}
                                        onChange={this.onInputChange}
                                    />
                                </div>

                            </form>
                            <div className="field">
                                <p className="control">
                                    <FormButton
                                        onClick={() => this.handleSubmit()}
                                        text="Add"
                                    />
                                </p>
                            </div>
                            <CommonSnackBar />
                        </div>
                    </div>
                </section>
            </div>


        )
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
});


const mapDispatchToProps = (dispatch) => ({
    create_post: (payload) => dispatch(create_post(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost);
