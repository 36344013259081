import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Auth } from 'aws-amplify';
import { history } from '../../reducers';
import configureStore from '../../store/configureStore';
import { resetState } from '../../actions';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import AdjustIcon from '@material-ui/icons/Adjust';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import AssessmentIcon from '@material-ui/icons/Assessment';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SettingsIcon from '@material-ui/icons/Settings';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import GroupIcon from '@material-ui/icons/Group';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { RedirectFunc } from "../common/CommonFunctions";
import { setActiveOuid, setActiveOuidChain } from "../../actions/action-permissions";
import { setActiveOiudConfigs } from "../../actions/action-config";
// Images
import AccountCircle from '@material-ui/icons/AccountCircle';

// Material Ui
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery'; // Import useMediaQuery



// const apps = require('../pages/Apps');


const store = configureStore();

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  select: {
    '& .MuiSelect-icon': {
      color: '#FFF',
    },
    '&.MuiInput-underline:before': {
      borderBottom: '2px solid #FFF',
    },
    '&:hover:not(.Mui-disabled).MuiInput-underline:before': {
      borderBottom: '2px solid #FFF',
    },
    '& .MuiSelect-select': {
      color:"#FFF"
    },
  },
}));



function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:640px)');
  const [open, setOpen] = React.useState(false);


  const [anchorProfile, setAnchorProfile] = React.useState(null);
  const openProfile = Boolean(anchorProfile);
  const isAuthenticated = props.auth.isAuthenticated;
  const { activeOuid, userRoles, policies, activeOuidType } = store.getState().permission;
  const { activeOuidCoreConfigs } = store.getState().config;
  const { session } = store.getState().jwt.api.userSession;
  useEffect(() => {
    setActiveOuidValue(store.getState().permission.activeOuid)
    if (isMobile) {
      setOpen(false); // Close drawer on mobile view by default
    }
  }, [JSON.stringify(props),isMobile]);
  const [activeOuidValue, setActiveOuidValue] = React.useState(activeOuid);

  const [Dropdownopen, setDropdownOpen] = React.useState(true);
  const handleDropdownClick = () => {
    setDropdownOpen(!Dropdownopen);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleProfileMenu = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorProfile(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const handleLogOut = async event => {
    event.preventDefault();
    try {
      Auth.signOut();
      props.auth.setAuthStatus(false);
      props.auth.setUser(null);
      store.dispatch(resetState());
      history.push("/");
    } catch (error) {
      console.log(error.message);
    }
    localStorage.removeItem("formState");
    localStorage.removeItem("activeStep");
  };

  const handleDropDown = async (event) => {
    setActiveOuidValue(event.target.value);
    const path = window.location.pathname.split('/');
    // await RedirectFunc(`/${event.target.value}/${path[path.length - 1]}`)
    await store.dispatch(setActiveOuid(event.target.value));
    await store.dispatch(setActiveOuidChain(event.target.value));
    await store.dispatch(setActiveOiudConfigs(event.target.value));
    const { activeOuidCoreConfigs } = store.getState().config;
    if (activeOuidCoreConfigs.data) {
      const deafaultUrl = activeOuidCoreConfigs.data.config.home;
      RedirectFunc(`/${event.target.value}/${deafaultUrl}`);
      return;
    } else {
      RedirectFunc(`/${event.target.value}`)
      return;
    }

  };

  const renderMenuTtem = () => {
    const activeOuidExist = userRoles.find(({ ouid }) => ouid === activeOuid);
    let renderOuids = userRoles;
    if (activeOuidExist) {
      renderOuids = userRoles;
    } else {
      renderOuids.push({ "ouid": activeOuid, "roles": [] })
    }
    return renderOuids.map((item, index) => (
      <MenuItem key={item.ouid} value={item.ouid}>{item.ouid}</MenuItem>
    ));
  };


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        color='secondary'
      >

        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}

          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            IBC
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop} >
            {isAuthenticated && (
              <div >
                <FormControl style={{ minWidth: 120 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={activeOuidValue}
                    onChange={handleDropDown}
                    className={classes.select}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  >
                    {(userRoles.length !== 0) ? renderMenuTtem()
                      :
                      <MenuItem value="">No item Found</MenuItem>
                    }
                  </Select>
                </FormControl>
              </div>
            )}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleProfileMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            {isAuthenticated && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorProfile}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={openProfile}
                onClose={handleProfileClose}
              >
                <MenuItem onClick={() => { history.push(`/${activeOuid}/my-profile`); }}>My Profile</MenuItem>
                <MenuItem onClick={handleLogOut}>Logout</MenuItem>
              </Menu>
            )}
            {!isAuthenticated && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorProfile}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openProfile}
                onClose={handleProfileClose}
              >
                <MenuItem onClick={() => { history.push("/login"); }}>Login</MenuItem>
              </Menu>
            )}
            {isAuthenticated && (
              <div>
                {session.username}
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <Drawer
          variant={isMobile ? "persistent" : "permanent"} 
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <div className={classes.toolbar} style={{display:"flex", alignItems:"center", gap:"26px", padding:"14px 14px 14px 70px"}}>
            {/* <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton> */}
            <h3 style={{marginBottom:"unset"}}>Dashboard</h3>
          </div>
          <Divider />
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.MenuList}
          >
            <ListItem button onClick={() => { history.push(`/${activeOuid}/dashboard`); }}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button onClick={() => { history.push(`/${activeOuid}/orgunits`); }}>
              <ListItemIcon>
                <AccountTreeIcon />
              </ListItemIcon>
              <ListItemText primary="Child Organisations" />
            </ListItem>
            <ListItem button onClick={() => { history.push(`/${activeOuid}/orgusers`); }}>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
            <ListItem button onClick={() => { history.push(`/${activeOuid}/users`); }}>
              <ListItemIcon>
                <PeopleOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="Authenticated Users" />
            </ListItem>
            <ListItem button onClick={() => { history.push(`/${activeOuid}/config-dash`); }}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={() => { history.push(`/${activeOuid}/document-dash`); }}>
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText primary="Documents" />
            </ListItem>
            <ListItem button onClick={() => { history.push(`/${activeOuid}/org-workqueue-dash`); }}>
              <ListItemIcon>
                <AssignmentTurnedInIcon />
              </ListItemIcon>
              <ListItemText primary="Tasks" />
            </ListItem>
            <ListItem button onClick={() => { history.push(`/${activeOuid}/booking-dash`); }}>
              <ListItemIcon>
                <MeetingRoomIcon />
              </ListItemIcon>
              <ListItemText primary="Meetings" />
            </ListItem>
          </List>
          <Divider />
        </Drawer>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  jwt: state.jwt,
  config: state.config,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniDrawer);
