import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../../../reducers";
import BookingCard from "../../common/BookingCard";
import configureStore from "../../../store/configureStore";
import SidebarWrapper from "../../layout/SidebarWrapper";
import SearchField from "../../common/SearchField";
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import { get_all_bookings_for } from "../../../actions/action-booking";
import { DataGrid } from "@material-ui/data-grid";
import { timeFormat } from "../../common/CommonFunctions";
import CommonPopUp from "../../common/CommonPopUp";
import Button from "@material-ui/core/Button";
import Loader from "../../common/Loader";
import DashboardFooter from "../../layout/DashboardFooter";

const store = configureStore();

class BookingDash extends Component {
  constructor() {
    super();
    this.state = {
      selectionModel: [],
      bookingListForToShow: [],
    };
  }

  componentDidMount() {
    this.fetchDetails();
  }

  async fetchDetails() {
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);
    const { get_all_bookings_for } = this.props;
    await get_all_bookings_for(
      activeOuid,
      activeOuidChain,
      "2022-01-0",
      "2222-12-30"
    );
    console.log(
      "get_all_bookings_for store.getState().booking.bookingsFor ",
      store.getState().booking.bookingsFor
    );
    this.setState({
      bookingListForToShow: store.getState().booking.bookingsFor,
    });
  }

  componentWillUnmount() {
    // console.log("componentWillUnmount");
    // this.unregisterHistoryListener()
    // store.dispatch(hideSnackbar());
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  setSelectionModel(selection) {
    this.setState({
      selectionModel: selection,
    });
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().permission;
    const { activeOuid, activeOuidChain } = store.getState().permission;

    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          {showGlobalLoader ? (
            <Loader />
          ) : (
            <div className="container">
              <div className="listHeader">
                <h1 className="pageHeading">MEETINGS</h1>
              </div>
              <div>
                <div className="List">
                  {this.state.bookingListForToShow.length !== 0
                    ? this.renderBookings(this.state.bookingListForToShow)
                    : "No Meetings Found"}
                </div>
              </div>
            </div>
          )}
          <DashboardFooter />
          <CommonSnackBar />
        </section>
      </div>
    );
  }

  renderBookings(BookingList) {
    console.log("BookingList : ", JSON.stringify(BookingList));
    return BookingList.map((item) => (
      <div>
        <BookingCard key={item.data.name} Booking={item} />
      </div>
    ));
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  bookings: state.booking,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_bookings_for: (activeOuid, activeOuidChain, fromDate, toDate) =>
    dispatch(
      get_all_bookings_for(activeOuid, activeOuidChain, fromDate, toDate)
    ),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingDash);
