import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import HrmDash from "./HrmDash";

const store = configureStore();

class HrmApp extends Component {
  render() {
    const { permissions } = store.getState().permission;
    return (
      <div className="page">
        <section className="section">
          <div className="container">
            <div>
              <Switch>
                <Route
                  exact
                  path="/:id/apps/hrmapp"
                  render={(props) => <HrmDash />}
                />
              </Switch>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HrmApp);
