import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import SidebarWrapper from "../../../layout/SidebarWrapper";
import { history } from "../../../../reducers";
import { get_inv_requests } from "../../../../actions/action-work";
import CommonSnackBar from "../../../common/CommonSnackBar";
import { setActiveOuid } from "../../../../actions/action-permissions";
import InvReqWorkQueue from "./InvReqWorkQueue";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Banner from "../../../assets/images/ibcImages/investmentrequested.png";
import HeaderInner from "../../../layout/HeaderInner";

const store = configureStore();

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class InvReqWqDashboardForInv extends Component {
  constructor() {
    super();
    this.state = {
      currentUser: "",
      teamsToshow: [],
      activeTeamId: "",
      worksToshow: [],
      orgWorkStatus: "OPEN",
      usersToshow: [],
      currentWork: {},
      workDash: false,
      tabValue: 0,
      detailsViewOpen: false,
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.unregisterHistoryListener = history.listen((location, action) => {
      const path = window.location.pathname.split("/");
      if (path[path.length - 1] === "board") {
        this.fetchDetails();
      }
    });
    this.fetchDetails();
  }

  componentWillUnmount() {
    console.log("componentWillUnmount");
    this.unregisterHistoryListener();
  }

  rerender = () => {
    console.log("rerender called *********");
    this.fetchDetails();
  };

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    await store.dispatch(setActiveOuid(ouid));
    const { session } = store.getState().jwt.api.userSession;
    const { activeOuidChain } = store.getState().permission;
    const parentOuid = activeOuidChain[activeOuidChain.length - 2];
    this.setState(
      {
        currentUser: session.username,
      },
      async () => {
        const { get_inv_requests } = this.props;
        await get_inv_requests(
          ouid,
          parentOuid,
          "INVESTMENTREQ",
          this.state.orgWorkStatus
        );
        this.setState({
          worksToshow: store.getState().work.works,
        });
      }
    );
  }

  openWorkDash(data) {
    this.setState({
      workDash: true,
      currentWork: data.row,
    });
  }

  renderWork(works, activeOuid, teamId) {
    let newRows = [];
    works.map((work, index) => {
      let newRow = work.data;
      newRow["id"] = work.data.identifier;
      newRows.push(newRow);
    });
    // console.log("newRows   :::: ", newRows);
    return newRows;
  }

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  handleDetailsClick() {
    this.setState((prevState) => ({
      detailsViewOpen: !prevState.detailsViewOpen,
    }));
  }

  render() {
    const { activeOuid, activeOuidChain, permissions } =
      store.getState().permission;

    return (
      <div className="">
        {/* <SidebarWrapper auth={this.props.auth} permissions={permissions} /> */}
        <HeaderInner />
        <div className="page">
          <div className="container">
            <div className="PageBannerWrapper">
              <div>
                <Paper className="PageBanner BannerWithGradient">
                  <div>
                    <h1>Business requested for investments</h1>
                    <p>
                      You can see the business which have requested for
                      investments. You can schedule a meeting with the channel
                      partners to make an investment in the company.
                    </p>
                  </div>
                  <div>
                    <img src={Banner} alt="" />
                  </div>
                </Paper>
              </div>
              {/* <div>
              <Paper className="HomeCompanyProfileCard" onClick={() => { history.push(`/${activeOuid}/company-profile`); }}>
                <div>
                  <div className='CompanyName'>Business Name</div>
                  <div className='OwnerName'>Owner Name</div>
                  <div className='AddressTitle'>Address</div>
                  <div className='Address'>1st floor, Trags complex</div>
                </div>
                <div>
                  <img src={Business} alt=''/>
                </div> 
              </Paper>
            </div> */}
            </div>
            <InvReqWorkQueue
              workContext={"org"}
              rows={this.renderWork(this.state.worksToshow)}
              usersToshow={this.state.usersToshow}
              defaultStatus={this.state.orgWorkStatus}
              handleDetailsClick={(e) => {
                this.handleDetailsClick(e);
              }}
              rerender={this.rerender}
            />
            <CommonSnackBar />
          </div>

          {/* <div className="container"></div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  work: state.work,
  teamUserProfile: state.teamUserProfile,
});

const mapDispatchToProps = (dispatch) => ({
  get_inv_requests: (ouid, type, status) =>
    dispatch(get_inv_requests(ouid, type, status)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvReqWqDashboardForInv);
