import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import configureStore from "../../../store/configureStore";
import { showSnackbar, hideSnackbar } from "../../../actions/action-snackbar";
import { history } from "../../../reducers";

import {
  create_workorder_success,
  create_workorder_errored,
  create_workorder_requested,
} from "../../../actions/action-workorder";

import axios from "axios";
import {
  document_upload_requested,
  create_document,
  document_upload_success,
  document_upload_errored,
} from "../../../actions/action-document";
import FormDocumentUploader, {
  delete_file,
} from "../../common/FormDocumentUploader";
import CloseIcon from "@material-ui/icons/Close";
import fileIcon from "../../../components/assets/images/ibcImages/file.png";
import { useDispatch } from "react-redux";

const store = configureStore();

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 750,
    margin: "0 auto",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  buttonWrapper: {
    marginTop: 30,
  },
}));

function getSteps() {
  return ["Step 1", "Step 2", "Step 3", "Step 4"];
}

export default function CreateInvestRequest(props) {
  const { userDetails, activeOuid, activeOuidChain } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = React.useState(0);
  const [attachment, setFile] = React.useState();
  const [uploadFileName, setUploadFileName] = useState("");
  const [isDprUploaded, setIsDprUploaded] = useState(false);
  const [isPitchVideoUploaded, setIsPitchVideoUploaded] = useState(false);

  const [state, setState] = React.useState({
    investmentRequestDetails: {
      businessName: "",
      natureofBusiness: "",
      industry: "",
      landPurchaseCost: 0,
      buildingPurchaseCost: 0,
      workingCapitalCost: 0,
      otherInitialCost: 0,
      computerPurchaseCost: 0,
      machinaryPurchaseCost: 0,
      dpr: "",
      pitchVideo: "",

      totalCost: 0,

      land: false,
      building: false,
      machinary: false,
      computers: false,
      capital: false,
      other: false,
    },
    reload: false,
  });

  const handleUploadChange = async (filelink, data) => {
    console.log("handleUploadChange", filelink);
    setState({
      ...state,
      investmentRequestDetails: {
        ...state.investmentRequestDetails,
        [data]: filelink,
      },
    });
    // setState({ ...state, dprFile: filelink });
  };
  console.log("state", state);

  const [error, setError] = React.useState();

  function validatePhoneNumber(input_str) {
    console.log("input_str", input_str);
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return re.test(input_str);
  }

  const steps = getSteps();

  const handleStepOne = (e) => {
    e.preventDefault();
    if (validatePhoneNumber(state.investmentRequestDetails.phoneNumber)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setError("");
    } else {
      setError("Phone number not valid");
    }
  };

  const handleStepTwo = (e) => {
    e.preventDefault();

    if (validatePhoneNumber(state.investmentRequestDetails.primaryOwnerPhone)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setError("");
    } else {
      setError("Phone number not valid");
    }
  };

  const handleStepThree = (e) => {
    e.preventDefault();

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  //  function validateDpr(input_dpr) {
  //    console.log("input_dpr", input_dpr);
  //    var dpr = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  //    return dpr.test(input_dpr);
  //  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      state.investmentRequestDetails.dpr != "" &&
      state.investmentRequestDetails.pitchVideo != ""
    ) {
      console.log("totalCost", state.investmentRequestDetails.totalCost);
      await createWorkOrder(userDetails, state.investmentRequestDetails);
      props.rerender();
    } else {
      setError("Document not Uploaded");
    }
  };

  const createWorkOrder = async (userDetails, investmentRequestDetails) => {
    await store.dispatch(create_workorder_requested());
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    const method = "post";
    const url = `${apiEndpoint}/workorder`;
    const jwt = `${credentials.idToken.jwtToken}`;
    const path = window.location.pathname.split("/");
    const activeOuid = path[1];
    const { activeOuidChain } = store.getState().permission;

    var targetOrg = activeOuidChain[activeOuidChain.length - 2];
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };

    const woDetails = {
      orgType: "biz",
      investmentRequestDetails: investmentRequestDetails,
    };
    const payload = {
      type: "RequestInvestmentWorkorder",
      description:
        "Investment Request Workorder to create a request for investments",
      targetOrg: targetOrg,
      clientOrg: activeOuid,
      requestId: "",
      deadline: "",
      details: woDetails,
    };

    console.log("payload:::::::", JSON.stringify(payload));
    return axios[method](url, payload, axiosHeader)
      .then(async (response) => {
        console.log("Create WorkOrder response : ", response.data);
        console.log(
          "Create WorkOrder response identifier : ",
          response.data.identifier
        );

        var data = JSON.parse(response.data);

        console.log("data.identifier : ", data.identifier);
        console.log("data : ", data);

        await store.dispatch(create_workorder_success(data));
        await store.dispatch(showSnackbar("Investment Requested", "success"));
        console.log("identifier: ", response.data["identifier"]);
        console.log(
          "store.getState().workorder.workorder: ",
          store.getState().workorder.workorder
        );

        // await onFileUpload(attachment, data.identifier);
        await store.dispatch(showSnackbar("Investment Requested", "success"));
      })
      .catch((err) => {
        console.log(
          "RequestInvestmentWorkorder failed, Please contact support : ",
          err
        );
        store.dispatch(create_workorder_errored(err));
      });
  };

  const onInputChange = (event) => {
    const newInvestmentRequestDetails = state.investmentRequestDetails;
    console.log("type : ", event.target.type);

    if (event.target.id) {
      newInvestmentRequestDetails[event.target.id] = event.target.value;
    } else {
      newInvestmentRequestDetails[event.target.name] = event.target.value;
    }
    setState({
      investmentRequestDetails: newInvestmentRequestDetails,
    });
  };

  const onCheckInputChange = (event) => {
    const newInvestmentRequestDetails = state.investmentRequestDetails;
    console.log("type : ", event.target.type);

    if (event.target.type === "checkbox") {
      newInvestmentRequestDetails[event.target.name] = event.target.checked;
      if (!event.target.checked) {
        if (event.target.name === "land") {
          newInvestmentRequestDetails["totalCost"] =
            +state.investmentRequestDetails["totalCost"] -
            +state.investmentRequestDetails["landPurchaseCost"];
          newInvestmentRequestDetails["landPurchaseCost"] = 0;
        }
        if (event.target.name === "machinary") {
          newInvestmentRequestDetails["totalCost"] =
            +state.investmentRequestDetails["totalCost"] -
            +state.investmentRequestDetails["machinaryPurchaseCost"];
          newInvestmentRequestDetails["machinaryPurchaseCost"] = 0;
        }
        if (event.target.name === "capital") {
          newInvestmentRequestDetails["totalCost"] =
            +state.investmentRequestDetails["totalCost"] -
            +state.investmentRequestDetails["workingCapitalCost"];
          newInvestmentRequestDetails["workingCapitalCost"] = 0;
        }
        if (event.target.name === "computers") {
          newInvestmentRequestDetails["totalCost"] =
            +state.investmentRequestDetails["totalCost"] -
            +state.investmentRequestDetails["computerPurchaseCost"];
          newInvestmentRequestDetails["computerPurchaseCost"] = 0;
        }
        if (event.target.name === "other") {
          newInvestmentRequestDetails["totalCost"] =
            +state.investmentRequestDetails["totalCost"] -
            +state.investmentRequestDetails["otherInitialCost"];
          newInvestmentRequestDetails["otherInitialCost"] = 0;
        }
      }
      setState({
        investmentRequestDetails: newInvestmentRequestDetails,
      });
    }
  };

  // const onFileChange = (event) => {
  //   console.log("setFile");
  //   setFile(event.target.files[0]);
  // };

  // const onFileUpload = async (file, workId) => {
  //   console.log(`onFileUpload`);
  //   await store.dispatch(document_upload_requested());
  //   console.log("showUploadLoader", store.getState().document.showUploadLoader);
  //   const { apiEndpoint, credentials } = store.getState().jwt.api;
  //   setUploadFileName(file.name);
  //   if (apiEndpoint && credentials) {
  //     const fileName = file.name;
  //     const type = file.type;
  //     const method = "get";
  //     const jwt = `${credentials.idToken.jwtToken}`;
  //     const url = `${apiEndpoint}/document?getUploadSignedUrl=true&file=${fileName}`;
  //     const axiosHeader = {
  //       headers: {
  //         Authorization: jwt,
  //         "x-ouid": activeOuid,
  //         "x-ouidchain": activeOuidChain,
  //       },
  //     };
  //     return axios[method](url, axiosHeader)
  //       .then(async (response) => {
  //         console.log("signed url get response : ", response);
  //         await uploadFile(response, file, workId);
  //       })
  //       .catch((err) => {
  //         console.log("signed url get failed : ", err);
  //       });
  //   }
  // };

  // const uploadFile = async (signedUrlData, file, workId) => {
  //   console.log(`uploadFile`);
  //   const { apiEndpoint, credentials } = store.getState().jwt.api;
  //   if (apiEndpoint && credentials) {
  //     const method = "put";
  //     const url = signedUrlData.data.uploadURL;
  //     const body = file;
  //     var axiosConfig = {
  //       headers: {
  //         "Content-type": file.type,
  //       },
  //     };

  //     return axios[method](url, body, axiosConfig)
  //       .then(async (response) => {
  //         console.log("s3 upload response cc : ", response);
  //         var payload = {
  //           name: file.name,
  //           title: file.name,
  //           key: signedUrlData.data.Key,
  //           workId: workId,
  //         };
  //         console.log("documet payload: ", payload);

  //         await store.dispatch(document_upload_success());
  //         await store.dispatch(create_document(payload));

  //         return;
  //       })
  //       .catch((err) => {
  //         store.dispatch(document_upload_errored());
  //         console.log("s3 upload failed : ", err);
  //       });
  //   }
  // };

  const onAmountInputChange = (event) => {
    const newInvestmentRequestDetails = state.investmentRequestDetails;
    console.log("type : ", event.target.type);
    event.target.value = event.target.value.replace(/[^0-9]/g, "");

    if (event.target.type === "checkbox") {
      newInvestmentRequestDetails[event.target.name] = event.target.checked;
      if (!event.target.checked) {
        if (event.target.name === "land") {
          console.log(
            "old Total: ",
            state.investmentRequestDetails["totalCost"]
          );
          console.log(
            "old land cost: ",
            state.investmentRequestDetails["landPurchaseCost"]
          );
          newInvestmentRequestDetails["totalCost"] =
            +state.investmentRequestDetails["totalCost"] -
            +state.investmentRequestDetails["landPurchaseCost"];
          console.log("new Total ", newInvestmentRequestDetails["totalCost"]);
          newInvestmentRequestDetails["landPurchaseCost"] = 0;
        }
      }
      setState({
        investmentRequestDetails: newInvestmentRequestDetails,
      });
      return;
    }

    console.log("oldValue: ", state.investmentRequestDetails[event.target.id]);
    console.log("newValue: ", event.target.value);
    console.log("oldTotal: ", state.investmentRequestDetails["totalCost"]);
    const diff =
      +event.target.value - +state.investmentRequestDetails[event.target.id];
    console.log("diff: ", diff);
    var newTotalCost = state.investmentRequestDetails["totalCost"] + diff;
    console.log("newTotalCost: ", newTotalCost);

    if (event.target.id) {
      newInvestmentRequestDetails[event.target.id] = event.target.value;
    } else {
      newInvestmentRequestDetails[event.target.name] = event.target.value;
    }

    newInvestmentRequestDetails["totalCost"] = newTotalCost;

    setState({
      investmentRequestDetails: newInvestmentRequestDetails,
    });
  };

  const handleDprDelete = (event) => {
    event.preventDefault();
    console.log("Handle delete");
    // var dUrl = new URL(state.investmentRequestDetails.dpr);
    // var dKey = dUrl.pathname;
    // dKey = dKey.slice(1);
    // console.log("dKey", dKey);
    // dispatch(delete_file(dKey, activeOuid));
    setState({
      ...state,
      investmentRequestDetails: {
        ...state.investmentRequestDetails,
        dpr: "",
      },
    });
  };

  const handlePitchVideoDelete = (event) => {
    event.preventDefault();
    console.log("Handle delete");
    // var dUrl = new URL(state.investmentRequestDetails.pitchVideo);
    // var dKey = dUrl.pathname;
    // dKey = dKey.slice(1);
    // console.log("dKey", dKey);
    // dispatch(delete_file(dKey, activeOuid));
    setState({
      ...state,
      investmentRequestDetails: {
        ...state.investmentRequestDetails,
        pitchVideo: "",
      },
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <form onSubmit={handleStepOne}>
            {error && <div className="form_error">{error}</div>}
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="businessName"
                label="Business Name"
                placeholder="Business Name"
                value={state.investmentRequestDetails.businessName || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="natureofBusiness"
                label="Nature of Business"
                placeholder="Example - Retail Shop, Bakery, Wholesale "
                value={state.investmentRequestDetails.natureofBusiness || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="industry">Industry</InputLabel>
                <Select
                  labelId="industry"
                  id="industry"
                  name="industry"
                  value={state.investmentRequestDetails.industry || ""}
                  onChange={onInputChange}
                  label="Industry"
                  className={classes.input}
                  required
                >
                  <MenuItem value={"Manufacturing"}>Manufacturing</MenuItem>
                  <MenuItem value={"Service"}>Service</MenuItem>
                  <MenuItem value={"Trading"}>Trading</MenuItem>
                  <MenuItem value={"Agriculture"}>Agriculture</MenuItem>
                  <MenuItem value={"Education"}>Education</MenuItem>
                  <MenuItem value={"Information Technology"}>
                    Information Technology
                  </MenuItem>

                  <MenuItem value={"Construction"}>Construction</MenuItem>
                  <MenuItem value={"Entertainment"}>Entertainment</MenuItem>
                  <MenuItem value={"Healthcare"}>Healthcare</MenuItem>
                  <MenuItem value={"Legal Services"}>Legal Services</MenuItem>
                  <MenuItem value={"Financial Services"}>
                    Financial Services
                  </MenuItem>
                  <MenuItem value={"Distribution"}>Distribution</MenuItem>
                  <MenuItem value={"Real Estate"}>Real Estate</MenuItem>
                  <MenuItem value={"Travel and Tourism"}>
                    Travel and Tourism
                  </MenuItem>
                  <MenuItem value={"Food"}>Food</MenuItem>
                  <MenuItem value={"Technology services"}>
                    Technology services
                  </MenuItem>

                  <MenuItem value={"E Commerce"}>E Commerce</MenuItem>
                  <MenuItem value={"Gaming"}>Gaming</MenuItem>
                  <MenuItem value={"Miscellaneous"}>Miscellaneous</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="business-type">Type Of Entity</InputLabel>
                <Select
                  labelId="entityType"
                  id="entityType"
                  name="entityType"
                  value={state.investmentRequestDetails.entityType || ""}
                  onChange={onInputChange}
                  label="Type Of Entity"
                  className={classes.input}
                  required
                >
                  <MenuItem value={"Company"}>Limited Company</MenuItem>
                  <MenuItem value={"Partnership"}>Partnership</MenuItem>
                  <MenuItem value={"Proprietorship"}>Proprietorship</MenuItem>
                  <MenuItem value={"LLP"}>LLP</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="address"
                label="Business Address"
                placeholder="Business Address"
                value={state.investmentRequestDetails.address || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="pincode"
                label="Pincode"
                placeholder="Pincode"
                value={state.investmentRequestDetails.pincode || ""}
                onChange={onInputChange}
                variant="outlined"
                required
                inputProps={{
                  maxLength: 6,
                  minLength: 6,
                  pattern: "\\d{6}",
                }}
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="phoneNumber"
                label="Phone Number"
                placeholder="Phone Number"
                value={state.investmentRequestDetails.phoneNumber || ""}
                onChange={onInputChange}
                variant="outlined"
                required
                // type="number"
                // type="tel"
                inputProps={{
                  maxLength: 12,
                  inputMode: "tel",
                  // pattern: "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/",
                }}
              />
            </div>
            <div className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="business-type">Years in business</InputLabel>
                <Select
                  labelId="yearsInBusiness"
                  id="yearsInBusiness"
                  name="yearsInBusiness"
                  value={state.investmentRequestDetails.yearsInBusiness || ""}
                  onChange={onInputChange}
                  label="Years In Business"
                  className={classes.input}
                  required
                >
                  <MenuItem value={"0"}>Just started</MenuItem>
                  <MenuItem value={"1"}>1 year</MenuItem>
                  <MenuItem value={"2"}>2 years</MenuItem>
                  <MenuItem value={"3"}>3 years</MenuItem>
                  <MenuItem value={"4"}>4 years</MenuItem>
                  <MenuItem value={"5"}>5 years</MenuItem>
                  <MenuItem value={"6+"}>Above 5 years</MenuItem>
                </Select>
              </FormControl>
              <div className={classes.buttonWrapper}>                
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleStepOne}
                  type="submit"
                  className={classes.button}
                >
                  Next
                </Button>
              </div>
            </div>
          </form>
        );
      case 1:
        return (
          <form onSubmit={handleStepTwo}>
            {error && <div className="form_error">{error}</div>}
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="numberOfStaffs"
                type="number"
                label="Number Of Staffs"
                placeholder="Number Of Staffs"
                value={state.investmentRequestDetails.numberOfStaffs || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="websiteAddress"
                label="Website Address"
                placeholder="Website Address"
                value={state.investmentRequestDetails.websiteAddress || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="primaryOwnerName"
                label="Name Of The Primary Owner"
                placeholder="Name of the owner"
                value={state.investmentRequestDetails.primaryOwnerName || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="primaryOwnerGender">Gender</InputLabel>
                <Select
                  labelId="primaryOwnerGender"
                  id="primaryOwnerGender"
                  name="primaryOwnerGender"
                  required
                  value={
                    state.investmentRequestDetails.primaryOwnerGender || ""
                  }
                  onChange={onInputChange}
                  label="Gender"
                  className={classes.input}
                >
                  <MenuItem value={"Female"}>Female</MenuItem>
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="primaryOwnerAddress"
                label="Primary Owner's Address"
                placeholder="Primary Owner's Address"
                value={state.investmentRequestDetails.primaryOwnerAddress || ""}
                onChange={onInputChange}
                variant="outlined"
                required
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="primaryOwnerPhone"
                label="Primary Owner's Contact Phone"
                placeholder="Primary Owner's Phone"
                value={state.investmentRequestDetails.primaryOwnerPhone || ""}
                onChange={onInputChange}
                variant="outlined"
                required
                inputProps={{
                  maxLength: 12,
                  inputMode: "tel",
                  // pattern: "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/",
                }}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                // onClick={handleStepTwo}
                className={classes.button}
              >
                Next
              </Button>
            </div>
          </form>
        );
      case 2:
        return (
          <form onSubmit={handleStepThree}>
            <Typography gutterBottom>Project Investments</Typography>

            <div className="form-item-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.investmentRequestDetails.land}
                    onChange={onCheckInputChange}
                    name="land"
                    id="land"
                  />
                }
                label="Land"
              />

              <Collapse in={state.investmentRequestDetails.land}>
                <TextField
                  id="landPurchaseCost"
                  name="landPurchaseCost"
                  value={state.investmentRequestDetails.landPurchaseCost}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </div>
            <div className="form-item-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.investmentRequestDetails.building}
                    onChange={onCheckInputChange}
                    name="building"
                  />
                }
                label="Building, Interior Work etc."
              />
              <Collapse in={state.investmentRequestDetails.building}>
                <TextField
                  id="buildingPurchaseCost"
                  name="buildingPurchaseCost"
                  value={state.investmentRequestDetails.buildingPurchaseCost}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </div>
            <div className="form-item-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.investmentRequestDetails.machinary}
                    onChange={onCheckInputChange}
                    name="machinary"
                  />
                }
                label="Machinery and Equipments"
              />
              <Collapse in={state.investmentRequestDetails.machinary}>
                <TextField
                  id="machinaryPurchaseCost"
                  name="machinaryPurchaseCost"
                  value={state.investmentRequestDetails.machinaryPurchaseCost}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </div>
            <div className="form-item-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.investmentRequestDetails.computers}
                    onChange={onCheckInputChange}
                    name="computers"
                  />
                }
                label="Mobile Devices, Computers, Routers, Printers etc."
              />
              <Collapse in={state.investmentRequestDetails.computers}>
                <TextField
                  id="computerPurchaseCost"
                  name="computerPurchaseCost"
                  value={state.investmentRequestDetails.computerPurchaseCost}
                  label="Purchase Cost (Rs)"
                  placeholder="Purchase Cost (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </div>
            <div className="form-item-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.investmentRequestDetails.other}
                    onChange={onCheckInputChange}
                    name="other"
                  />
                }
                label="Other Initial Expenses"
              />
              <Collapse in={state.investmentRequestDetails.other}>
                <TextField
                  id="otherInitialCost"
                  name="otherInitialCost"
                  value={state.investmentRequestDetails.otherInitialCost}
                  label="Other Initial Costs  (Rs)"
                  placeholder="Other Initial Costs(Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </div>
            <div className="form-item-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.investmentRequestDetails.capital}
                    onChange={onCheckInputChange}
                    name="capital"
                  />
                }
                label="Working capital needed"
              />
              <Collapse in={state.investmentRequestDetails.capital}>
                <TextField
                  id="workingCapitalCost"
                  name="workingCapitalCost"
                  value={state.investmentRequestDetails.workingCapitalCost}
                  label="Working Capital Needed  (Rs)"
                  placeholder="Working Capital Needed (Rs)"
                  onChange={onAmountInputChange}
                  variant="outlined"
                />
              </Collapse>
            </div>
            <Typography className={classes.instructions}>
              Total Project Investment &nbsp; &nbsp;&nbsp;&nbsp;
              <strong>
                Rs.&nbsp; {state.investmentRequestDetails.totalCost}
              </strong>
            </Typography>

            {/* <FormHelperText>
              *Investment Amount can be changed later.
            </FormHelperText> */}
            <div className={classes.buttonWrapper}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                // onClick={handleStepThree}
                type="submit"
                className={classes.button}
              >
                Next
              </Button>
            </div>
          </form>
        );
      case 3:
        return (
          <form onSubmit={handleSubmit}>
            {error && <div className="form_error">{error}</div>}
            <div className="form-item-wrapper">
              {/* <input type="file" onChange={onFileChange} id="fileInput" /> */}
              <div className="image_input_wrap">
                <FormHelperText>Upload Your DPR</FormHelperText>
                <div>&nbsp;</div>

                {state.investmentRequestDetails.dpr && (
                  <>
                    <img className="file_input_img_preview" src={fileIcon} />
                    <button
                      className="img_delete_btn"
                      onClick={handleDprDelete}
                    >
                      <CloseIcon />
                    </button>
                  </>
                )}
                {state.investmentRequestDetails.dpr === "" && (
                  <>
                    <FormDocumentUploader
                      data="dpr"
                      activeOuid={activeOuid}
                      activeOuidChain={activeOuidChain}
                      defaultState={state.upload}
                      handleUploadChange={handleUploadChange}
                    />
                    <div>&nbsp;</div>
                  </>
                )}
              </div>
            </div>
            <div className="form-item-wrapper">
              <FormHelperText>Upload Your Pitch Video</FormHelperText>
              <div>&nbsp;</div>
              {/* <input type="file" onChange={onFileChange} id="fileInput" /> */}
              {state.investmentRequestDetails.pitchVideo && (
                <>
                  <img className="file_input_img_preview" src={fileIcon} />
                  <button
                    className="img_delete_btn"
                    onClick={handlePitchVideoDelete}
                  >
                    <CloseIcon />
                  </button>
                </>
              )}
              {state.investmentRequestDetails.pitchVideo === "" && (
                <>
                  <FormDocumentUploader
                    data="pitchVideo"
                    activeOuid={activeOuid}
                    activeOuidChain={activeOuidChain}
                    defaultState={state.upload}
                    handleUploadChange={handleUploadChange}
                  />
                  <div>&nbsp;</div>
                </>
              )}
            </div>
            <div>&nbsp;</div>
            <div className={classes.buttonWrapper}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleSubmit}
                  type="submit"
                  className={classes.button}
                >
                  Submit
                </Button>
              )}
            </div>
          </form>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re Submitted
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
          </div>
        )}
      </div>

      {/* {JSON.stringify(state.businessDetails)}
            {JSON.stringify(userDetails)} */}
    </div>
  );
}
