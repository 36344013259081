import axios from 'axios';
import {
  CREATE_POST_CATEGORY_REQUESTED,
  CREATE_POST_CATEGORY_SUCCESS,
  CREATE_POST_CATEGORY_ERRORED,
  GET_ALL_POST_CATEGORIES_BY_TYPE_REQUESTED,
  GET_ALL_POST_CATEGORIES_BY_TYPE_SUCCESS,
  GET_ALL_POST_CATEGORIES_BY_TYPE_ERRORED,
  UPDATE_POST_CATEGORY_REQUESTED,
  UPDATE_POST_CATEGORY_SUCCESS,
  UPDATE_POST_CATEGORY_ERRORED,
  GET_POST_CATEGORY_REQUESTED,
  GET_POST_CATEGORY_SUCCESS,
  GET_POST_CATEGORY_ERRORED,
} from '../constant/action-types';
import { showSnackbar } from './action-snackbar';
import configureStore from '../store/configureStore';

const store = configureStore();

export const create_post_category_requested = () => ({
  type: CREATE_POST_CATEGORY_REQUESTED,
});

export const create_post_category_success = () => ({
  type: CREATE_POST_CATEGORY_SUCCESS,
});

export const create_post_category_errored = (error) => ({
  type: CREATE_POST_CATEGORY_ERRORED,
  error,
});


export const get_all_post_categories_by_type_requested = () => ({
  type: GET_ALL_POST_CATEGORIES_BY_TYPE_REQUESTED,
});

export const get_all_post_categories_by_type_success = (categories) => ({
  type: GET_ALL_POST_CATEGORIES_BY_TYPE_SUCCESS,
  categories,
});

export const get_all_post_categories_by_type_errored = (error) => ({
  type: GET_ALL_POST_CATEGORIES_BY_TYPE_ERRORED,
  error,
});

export const update_post_category_requested = () => ({
  type: UPDATE_POST_CATEGORY_REQUESTED,
});

export const update_post_category_success = () => ({
  type: UPDATE_POST_CATEGORY_SUCCESS,
});

export const update_post_category_errored = (error) => ({
  type: UPDATE_POST_CATEGORY_ERRORED,
  error,
});

export const get_post_category_requested = () => ({
  type: GET_POST_CATEGORY_REQUESTED,
});

export const get_post_category_success = (category) => ({
  type: GET_POST_CATEGORY_SUCCESS,
  category,
});

export const get_post_category_errored = (error) => ({
  type: GET_POST_CATEGORY_ERRORED,
  error,
});

export const create_post_category = (payload) => async (dispatch, getState) => {
  dispatch(create_post_category_requested());

  const path = window.location.pathname.split('/');
  const activeOuid = path[1];
  const { activeOuidChain } = getState().permission;
  const { apiEndpoint, credentials } = getState().jwt.api;

  if (apiEndpoint && credentials) {
    const jwt = credentials.idToken.jwtToken;
    const url = `${apiEndpoint}/postcategory`;
    const method = 'post';

    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid,
        'x-ouidchain': activeOuidChain,
      },
    };

    try {
      const response = await axios[method](url, payload, axiosHeader);
      dispatch(create_post_category_success());
      dispatch(showSnackbar('Post category created successfully', 'success'));
      return response.data;
    } catch (error) {
      console.error('Post category creation failed:', error);
      dispatch(create_post_category_errored(error));
      dispatch(showSnackbar('Post category creation failed', 'error'));
      throw error;
    }
  } else {
    console.error('API endpoint or credentials not available');
    dispatch(create_post_category_errored('API endpoint or credentials not available'));
    dispatch(showSnackbar('Post category creation failed', 'error'));
    return null;
  }
};

export const getAllPostCategoriesByType = (postType, activeOuid, activeOuidChain) => async (dispatch) => {
  dispatch(get_all_post_categories_by_type_requested());
  
  const { apiEndpoint, credentials } = store.getState().jwt.api;

  if (apiEndpoint && credentials) {
    const jwt = credentials.idToken.jwtToken;
    const url = `${apiEndpoint}/postcategory?ouid=${activeOuid}&postType=${postType}`;
    
    const method = 'get';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid,
        'x-ouidchain': activeOuidChain,
      },
    };

    try {
      const response = await axios[method](url, axiosHeader);
      console.log('API response:', response.data.items); // Log the API response
      dispatch(get_all_post_categories_by_type_success(response.data));
      return response.data;
    } catch (error) {
      console.error('Get All Post Categories By Type Failed:', error);
      if (error.response) {
        const data = error.response.data;
        dispatch(showSnackbar(data.error, 'error'));
      } else {
        dispatch(showSnackbar('Get All Post Categories By Type Failed', 'error'));
      }
      dispatch(get_all_post_categories_by_type_errored(error));
      throw error;
    }
  } else {
    console.error('API endpoint or credentials not available');
    dispatch(get_all_post_categories_by_type_errored('API endpoint or credentials not available'));
    dispatch(showSnackbar('Fetching post categories by type failed', 'error'));
    throw new Error('API endpoint or credentials not available');
  }
};

export const update_post_category = (payload) => async (dispatch, getState) => {
  dispatch(update_post_category_requested());

  const path = window.location.pathname.split('/');
  const activeOuid = path[1];
  const { activeOuidChain } = getState().permission;
  const id = path[3];
  const { apiEndpoint, credentials } = getState().jwt.api;

  if (apiEndpoint && credentials) {
    const jwt = credentials.idToken.jwtToken;
    const url = `${apiEndpoint}/postcategory/${id}?ouid=${activeOuid}`;
    const method = 'put';

    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid,
        'x-ouidchain': activeOuidChain,
      },
    };
console.log("payload",payload);
    try {
      const response = await axios[method](url, payload, axiosHeader);
      dispatch(update_post_category_success());
      dispatch(showSnackbar('Post category updated successfully', 'success'));
      return response.data;
    } catch (error) {
      console.error('Post category update failed:', error);
      dispatch(update_post_category_errored(error));
      dispatch(showSnackbar('Post category update failed', 'error'));
      throw error;
    }
  } else {
    console.error('API endpoint or credentials not available');
    dispatch(update_post_category_errored('API endpoint or credentials not available'));
    dispatch(showSnackbar('Post category update failed', 'error'));
    return null;
  }
};


export const get_post_category = (id) => async (dispatch, getState) => {
  dispatch(get_post_category_requested());

  const { apiEndpoint, credentials } = store.getState().jwt.api;

  if (apiEndpoint && credentials) {
    const jwt = credentials.idToken.jwtToken;
    const url = `${apiEndpoint}/postcategory/${id}`;
    const method = 'get';

    const axiosHeader = {
      headers: {
        Authorization: jwt,
      },
    };

    try {
      const response = await axios[method](url, axiosHeader);
      dispatch(get_post_category_success(response.data));
      return response.data;
    } catch (error) {
      console.error('Get post category failed:', error);
      if (error.response) {
        const data = error.response.data;
        dispatch(showSnackbar(data.error, 'error'));
      } else {
        dispatch(showSnackbar('Get post category failed', 'error'));
      }
      dispatch(get_post_category_errored(error));
      throw error;
    }
  } else {
    console.error('API endpoint or credentials not available');
    dispatch(get_post_category_errored('API endpoint or credentials not available'));
    dispatch(showSnackbar('Fetching post category failed', 'error'));
    throw new Error('API endpoint or credentials not available');
  }
};