import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import "./App.css";

import Orgunit from "./components/pages/Orgunit/Orgunit";
import RegisterOrgunit from "./components/pages/Orgunit/RegisterOrgunit";
import EditOrgunitDetails from "./components/pages/Orgunit/EditOrgunitDetails";
import OrgunitDetails from "./components/pages/Orgunit/OrgunitDetails";
import MyCPBusinesses from "./components/pages/InvestorPages/MyCPBusinesses";

import OrgUsers from "./components/pages/UserManagement/OrgUsers";
import OrgUserDetails from "./components/pages/UserManagement/OrgUserDetails";
import Users from "./components/pages/UserManagement/Users";
import CreateUser from "./components/pages/UserManagement/CreateUser";
import UserDetails from "./components/pages/UserManagement/UserDetails";
import EditUser from "./components/pages/UserManagement/EditUser";
import CategoryPage from "./components/pages/InfoPortalCategory/CategoryPage";
import { Auth } from "aws-amplify";
import LogIn from "./components/auth/LogIn";
import Register from "./components/auth/Register";
import RegisterInvestor from "./components/auth/RegisterInvestor";
import RegisterCP from "./components/auth/RegisterCP";

import ForgotPassword from "./components/auth/ForgotPassword";
import ForgotPasswordVerification from "./components/auth/ForgotPasswordVerification";
import ChangePassword from "./components/auth/ChangePassword";
import ChangePasswordConfirm from "./components/auth/ChangePasswordConfirm";

import Home from "./components/pages/Home";
import Dashboard from "./components/pages/Dashboard";

import MyProfile from "./components/pages/MyProfile";
import Products from "./components/Products";
import ProductAdmin from "./components/ProductAdmin";
import Welcome from "./components/auth/Welcome";

import ThankYouPage from "./components/pages/ThankYouPage";
import PageNotFound from "./components/pages/PageNotFound";
import { setJwt } from "../src/actions/action-jwt";
import { setAllPermissions } from "../src/actions/action-permissions";
import { history } from "./reducers";
import jwt from "jwt-decode";
import Help from "./components/pages/Help";

import UserHome from "./components/pages/UserHome";
import InvestorUserHome from "./components/pages/InvestorUserHome";
import CpUserHome from "./components/pages/CpUserHome";

import Notifications from "./components/pages/Notification/Notifications";
import PermissionError from "./components/pages/PermissionError";

import {
  userRoleCheck,
  permissionsCheck,
  getParentChain,
} from "../src/components/common/CommonFunctions";
import configureStore from "../src/store/configureStore";
import config from "../src/config";
import EditOrgUser from "./components/pages/UserManagement/EditOrgUser";

import {
  setActiveOuid,
  setActiveOuidChain,
} from "../src/actions/action-permissions";
import { setActiveOiudConfigs } from "../src/actions/action-config";
import CreateOrgUser from "./components/pages/UserManagement/CreateOrgUser";
import OrgPlan from "./components/pages/Orgunit/OrgPlan";
import Teams from "./components/pages/Team/Teams";
import RegisterTeam from "./components/pages/Team/RegisterTeam";
import TeamDetails from "./components/pages/Team/TeamDetails";
import EditTeamDetails from "./components/pages/Team/EditTeamDetails";
import TeamBoard from "./components/pages/Team/TeamBoard";
import CreateWork from "./components/pages/Work/CreateWork";
import CreateTeamUser from "./components/pages/UserManagement/CreateTeamUser";
import TeamUserDetails from "./components/pages/UserManagement/TeamUserDetails";
import EditTeamUser from "./components/pages/UserManagement/EditTeamUser";
import DocumentBoard from "./components/pages/Document/DocumentBoard";

import BookingDash from "./components/pages/Booking/BookingDash";
import AppointmentDash from "./components/pages/Booking/AppointmentDash";
import BookingDetails from "./components/pages/Booking/BookingDetails";
import CreateBooking from "./components/pages/Booking/CreateBooking";

import LoanRequestDash from "./components/pages/RequestForms/LoanRequestDash";
import CreateLoanRequest from "./components/pages/RequestForms/CreateLoanRequest";

import InvestRequestDash from "./components/pages/RequestForms/InvestRequestDash";
import DprRequestDash from "./components/pages/RequestForms/DprRequestDash";

import CreateInvestRequest from "./components/pages/RequestForms/CreateInvestRequest";
import CreateDprRequest from "./components/pages/RequestForms/CreateDprRequest";

import PostDash from "./components/pages/Post/PostDash";
import GovtSchemeDash from "./components/pages/Post/GovtSchemeDash";
import CourseDash from "./components/pages/Post/CourseDash";
import FaqDash from "./components/pages/Post/FaqDash";
import FaqDetails from "./components/pages/Post/FaqDetails";
import GovtSchemeDetails from "./components/pages/Post/GovtSchemeDetails";
import Faq from "./components/pages/UserPage/Faq";
import Slate from "./components/pages/UserPage/Slate";

import PostDetails from "./components/pages/Post/PostDetails";
import CourseDetails from "./components/pages/Post/CourseDetails";
import Quiz from "./components/pages/Post/Quiz";

import CreatePost from "./components/pages/Post/CreatePost";

import OrgWorkQueueBoard from "./components/pages/Orgunit/OrgWorkQueueBoard";
import ConfigDash from "./components/pages/Config/ConfigDash";
import SalesPro from "./components/pages/Apps/SalesPro/SalesPro";
import TicketApp from "./components/pages/Apps/TicketApp/TicketApp";
import HrmApp from "./components/pages/Apps/HrmApp/HrmApp";
import ApprovalTasks from "./components/pages/Apps/ApprovalTasks/ApprovalTasks";
import { get_profile_details } from "../src/actions/action-myProfile";

import OrgHome from "./components/pages/Orgunit/OrgHome";
import Support from "./components/pages/UserPage/Support";
import CompanyProfile from "./components/pages/UserPage/CompanyProfile";

import RegistartionCompleted from "./components/pages/UserPage/RegistartionCompleted";
import CreateCourse from "./components/pages/Post/CreateCourse";
import CreateFaq from "./components/pages/Post/CreateFaq";

import CreateGovtScheme from "./components/pages/Post/CreateGovtScheme";
import GovtScheme from "./components/pages/UserPage/GovtScheme";
import GovtSchemeCategoryDash from "./components/pages/Post/GovtSchemeCategoryDash";
import CreateGovtSchemeCategory from "./components/pages/Post/CreateGovtSchemeCategory";
import EditGovtSchemeCategory from "./components/pages/Post/EditGovtSchemeCategory";

import UserGovtSchemeDetails from "./components/pages/UserPage/UserGovtSchemeDetails";
import InvReqWqDashboard from "./components/pages/Apps/Investments/InvReqWqDashboard";
import LoanReqWqDashboard from "./components/pages/Apps/Loans/LoanReqWqDashboard";
import DprReqWqDashboard from "./components/pages/Apps/DPRs/DprReqWqDashboard";
import ChannelPartnerMapping from "./components/pages/Orgunit/ChannelPartnerMapping";
import MapOrgUnit from "./components/pages/Orgunit/MapOrgUnit";
import InvReqWqDashboardForInv from "./components/pages/Apps/Investments/InvReqWqDashboardForInv";
import RegisterIncubator from "./components/auth/RegisterIncubator";
import IncubatorUserHome from "./components/pages/IncubatorUserHome";
import RootUserHome from "./components/pages/RootUserHome";
import InfoPortalDashboard from "./components/pages/InfoPortalDashboard/InfoPortalDashboard";
import UserGovtSchemeDetailsNew from "./components/pages/UserPage/UserGovtSchemeDetailsNew";
import Loader from "./components/common/Loader";

const store = configureStore();

class App extends Component {
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
  };

  setAuthStatus = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  async componentDidMount() {  
    try {
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);
      const { setJwt, get_profile_details } = this.props;
      await setJwt(user);
      await get_profile_details(user.username);  
      const idToken = user.signInUserSession.idToken;
      const decodedToken = jwt(idToken.jwtToken);
      const path = window.location.pathname.split("/");
      const urlOuid = path[1];
      await store.dispatch(setActiveOuid(urlOuid));
      if (decodedToken.policies) {
        const policies = decodedToken.policies.split(";");
        let teamPolicies = [];
        if (decodedToken.teamPolicies !== "")
          teamPolicies = decodedToken.teamPolicies.split(";");
        let appPolicies = [];
        if (decodedToken.appPolicies !== "")
          appPolicies = decodedToken.appPolicies.split(";");
        const allPolicy = {
          policies,
          teamPolicies,
          appPolicies,
        };
        const { setAllPermissions } = this.props;
        await setAllPermissions(allPolicy);
        const userRole = await userRoleCheck(policies);
        if (!urlOuid) {
          const { userRoles } = store.getState().permission;          
          await store.dispatch(setActiveOuid(userRoles[0].ouid));
          await store.dispatch(setActiveOuidChain(userRoles[0].ouid));
          await store.dispatch(setActiveOiudConfigs(userRoles[0].ouid));
        } else {          
          await store.dispatch(setActiveOuidChain(urlOuid));
          await store.dispatch(setActiveOiudConfigs(urlOuid));
        }
        if (this.state.redirection) {
          this.props.history.push(this.state.redirection);
        } else {
          const activeOuid = store.getState().permission.activeOuid;
        }
      } else {
        this.props.history.push(`/system/user-home`);
      }
    } 
    catch (error) {
      if (error !== "No current user") {
      }
    }
    this.setState({ isAuthenticating: false });
  }

  async refreshJwt() {
    try {
      let intervalId = setInterval(async () => {
        const session = await Auth.currentSession();
        this.setAuthStatus(true);
        const user = await Auth.currentAuthenticatedUser();
        this.setUser(user);
        const { setJwt } = this.props;
        await setJwt(user);
      }, 60000);
      this.setState({ intervalId: intervalId });
    } catch (e) {
      console.log("setInterval error :", e);
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
    };
    const { userRoles } = store.getState().permission;
    return (
      
        <div className="App">
          {!this.state.isAuthenticating ? (
          <Router history={history}>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Home {...props} auth={authProps} />}
              />              
              <Route
                exact
                path="/login"
                render={(props) => <LogIn {...props} auth={authProps} />}
              />

              <Route
                exact
                path="/admin"
                render={(props) => <ProductAdmin {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/login"
                render={(props) => <LogIn {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/register"
                render={(props) => <Register {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/register-investor"
                render={(props) => (
                  <RegisterInvestor {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/register-incubator"
                render={(props) => (
                  <RegisterIncubator {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/register-cp"
                render={(props) => <RegisterCP {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/forgotpassword"
                render={(props) => (
                  <ForgotPassword {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/forgotpasswordverification"
                render={(props) => (
                  <ForgotPasswordVerification {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/changepassword"
                render={(props) => (
                  <ChangePassword {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/changepasswordconfirmation"
                render={(props) => (
                  <ChangePasswordConfirm {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/welcome"
                render={(props) => <Welcome {...props} auth={authProps} />}
              />
              
              <Route
                exact
                path="/:id/register-orgunit"
                render={(props) =>
                  permissionsCheck(userRoles, "CreateOrgunit", "orgunit") ? (
                    <RegisterOrgunit {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/orgunits"
                render={(props) =>
                  permissionsCheck(userRoles, "ListOrgunit", "orgunit") ? (
                    <Orgunit {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/mapcp"
                render={(props) =>
                  permissionsCheck(userRoles, "ListOrgunit", "orgunit") ? (
                    <ChannelPartnerMapping {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />

              <Route
                exact
                path="/:id/orgunit/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "ViewOrgunit", "orgunit") ? (
                    <OrgunitDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/edit-orgunit/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "EditOrgunit", "orgunit") ? (
                    <EditOrgunitDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/mapcp/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "EditOrgunit", "orgunit") ? (
                    <MapOrgUnit {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/orgplan"
                render={(props) =>
                  permissionsCheck(userRoles, "OrgPlan", "orgunit") ? (
                    <OrgPlan {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/org-workqueue-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "OrgWorkQueue", "orgunit") ? (
                    <OrgWorkQueueBoard {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "OrgHome", "orgunit") ? (
                    <OrgHome {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              
              <Route
                exact
                path="/:id/apps/salespro"
                render={(props) =>
                  permissionsCheck(userRoles, "Application", "orgunit") ? (
                    <SalesPro {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/apps/ticketapp"
                render={(props) =>
                  permissionsCheck(userRoles, "Application", "orgunit") ? (
                    <TicketApp {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/apps/approvals"
                render={(props) =>
                  permissionsCheck(userRoles, "Application", "orgunit") ? (
                    <ApprovalTasks {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/apps/hrmapp"
                render={(props) =>
                  permissionsCheck(userRoles, "Application", "orgunit") ? (
                    <HrmApp {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/investreq-wq-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "Application", "work") ? (
                    <InvReqWqDashboard {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/investreq-wq-dash-inv"
                render={(props) =>
                  permissionsCheck(userRoles, "Application", "work") ? (
                    <InvReqWqDashboardForInv {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/businesses-inv"
                render={(props) =>
                  permissionsCheck(userRoles, "Application", "work") ? (
                    <MyCPBusinesses {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />

              <Route
                exact
                path="/:id/loanreq-wq-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "Application", "work") ? (
                    <LoanReqWqDashboard {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/dprreq-wq-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "Application", "work") ? (
                    <DprReqWqDashboard {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              
              <Route
                exact
                path="/:id/teams"
                render={(props) =>
                  permissionsCheck(userRoles, "ListTeam", "team") ? (
                    <Teams {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/register-team"
                render={(props) =>
                  permissionsCheck(userRoles, "ListTeam", "team") ? (
                    <RegisterTeam {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/team/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "ViewTeam", "team") ? (
                    <TeamDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/edit-team/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "EditTeam", "team") ? (
                    <EditTeamDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/team/:id/board"
                render={(props) =>
                  permissionsCheck(userRoles, "TeamBoard", "team") ? (
                    <TeamBoard {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              
              <Route
                exact
                path="/:id/create-work"
                render={(props) =>
                  permissionsCheck(userRoles, "TeamBoard", "team") ? (
                    <CreateWork {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              
              <Route
                exact
                path="/:id/document-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "DocumentBoard", "document") ? (
                    <DocumentBoard {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />

              <Route
                exact
                path="/:id/appointment-dash"
                render={(props) =>
                  permissionsCheck(
                    userRoles,
                    "AppointmentDash",
                    "appointment"
                  ) ? (
                    <AppointmentDash {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/booking-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "BookingDash", "booking") ? (
                    <BookingDash {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/booking/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "BookingDetails", "booking") ? (
                    <BookingDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/create-booking"
                render={(props) =>
                  permissionsCheck(userRoles, "CreateBooking", "booking") ? (
                    <CreateBooking {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />

              <Route
                exact
                path="/:id/loanreq-dash"
                render={(props) =>
                  permissionsCheck(
                    userRoles,
                    "LoanRequestDash",
                    "workorder"
                  ) ? (
                    <LoanRequestDash {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/create-loanreq"
                render={(props) =>
                  permissionsCheck(
                    userRoles,
                    "CreateLoanRequest",
                    "workorder"
                  ) ? (
                    <CreateLoanRequest {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/investreq-dash"
                render={(props) =>
                  permissionsCheck(
                    userRoles,
                    "InvestRequestDash",
                    "workorder"
                  ) ? (
                    <InvestRequestDash {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/dprreq-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "DprRequestDash", "workorder") ? (
                    <DprRequestDash {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />

              <Route
                exact
                path="/:id/create-investreq"
                render={(props) =>
                  permissionsCheck(
                    userRoles,
                    "CreateInvestRequest",
                    "workorder"
                  ) ? (
                    <CreateInvestRequest {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/create-dprreq"
                render={(props) =>
                  permissionsCheck(
                    userRoles,
                    "CreateDprRequest",
                    "workorder"
                  ) ? (
                    <CreateDprRequest {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />

              <Route
                exact
                path="/:id/post-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "PostDash", "post") ? (
                    <PostDash {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/govtscheme-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "GovtSchemeDash", "post") ? (
                    <GovtSchemeDash {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/govtscheme-category-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "GovtSchemeCategoryDash", "post") ? (
                    <GovtSchemeCategoryDash {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />              
              <Route
                exact
                path="/:id/user-govtscheme"
                render={(props) =>
                  permissionsCheck(userRoles, "InfoPortalDashboard", "post") ? (
                    <InfoPortalDashboard {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/course-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "CourseDash", "post") ? (
                    <CourseDash {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              
              <Route
                exact
                path="/:id/faq-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "FaqDash", "post") ? (
                    <FaqDash {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/faq"
                render={(props) =>
                  permissionsCheck(userRoles, "Faq", "post") ? (
                    <Faq {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/Slate"
                render={(props) =>
                  permissionsCheck(userRoles, "Faq", "post") ? (
                    <Slate {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/faq/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "FaqDetails", "post") ? (
                    <FaqDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/govtscheme/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "GovtSchemeDetails", "post") ? (
                    <GovtSchemeDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />              
              <Route
                exact
                path="/:id/user-govtscheme/:id"
                render={(props) =>
                  permissionsCheck(
                    userRoles,
                    "UserGovtSchemeDetailsNew",
                    "post"
                  ) ? (
                    <UserGovtSchemeDetailsNew {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />

              <Route
                exact
                path="/:id/inforportalCategory/:id"
                render={(props) =>
                  permissionsCheck(
                    userRoles,
                    "CategoryPage",
                    "post"
                  ) ? (
                    <CategoryPage {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />

              <Route
                exact
                path="/:id/post/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "PostDetails", "post") ? (
                    <PostDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/create-post"
                render={(props) =>
                  permissionsCheck(userRoles, "CreatePost", "post") ? (
                    <CreatePost {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/create-course"
                render={(props) =>
                  permissionsCheck(userRoles, "CreateCourse", "post") ? (
                    <CreateCourse {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/create-faq"
                render={(props) =>
                  permissionsCheck(userRoles, "CreateCourse", "post") ? (
                    <CreateFaq {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/create-govtscheme"
                render={(props) =>
                  permissionsCheck(userRoles, "CreateGovtScheme", "post") ? (
                    <CreateGovtScheme {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/create-post-category"
                render={(props) =>
                  permissionsCheck(userRoles, "CreateGovtSchemeCategory", "post") ? (
                    <CreateGovtSchemeCategory {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/edit-govtscheme/:categoryId"
                render={(props) =>
                  permissionsCheck(userRoles, "EditGovtScheme", "put") ? (
                    <EditGovtSchemeCategory {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/course/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "CourseDetails", "post") ? (
                    <CourseDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/quiz/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "Quiz", "post") ? (
                    <Quiz {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />

              <Route
                exact
                path="/:id/config-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "ConfigDash", "config") ? (
                    <ConfigDash {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              
              <Route
                exact
                path="/:id/orgusers"
                render={(props) =>
                  permissionsCheck(userRoles, "ListOrgUsers", "orgUser") ? (
                    <OrgUsers {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/orguser/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "ViewOrgUser", "orgUser") ? (
                    <OrgUserDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/edit-orguser/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "EditOrgUser", "orgUser") ? (
                    <EditOrgUser {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/users"
                render={(props) =>
                  permissionsCheck(userRoles, "ListUsers", "authUser") ? (
                    <Users {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/create-user"
                render={(props) =>
                  permissionsCheck(userRoles, "CreateUser", "authUser") ? (
                    <CreateUser {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/create-org-user"
                render={(props) =>
                  permissionsCheck(userRoles, "CreateUser", "authUser") ? (
                    <CreateOrgUser {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/user/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "ViewUser", "authUser") ? (
                    <UserDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/edit-user/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "EditUser", "authUser") ? (
                    <EditUser {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/user-home"
                render={(props) => <UserHome {...props} auth={authProps} />}
              />

              <Route
                exact
                path="/:id/inv-user-home"
                render={(props) => (
                  <InvestorUserHome {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/:id/incubator-user-home"
                render={(props) => (
                  <IncubatorUserHome {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/:id/cp-user-home"
                render={(props) => <CpUserHome {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/:id/root-user-home"
                render={(props) => <RootUserHome {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/:id/create-team-user/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "CreateTeamUser", "teamUser") ? (
                    <CreateTeamUser {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/team/:id/teamuser/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "ViewTeamUser", "teamUser") ? (
                    <TeamUserDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/team/:id/edit-teamuser/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "ViewTeamUser", "teamUser") ? (
                    <EditTeamUser {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/user-support"
                render={(props) => <Support {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/:id/company-profile"
                render={(props) => (
                  <CompanyProfile {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/:id/registration-completed"
                render={(props) => (
                  <RegistartionCompleted {...props} auth={authProps} />
                )}
              />
              
              <Route
                exact
                path="/:id/notifications"
                render={(props) => (
                  <Notifications {...props} auth={authProps} />
                )}
              />

              <Route
                exact
                path="/:id/dashboard"
                render={(props) =>
                  permissionsCheck(
                    userRoles,
                    "ViewDashboard",
                    "commonPages"
                  ) ? (
                    <Dashboard {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/my-profile"
                render={(props) => <MyProfile {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/help"
                render={(props) => <Help {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/products"
                render={(props) => <Products {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/thankyou"
                render={(props) => <ThankYouPage {...props} auth={authProps} />}
              />              
              <Route
                exact
                path="/:id/InfoPortalDashboard"
                render={(props) => <InfoPortalDashboard {...props} auth={authProps}/>}
              />
              <Route exact path="/permission-error" render={(props) => <PermissionError {...props} auth={authProps} />} />
              <Route exact path="/404" render={(props) => <PageNotFound />} />
              <Redirect from="*" to={"/404"} />
            </Switch>
          </Router> ) : (
          <Loader /> 
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  setJwt: (user) => dispatch(setJwt(user)),
  setAllPermissions: (policies) => dispatch(setAllPermissions(policies)),
  get_profile_details: (username) => dispatch(get_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
