import React, { Component } from "react";
import { connect } from "react-redux";
import { update_post } from "../../../actions/action-post";
import configureStore from "../../../store/configureStore";
import { get_post } from "../../../actions/action-post";
import Loader from "../../common/Loader";
import SidebarWrapper from "../../layout/SidebarWrapper";
import CommonDelete from "../../common/CommonDelete";
import { timeFormat } from "../../common/CommonFunctions";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { Paper } from "@material-ui/core";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from "../../../reducers";
import { Auth } from "aws-amplify";
import FormErrors from "../../FormErrors";
import Input from "@material-ui/core/Input";
import FormButton from "../../common/FormButton";
import Validate from "../../utility/FormValidation";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import HeaderInner from "../../layout/HeaderInner";
import DashboardFooter from "../../layout/DashboardFooter";



const store = configureStore();

class UserGovtSchemeDetails extends Component {
  constructor() {
    super();
    this.state = {
      postDetails: null,
      categoriesToshow: ["ChannelPartner", "Business", "Investor"],
    };
    EditorState.createEmpty();
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {
    // store.dispatch(hideSnackbar());
  }

  clearErrorState = () => {
    this.setState({
      errors: {},
    });
  };

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("id : ", id);
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const { get_post } = this.props;
    await get_post(activeOuid, activeOuidChain, id);
    console.log("store.getState().post.post[0] : ", store.getState().post.post);
    this.setState({
      postDetails: store.getState().post.post,
    });
    if (store.getState().post.post.data.content) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(store.getState().post.post.data.content)
        ),
      });
    } else {
      this.setState({
        editorState: EditorState.createEmpty(),
      });
    }
  }

  render() {
    const { showGlobalLoader } = store.getState().post;
    const { permissions } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    return (
      <div>
        <HeaderInner />
        <div className="page MainAppPage">
          {/* <SidebarWrapper auth={this.props.auth} permissions={permissions} /> */}
          <div className="section">
            {showGlobalLoader ? (
              <Loader />
            ) : (
              <div className="List">
                {this.state.postDetails
                  ? this.renderPostDetails(this.state.postDetails)
                  : "No Posts Details..."}
              </div>
            )}
          </div>
        </div>
        <DashboardFooter />
      </div>
    );
  }

  renderPostDetails(postDetails) {
    console.log("postDetails : ", JSON.stringify(postDetails));
    console.log("this.state.postDetails : ", this.state.postDetails);

    return (
      <div className="container">
        <Paper className="paper-class">
          <img
            src={this.state.postDetails.data.featuredImage}
            className="postImage"
          />
          <div className="formContainer">
            <h1>{this.state.postDetails.data.title}</h1>
            <div className="form-item-wrapper">
              {this.state.editorState ? (
                <Editor
                  editorState={this.state.editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="userEditorWrapper"
                  editorClassName="editorClassName"
                  readOnly={true}
                  toolbarHidden={true}
                />
              ) : (
                ""
              )}
            </div>
            <div className="form-item-wrapper">
              {this.state.editorState ? (
                <div>Category: {this.state.postDetails.data.category}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  post: state.post,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_post: (activeOuid, activeOuidChain, id) =>
    dispatch(get_post(activeOuid, activeOuidChain, id)),
  update_post: (payload) => dispatch(update_post(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserGovtSchemeDetails);
