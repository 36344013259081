import React, { Component } from "react";
import { connect } from "react-redux";
import SidebarWrapper from "../layout/SidebarWrapper";
import Footer from "../layout/Footer";
import configureStore from "../../store/configureStore";
import RegisterFormCp from "./CpPages/RegisterFormCp";
import CpRegisteredUser from "./CpPages/CpRegisteredUser";
import { history } from "../../reducers";
import CommonSnackBar from "../common/CommonSnackBar";
import Header from "../layout/Header";
import { Link } from "react-router-dom";
import Meetings from "../assets/images/ibcImages/round-table(cp).png";
import scheme from "../assets/images/ibcImages/scheme.png";
import certification from "../assets/images/ibcImages/certification.png";
import question from "../assets/images/ibcImages/question.png";
import channelPartner from "../assets/images/ibcImages/channelPartner.png";
import inv from "../assets/images/ibcImages/investment(cp).png";
import loan from "../assets/images/ibcImages/money-bag(cp).png";
import dpr from "../assets/images/ibcImages/dpReport.png";

const store = configureStore();

class RootUserHome extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    console.log("componentDidMount  : ");
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
  }

  render() {
    const { permissions } = store.getState().permission;
    const { details } = store.getState().myProfile;
    const { activeOuid, activeOuidChain } = store.getState().permission;

    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <div className="rootDash is-primary container--narrow">
          <div className="w_box mb mt">
            {/* <div className="DashboardCategoryTitle">services</div> */}
            <div className="AppRootServiceWrap">
              <Link
                to={`/${activeOuid}/booking-dash`}
                className="AppRootListItem"
              >
                <div className="rootItemIcon">
                  <img src={Meetings} />
                </div>
                <p className="AppLabel">Meetings</p>
              </Link>
              <Link
                to={`/${activeOuid}/govtscheme-dash`}
                className="AppRootListItem"
              >
                <div className="rootItemIcon">
                  <img src={scheme} />
                </div>
                <p className="AppLabel">Govt Scheme</p>
              </Link>
              <Link
                to={`/${activeOuid}/course-dash`}
                className="AppRootListItem"
              >
                <div className="rootItemIcon">
                  <img src={certification} />
                </div>
                <p className="AppLabel">Certification</p>
              </Link>
              <Link to={`/${activeOuid}/faq-dash`} className="AppRootListItem">
                <div className="rootItemIcon">
                  <img src={question} />
                </div>
                <p className="AppLabel">FAQ</p>
              </Link>
              <Link to={`/${activeOuid}/mapcp`} className="AppRootListItem">
                <div className="rootItemIcon">
                  <img src={channelPartner} />
                </div>
                <p className="AppLabel">Channel Partner</p>
              </Link>
              <Link
                to={`/${activeOuid}/investreq-wq-dash`}
                className="AppRootListItem"
              >
                <div className="rootItemIcon">
                  <img src={inv} />
                </div>
                <p className="AppLabel">Investment Request</p>
              </Link>
              <Link
                to={`/${activeOuid}/loanreq-wq-dash`}
                className="AppRootListItem"
              >
                <div className="rootItemIcon">
                  <img src={loan} />
                </div>
                <p className="AppLabel">Loan Request</p>
              </Link>
              <Link
                to={`/${activeOuid}/dprreq-wq-dash`}
                className="AppRootListItem"
              >
                <div className="rootItemIcon">
                  <img src={dpr} />
                </div>
                <p className="AppLabel">DPR Request </p>
              </Link>
            </div>
          </div>
        </div>

        <CommonSnackBar />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  myProfile: state.myProfile,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RootUserHome);
