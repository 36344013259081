import React, { Component } from 'react';
import { connect } from 'react-redux';
import SidebarBiz from '../layout/SidebarBiz';
import SidebarInv from '../layout/SidebarInv';
import SidebarCP from '../layout/SidebarCP';
import Sidebar from '../layout/Sidebar';
import SidebarRoot from '../layout/SidebarRoot';
import SidebarSystem from '../layout/SidebarSystem';
import Header from '../layout/Header';


import Footer from '../layout/Footer';
import configureStore from '../../store/configureStore';
import { history } from '../../reducers';
import CommonSnackBar from '../common/CommonSnackBar';
import HeaderInv from './HeaderInv';
import HeaderBiz from './HeaderBiz';

const store = configureStore();

class SidebarWrapper extends Component {
    constructor() {
        super();
        this.state = {
        };
    }
    componentDidMount() {
        
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
        const { activeOuid, userRoles, policies, activeOuidType } = store.getState().permission;

        console.log("componentDidMount activeOuidType  : ", activeOuidType);
        console.log("this.props  : ", this.props);
        console.log("store.getState().permission  : ", store.getState().permission);

        const { activeOuidCoreConfigs } = store.getState().config;
        const { session } = store.getState().jwt.api.userSession;
    }



    render() {
        const { activeOuidType } = this.props.permissions.activeOuidType;
        const { permissions } = this.props.permissions;
        console.log("Sidebar Wrapper render this.activeOuidType  : ", this.props.permissions.activeOuidType);
        console.log("this.props in render  : ", this.props.permissions.activeOuidType);


        return (
            <div className="SidebarWrapper">
                <div className="container">
                    {this.renderSidebar()}
                </div>
            </div >
        )
    }

    renderSidebar() {
        const { activeOuid, userRoles, policies, activeOuidType } = store.getState().permission;
        console.log("Sidebar Wrapper this.activeOuidType  : ", this.props.permissions.activeOuidType);

        if (this.props.permissions.activeOuidType === "biz") {
            return <HeaderBiz auth={this.props.auth}/>;
        }
        else if (this.props.permissions.activeOuidType === "inv") {
            return <HeaderInv
                auth={this.props.auth}
                permissions={this.props.permissions}
            />;
        }
        else if (this.props.permissions.activeOuidType === "cp") {
            return <SidebarCP
                auth={this.props.auth}
                permissions={this.props.permissions}
            />;
        }
        else if (this.props.permissions.activeOuidType === "ROOT") {
            return <SidebarRoot
                auth={this.props.auth}
                permissions={this.props.permissions}
            />;
        }
        else if (this.props.permissions.activeOuidType === "SYSTEM") {
            return <SidebarSystem
                auth={this.props.auth}
                permissions={this.props.permissions}
            />;
        }
        return <Header auth={this.props.auth}/>;
    }
}

const mapStateToProps = (state) => ({
    permissions: state.permission,
    jwt: state.jwt,
    config: state.config,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarWrapper);